import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { selectDarkMode } from 'reducers/ui';

import styles from './index.module.css';

export default () => {
  const darkMode = useSelector(selectDarkMode);
  const classes = classNames({ 'bp4-dark': darkMode });

  const isActiveClass = ({ isActive }: { isActive: boolean }) => {
    return isActive ? styles.activeNavMenuItem : undefined;
  };

  return (
    <div className={classes}>
      <ul className={styles.navMenu}>
        <li>
          <NavLink to="/" className={isActiveClass} end>Setups</NavLink>
          <ul className={styles.subMenu}>
            <li>
              <NavLink to="/setups/fields" className={isActiveClass}>Setup Fields</NavLink>
            </li>
            <li>
              <NavLink to="/setups/suits" className={isActiveClass}>SUITs</NavLink>
            </li>
          </ul>
        </li>
        <li>
          <NavLink to="/runs" className={isActiveClass} end>Runs</NavLink>
          <ul className={styles.subMenu}>
            <li>
              <NavLink to="/runs/compare" className={isActiveClass}>Run Compare</NavLink>
            </li>
            <li>
              <NavLink to="/runs/fields" className={isActiveClass}>Run Fields</NavLink>
            </li>
            <li>
              <NavLink to="/runs/ruits" className={isActiveClass}>RUITs</NavLink>
            </li>
          </ul>
        </li>
        <li>
          <NavLink to="/sims" className={isActiveClass} end>Sims</NavLink>
          <ul className={styles.subMenu}>
            <li>
              <NavLink to="/sims/reporting" className={isActiveClass}>Reporting</NavLink>
            </li>
            <li>
              <NavLink to="/sims/documents" className={isActiveClass}>Documents</NavLink>
            </li>
            <li>
              <NavLink to="/sim-worksheets" className={isActiveClass}>Worksheets</NavLink>
            </li>
            <li>
              <NavLink to="/sims/calcs" className={isActiveClass}>Calculations</NavLink>
            </li>
          </ul>
        </li>
        <li>
          <NavLink to="/environments" className={isActiveClass} end>Environments</NavLink>
        </li>
        <li>
          <NavLink to="/parts" className={isActiveClass} end>Parts</NavLink>
          <ul className={styles.subMenu}>
            <li>
              <NavLink to="/parts/properties" className={isActiveClass}>Properties</NavLink>
            </li>
            <li>
              <NavLink to="/parts/configs" className={isActiveClass}>Configs</NavLink>
            </li>
            <li>
              <NavLink to="/parts/lifing" className={isActiveClass}>Lifing</NavLink>
            </li>
          </ul>
        </li>
        {/* <li>
          <NavLink to="/admin" className={isActiveClass} end>Admin</NavLink>
          <ul className={styles.subMenu}>
            <li>
              <NavLink to="/admin/permissions" className={isActiveClass}>Permissions</NavLink>
            </li>
          </ul>
        </li> */}
      </ul>
    </div>
  );
};
