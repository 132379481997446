import React, { useEffect, useMemo, useState } from 'react';
import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getExpandedRowModel,
  PaginationState,
  Row,
  SortingState,
  Table,
  useReactTable,
  createColumnHelper,
  CellContext,
  ExpandedState,
  VisibilityState,
} from '@tanstack/react-table';
import { Button, Checkbox, Divider, H4, Intent, Menu, MenuItem, NonIdealState, Switch } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { isEmpty, isEqual, isNil, range, toPairs, get } from 'lodash';
import jsonPatch from 'fast-json-patch';

import ColumnFilterPopover from './ColumnFilterPopover';
import Select from 'components/Select';
import { TableRowAction, SelectItem } from 'types';

import styles from './index.module.css';

export type RowSelections = { [k: number]: boolean };
export type BulkRowAction<TData> = SelectItem<(rows: Row<TData>[]) => void>;
export type BulkRowActions<TData> = BulkRowAction<TData>[];
export type RowAction<TData> = TableRowAction<Row<TData>>;
export type RowActions<TData> = RowAction<TData>[];
export type ParamsChangeFn = (filters: ColumnFiltersState, pagination: PaginationState, sorting: SortingState) => Promise<void>;

const HeaderSelectCheckbox = <TData, >({ table }: { table: Table<TData> }) => (
  <Checkbox
    checked={table.getIsAllRowsSelected()}
    className={styles.rowSelectCheckbox}
    indeterminate={table.getIsSomeRowsSelected()}
    onChange={table.getToggleAllRowsSelectedHandler()}
  />
);
const RowSelectCheckbox = <TData, >({ row }: { row: Row<TData> }) => (
  <Checkbox
    checked={row.getIsSelected()}
    className={styles.rowSelectCheckbox}
    disabled={!row.getCanSelect()}
    indeterminate={row.getIsSomeSelected()}
    onChange={row.getToggleSelectedHandler()}
  />
);
const RowSingleSelectCheckbox = <TData, >({ row, table }: { row: Row<TData>, table: Table<TData> }) => (
  <Checkbox
    checked={row.getIsSelected()}
    className={styles.rowSelectCheckbox}
    disabled={!row.getCanSelect()}
    indeterminate={row.getIsSomeSelected()}
    onChange={() => {
      table.toggleAllRowsSelected(false);
      row.toggleSelected();
    }}
  />
);
const RowActionCell = <TData, >({ row }: { row: Row<TData> }, actions: RowActions<TData>) => (
  <Popover2
    content={(
      <Menu>
        {actions.map((action, index) => {
          const menuItemProps: Record<string, unknown> = {
            intent: action.intent,
            key: index,
            onClick: () => action.value(row),
            text: action.label,
          };
          const icon = action.icon?.(row);
          if (icon) {
            menuItemProps.icon = icon;
          }
          return (
            <MenuItem {...menuItemProps} />
          );
        })}
      </Menu>
    )}
    placement="left"
  >
    <Button minimal icon={<FontAwesomeIcon icon="ellipsis-vertical" />} />
  </Popover2>
);

const sortIcons = {
  asc: <FontAwesomeIcon icon="sort-up" />,
  desc: <FontAwesomeIcon icon="sort-down" />,
} as { [k: string]: React.ReactElement };

type ExpandedChange<TData, > = {
  rowData: TData,
  expanded: boolean,
}

interface Props<TData> {
  bulkRowActions?: BulkRowActions<TData>;
  className?: string;
  columns: ColumnDef<TData>[];
  data: TData[];
  enableHiding?: boolean;
  enablePagination?: boolean;
  enableRowSelection?: boolean;
  enableSingleRowSelection?: boolean;
  id?: string;
  manualFiltering?: boolean;
  manualPagination?: boolean;
  manualSorting?: boolean;
  onRowSelect?: (selections: RowSelections) => void;
  onParamsChange?: ParamsChangeFn;
  persistColumnVisibility?: boolean;
  rowActions?: RowActions<TData>;
  rowActionAtStart?: boolean;
  rowsPerPage?: number[];
  totalRowCount?: number;
  getRowCanExpand?: (row: Row<TData>) => boolean;
  onExpandedChange?: (change: ExpandedChange<TData>[]) => void;
  manualExpanding?: boolean;
  getSubRows?: (row: TData, index: number) => TData[];
  enableSubRowSelection?: boolean;
  highlightedRows?: number [];
}

export default <TData, >(props: Props<TData>) => {
  /**
   * Selections
   */
  const [rowSelection, setRowSelection] = useState<RowSelections>({});
  const hasSelections = useMemo(() => {
    return Object.keys(rowSelection).length > 0;
  }, [rowSelection]);

  // Notifies the parent whenever row selection changes in case they want to do
  // something with it outside of `bulkRowActions`
  useEffect(() => {
    props.onRowSelect?.(rowSelection);
  }, [rowSelection]);

  /**
   * Expanding Rows
   */
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const [prevExpanded, setPrevExpanded] = useState<ExpandedState>({});

  /**
   * Pagination
   */
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 15,
  });
  const [totalRowCount, setTotalRowCount] = useState<number>(props.totalRowCount ?? props.data.length);
  useEffect(() => {
    if (!isNil(props.totalRowCount)) setTotalRowCount(props.totalRowCount);
  }, [props.totalRowCount]);
  const pageCount = useMemo(() => {
    return Math.ceil(totalRowCount / pagination.pageSize);
  }, [totalRowCount, pagination]);
  const pageCountStart = useMemo(() => {
    if (pagination.pageIndex === 0) return 1;
    return pagination.pageIndex * pagination.pageSize + 1;
  }, [pagination]);
  const pageCountEnd = useMemo(() => {
    if (pagination.pageIndex === pageCount - 1) return totalRowCount;
    return pageCountStart + pagination.pageSize - 1;
  }, [pagination, pageCount, totalRowCount, pageCountStart]);
  const rowsPerPageItems: SelectItem<number>[] = useMemo(() => {
    if (!props.rowsPerPage) {
      return [
        { label: '15', value: 15 },
        { label: '25', value: 25 },
        { label: '50', value: 50 },
      ];
    }
    return props.rowsPerPage.map(n => ({ label: `${n}`, value: n }));
  }, [props.rowsPerPage]);
  const goToPageItems: SelectItem<number>[] = useMemo(() => {
    return range(1, pageCount + 1).map(n => ({ label: `${n}`, value: n }));
  }, [pageCount]);

  /**
   * Sorting
   */
  const [sorting, setSorting] = useState<SortingState>([]);

  /**
   * Filtering
   */
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  useEffect(() => {
    props.onParamsChange?.(columnFilters, pagination, sorting);
  }, [sorting, columnFilters, pagination]);

  /**
   * Column visibility
   */
  const initialVisState = props.columns.reduce((acc, col) => {
    if (col.meta?.initiallyHidden && col.id) acc[col.id] = false;
    return acc;
  }, {} as VisibilityState);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(initialVisState);
  const renderColumnVisibilityPopoverContent = () => {
    const columns = table.getAllLeafColumns();
    const hidableColumns = columns.filter(c => c.getCanHide());

    return (
      <div className={styles.columnVisibilityContainer}>
        <H4>Visible Columns</H4>
        <ul className={styles.columnVisibilityList}>
          {hidableColumns.map(c => (
            <li>
              <Switch
                checked={c.getIsVisible()}
                className={styles.columnVisibilitySwitch}
                label={c.columnDef.header?.toString()}
                onChange={c.getToggleVisibilityHandler()}
              />
            </li>
          ))}
        </ul>
      </div>
    );
  };

  // Hydrates the column visibility state from local storage if it exists
  useEffect(() => {
    if (props.persistColumnVisibility && !props.id) {
      throw new Error('Table ID required to persist column visibility');
    }

    if (!props.persistColumnVisibility) return;

    const visJSONStr = localStorage.getItem(`table:${props.id}:visibility`);
    if (visJSONStr) {
      try {
        const columnVisState = JSON.parse(visJSONStr);
        setColumnVisibility(columnVisState);
      } catch (e) {
        localStorage.removeItem(`table:${props.id}:visibility`);
      }
    }
  }, []);

  useEffect(() => {
    // When the columns change (most are static, but not e.g. Parts table),
    // checks the meta to see if they should be hidden by default and
    // hides them if they haven't been changed by the user yet
    const visState = props.columns.reduce((acc, col) => {
      if (col.meta?.initiallyHidden && col.id && acc[col.id] === undefined) {
        acc[col.id] = false;
      }
      return acc;
    }, { ...columnVisibility } as VisibilityState);

    // Only sets a new vis state if it hasn't changed; infinite loop if not
    if (!isEqual(visState, columnVisibility)) {
      setColumnVisibility(visState);
    }
  }, [props.columns, columnVisibility]);

  // Persists the column visibility state to local storage, if the flag is
  // enabled and an ID is given.
  //
  // Also skips when column visibility is empty, which happens once on page load.
  useEffect(() => {
    if (!props.persistColumnVisibility || !props.id || isEmpty(columnVisibility)) {
      return;
    }

    const visJSONStr = JSON.stringify(columnVisibility);
    localStorage.setItem(`table:${props.id}:visibility`, visJSONStr);
  }, [columnVisibility, props.persistColumnVisibility, props.id]);

  const hasHiddenColumns = useMemo(() => {
    return toPairs(columnVisibility).some(([, shown]) => !shown);
  }, [columnVisibility]);

  // Adds the expansion column when a row expansion function is provided, the
  // select checkbox column when row selection is enabled, and the row actions
  // column when row actions are provided.
  const columns = useMemo(() => {
    const allColumns = [...props.columns];
    if (!isNil(props.getRowCanExpand)) {
      const columnHelper = createColumnHelper<TData>();
      const makeExpanderCell = (cellProps: CellContext<TData, unknown>) => {
        const { row } = cellProps;
        if (row.getCanExpand()) {
          return (
            <Button {...{
              onClick: row.getToggleExpandedHandler(),
              style: { cursor: 'pointer' },
              minimal: true,
            }}
            >
              {row.getIsExpanded() ? <FontAwesomeIcon icon="angle-down" /> : <FontAwesomeIcon icon="angle-right" />}
            </Button>
          );
        }
        return null;
      };

      allColumns.unshift(columnHelper.display({
        id: 'expander',
        header: () => null,
        cell: makeExpanderCell,
        enableHiding: false,
      }));
    }

    if (props.enableRowSelection) {
      allColumns.unshift({
        id: 'select',
        header: !props.enableSingleRowSelection ? HeaderSelectCheckbox : undefined,
        cell: !props.enableSingleRowSelection ? RowSelectCheckbox : RowSingleSelectCheckbox,
        enableHiding: false,
      });
    }
    if (props.rowActions) {
      const cellData: typeof allColumns[number] = {
        id: 'actions',
        cell: row => RowActionCell(row, props.rowActions!), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        enableHiding: false,
      };
      if (props.rowActionAtStart) {
        allColumns.unshift(cellData);
      } else {
        allColumns.push(cellData);
      }
    }
    return allColumns;
  }, [props.columns, props.enableRowSelection, props.rowActionAtStart, props.enableSingleRowSelection]);

  const table = useReactTable({
    columns,
    data: props.data,
    defaultColumn: {
      enableColumnFilter: false,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableRowSelection: props.enableRowSelection,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: props.getSubRows,
    pageCount,
    manualExpanding: props.manualExpanding,
    manualFiltering: props.manualFiltering,
    manualPagination: props.manualPagination,
    manualSorting: props.manualSorting,
    state: {
      columnFilters,
      columnVisibility,
      pagination,
      rowSelection,
      sorting,
      expanded,
    },
    onExpandedChange: setExpanded,
    getRowCanExpand: props.getRowCanExpand,
    enableSubRowSelection: props.enableSubRowSelection,
  });

  /**
   * Expanding Rows
   */
  useEffect(() => {
    // find new changes from before
    const diff = jsonPatch.compare(prevExpanded, expanded);

    const arr = diff.map(d => {
      const { rows } = table.getPreExpandedRowModel();
      return {
        rowData: rows[Number(d.path.slice(1))].original,
        expanded: d.op !== 'remove',
      };
    });
    props.onExpandedChange?.(arr);
    setPrevExpanded(expanded);
  }, [expanded]);

  const selectedRows = useMemo(() => {
    return table.getSelectedRowModel().rows;
  }, [rowSelection]);

  /**
   * Action handlers
   */
  const onBulkRowActionSelect = (action: BulkRowAction<TData>) => {
    action.value(selectedRows);
  };
  const onRowsPerPageChange = (item: SelectItem<number>) => {
    setPagination({ pageIndex: pagination.pageIndex, pageSize: item.value });
  };
  const goToFirstPage = () => {
    setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
  };
  const goToLastPage = () => {
    setPagination({ pageIndex: pageCount - 1, pageSize: pagination.pageSize });
  };
  const goToPrevPage = () => {
    setPagination({ pageIndex: pagination.pageIndex - 1, pageSize: pagination.pageSize });
  };
  const goToNextPage = () => {
    setPagination({ pageIndex: pagination.pageIndex + 1, pageSize: pagination.pageSize });
  };
  const onGoToPageChange = (item: SelectItem<number>) => {
    setPagination({
      pageIndex: item.value - 1,
      pageSize: pagination.pageSize,
    });
  };

  const tableClasses = classNames(
    'bp4-html-table bp4-compact bp4-html-table-striped',
    styles.table,
    props.className,
  );
  const { rows } = table.getRowModel();
  const highlightIds: number [] = props.highlightedRows || [];
  return (
    <div className={styles.container}>
      {(props.enableRowSelection || props.enableHiding) && (
        <div className={styles.header}>
          {props.enableRowSelection && (
            <div className={styles.bulkRowActionsContainer}>
              <span>{Object.keys(rowSelection).length} selected</span>
              {props.bulkRowActions && (
                <Select
                  disabled={!hasSelections}
                  onChange={onBulkRowActionSelect}
                  items={props.bulkRowActions}
                  selectProps={{ filterable: false }}
                />
              )}
            </div>
          )}
          {props.enableRowSelection && props.enableHiding && <Divider className={styles.headerDivider} />}
          {props.enableHiding && (
            <Popover2
              content={renderColumnVisibilityPopoverContent()}
              popoverClassName={styles.columnVisibilityPopover}
            >
              <Button
                icon="eye-open"
                intent={hasHiddenColumns ? Intent.SUCCESS : Intent.NONE}
                minimal
              />
            </Popover2>
          )}
        </div>
      )}
      <table className={tableClasses}>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                let width;

                if (header.id === 'actions' || header.id === 'select' || header.id === 'expander') {
                  width = '2%';
                } else if (header.getSize() !== 150) {
                  width = header.getSize();
                }

                return (
                  <th key={header.id} style={{ width }}>
                    <div className={styles.headerContents}>
                      <div
                        className={classNames(styles.headerSort, {
                          [styles.headerSortable]: header.column.getCanSort(),
                        })}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        <span>{flexRender(header.column.columnDef.header, header.getContext())}</span>
                        <span>{sortIcons[header.column.getIsSorted() as string] ?? null}</span>
                      </div>
                      {header.column.getCanFilter() && <ColumnFilterPopover column={header.column} />}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {rows.map(row => {
            const rowClass = row.depth > 0 ? styles.subRow : '';
            const isHighlighted = highlightIds.includes(Number(get(row, 'original.id', -1)));
            return (
              <tr key={row.id} className={rowClass} style={{ backgroundColor: isHighlighted ? '#2d72d2' : 'transparent' }}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {rows.length === 0 && (
        <NonIdealState
          className={styles.noResults}
          description="There is no data to display, or the applied filters have no results."
          icon="search"
          title="No results"
        />
      )}
      {props.enablePagination && (
        <div className={styles.paginationFooter}>
          <div className={styles.rowsPerPage}>
            <span>Rows per page:</span>
            <Select
              selectProps={{ filterable: false }}
              items={rowsPerPageItems}
              onChange={onRowsPerPageChange}
              value={{ label: `${pagination.pageSize}`, value: pagination.pageSize }}
            />
          </div>
          {rows.length > 0 && (
            <div className={styles.pageCounts}>
              <span>{pageCountStart} - {pageCountEnd} of {totalRowCount}</span>
            </div>
          )}
          <div className={styles.pageControls}>
            <Button
              disabled={!table.getCanPreviousPage()}
              icon="double-chevron-left"
              minimal
              onClick={goToFirstPage}
            />
            <Button
              disabled={!table.getCanPreviousPage()}
              icon="chevron-left"
              minimal
              onClick={goToPrevPage}
            />
            <span>Page:</span>
            <Select
              disabled={rows.length === 0}
              selectProps={{ filterable: false }}
              items={goToPageItems}
              onChange={onGoToPageChange}
              value={{ label: `${pagination.pageIndex + 1}`, value: pagination.pageIndex + 1 }}
            />
            <Button
              disabled={!table.getCanNextPage()}
              icon="chevron-right"
              minimal
              onClick={goToNextPage}
            />
            <Button
              disabled={!table.getCanNextPage()}
              icon="double-chevron-right"
              minimal
              onClick={goToLastPage}
            />
          </div>
        </div>
      )}
    </div>
  );
};
