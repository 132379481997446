import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Intent, Dialog, DialogBody, DialogFooter, Button, InputGroup } from '@blueprintjs/core';
import { format } from 'date-fns';
import classNames from 'classnames';
import Table, { ParamsChangeFn, RowActions } from 'components/Table';
import { useAlert } from 'components/Alert';
import AppToaster from 'helpers/toaster';
import {
  Environment,
  useDeleteEnvironmentMutation,
  useCloneEnvironmentMutation,
  CreateEnvironmentInput,
  useCreateEnvironmentMutation,
  useEnvironmentsLazyQuery,
} from 'graphql/generated/graphql';
import { selectDarkMode } from 'reducers/ui';
import { debounce, keyBy, mapValues } from 'lodash';
import AddEnvironmentDialog from 'pages/EnvironmentViews/AddEnvironmentDialog';

interface Props {
  isAddEnvironmentDialogOpen: boolean;
  onAddEnvironmentDialogClose: () => void;
}

export default (props: Props) => {
  const { isAddEnvironmentDialogOpen, onAddEnvironmentDialogClose } = props;
  const darkMode = useSelector(selectDarkMode);
  const [tableData, setTableData] = useState<Environment[]>([]);
  const [cloneSource, setCloneSource] = useState<Environment>();
  const [isCloneModalOpen, setCloneModalOpen] = useState(false);
  const cloneName = useRef<HTMLInputElement>(null);
  const alert = useAlert();
  const navigate = useNavigate();

  const [cloneEnvironment] = useCloneEnvironmentMutation();
  const [deleteEnvironment] = useDeleteEnvironmentMutation();
  const [createEnvironment] = useCreateEnvironmentMutation();

  const [getEnvironments, { refetch: refetchEnvironments, data: environmentsData }] = useEnvironmentsLazyQuery({
    onCompleted: data => setTableData(data.environments.rows as Environment[]),
    fetchPolicy: 'cache-and-network',
  });

  const handleCreateEnvironment = (input: CreateEnvironmentInput) => {
    createEnvironment({
      variables: {
        input: {
          ...input,
        },
      },
      onCompleted: () => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Environment successfully created',
        });
        refetchEnvironments();
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Error creating Environment: ${e.message}`,
        });
      },
    });
  };

  const columnHelper = createColumnHelper<Environment>();
  const columns = [
    columnHelper.accessor('name', {
      header: 'Name',
      cell: info => info.getValue(),
      enableColumnFilter: true,
    }),
    columnHelper.accessor('track', {
      header: 'Track',
      cell: info => info.getValue(),
      enableColumnFilter: true,
    }),
    columnHelper.accessor('owner', {
      header: 'Owner',
      cell: info => info.getValue(),
      enableColumnFilter: true,
    }),
    columnHelper.accessor('created_at', {
      header: 'Created',
      cell: info => {
        const value = info.getValue() as string;
        return format(new Date(value), 'MM/dd/yy HH:mm:ss');
      },
    }),
    columnHelper.accessor('updated_at', {
      header: 'Modified',
      cell: info => {
        const value = info.getValue() as string;
        return format(new Date(value), 'MM/dd/yy HH:mm:ss');
      },
    }),
  ] as ColumnDef<Environment>[];

  const rowActions: RowActions<Environment> = [{
    label: 'Edit',
    value: row => {
      navigate(`/environments/${row.original.id}`);
    },
  }, {
    label: 'Clone',
    value: row => {
      setCloneSource(row.original);
      setCloneModalOpen(true);
      cloneName.current?.focus();
    },
  }, {
    intent: Intent.DANGER,
    label: 'Delete',
    value: row => {
      const branchId = row.original.id;
      if (!branchId) return;
      alert.showAlert(`Delete Environment "${row.original.name}?`, {
        intent: Intent.DANGER,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
      }).then((yes) => {
        if (!yes) return;
        deleteEnvironment({
          variables: {
            id: branchId,
          },
          onCompleted: () => {
            AppToaster.show({
              intent: Intent.SUCCESS,
              message: 'Successfully deleted environment',
            });
          },
          onError: e => {
            AppToaster.show({
              intent: Intent.DANGER,
              message: `Failed to delete environment: ${e.message}`,
            });
          },
          refetchQueries: ['Environments'],
        });
      });
    },
  }];

  useEffect(() => {
    getEnvironments();
  }, []);

  const handleClone = () => {
    if (!cloneSource) return;
    cloneEnvironment({
      variables: {
        id: cloneSource.id,
        name: cloneName.current?.value || `${cloneSource.name}@${Date.now()}`,
      },
      onCompleted: () => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Successfully cloned environment',
        });
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Failed to clone environment: ${e.message}`,
        });
      },
      refetchQueries: ['Environments'],
    });
    setCloneModalOpen(false);
  };

  useEffect(() => {
    const onEnter = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleClone();
      }
    };

    if (isCloneModalOpen) {
      document.addEventListener('keydown', onEnter);
    }

    return () => {
      document.removeEventListener('keydown', onEnter);
    };
  }, [isCloneModalOpen]);

  const onTableParamsChange: ParamsChangeFn = async (filters, pagination, sorting) => {
    let sorts = {};
    if (sorting.length > 0) {
      sorts = { [sorting[0].id]: sorting[0].desc ? 'DESC' : 'ASC' };
    }

    getEnvironments({
      variables: {
        input: {
          filters: mapValues(keyBy(filters, 'id'), 'value'),
          pagination: {
            offset: pagination.pageIndex * pagination.pageSize,
            limit: pagination.pageSize,
          },
          sorts,
        },
      },
    });
  };

  const debouncedOnTableParamsChange = debounce(onTableParamsChange, 200);

  return (
    <div>
      <Table
        id="environment-summary"
        columns={columns}
        data={tableData}
        rowActions={rowActions}
        enableHiding
        enablePagination
        manualFiltering
        manualSorting
        manualPagination
        persistColumnVisibility
        onParamsChange={debouncedOnTableParamsChange as ParamsChangeFn}
        totalRowCount={environmentsData?.environments.totalCount || 0}
      />
      <Dialog
        className={classNames({ 'bp4-dark': darkMode })}
        isCloseButtonShown
        isOpen={isCloneModalOpen}
        onClose={() => setCloneModalOpen(false)}
        title={`Cloning from "${cloneSource?.name}"`}
        onOpened={() => cloneName.current?.focus()}
      >
        <DialogBody>
          <InputGroup
            placeholder="Enter new environment name"
            inputRef={cloneName}
            defaultValue={`${cloneSource?.name} CLONE`}
          />
        </DialogBody>
        <DialogFooter
          actions={(
            <Button
              intent="primary"
              text="OK"
              onClick={() => handleClone()}
            />
          )}
        />
      </Dialog>
      <AddEnvironmentDialog
        isOpen={isAddEnvironmentDialogOpen}
        onClose={onAddEnvironmentDialogClose}
        handleCreateEnvironment={handleCreateEnvironment}
      />
    </div>
  );
};
