import { Link } from 'react-router-dom';
import { H3, H4 } from '@blueprintjs/core';
import { chain } from 'lodash';

import PartNavigationMenu from 'components/PartNavigationMenu';
import { usePartManagementQuery } from 'graphql/generated/graphql';

import styles from './index.module.css';

export default () => {
  // Does not use a react-router loader because queries performed in a loader cannot be
  // refetched from another component, and this one s refetched from the part nav
  const { data } = usePartManagementQuery({ fetchPolicy: 'no-cache' });

  const groupedPartConfigs = chain(data?.partConfigs.rows)
    .groupBy(config => config.category.name)
    .toPairs()
    .orderBy([0], ['asc'])
    .value();

  return (
    <div className={styles.container}>
      <PartNavigationMenu />
      <div className={styles.categoriesListContainer}>
        <H3>All Categories</H3>
        {groupedPartConfigs.map(([categoryName, configs]) => {
          const category = data?.partCategories.find(c => c.name === categoryName);
          if (!category) return null;
          return (
            <div key={categoryName}>
              <H4 className={styles.categoryListHeading}>{category.display_name}</H4>
              <ul className={styles.categoryList}>
                {configs.map(config => (
                  <li key={`config-${config.name}`}>
                    <Link
                      className={styles.configLink}
                      to={`category/${category.name}/${config.name}`}
                    >
                      {config.display_name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          );
        })}
      </div>
    </div>
  );
};
