import { FormEvent, useCallback, useEffect, useState, useRef } from 'react';
import {
  Button,
  FormGroup,
  Intent,
  Radio,
  RadioGroup,
  Alignment,
  Checkbox,
  EditableText,
  Tree,
  TreeNodeInfo,
} from '@blueprintjs/core';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import { get, find } from 'lodash';
import {
  useLoaderData,
  unstable_useBlocker,      // eslint-disable-line camelcase
  unstable_BlockerFunction, // eslint-disable-line camelcase
} from 'react-router-dom';

import {
  Sim,
  useUpdateSimMutation,
  UpdateSimInput,
  useSimStatesQuery,
  SimStates,
} from 'graphql/generated/graphql';
import RHFNumericInput from 'components/RHFInputs/NumericInput';
import RHFTextInput from 'components/RHFInputs/TextInput';
import RHFSelect from 'components/RHFInputs/Select';
import styles from '../index.module.css';
import AppToaster from 'helpers/toaster';
import Accordion from 'components/Accordion';
import { SetupSim } from '../types';
import { PermissionName } from '../../../constants';
import { addWarningListener, removeWarningListener } from 'helpers/browserCloseWarning';

interface Props {
  sim: Sim;
}

const nodes: TreeNodeInfo[] = [
  {
    id: 0,
    hasCaret: false,
    label: 'Config',
  },
  {
    id: 1,
    hasCaret: false,
    label: 'Sim States',
  },
  {
    id: 2,
    hasCaret: false,
    label: 'Output',
  },
];

export default (props: Props) => {
  const defaultSetupSim: SetupSim = props.sim;
  const [simStateOptions, setSimStateOptions] = useState<{ label: string, value: number }[]>([]);
  const [simStates, setSimStates] = useState<SimStates[]>([]);
  const userPermissions = useLoaderData() as string[];
  const form = useForm<Partial<SetupSim>>({
    defaultValues: {
      ...defaultSetupSim,
    },
  });
  const { control, watch, setValue, formState: { errors, isDirty }, reset } = form;
  const simStateId = watch('sim_states_id', props.sim.sim_states_id);
  const stateOptionId = watch('state_option_id', props.sim.state_option_id);

  const firstUpdate = useRef(true);

  const [updateSim] = useUpdateSimMutation();

  const handleNodeClick = useCallback(
    (node: TreeNodeInfo) => {
      const sectionElement = document.getElementById(`section-${node.id}`);

      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: 'smooth' });
      }
    },
    [],
  );

  useSimStatesQuery({
    onCompleted: data => {
      setSimStates(data.simStates);
      const stateOptions = data.simStates.map(item => ({ label: item.desc, value: item.id }));
      setSimStateOptions(stateOptions);
    },
  });

  useEffect(() => {
    if (!firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      setValue('state_option_id', null, { shouldDirty: true });
    }
  }, [simStateId]);

  useEffect(() => {
    // Reset the form with default values
    reset({ ...defaultSetupSim });
  }, [defaultSetupSim]);

  useEffect(() => {
    const currentSimStates = find(simStates, (o) => (o.id === simStateId));
    const selectedState = find(currentSimStates?.states, (o) => (o.id === stateOptionId));

    if (selectedState) {
      const tireData = selectedState.tire;
      const fuelData = selectedState.fuel;

      setValue('data.build_lf', tireData?.pressure_build_lf, { shouldDirty: true });
      setValue('data.build_rf', tireData?.pressure_build_rf, { shouldDirty: true });
      setValue('data.build_lr', tireData?.pressure_build_lr, { shouldDirty: true });
      setValue('data.build_rr', tireData?.pressure_build_rr, { shouldDirty: true });
      setValue('data.fuel_burn', fuelData?.burn, { shouldDirty: true });
    }
  }, [stateOptionId]);

  useEffect(() => {
    if (isDirty) {
      addWarningListener();
    } else {
      removeWarningListener();
    }
  }, [isDirty]);

  // Removes the listener when deconstructing this component
  useEffect(() => removeWarningListener, []);

  // eslint-disable-next-line
  const blockerFunc: unstable_BlockerFunction = () => {
    if (isDirty) {
      // eslint-disable-next-line
      return !window.confirm(
        'There are unsaved changes. Navigate away from this view?'
      );
    }
    return false;
  };
  unstable_useBlocker(blockerFunc);

  const onSetupStateChange = (e: FormEvent<HTMLInputElement>) => {
    setValue('data.state', e.currentTarget.value, { shouldDirty: true });
  };

  const onSetupNameChange = (value: string) => {
    setValue('name', value, { shouldDirty: true });
  };

  const onSubmit = (input: Partial<SetupSim>) => {
    // eslint-disable-next-line camelcase, @typescript-eslint/no-unused-vars
    const { drive_file, sim_states, driver, lap_time_data, eight_post,
      ...setup } = input;

    const updatedSimInput: UpdateSimInput = {
      ...setup as SetupSim,
    };
    updateSim({
      variables: {
        input: {
          ...updatedSimInput,
        },
      },
      onCompleted: () => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Sim successfully updated',
        });

        form.reset({ ...input });
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Error updating Sim: ${e.message}`,
        });
      },
    });
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className={styles.titleBar}>
          <div className={styles.titleColumn}>
            <div className={styles.titleLabel}>Setup</div>
            <EditableText
              className={styles.titleValue}
              defaultValue={props.sim.name}
              onChange={value => onSetupNameChange(value)}
              placeholder="Setup"
            />
          </div>
        </div>
        <div className={styles.mainContainer}>
          <div className={styles.nav}>
            <Button
              className={styles.saveButton}
              intent={Intent.PRIMARY}
              type="submit"
              text="Save"
              fill
              disabled={!isDirty}
            />
            <div className={styles.sideNavContainer}>
              <Tree
                contents={nodes}
                onNodeClick={handleNodeClick}
              />
            </div>
          </div>
          <div className={styles.simContainer}>
            <Accordion
              className={styles.itemContainer}
              id="section-0"
              initialOpen
              key="config"
              title="Config"
              buttonProps={{
                className: styles.accordionHeader,
              }}
            >
              <div className={styles.sectionContainer}>
                <div className={styles.inputRow}>
                  <Controller
                    control={control}
                    name="data.state"
                    render={({ field }) => (
                      <RadioGroup
                        inline
                        onChange={(e) => onSetupStateChange(e)}
                        selectedValue={field.value ?? ''}
                        className={styles.flexRow}
                      >
                        <Radio label="Setup State" value="setup" />
                        <Radio label="Simulation State" value="simulation" />
                      </RadioGroup>
                    )}
                  />
                </div>
                <div className={styles.inputRow}>
                  <Controller
                    control={control}
                    name="data.include_driver"
                    render={({ field }) => (
                      <Checkbox
                        label="Include Driver"
                        alignIndicator={Alignment.LEFT}
                        checked={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </div>
                <div className={styles.inputRow}>
                  <Controller
                    control={control}
                    name="data.apply_post_setup_adjustments"
                    render={({ field }) => (
                      <Checkbox
                        label="Apply Post Setup Adjustments"
                        alignIndicator={Alignment.LEFT}
                        checked={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </div>
                <div className={styles.inputRow}>
                  <Controller
                    control={control}
                    name="data.use_shocks_as_droop_limiters"
                    render={({ field }) => (
                      <Checkbox
                        label="Use Shocks as Droop Limiters"
                        alignIndicator={Alignment.LEFT}
                        checked={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </div>
              </div>
            </Accordion>
            <Accordion
              className={styles.itemContainer}
              id="section-1"
              initialOpen
              key="simStates"
              title="Sim States"
              buttonProps={{
                className: styles.accordionHeader,
              }}
            >
              <div className={styles.sectionContainer}>
                <div className={styles.inputRow}>
                  <FormGroup
                    className={classNames(styles.formGroup, styles.flex)}
                    contentClassName={styles.simInput}
                    helperText={<ErrorMessage errors={errors} name="sim_states_id" />}
                    label="Simulation States"
                    labelInfo="(required)"
                    inline
                  >
                    <RHFSelect
                      controllerProps={{
                        control,
                        name: 'sim_states_id',
                        rules: { required: 'Simulation States are required' },
                      }}
                      intent={get(errors, 'sim_states_id') && Intent.DANGER}
                      items={simStateOptions}
                      fill
                    />
                  </FormGroup>
                </div>
                <div className={styles.inputRow}>
                  <FormGroup
                    className={classNames(styles.formGroup, styles.flex)}
                    contentClassName={styles.simInput}
                    helperText={<ErrorMessage errors={errors} name="sim_state_label" />}
                    label="State Option"
                    inline
                  >
                    <RHFSelect
                      controllerProps={{
                        control,
                        name: 'state_option_id',
                      }}
                      intent={get(errors, 'state_option_id') && Intent.DANGER}
                      items={simStates.find(state => state.id === simStateId)?.states.map(item => ({ label: item.label, value: item.id })) ?? []}
                      fill
                    />
                  </FormGroup>
                </div>
                <div className={styles.inputRow}>
                  <FormGroup
                    className={classNames(styles.formGroup, styles.flex)}
                    contentClassName={styles.simInput}
                    label="Build"
                    inline
                  >
                    <div className={styles.gridInputs}>
                      <RHFNumericInput
                        controllerProps={{
                          control,
                          name: 'data.build_lf',
                        }}
                        inputProps={{
                          fill: true,
                          placeholder: 'LF',
                          value: watch('data.build_lf'),
                        }}
                      />
                      <RHFNumericInput
                        controllerProps={{
                          control,
                          name: 'data.build_rf',
                        }}
                        inputProps={{
                          fill: true,
                          placeholder: 'RF',
                          value: watch('data.build_rf'),
                        }}
                      />
                      <RHFNumericInput
                        controllerProps={{
                          control,
                          name: 'data.build_lr',
                        }}
                        inputProps={{
                          fill: true,
                          placeholder: 'LR',
                          value: watch('data.build_lr'),
                        }}
                      />
                      <RHFNumericInput
                        controllerProps={{
                          control,
                          name: 'data.build_rr',
                        }}
                        inputProps={{
                          fill: true,
                          placeholder: 'RR',
                          value: watch('data.build_rr'),
                        }}
                      />
                    </div>
                  </FormGroup>
                </div>
                <div className={styles.inputRow}>
                  <FormGroup
                    className={classNames(styles.formGroup, styles.flex)}
                    contentClassName={styles.simInput}
                    label="Fuel Burn"
                    inline
                  >
                    <RHFNumericInput
                      controllerProps={{
                        control,
                        name: 'data.fuel_burn',
                      }}
                      inputProps={{
                        fill: true,
                        className: styles.inline,
                        value: watch('data.fuel_burn'),
                      }}
                    />
                  </FormGroup>
                </div>
              </div>
            </Accordion>
            <Accordion
              className={styles.itemContainer}
              id="section-2"
              initialOpen
              key="output"
              title="Output"
              buttonProps={{
                className: styles.accordionHeader,
              }}
            >
              <div className={styles.sectionContainer}>
                <div className={styles.inputRow}>
                  <FormGroup
                    className={classNames(styles.formGroup, styles.flex)}
                    contentClassName={styles.simInput}
                    label="Output Directory"
                    inline
                  >
                    <RHFTextInput
                      controllerProps={{
                        control,
                        name: 'output_directory',
                      }}
                      inputProps={{
                        fill: true,
                      }}
                    />
                  </FormGroup>
                </div>
                <div className={styles.inputRow}>
                  <div className={styles.outputCheckboxContainer}>
                    <div className={styles.outputCheckboxName}>JSON</div>
                    <Controller
                      control={control}
                      name="export_json"
                      render={({ field }) => (
                        <Checkbox
                          label="Export"
                          alignIndicator={Alignment.LEFT}
                          checked={field.value || false}
                          onChange={field.onChange}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="download_json"
                      render={({ field }) => (
                        <Checkbox
                          label="Download"
                          alignIndicator={Alignment.LEFT}
                          checked={field.value || false}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </div>
                </div>
                {
                  userPermissions.includes(PermissionName.SIM_SUCCESS_DEBUG) && (
                    <div className={styles.inputRow}>
                      <div className={styles.outputCheckboxContainer}>
                        <div className={styles.outputCheckboxName}>Debug</div>
                        <Controller
                          control={control}
                          name="export_debug"
                          render={({ field }) => (
                            <Checkbox
                              label="Export"
                              alignIndicator={Alignment.LEFT}
                              checked={field.value || false}
                              onChange={field.onChange}
                            />
                          )}
                        />
                        <Controller
                          control={control}
                          name="download_debug"
                          render={({ field }) => (
                            <Checkbox
                              label="Download"
                              alignIndicator={Alignment.LEFT}
                              checked={field.value || false}
                              onChange={field.onChange}
                            />
                          )}
                        />
                      </div>
                    </div>
                  )
                }
              </div>
            </Accordion>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
