import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { FormGroup, Intent } from '@blueprintjs/core';

import IconTooltip from 'components/IconTooltip';
import RHFCheckbox from 'components/RHFInputs/Checkbox';
import RHFNumericInput from 'components/RHFInputs/NumericInput';
import RHFSelect from 'components/RHFInputs/Select';
import RHFTextInput from 'components/RHFInputs/TextInput';
import { usePartConfigFormQuery } from 'graphql/generated/graphql';
import PropertiesTable from './PropertiesTable';

import styles from './index.module.css';

export default () => {
  const { control, formState: { errors } } = useFormContext();
  const { data } = usePartConfigFormQuery({ fetchPolicy: 'no-cache' });

  const partCategoryItems = data?.partCategories.map(category => ({
    label: category.display_name,
    value: category.id,
  }));

  return (
    <>
      <div className={styles.mainForm}>
        <FormGroup
          contentClassName={styles.selectFormGroup}
          helperText={<ErrorMessage errors={errors} name="part_category_id" />}
          label="Category"
          labelInfo="(required)"
        >
          <RHFSelect
            controllerProps={{
              control,
              name: 'part_category_id',
              rules: { required: 'Category is required' },
            }}
            intent={errors.part_category_id && Intent.DANGER}
            items={partCategoryItems ?? []}
          />
        </FormGroup>
        <FormGroup
          helperText={<ErrorMessage errors={errors} name="name" />}
          label="Name"
          labelInfo={(
            <>
              <IconTooltip content="Used in Setup Fields when identifying a part type. String without spaces." />
              <span> (required)</span>
            </>
          )}
        >
          <RHFTextInput
            controllerProps={{
              control,
              name: 'name',
              rules: {
                required: 'Name is required',
                pattern: {
                  value: /^\S*$/,
                  message: 'Name must not contain spaces',
                },
              },
            }}
            inputProps={{
              intent: errors.name && Intent.DANGER,
            }}
          />
        </FormGroup>
        <FormGroup
          helperText={<ErrorMessage errors={errors} name="display_name" />}
          label="Display Name"
          labelInfo="(required)"
        >
          <RHFTextInput
            controllerProps={{
              control,
              name: 'display_name',
              rules: { required: 'Display Name is required' },
            }}
            inputProps={{
              intent: errors.display_name && Intent.DANGER,
            }}
          />
        </FormGroup>
        <FormGroup
          helperText={<ErrorMessage errors={errors} name="mileage_limit" />}
          label="Mileage Limit"
        >
          <RHFNumericInput
            controllerProps={{
              control,
              name: 'mileage_limit',
              rules: {
                min: {
                  value: 1,
                  message: 'Mileage Limit cannot be less than 1',
                },
              },
            }}
            inputProps={{
              fill: true,
              intent: errors.mileage_limit && Intent.DANGER,
            }}
          />
        </FormGroup>
        <FormGroup label="Expires" inline>
          <RHFCheckbox
            controllerProps={{
              name: 'expires',
            }}
          />
        </FormGroup>
      </div>
      <PropertiesTable properties={data?.properties.rows ?? []} />
    </>
  );
};
