import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Button, Dialog, DialogBody, DialogFooter, FormGroup, Intent } from '@blueprintjs/core';
import classNames from 'classnames';

import { propertyTypeSelectItems } from '../../constants';
import IconTooltip from 'components/IconTooltip';
import { RHFNumericInput, RHFSelect, RHFTagInput, RHFTextInput } from 'components/RHFInputs';
import AppToaster from 'helpers/toaster';
import { selectDarkMode } from 'reducers/ui';
import {
  CreatePropertyInput,
  Property,
  PropertyType,
  UpdatePropertyInput,
  useCreatePropertyMutation,
  useUpdatePropertyMutation,
} from 'graphql/generated/graphql';
import { PropertyInput } from 'types';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (property: Property) => void;
  property?: Property;
}

const defaultProperty: PropertyInput = {
  name: '',
  display_name: '',
  description: '',
  type: PropertyType.STRING,
  values: [] as string[],
  unit: '',
};

export default (props: Props) => {
  let defaultValues = defaultProperty;
  if (props.property) {
    defaultValues = {
      ...props.property,
    };
  }
  const form = useForm<PropertyInput>({ defaultValues });
  useEffect(() => {
    form.reset(defaultValues);
  }, [props.property]);
  const name = useWatch({ control: form.control, name: 'name' });
  const min = useWatch({ control: form.control, name: 'min' });
  const max = useWatch({ control: form.control, name: 'max' });
  const type = useWatch({ control: form.control, name: 'type' });
  const darkMode = useSelector(selectDarkMode);

  const isEdit = Boolean(props.property);

  const [createProperty] = useCreatePropertyMutation();
  const [updateProperty] = useUpdatePropertyMutation();

  const { errors } = form.formState;

  const handleCreateProperty = (input: CreatePropertyInput) => {
    createProperty({
      variables: { input },
      onCompleted: data => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: `Part property "${input.name}" successfully created`,
        });
        form.reset(defaultProperty);
        props.onSuccess?.(data.property as Property);
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Error creating part property: ${e.message}`,
        });
      },
    });
  };

  const handleUpdateProperty = (input: UpdatePropertyInput) => {
    updateProperty({
      variables: { input },
      onCompleted: data => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: `Part property "${input.name}" successfully updated`,
        });
        form.reset(defaultProperty);
        props.onSuccess?.(data.property as Property);
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Error updating part property: ${e.message}`,
        });
      },
    });
  };

  const onSubmit = (input: PropertyInput) => {
    if (isEdit) handleUpdateProperty(input as UpdatePropertyInput);
    else handleCreateProperty(input as CreatePropertyInput);
  };

  return (
    <Dialog
      className={classNames({ 'bp4-dark': darkMode })}
      isCloseButtonShown
      isOpen={props.isOpen}
      onClose={() => {
        form.reset(defaultProperty);
        props.onClose();
      }}
      title={`${isEdit ? 'Edit' : 'Create'} Part Property`}
    >
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <DialogBody>
            <FormGroup
              helperText={<ErrorMessage errors={errors} name="name" />}
              intent={errors.name ? Intent.DANGER : Intent.NONE}
              label="Name"
              labelInfo={(
                <>
                  <IconTooltip
                    content={`
                      Used in Part Config when identifying a Part Property.
                      No spaces, typically using underscores in their place,
                      e.g. linear_rate, thread_size, etc
                    `}
                  />
                  <span> (required)</span>
                </>
              )}
            >
              <RHFTextInput
                controllerProps={{
                  // There's a typing issue with RHF's `Control` type not inheriting the proper
                  // type of the form so it's being typed as `any`
                  control: form.control as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                  name: 'name',
                  rules: {
                    required: 'Name is required',
                    pattern: {
                      value: /^\S*$/,
                      message: 'Name must not contain spaces',
                    },
                  },
                }}
                inputProps={{
                  intent: errors.name && Intent.DANGER,
                }}
              />
            </FormGroup>
            <FormGroup
              helperText={<ErrorMessage errors={errors} name="display_name" />}
              intent={errors.display_name ? Intent.DANGER : Intent.NONE}
              label="Display Name"
              labelInfo="(required)"
            >
              <RHFTextInput
                controllerProps={{
                  // There's a typing issue with RHF's `Control` type not inheriting the proper
                  // type of the form so it's being typed as `any`
                  control: form.control as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                  name: 'display_name',
                  rules: {
                    required: 'Display Name is required',
                  },
                }}
                inputProps={{
                  intent: errors.display_name && Intent.DANGER,
                }}
              />
            </FormGroup>
            <FormGroup
              helperText={<ErrorMessage errors={errors} name="description" />}
              intent={errors.description ? Intent.DANGER : Intent.NONE}
              label="Description"
            >
              <RHFTextInput
                controllerProps={{
                  control: form.control as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                  name: 'description',
                }}
                inputProps={{
                  intent: errors.description && Intent.DANGER,
                }}
              />
            </FormGroup>
            <FormGroup
              helperText={<ErrorMessage errors={errors} name="path" />}
              intent={errors.path ? Intent.DANGER : Intent.NONE}
              label="Path"
              labelInfo={(
                <IconTooltip
                  content={`
                    Optional path to the property in the Part's data field.
                    If not specified, the path defaults to this Property's name field.
                  `}
                />
              )}
            >
              <RHFTextInput
                controllerProps={{
                  control: form.control as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                  name: 'path',
                }}
                inputProps={{
                  intent: errors.path && Intent.DANGER,
                  placeholder: name,
                }}
              />
            </FormGroup>
            <FormGroup
              helperText={<ErrorMessage errors={errors} name="type" />}
              intent={errors.type ? Intent.DANGER : Intent.NONE}
              label="Type"
              labelInfo="(required)"
            >
              <RHFSelect
                controllerProps={{
                  control: form.control as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                  name: 'type',
                  rules: { required: 'Type is required' },
                }}
                intent={errors.type && Intent.DANGER}
                items={propertyTypeSelectItems}
              />
            </FormGroup>
            {type === PropertyType.STRING && (
              <FormGroup label="Values">
                <RHFTagInput
                  controllerProps={{
                    control: form.control as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                    name: 'values',
                  }}
                />
              </FormGroup>
            )}
            {type === PropertyType.NUMBER && (
              <>
                <FormGroup
                  helperText={<ErrorMessage errors={errors} name="min" />}
                  intent={errors.min ? Intent.DANGER : Intent.NONE}
                  label="Min"
                >
                  <RHFNumericInput
                    controllerProps={{
                      control: form.control as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                      name: 'min',
                      rules: {
                        validate: {
                          lessThanMax: v => !max || v < max || 'Min must be less than max',
                        },
                      },
                    }}
                    inputProps={{
                      fill: true,
                      intent: errors.min && Intent.DANGER,
                    }}
                  />
                </FormGroup>
                <FormGroup
                  helperText={<ErrorMessage errors={errors} name="max" />}
                  intent={errors.max ? Intent.DANGER : Intent.NONE}
                  label="Max"
                >
                  <RHFNumericInput
                    controllerProps={{
                      control: form.control as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                      name: 'max',
                      rules: {
                        validate: {
                          greaterThanMin: v => !min || v > min || 'Max must be greater than min',
                        },
                      },
                    }}
                    inputProps={{
                      fill: true,
                      intent: errors.max && Intent.DANGER,
                    }}
                  />
                </FormGroup>
              </>
            )}
            {(type === PropertyType.NUMBER || type === PropertyType.STRING) && (
              <FormGroup
                helperText={<ErrorMessage errors={errors} name="unit" />}
                intent={errors.unit ? Intent.DANGER : Intent.NONE}
                label="Unit"
              >
                <RHFTextInput
                  controllerProps={{
                    control: form.control as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                    name: 'unit',
                  }}
                  inputProps={{
                    intent: errors.unit && Intent.DANGER,
                  }}
                />
              </FormGroup>
            )}
          </DialogBody>
          <DialogFooter
            actions={(
              <>
                <Button
                  onClick={props.onClose}
                  text="Cancel"
                />
                <Button
                  intent="primary"
                  text="Save"
                  type="submit"
                />
              </>
            )}
          />
        </form>
      </FormProvider>
    </Dialog>
  );
};
