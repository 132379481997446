import { KeyboardEvent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, H3, Intent } from '@blueprintjs/core';
import { omit } from 'lodash';

import PartConfigForm from 'components/PartConfigForm';
import {
  UpdatePartConfigInput,
  usePartConfigByIdQuery,
  useUpdatePartConfigMutation,
} from 'graphql/generated/graphql';
import AppToaster from 'helpers/toaster';

import styles from './index.module.css';

const defaultPartConfig = {
  name: '',
  display_name: '',
  expires: false,
  properties: [],
};

export default () => {
  const navigate = useNavigate();
  const params = useParams();
  const configId = Number(params.configId);

  const form = useForm<UpdatePartConfigInput>({ defaultValues: defaultPartConfig });

  usePartConfigByIdQuery({
    variables: { id: configId },
    skip: !configId,
    onCompleted: data => {
      if (data?.partConfig) {
        form.reset({
          ...omit(data.partConfig, ['category']),
          part_category_id: data.partConfig.category.id,
        });
      } else {
        navigate('/parts/configs');
      }
    },
  });
  const [updatePartConfig] = useUpdatePartConfigMutation();

  const onSubmit = (input: UpdatePartConfigInput) => {
    updatePartConfig({
      variables: {
        input: {
          ...input,
          properties: input.properties?.map(p => omit(p, 'property')) ?? [],
        },
      },
      onCompleted: () => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Part config successfully updated',
        });
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Error updating part config: ${e.message}`,
        });
      },
    });
  };

  // Prevents submission on Enter:
  // Tag inputs use Enter to "tagify" input which is triggering form submission
  const checkKeyDown = (e: KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter') e.preventDefault();
  };

  return (
    <>
      <H3>Edit Part Config</H3>
      <FormProvider {...form}>
        <form
          className={styles.form}
          onKeyDown={checkKeyDown}
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <PartConfigForm />
          <Button
            className={styles.saveButton}
            icon="floppy-disk"
            intent={Intent.PRIMARY}
            large
            text="Save"
            type="submit"
          />
        </form>
      </FormProvider>
    </>
  );
};
