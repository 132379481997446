import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Intent, Dialog, DialogBody, DialogFooter, Button, InputGroup } from '@blueprintjs/core';
import { format } from 'date-fns';
import classNames from 'classnames';
import Table, { ParamsChangeFn, RowActions } from 'components/Table';
import { useAlert } from 'components/Alert';
import AppToaster from 'helpers/toaster';
import {
  Sim,
  useSimsLazyQuery,
  useDeleteSimMutation,
  useCloneSimMutation,
} from 'graphql/generated/graphql';
import { selectDarkMode } from 'reducers/ui';
import { specSelectItems, simTypeSelectItems } from '../../constants';
import { FilterType } from 'types';
import { debounce, keyBy, mapValues } from 'lodash';

export const getFormattedSimType = (type: string) => {
  switch (type) {
    case 'KINEMATICS': return 'Kinematics';
    case 'SETUP': return 'Setup';
    case 'RIDEMODEL': return 'Ride Model';
    case 'SIMULATOR': return 'Simulator';
    case 'EIGHTPOST': return 'Eight Post';
    case 'ELAP': return 'eLap';
    default: return '';
  }
};

export default () => {
  const darkMode = useSelector(selectDarkMode);
  const [tableData, setTableData] = useState<Sim[]>([]);
  const [cloneSource, setCloneSource] = useState<Sim>();
  const [isCloneModalOpen, setCloneModalOpen] = useState(false);
  const cloneName = useRef<HTMLInputElement>(null);
  const alert = useAlert();
  const navigate = useNavigate();

  const [getSims, { data: simsData }] = useSimsLazyQuery({
    onCompleted: data => setTableData(data.sims.rows as Sim[]),
    fetchPolicy: 'cache-and-network',
  });
  const [cloneSim] = useCloneSimMutation();
  const [deleteSim] = useDeleteSimMutation();

  const columnHelper = createColumnHelper<Sim>();
  const columns = [
    columnHelper.accessor('name', {
      header: 'Name',
      cell: info => info.getValue(),
      enableColumnFilter: true,
    }),
    columnHelper.accessor('spec', {
      header: 'Spec',
      cell: info => info.getValue(),
      enableColumnFilter: true,
      meta: {
        filter: {
          type: FilterType.SELECT,
          selectItems: specSelectItems,
          multiSelect: true,
        },
      },
    }),
    columnHelper.accessor('type', {
      header: 'Type',
      cell: info => {
        const value = info.getValue() as string;
        return getFormattedSimType(value);
      },
      enableColumnFilter: true,
      meta: {
        filter: {
          type: FilterType.SELECT,
          selectItems: simTypeSelectItems,
          multiSelect: true,
        },
      },
    }),
    columnHelper.accessor('owner', {
      header: 'Owner',
      cell: info => info.getValue(),
      enableColumnFilter: true,
    }),
    columnHelper.accessor('created_at', {
      header: 'Created',
      cell: info => {
        const value = info.getValue() as string;
        return format(new Date(value), 'MM/dd/yy HH:mm:ss');
      },
    }),
    columnHelper.accessor('updated_at', {
      header: 'Modified',
      cell: info => {
        const value = info.getValue() as string;
        return format(new Date(value), 'MM/dd/yy HH:mm:ss');
      },
    }),
  ] as ColumnDef<Sim>[];

  const rowActions: RowActions<Sim> = [{
    label: 'Edit',
    value: row => {
      navigate(`/sims/${row.original.id}`);
    },
  }, {
    label: 'Clone',
    value: row => {
      setCloneSource(row.original);
      setCloneModalOpen(true);
      cloneName.current?.focus();
    },
  }, {
    intent: Intent.DANGER,
    label: 'Delete',
    value: row => {
      const branchId = row.original.id;
      if (!branchId) return;
      alert.showAlert(`Delete Sim "${row.original.name}?`, {
        intent: Intent.DANGER,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
      }).then((yes) => {
        if (!yes) return;
        deleteSim({
          variables: {
            id: branchId,
          },
          onCompleted: () => {
            AppToaster.show({
              intent: Intent.SUCCESS,
              message: 'Successfully deleted sim',
            });
          },
          onError: e => {
            AppToaster.show({
              intent: Intent.DANGER,
              message: `Failed to delete sim: ${e.message}`,
            });
          },
          refetchQueries: ['Sims'],
        });
      });
    },
  }];

  useEffect(() => {
    getSims();
  }, []);

  useEffect(() => {
    const onEnter = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleClone();
      }
    };

    if (isCloneModalOpen) {
      document.addEventListener('keydown', onEnter);
    }

    return () => {
      document.removeEventListener('keydown', onEnter);
    };
  }, [isCloneModalOpen]);

  const handleClone = () => {
    if (!cloneSource) return;
    cloneSim({
      variables: {
        id: cloneSource.id,
        name: cloneName.current?.value || `${cloneSource.name}@${Date.now()}`,
      },
      onCompleted: () => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Successfully cloned sim',
        });
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Failed to clone sim: ${e.message}`,
        });
      },
      refetchQueries: ['Sims'],
    });
    setCloneModalOpen(false);
  };

  const onTableParamsChange: ParamsChangeFn = async (filters, pagination, sorting) => {
    let sorts = {};
    if (sorting.length > 0) {
      sorts = { [sorting[0].id]: sorting[0].desc ? 'DESC' : 'ASC' };
    }

    getSims({
      variables: {
        input: {
          filters: mapValues(keyBy(filters, 'id'), 'value'),
          pagination: {
            offset: pagination.pageIndex * pagination.pageSize,
            limit: pagination.pageSize,
          },
          sorts,
        },
      },
    });
  };

  const debouncedOnTableParamsChange = debounce(onTableParamsChange, 200);

  return (
    <div>
      <Table
        id="sim_summary"
        columns={columns}
        data={tableData}
        rowActions={rowActions}
        totalRowCount={simsData?.sims.totalCount || 0}
        enableHiding
        enablePagination
        manualFiltering
        manualPagination
        manualSorting
        persistColumnVisibility
        onParamsChange={debouncedOnTableParamsChange as ParamsChangeFn}
      />
      <Dialog
        className={classNames({ 'bp4-dark': darkMode })}
        isCloseButtonShown
        isOpen={isCloneModalOpen}
        onClose={() => setCloneModalOpen(false)}
        title={`Cloning from "${cloneSource?.name}"`}
        onOpened={() => cloneName.current?.focus()}
      >
        <DialogBody>
          <InputGroup
            placeholder="Enter new sim name"
            inputRef={cloneName}
            defaultValue={`${cloneSource?.name} CLONE`}
          />
        </DialogBody>
        <DialogFooter
          actions={(
            <Button
              intent="primary"
              text="OK"
              onClick={() => handleClone()}
            />
          )}
        />
      </Dialog>
    </div>
  );
};
