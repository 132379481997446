import { Icon, Intent } from '@blueprintjs/core';
import { SimStatus } from 'context/SubscriptionContext';
import React, { useEffect, useState } from 'react';

interface SimProgressTextProps {
  cancelId: string;
  displayName: string;
  vehicle: string;
  status: string;
  curr?: number;
  total?: number;
  simStatus?: SimStatus;
}

const SimProgressText : React.FC<SimProgressTextProps> = (props : SimProgressTextProps) => {
  const { status, cancelId, displayName: simulationName, vehicle: setupName, curr, total, simStatus } = props;
  const [isFailed, setIsFailed] = useState<boolean>(false);
  const [prevCancelId, setPrevCancelId] = useState<string>(cancelId);

  useEffect(() => {
    // Reset when sim ID changes
    if (cancelId !== prevCancelId) {
      setIsFailed(false);
      setPrevCancelId(cancelId);
    }
  }, [cancelId]);

  useEffect(() => {
    if (status === 'Failed' || simStatus?.state === 'failed') {
      setIsFailed(true);
    }
  }, [status, simStatus]);

  return (
    <>
      {`${setupName} - ${simulationName}`}
      {(curr && total && !isFailed) && ` - ${curr}/${total}`}
      {isFailed && <> - <Icon icon="cross" intent={Intent.DANGER} /> Failed</>}
    </>
  );
};

export default SimProgressText;
