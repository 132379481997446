import { LoaderFunctionArgs } from 'react-router-dom';

import {
  PartCategoryDocument,
} from 'graphql/generated/graphql';
import apolloClient from '../graphql';
import { ApiRequest } from 'api';

// eslint-disable-next-line import/prefer-default-export
export const partCategory = async ({ params }: LoaderFunctionArgs) => {
  const { data } = await apolloClient.query({
    query: PartCategoryDocument,
    variables: {
      categoryName: params.categoryName,
    },
  });
  return data;
};

export const getPermissions = async () => {
  try {
    const { data } = await ApiRequest.get('/auth/list-user-permissions');
    const permissions = data.data.map((p: { name: any; }) => p.name); // eslint-disable-line @typescript-eslint/no-explicit-any
    return permissions;
  } catch (e) {
    // return error but do nothing else. This request should only fail if the user is not logged in.
    // The Platform Toolbar will handle redirection to login screen.
    return e;
  }
};
