import { H3, Button, Intent } from '@blueprintjs/core';
import { useState } from 'react';
import styles from './index.module.css';
import SimTable from '../../components/SimTable';
import AppToaster from 'helpers/toaster';
import { useNavigate } from 'react-router-dom';
import { CreateSimInput, useCreateSimMutation } from 'graphql/generated/graphql';
import AddSimDialog from './addSimDialog';

export default () => {
  const [isAddSimOpen, setAddSimOpen] = useState(false);
  const [createSim] = useCreateSimMutation();
  const navigate = useNavigate();

  const onAddSim = (input: CreateSimInput) => {
    createSim({
      variables: { input },
      onCompleted: data => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Successfully created sim',
        });
        navigate(`/sims/${data.createSim?.id}`);
      },
      refetchQueries: ['Sims'],
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Failed to create sim: ${e.message}`,
        });
      },
    });
  };

  return (
    <>
      <div className={styles.actionsHeader}>
        <H3>Sim Summary</H3>
        <Button
          icon="plus"
          intent={Intent.PRIMARY}
          onClick={() => setAddSimOpen(true)}
          text="Create Sim"
        />
      </div>
      <SimTable />
      <AddSimDialog
        isOpen={isAddSimOpen}
        onClose={() => setAddSimOpen(false)}
        onOk={onAddSim}
      />
    </>
  );
};
