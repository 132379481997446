import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Dialog, DialogBody, DialogFooter, Intent } from '@blueprintjs/core';
import { Required } from 'utility-types';
import classNames from 'classnames';
import _ from 'lodash';

import SetupFieldForm from 'components/SetupFieldForm';
import {
  CreateSetupFieldInput,
  PartConfig,
  SetupField,
  SetupFieldPosition,
  SetupFieldType,
  SetupFieldsDocument,
  Spec,
  UpdateSetupFieldInput,
  useCreateSetupFieldMutation,
  useUpdateSetupFieldMutation,
} from 'graphql/generated/graphql';
import AppToaster from 'helpers/toaster';
import { selectDarkMode } from 'reducers/ui';
import { SetupFieldFormInput, SetupFieldInput } from 'types';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (setupField: SetupField) => void;
  partConfigs: Required<Partial<PartConfig>, 'id' | 'display_name'>[];
  setupField?: SetupFieldFormInput | null;
}

const defaultSetupField: SetupFieldInput = {
  type: SetupFieldType.STRING,
  specs: [] as Spec[],
  name: '',
  label: '',
  path: '',
  tooltip: '',
  positions: [] as SetupFieldPosition[],
  options: [] as string[],
  use_in_sim: false,
};

export default (props: Props) => {
  let defaultValues = defaultSetupField;
  if (props.setupField) {
    defaultValues = {
      ..._.omit(props.setupField, 'part_config'),
      part_config_id: props.setupField.part_config?.id,
    };
  }

  const darkMode = useSelector(selectDarkMode);
  const form = useForm<SetupFieldInput>({ defaultValues });
  useEffect(() => {
    form.reset(defaultValues);
  }, [props.setupField]);
  const isEdit = Boolean(props.setupField?.id);

  const [createSetupField] = useCreateSetupFieldMutation();
  const [updateSetupField] = useUpdateSetupFieldMutation();

  const handleCreateSetupField = (input: CreateSetupFieldInput) => {
    createSetupField({
      variables: { input },
      onCompleted: setupField => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Setup field successfully created',
        });
        form.reset(defaultSetupField);
        props.onSuccess(setupField.setupField as SetupField);
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Error creating setup field: ${e.message}`,
        });
      },
      update: (cache, { data: mutationData }) => {
        if (mutationData?.setupField) {
          cache.updateQuery({
            query: SetupFieldsDocument,
          }, queryData => {
            if (!queryData) return undefined;
            return {
              setupFields: {
                rows: [
                  ...queryData.setupFields.rows,
                  mutationData?.setupField,
                ],
              },
            };
          });
        }
      },
    });
  };
  const handleUpdateSetupField = (input: UpdateSetupFieldInput) => {
    updateSetupField({
      variables: { input },
      onCompleted: setupField => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Setup field successfully updated',
        });
        form.reset(defaultSetupField);
        props.onSuccess(setupField.setupField as SetupField);
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Error updating setup field: ${e.message}`,
        });
      },
    });
  };

  const onClose = () => {
    form.reset(defaultSetupField);
    props.onClose();
  };
  const onSubmit = (input: SetupFieldInput) => {
    if (isEdit) handleUpdateSetupField(input as UpdateSetupFieldInput);
    else handleCreateSetupField(input as CreateSetupFieldInput);
  };

  return (
    <Dialog
      className={classNames({ 'bp4-dark': darkMode })}
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={`${isEdit ? 'Edit' : 'Create'} Setup Field`}
    >
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <DialogBody>
            <SetupFieldForm partConfigs={props.partConfigs} />
          </DialogBody>
        </form>
      </FormProvider>
      <DialogFooter
        actions={(
          <>
            <Button
              onClick={onClose}
              text="Cancel"
            />
            <Button
              intent={Intent.PRIMARY}
              onClick={form.handleSubmit(onSubmit)}
              key="submit"
              text="Save"
            />
          </>
        )}
      />
    </Dialog>
  );
};
