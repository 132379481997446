import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  JSONObject: any;
  Upload: any;
};

export type BranchDeleteResult = {
  __typename?: 'BranchDeleteResult';
  branches: Scalars['Int'];
  setups: Scalars['Int'];
};

export type ClearSimProgressResult = {
  __typename?: 'ClearSimProgressResult';
  success: Scalars['Boolean'];
};

export type ClearSimStatusResult = {
  __typename?: 'ClearSimStatusResult';
  success: Scalars['Boolean'];
};

export type ClonePartInput = {
  data: Scalars['JSONObject'];
  description: Scalars['String'];
  exp_date?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  mileage?: InputMaybe<Scalars['Int']>;
  part_config_id: Scalars['Int'];
  part_number?: InputMaybe<Scalars['String']>;
  properties?: InputMaybe<Array<PartPropertyInput>>;
  serial_number?: InputMaybe<Scalars['String']>;
  spec: Spec;
};

export type CloneSetupResult = {
  __typename?: 'CloneSetupResult';
  branch: SetupBranch;
  setup: Setup;
};

export type CreateDriveFileInput = {
  data: DriveFileDataInput;
  desc: Scalars['String'];
  name: Scalars['String'];
  venue: Scalars['String'];
};

export type CreateDriverInput = {
  data: Scalars['JSONObject'];
  desc: Scalars['String'];
  name: Scalars['String'];
  track: Scalars['String'];
};

export type CreateEightPostInput = {
  data: EightPostDataInput;
  desc: Scalars['String'];
  name: Scalars['String'];
  venue: Scalars['String'];
};

export type CreateEnvironmentInput = {
  elevation: Scalars['Float'];
  humidity: Scalars['Float'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
  owner?: InputMaybe<Scalars['String']>;
  pressure: Scalars['Float'];
  spec?: InputMaybe<Spec>;
  temperature: Scalars['Float'];
  track: Scalars['String'];
  traffic_wind: Scalars['Float'];
  traffic_wind_angle: Scalars['Float'];
  use_lap_wind_data?: InputMaybe<Scalars['Boolean']>;
  wind_direction: Scalars['Float'];
  wind_scalar: Scalars['Float'];
  wind_speed: Scalars['Float'];
};

export type CreateLapTimeDataInput = {
  data: Scalars['JSONObject'];
  desc: Scalars['String'];
  name: Scalars['String'];
};

export type CreateLapWindDataInput = {
  data: LapWindDataInput;
  desc: Scalars['String'];
  name: Scalars['String'];
};

export type CreatePartCategoryInput = {
  description?: InputMaybe<Scalars['String']>;
  display_name: Scalars['String'];
  name: Scalars['String'];
};

export type CreatePartConfigInput = {
  display_name: Scalars['String'];
  expires: Scalars['Boolean'];
  mileage_limit?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  part_category_id: Scalars['Int'];
  properties?: InputMaybe<Array<PartConfigPropertyInput>>;
};

export type CreatePartInput = {
  data: Scalars['JSONObject'];
  description: Scalars['String'];
  exp_date?: InputMaybe<Scalars['String']>;
  mileage?: InputMaybe<Scalars['Int']>;
  part_config_id: Scalars['Int'];
  part_number?: InputMaybe<Scalars['String']>;
  properties?: InputMaybe<Array<PartPropertyInput>>;
  serial_number?: InputMaybe<Scalars['String']>;
  spec: Spec;
};

export type CreatePropertyInput = {
  description?: InputMaybe<Scalars['String']>;
  display_name: Scalars['String'];
  max?: InputMaybe<Scalars['Float']>;
  min?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  path?: InputMaybe<Scalars['String']>;
  type: PropertyType;
  unit?: InputMaybe<Scalars['String']>;
  values?: InputMaybe<Array<Scalars['String']>>;
};

export type CreateRUITInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateRunFieldInput = {
  label: Scalars['String'];
  name: Scalars['String'];
  options?: InputMaybe<Array<Scalars['String']>>;
  positions?: InputMaybe<Array<RunFieldPositionInput>>;
  tooltip?: InputMaybe<Scalars['String']>;
  type: RunFieldType;
};

export type CreateRunInput = {
  data?: InputMaybe<Scalars['JSONObject']>;
};

export type CreateSUITInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  spec: Spec;
};

export type CreateSetupFieldInput = {
  label: Scalars['String'];
  name: Scalars['String'];
  options?: InputMaybe<Array<Scalars['String']>>;
  part_config_id?: InputMaybe<Scalars['Int']>;
  path: Scalars['String'];
  positions?: InputMaybe<Array<SetupFieldPositionInput>>;
  specs?: InputMaybe<Array<InputMaybe<Spec>>>;
  tooltip?: InputMaybe<Scalars['String']>;
  type: SetupFieldType;
  use_in_sim: Scalars['Boolean'];
};

export type CreateSetupInput = {
  car: Scalars['String'];
  created_at?: InputMaybe<Scalars['Date']>;
  data?: InputMaybe<Scalars['JSONObject']>;
  description?: InputMaybe<Scalars['String']>;
  event: Scalars['String'];
  name: Scalars['String'];
  owner: Scalars['String'];
  parent_id?: InputMaybe<Scalars['Int']>;
  root_id: Scalars['Int'];
  series: Scalars['String'];
  spec: Spec;
  updated_at?: InputMaybe<Scalars['Date']>;
  year: Scalars['Int'];
};

export type CreateSimInput = {
  name: Scalars['String'];
  spec: Spec;
  type: SimType;
};

export type CreateSimStatesInput = {
  simStates: SimStatesInput;
  simStatesStates: Array<SimStatesStateInput>;
};

export type CreateSweepInput = {
  description?: InputMaybe<Scalars['String']>;
  method: SweepMethod;
  name: Scalars['String'];
};

export type Document = {
  __typename?: 'Document';
  _id?: Maybe<Scalars['String']>;
  _rev?: Maybe<Scalars['String']>;
  driver?: Maybe<Scalars['JSONObject']>;
  lap_time_data?: Maybe<Scalars['JSONObject']>;
  meta: DocumentMeta;
  part?: Maybe<Scalars['JSONObject']>;
  sim?: Maybe<Scalars['JSONObject']>;
  vehicle?: Maybe<Scalars['JSONObject']>;
};

export type DocumentInput = {
  _id?: InputMaybe<Scalars['String']>;
  _rev?: InputMaybe<Scalars['String']>;
  driver?: InputMaybe<Scalars['JSONObject']>;
  lap_time_data?: InputMaybe<Scalars['JSONObject']>;
  meta: DocumentMetaInput;
  part?: InputMaybe<Scalars['JSONObject']>;
  sim?: InputMaybe<Scalars['JSONObject']>;
  vehicle?: InputMaybe<Scalars['JSONObject']>;
};

export type DocumentMeta = {
  __typename?: 'DocumentMeta';
  car?: Maybe<Scalars['String']>;
  creation_date?: Maybe<Scalars['String']>;
  desc?: Maybe<Scalars['String']>;
  event?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  revision_date?: Maybe<Scalars['String']>;
  root_id?: Maybe<Scalars['Int']>;
  session?: Maybe<Scalars['String']>;
  simType?: Maybe<SimType>;
  spec?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  track?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  version?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['String']>;
};

export type DocumentMetaInput = {
  car?: InputMaybe<Scalars['String']>;
  desc?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<Scalars['String']>;
  group?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  root_id?: InputMaybe<Scalars['Int']>;
  series?: InputMaybe<Scalars['String']>;
  session?: InputMaybe<Scalars['String']>;
  simType?: InputMaybe<SimType>;
  spec?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  track?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['String']>;
};

export type DriveFile = {
  __typename?: 'DriveFile';
  created_at?: Maybe<Scalars['Date']>;
  data?: Maybe<DriveFileDataObj>;
  desc: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  owner?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  updated_at?: Maybe<Scalars['Date']>;
  venue: Scalars['String'];
};

export type DriveFileDataControllerInput = {
  acceleration_k: Scalars['Float'];
  controller_Td: Scalars['Float'];
  controller_Ti: Scalars['Float'];
  controller_k: Scalars['Float'];
  decceleration_k: Scalars['Float'];
  enable_shifting: Scalars['Float'];
  filter_time_constant: Scalars['Float'];
  max_brake: Scalars['Float'];
  max_throttle: Scalars['Float'];
};

export type DriveFileDataDetailsInput = {
  date?: InputMaybe<Scalars['String']>;
  line?: InputMaybe<Scalars['String']>;
  original_desc?: InputMaybe<Scalars['String']>;
  pace?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  track?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type DriveFileDataInput = {
  controller: DriveFileDataControllerInput;
  details?: InputMaybe<DriveFileDataDetailsInput>;
  laptime: Scalars['Float'];
  segments: Array<DriveFileDataSegmentsInput>;
  signalsArray: DriveFileDataSignalsArrayInput;
  trim_start: Scalars['Float'];
  venue: Scalars['String'];
};

export type DriveFileDataObj = {
  __typename?: 'DriveFileDataObj';
  controller?: Maybe<Scalars['JSONObject']>;
  laptime?: Maybe<Scalars['Float']>;
  segments?: Maybe<Array<Maybe<Scalars['JSONObject']>>>;
  signalsArray?: Maybe<Scalars['JSONObject']>;
  trim_start?: Maybe<Scalars['Float']>;
  venue?: Maybe<Scalars['String']>;
};

export type DriveFileDataSegmentsInput = {
  begin: Scalars['Float'];
  end: Scalars['Float'];
  label: Scalars['String'];
  loc: Scalars['String'];
};

export type DriveFileDataSignalsArrayInput = {
  data: Array<Array<Scalars['Float']>>;
};

export type Driver = {
  __typename?: 'Driver';
  created_at?: Maybe<Scalars['Date']>;
  data?: Maybe<Scalars['JSONObject']>;
  desc: Scalars['String'];
  id: Scalars['Int'];
  name?: Maybe<DriverSimType>;
  owner: Scalars['String'];
  status: Scalars['String'];
  track: Scalars['String'];
  updated_at?: Maybe<Scalars['Date']>;
};

export enum DriverSimType {
  avl = 'avl',
  trd = 'trd'
}

export type EightPost = {
  __typename?: 'EightPost';
  created_at?: Maybe<Scalars['Date']>;
  data?: Maybe<EightPostDataObj>;
  desc: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: Scalars['String'];
  status: Scalars['String'];
  updated_at?: Maybe<Scalars['Date']>;
  venue: Scalars['String'];
};

export type EightPostDataInput = {
  laptime: Scalars['Float'];
  segments: Array<EightPostDataSegmentInput>;
  signalsArray: EightPostDataSignalsArrayInput;
  trim_start: Scalars['Float'];
  venue: Scalars['String'];
};

export type EightPostDataObj = {
  __typename?: 'EightPostDataObj';
  laptime?: Maybe<Scalars['Float']>;
  segments?: Maybe<Array<Maybe<Scalars['JSONObject']>>>;
  signalsArray?: Maybe<Scalars['JSONObject']>;
  trim_start?: Maybe<Scalars['Float']>;
  venue?: Maybe<Scalars['String']>;
};

export type EightPostDataSegmentInput = {
  begin: Scalars['Float'];
  end: Scalars['Float'];
  label: Scalars['String'];
  loc: Scalars['String'];
};

export type EightPostDataSignalsArrayInput = {
  data?: InputMaybe<Array<InputMaybe<Array<InputMaybe<Scalars['Float']>>>>>;
};

export type Environment = {
  __typename?: 'Environment';
  created_at?: Maybe<Scalars['Date']>;
  elevation: Scalars['Float'];
  historical_time?: Maybe<Scalars['Date']>;
  historical_weather?: Maybe<Scalars['JSONObject']>;
  humidity: Scalars['Float'];
  id: Scalars['Int'];
  lap_wind_data?: Maybe<LapWindData>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
  owner: Scalars['String'];
  pressure: Scalars['Float'];
  spec: Spec;
  temperature: Scalars['Float'];
  track: Scalars['String'];
  traffic_wind: Scalars['Float'];
  traffic_wind_angle: Scalars['Float'];
  updated_at?: Maybe<Scalars['Date']>;
  use_lap_wind_data?: Maybe<Scalars['Boolean']>;
  wind_direction: Scalars['Float'];
  wind_scalar: Scalars['Float'];
  wind_speed: Scalars['Float'];
};

export type EnvironmentFilters = {
  name?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['String']>;
  track?: InputMaybe<Scalars['String']>;
};

export type EnvironmentSorts = {
  created_at?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  owner?: InputMaybe<SortDirection>;
  track?: InputMaybe<SortDirection>;
  updated_at?: InputMaybe<SortDirection>;
};

export type EnvironmentsQueryInput = {
  filters?: InputMaybe<EnvironmentFilters>;
  pagination?: InputMaybe<Pagination>;
  sorts?: InputMaybe<EnvironmentSorts>;
};

export type EnvironmentsResult = {
  __typename?: 'EnvironmentsResult';
  rows: Array<Environment>;
  totalCount: Scalars['Int'];
};

export type Event = {
  __typename?: 'Event';
  event_id: Scalars['String'];
  name: Scalars['String'];
};

export type File = {
  __typename?: 'File';
  encoding: Scalars['String'];
  filename: Scalars['String'];
  mimetype: Scalars['String'];
};

export type HistoricalWeather = {
  __typename?: 'HistoricalWeather';
  asOf: Scalars['String'];
  humidity: Scalars['Float'];
  pressure: Scalars['Float'];
  source: Scalars['String'];
  temperature: Scalars['Float'];
  windDirection: Scalars['Float'];
  windSpeed: Scalars['Float'];
  wind_scalar?: Maybe<Scalars['Float']>;
};

export type ImportPartResult = {
  __typename?: 'ImportPartResult';
  data?: Maybe<Scalars['JSONObject']>;
  error?: Maybe<Scalars['String']>;
  pos: Scalars['String'];
  success: Scalars['Boolean'];
  type: Scalars['String'];
};

export type LapTimeData = {
  __typename?: 'LapTimeData';
  created_at?: Maybe<Scalars['Date']>;
  data?: Maybe<LapTimeDataObj>;
  desc: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: Scalars['String'];
  status: Scalars['String'];
  updated_at?: Maybe<Scalars['Date']>;
};

export type LapTimeDataObj = {
  __typename?: 'LapTimeDataObj';
  brake_aggression?: Maybe<Scalars['Float']>;
  bypass_svm?: Maybe<Scalars['Boolean']>;
  corner_spd_mode?: Maybe<Scalars['Boolean']>;
  dlift_dvelocity?: Maybe<Scalars['Float']>;
  part_throttle_pct?: Maybe<Scalars['Float']>;
  path_mu?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']>>>>>;
  path_v?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']>>>>>;
  path_velocity?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']>>>>>;
  saturation?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']>>>>>;
  saturation_target_pct?: Maybe<Scalars['Float']>;
  scouting_speed_pct?: Maybe<Scalars['Float']>;
  scouting_target?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']>>>>>;
  segments?: Maybe<Array<Maybe<LapTimeDataSegment>>>;
  start_position?: Maybe<Scalars['Float']>;
  start_speed?: Maybe<Scalars['Float']>;
  throttle_aggression?: Maybe<Scalars['Float']>;
  track_filename?: Maybe<Scalars['String']>;
  traffic_wind_scaling?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']>>>>>;
};

export type LapTimeDataSegment = {
  __typename?: 'LapTimeDataSegment';
  begin: Scalars['Float'];
  end: Scalars['Float'];
  label: Scalars['String'];
  loc: Scalars['String'];
};

export type LapWindData = {
  __typename?: 'LapWindData';
  created_at?: Maybe<Scalars['Date']>;
  data?: Maybe<Scalars['JSONObject']>;
  desc: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: Scalars['String'];
  status: Scalars['String'];
  updated_at?: Maybe<Scalars['Date']>;
};

export type LapWindDataInput = {
  x: Array<Array<Scalars['Float']>>;
  y: Array<Array<Scalars['Float']>>;
  z: Array<Array<Scalars['Float']>>;
};

export type Message = {
  __typename?: 'Message';
  msgContent: Scalars['String'];
  msgId: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addEnvToSimOutline?: Maybe<SimOutline>;
  addSetupBranchToSimOutline?: Maybe<SimOutline>;
  addSimOutlineToWorksheet?: Maybe<SimOutline>;
  addSimToSimOutline?: Maybe<SimOutline>;
  addSweepToSimOutline?: Maybe<SimOutline>;
  applyPatchToSetup?: Maybe<Setup>;
  bulkAddEnvToSimOutline?: Maybe<SimOutline>;
  bulkAddSetupBranchToSimOutline?: Maybe<SimOutline>;
  bulkAddSimToSimOutline?: Maybe<SimOutline>;
  bulkAddSweepToSimOutline?: Maybe<SimOutline>;
  bulkCloneSimOutline?: Maybe<Array<Maybe<SimOutline>>>;
  bulkDeleteEnvFromSimOutline?: Maybe<Scalars['Int']>;
  bulkDeletePartConfigs?: Maybe<Scalars['Int']>;
  bulkDeleteRUITs?: Maybe<Scalars['Int']>;
  bulkDeleteRunFields?: Maybe<Scalars['Int']>;
  bulkDeleteSUITs?: Maybe<Scalars['Int']>;
  bulkDeleteSetupBranchFromSimOutline?: Maybe<Scalars['Int']>;
  bulkDeleteSetupFields?: Maybe<Scalars['Int']>;
  bulkDeleteSimFromSimOutline?: Maybe<Scalars['Int']>;
  bulkDeleteSimOutline?: Maybe<Scalars['Int']>;
  bulkDeleteSimWorksheets?: Maybe<Scalars['Int']>;
  bulkDeleteSweepToSimOutline?: Maybe<Scalars['Int']>;
  bulkUpdateRuns?: Maybe<Array<Maybe<Run>>>;
  clearSimOutline?: Maybe<SimOutline>;
  clearSimProgress?: Maybe<ClearSimProgressResult>;
  clearSimResultMessages?: Maybe<SimMessageResult>;
  clearSimStatus?: Maybe<ClearSimStatusResult>;
  clearSweepStatus?: Maybe<ClearSimStatusResult>;
  cloneEnvironment?: Maybe<Environment>;
  clonePart?: Maybe<Part>;
  cloneRUIT?: Maybe<RUIT>;
  cloneRun?: Maybe<Run>;
  cloneSUIT?: Maybe<SUIT>;
  cloneSetup: CloneSetupResult;
  cloneSim?: Maybe<Sim>;
  cloneSimOutline?: Maybe<SimOutline>;
  cloneSimWorksheet?: Maybe<SimWorksheet>;
  commitSetup?: Maybe<Setup>;
  createBranch?: Maybe<SetupBranch>;
  createDriveFile?: Maybe<DriveFile>;
  createDriver?: Maybe<Driver>;
  createEightPost?: Maybe<EightPost>;
  createEnvironment?: Maybe<Environment>;
  createLapTimeData?: Maybe<LapTimeData>;
  createLapWindData?: Maybe<LapWindData>;
  createPart?: Maybe<Part>;
  createPartCategory?: Maybe<PartCategory>;
  createPartConfig?: Maybe<PartConfig>;
  createProperty?: Maybe<Property>;
  createRUIT?: Maybe<RUIT>;
  createRun?: Maybe<Run>;
  createRunField?: Maybe<RunField>;
  createSUIT?: Maybe<SUIT>;
  createSetup?: Maybe<Setup>;
  createSetupField?: Maybe<SetupField>;
  createSim?: Maybe<Sim>;
  createSimStates?: Maybe<SimStates>;
  createSimWorksheet?: Maybe<SimWorksheet>;
  createSweep?: Maybe<Sweep>;
  createUserSimResultMessage?: Maybe<SimMessageResult>;
  deleteBranch: BranchDeleteResult;
  deleteEnvFromSimOutline?: Maybe<Scalars['Int']>;
  deleteEnvironment?: Maybe<Scalars['Int']>;
  deletePartConfig?: Maybe<Scalars['Int']>;
  deleteRUIT?: Maybe<Scalars['Int']>;
  deleteRepo: RepoDeleteResult;
  deleteRun?: Maybe<Scalars['Int']>;
  deleteRunField?: Maybe<Scalars['Int']>;
  deleteSUIT?: Maybe<Scalars['Int']>;
  deleteSetupBranchFromSimOutline?: Maybe<Scalars['Int']>;
  deleteSetupField?: Maybe<Scalars['Int']>;
  deleteSim?: Maybe<Scalars['Int']>;
  deleteSimFromSimOutline?: Maybe<Scalars['Int']>;
  deleteSimOutline?: Maybe<Scalars['Int']>;
  deleteSimResultMessage?: Maybe<SimMessageResult>;
  deleteSimWorksheet?: Maybe<Scalars['Int']>;
  deleteSweep?: Maybe<Scalars['Int']>;
  deleteSweepToSimOutline?: Maybe<Scalars['Int']>;
  getBatchSimResultMessages?: Maybe<SimMessageResult>;
  getSimResultMessageCount?: Maybe<SimMessageResult>;
  importPartFile: ImportPartResult;
  requestPartFit?: Maybe<PartFitRequest>;
  requestPartPlotting?: Maybe<PartPlotRequest>;
  submit?: Maybe<Scalars['String']>;
  updateBranch: Scalars['Int'];
  updateDriveFile?: Maybe<DriveFile>;
  updateDriver?: Maybe<Driver>;
  updateEightPost?: Maybe<EightPost>;
  updateEnvironment?: Maybe<Environment>;
  updateLapTimeData?: Maybe<LapTimeData>;
  updateLapWindData?: Maybe<LapWindData>;
  updatePart?: Maybe<Part>;
  updatePartConfig?: Maybe<PartConfig>;
  updateProperty?: Maybe<Property>;
  updateRUIT?: Maybe<RUIT>;
  updateRun?: Maybe<Run>;
  updateRunField?: Maybe<RunField>;
  updateSUIT?: Maybe<SUIT>;
  updateSetup?: Maybe<Setup>;
  updateSetupField?: Maybe<SetupField>;
  updateSim?: Maybe<Sim>;
  updateSimOutlineDetails?: Maybe<SimOutline>;
  updateSimOutlineOrder?: Maybe<SimOutline>;
  updateSimStates?: Maybe<SimStates>;
  updateSimWorksheet?: Maybe<SimWorksheet>;
  updateSweep?: Maybe<Sweep>;
};


export type MutationaddEnvToSimOutlineArgs = {
  envId: Scalars['Int'];
  id: Scalars['Int'];
};


export type MutationaddSetupBranchToSimOutlineArgs = {
  id: Scalars['Int'];
  setupBranchId: Scalars['Int'];
};


export type MutationaddSimOutlineToWorksheetArgs = {
  simOutlineName?: InputMaybe<Scalars['String']>;
  worksheetId: Scalars['Int'];
};


export type MutationaddSimToSimOutlineArgs = {
  id: Scalars['Int'];
  simId: Scalars['Int'];
};


export type MutationaddSweepToSimOutlineArgs = {
  id: Scalars['Int'];
  sweepId: Scalars['Int'];
};


export type MutationapplyPatchToSetupArgs = {
  fromBranchId: Scalars['Int'];
  fullMerge?: InputMaybe<Scalars['Boolean']>;
  ops: Array<Scalars['JSONObject']>;
  toBranchId: Scalars['Int'];
};


export type MutationbulkAddEnvToSimOutlineArgs = {
  envIds: Array<Scalars['Int']>;
  id: Scalars['Int'];
};


export type MutationbulkAddSetupBranchToSimOutlineArgs = {
  id: Scalars['Int'];
  setupBranchIds: Array<Scalars['Int']>;
};


export type MutationbulkAddSimToSimOutlineArgs = {
  id: Scalars['Int'];
  simIds: Array<Scalars['Int']>;
};


export type MutationbulkAddSweepToSimOutlineArgs = {
  id: Scalars['Int'];
  sweepIds: Array<Scalars['Int']>;
};


export type MutationbulkCloneSimOutlineArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationbulkDeleteEnvFromSimOutlineArgs = {
  envIds: Array<Scalars['Int']>;
  id: Scalars['Int'];
};


export type MutationbulkDeletePartConfigsArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationbulkDeleteRUITsArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationbulkDeleteRunFieldsArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationbulkDeleteSUITsArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationbulkDeleteSetupBranchFromSimOutlineArgs = {
  id: Scalars['Int'];
  setupBranchIds: Array<Scalars['Int']>;
};


export type MutationbulkDeleteSetupFieldsArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationbulkDeleteSimFromSimOutlineArgs = {
  id: Scalars['Int'];
  simIds: Array<Scalars['Int']>;
};


export type MutationbulkDeleteSimOutlineArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationbulkDeleteSimWorksheetsArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationbulkDeleteSweepToSimOutlineArgs = {
  id: Scalars['Int'];
  sweepIds: Array<Scalars['Int']>;
};


export type MutationbulkUpdateRunsArgs = {
  inputs: Array<UpdateRunInput>;
};


export type MutationclearSimOutlineArgs = {
  id: Scalars['Int'];
};


export type MutationcloneEnvironmentArgs = {
  id: Scalars['Int'];
  name: Scalars['String'];
};


export type MutationclonePartArgs = {
  input: ClonePartInput;
};


export type MutationcloneRUITArgs = {
  id: Scalars['Int'];
  name: Scalars['String'];
};


export type MutationcloneRunArgs = {
  fromBranchId: Scalars['Int'];
  id: Scalars['Int'];
};


export type MutationcloneSUITArgs = {
  id: Scalars['Int'];
  name: Scalars['String'];
};


export type MutationcloneSetupArgs = {
  id: Scalars['Int'];
  name: Scalars['String'];
};


export type MutationcloneSimArgs = {
  id: Scalars['Int'];
  name: Scalars['String'];
};


export type MutationcloneSimOutlineArgs = {
  id: Scalars['Int'];
  name: Scalars['String'];
};


export type MutationcloneSimWorksheetArgs = {
  id: Scalars['Int'];
  name: Scalars['String'];
};


export type MutationcommitSetupArgs = {
  branchId: Scalars['Int'];
  setup: CreateSetupInput;
};


export type MutationcreateBranchArgs = {
  fromId: Scalars['Int'];
  name: Scalars['String'];
};


export type MutationcreateDriveFileArgs = {
  input: CreateDriveFileInput;
};


export type MutationcreateDriverArgs = {
  input: CreateDriverInput;
};


export type MutationcreateEightPostArgs = {
  input: CreateEightPostInput;
};


export type MutationcreateEnvironmentArgs = {
  input: CreateEnvironmentInput;
};


export type MutationcreateLapTimeDataArgs = {
  input: CreateLapTimeDataInput;
};


export type MutationcreateLapWindDataArgs = {
  input: CreateLapWindDataInput;
};


export type MutationcreatePartArgs = {
  input: CreatePartInput;
};


export type MutationcreatePartCategoryArgs = {
  input: CreatePartCategoryInput;
};


export type MutationcreatePartConfigArgs = {
  input: CreatePartConfigInput;
};


export type MutationcreatePropertyArgs = {
  input: CreatePropertyInput;
};


export type MutationcreateRUITArgs = {
  input: CreateRUITInput;
};


export type MutationcreateRunArgs = {
  fromBranchId: Scalars['Int'];
  input: CreateRunInput;
  name: Scalars['String'];
  session?: InputMaybe<Scalars['String']>;
  sessionId?: InputMaybe<Scalars['String']>;
};


export type MutationcreateRunFieldArgs = {
  input: CreateRunFieldInput;
};


export type MutationcreateSUITArgs = {
  input: CreateSUITInput;
};


export type MutationcreateSetupArgs = {
  input: CreateSetupInput;
};


export type MutationcreateSetupFieldArgs = {
  input: CreateSetupFieldInput;
};


export type MutationcreateSimArgs = {
  input: CreateSimInput;
};


export type MutationcreateSimStatesArgs = {
  input: CreateSimStatesInput;
};


export type MutationcreateSimWorksheetArgs = {
  input: SimWorksheetInput;
};


export type MutationcreateSweepArgs = {
  input: CreateSweepInput;
};


export type MutationcreateUserSimResultMessageArgs = {
  message: Scalars['String'];
};


export type MutationdeleteBranchArgs = {
  id: Scalars['Int'];
};


export type MutationdeleteEnvFromSimOutlineArgs = {
  envId: Scalars['Int'];
  id: Scalars['Int'];
};


export type MutationdeleteEnvironmentArgs = {
  id: Scalars['Int'];
};


export type MutationdeletePartConfigArgs = {
  id: Scalars['Int'];
};


export type MutationdeleteRUITArgs = {
  id: Scalars['Int'];
};


export type MutationdeleteRepoArgs = {
  rootId: Scalars['Int'];
};


export type MutationdeleteRunArgs = {
  id: Scalars['Int'];
};


export type MutationdeleteRunFieldArgs = {
  id: Scalars['Int'];
};


export type MutationdeleteSUITArgs = {
  id: Scalars['Int'];
};


export type MutationdeleteSetupBranchFromSimOutlineArgs = {
  id: Scalars['Int'];
  setupBranchId: Scalars['Int'];
};


export type MutationdeleteSetupFieldArgs = {
  id: Scalars['Int'];
};


export type MutationdeleteSimArgs = {
  id: Scalars['Int'];
};


export type MutationdeleteSimFromSimOutlineArgs = {
  id: Scalars['Int'];
  simId: Scalars['Int'];
};


export type MutationdeleteSimOutlineArgs = {
  id: Scalars['Int'];
};


export type MutationdeleteSimResultMessageArgs = {
  id: Scalars['String'];
};


export type MutationdeleteSimWorksheetArgs = {
  id: Scalars['Int'];
};


export type MutationdeleteSweepArgs = {
  id: Scalars['Int'];
};


export type MutationdeleteSweepToSimOutlineArgs = {
  id: Scalars['Int'];
  sweepId: Scalars['Int'];
};


export type MutationgetBatchSimResultMessagesArgs = {
  ids?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationimportPartFileArgs = {
  fileBuffer: Scalars['String'];
  fileName: Scalars['String'];
  pos: Scalars['String'];
  type: Scalars['String'];
};


export type MutationrequestPartFitArgs = {
  input: Array<PartInput>;
  partType: Scalars['String'];
};


export type MutationrequestPartPlottingArgs = {
  input: Array<PartInput>;
  partType: Scalars['String'];
};


export type MutationsubmitArgs = {
  _id?: InputMaybe<Scalars['String']>;
  _rev?: InputMaybe<Scalars['String']>;
  driver?: InputMaybe<Scalars['JSONObject']>;
  lap_time_data?: InputMaybe<Scalars['JSONObject']>;
  meta: DocumentMetaInput;
  part?: InputMaybe<Scalars['JSONObject']>;
  sim?: InputMaybe<Scalars['JSONObject']>;
  vehicle?: InputMaybe<Scalars['JSONObject']>;
};


export type MutationupdateBranchArgs = {
  input: SetupBranchInput;
};


export type MutationupdateDriveFileArgs = {
  input: UpdateDriveFileInput;
};


export type MutationupdateDriverArgs = {
  input: UpdateDriverInput;
};


export type MutationupdateEightPostArgs = {
  input: UpdateEightPostInput;
};


export type MutationupdateEnvironmentArgs = {
  input: UpdateEnvironmentInput;
};


export type MutationupdateLapTimeDataArgs = {
  input: UpdateLapTimeDataInput;
};


export type MutationupdateLapWindDataArgs = {
  input: UpdateLapWindDataInput;
};


export type MutationupdatePartArgs = {
  input: UpdatePartInput;
};


export type MutationupdatePartConfigArgs = {
  input: UpdatePartConfigInput;
};


export type MutationupdatePropertyArgs = {
  input: UpdatePropertyInput;
};


export type MutationupdateRUITArgs = {
  input: UpdateRUITInput;
};


export type MutationupdateRunArgs = {
  input: UpdateRunInput;
};


export type MutationupdateRunFieldArgs = {
  input: UpdateRunFieldInput;
};


export type MutationupdateSUITArgs = {
  input: UpdateSUITInput;
};


export type MutationupdateSetupArgs = {
  input: UpdateSetupInput;
};


export type MutationupdateSetupFieldArgs = {
  input: UpdateSetupFieldInput;
};


export type MutationupdateSimArgs = {
  input: UpdateSimInput;
};


export type MutationupdateSimOutlineDetailsArgs = {
  input: UpdateSimOutlineDetailsInput;
};


export type MutationupdateSimOutlineOrderArgs = {
  id: Scalars['Int'];
  newIndex: Scalars['Int'];
  oldIndex: Scalars['Int'];
};


export type MutationupdateSimStatesArgs = {
  input: UpdateSimStatesInput;
};


export type MutationupdateSimWorksheetArgs = {
  id: Scalars['Int'];
  input: SimWorksheetInput;
};


export type MutationupdateSweepArgs = {
  input: UpdateSweepInput;
};

export type Pagination = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type Part = {
  __typename?: 'Part';
  config: PartConfig;
  data: Scalars['JSONObject'];
  description: Scalars['String'];
  exp_date?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  mileage?: Maybe<Scalars['Int']>;
  part_number?: Maybe<Scalars['String']>;
  properties?: Maybe<Array<PartProperty>>;
  serial_number?: Maybe<Scalars['String']>;
  spec: Spec;
};

export type PartCategory = {
  __typename?: 'PartCategory';
  configs?: Maybe<Array<PartConfig>>;
  description?: Maybe<Scalars['String']>;
  display_name: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type PartConfig = {
  __typename?: 'PartConfig';
  category: PartCategory;
  display_name: Scalars['String'];
  expires: Scalars['Boolean'];
  id: Scalars['Int'];
  mileage_limit?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  properties: Array<PartConfigProperty>;
};

export type PartConfigProperty = {
  __typename?: 'PartConfigProperty';
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  order: Scalars['Int'];
  part_config: PartConfig;
  path?: Maybe<Scalars['String']>;
  property: Property;
  required: Scalars['Boolean'];
  specs?: Maybe<Array<Maybe<Spec>>>;
  values?: Maybe<Array<Scalars['String']>>;
};

export type PartConfigPropertyInput = {
  description?: InputMaybe<Scalars['String']>;
  destroy?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  max?: InputMaybe<Scalars['Float']>;
  min?: InputMaybe<Scalars['Float']>;
  order?: InputMaybe<Scalars['Int']>;
  path?: InputMaybe<Scalars['String']>;
  property_id?: InputMaybe<Scalars['Int']>;
  required: Scalars['Boolean'];
  specs?: InputMaybe<Array<InputMaybe<Spec>>>;
  values?: InputMaybe<Array<Scalars['String']>>;
};

export type PartConfigSorts = {
  category?: InputMaybe<SortDirection>;
  display_name?: InputMaybe<SortDirection>;
  expires?: InputMaybe<SortDirection>;
  mileage_limit?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
};

export type PartConfigsFilters = {
  category?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  display_name?: InputMaybe<Scalars['String']>;
  expires?: InputMaybe<Scalars['Boolean']>;
  mileage_limit?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  name?: InputMaybe<Scalars['String']>;
};

export type PartConfigsQueryInput = {
  filters?: InputMaybe<PartConfigsFilters>;
  pagination?: InputMaybe<Pagination>;
  sorts?: InputMaybe<PartConfigSorts>;
};

export type PartConfigsResult = {
  __typename?: 'PartConfigsResult';
  rows: Array<PartConfig>;
  totalCount: Scalars['Int'];
};

export type PartFitRequest = {
  __typename?: 'PartFitRequest';
  success: Scalars['Boolean'];
};

export type PartInput = {
  data: Scalars['JSONObject'];
  description: Scalars['String'];
  partFormat?: InputMaybe<Scalars['String']>;
  partId?: InputMaybe<Scalars['Int']>;
  partType?: InputMaybe<Scalars['String']>;
  pos?: InputMaybe<Scalars['String']>;
  setupId: Scalars['Int'];
  spec: Spec;
};

export type PartPlotRequest = {
  __typename?: 'PartPlotRequest';
  success: Scalars['Boolean'];
};

export type PartProperty = {
  __typename?: 'PartProperty';
  config_property: PartConfigProperty;
  id: Scalars['Int'];
  part: Part;
  value?: Maybe<Scalars['String']>;
};

export type PartPropertyFilter = {
  bool?: InputMaybe<Scalars['Boolean']>;
  minMax?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  name: Scalars['String'];
  value?: InputMaybe<Scalars['String']>;
  values?: InputMaybe<Array<Scalars['String']>>;
};

export type PartPropertyInput = {
  destroy?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  part_config_property_id?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

export type PartPropertySort = {
  direction: SortDirection;
  name: Scalars['String'];
};

export type PartSorts = {
  description?: InputMaybe<SortDirection>;
  exp_date?: InputMaybe<SortDirection>;
  mileage?: InputMaybe<SortDirection>;
  part_number?: InputMaybe<SortDirection>;
  properties?: InputMaybe<Array<PartPropertySort>>;
  serial_number?: InputMaybe<SortDirection>;
};

export type PartsFilters = {
  description?: InputMaybe<Scalars['String']>;
  mileage?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  part_config?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  part_number?: InputMaybe<Scalars['String']>;
  properties?: InputMaybe<Array<PartPropertyFilter>>;
  serial_number?: InputMaybe<Scalars['String']>;
  spec?: InputMaybe<Spec>;
};

export type PartsQueryInput = {
  filters?: InputMaybe<PartsFilters>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  pagination?: InputMaybe<Pagination>;
  sorts?: InputMaybe<PartSorts>;
};

export type PartsResult = {
  __typename?: 'PartsResult';
  rows: Array<Part>;
  totalCount: Scalars['Int'];
};

export type PropertiesFilters = {
  description?: InputMaybe<Scalars['String']>;
  display_name?: InputMaybe<Scalars['String']>;
  max_max?: InputMaybe<Scalars['Float']>;
  max_min?: InputMaybe<Scalars['Float']>;
  min_max?: InputMaybe<Scalars['Float']>;
  min_min?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Array<InputMaybe<PropertyType>>>;
  unit?: InputMaybe<Scalars['String']>;
};

export type PropertiesQueryInput = {
  filters?: InputMaybe<PropertiesFilters>;
  pagination?: InputMaybe<Pagination>;
  sorts?: InputMaybe<PropertiesSorts>;
};

export type PropertiesResult = {
  __typename?: 'PropertiesResult';
  rows: Array<Property>;
  totalCount: Scalars['Int'];
};

export type PropertiesSorts = {
  description?: InputMaybe<SortDirection>;
  display_name?: InputMaybe<SortDirection>;
  max?: InputMaybe<SortDirection>;
  min?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  type?: InputMaybe<SortDirection>;
  unit?: InputMaybe<SortDirection>;
};

export type Property = {
  __typename?: 'Property';
  description?: Maybe<Scalars['String']>;
  display_name: Scalars['String'];
  id: Scalars['Int'];
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  type: PropertyType;
  unit?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Scalars['String']>>;
};

export enum PropertyType {
  BOOLEAN = 'BOOLEAN',
  COORDINATE = 'COORDINATE',
  NUMBER = 'NUMBER',
  STRING = 'STRING'
}

export type Query = {
  __typename?: 'Query';
  commonSetup?: Maybe<Setup>;
  driveFile?: Maybe<DriveFile>;
  driveFiles: Array<DriveFile>;
  driver?: Maybe<Driver>;
  drivers: Array<Driver>;
  eightPost?: Maybe<EightPost>;
  eightPosts: Array<EightPost>;
  environment?: Maybe<Environment>;
  environments: EnvironmentsResult;
  events: Array<Event>;
  firstPartConfigByCategoryName?: Maybe<PartConfig>;
  lapTimeData?: Maybe<LapTimeData>;
  lapTimeDatas: Array<LapTimeData>;
  lapWindData?: Maybe<LapWindData>;
  lapWindDatas: Array<LapWindData>;
  mainSetups: Array<Setup>;
  part?: Maybe<Part>;
  partCategories: Array<PartCategory>;
  partConfig?: Maybe<PartConfig>;
  partConfigByTypeName?: Maybe<PartConfig>;
  partConfigs: PartConfigsResult;
  partConfigsByCategoryName: Array<PartConfig>;
  parts: PartsResult;
  properties: PropertiesResult;
  query: Array<Document>;
  ruit?: Maybe<RUIT>;
  ruits: RUITsResult;
  runFields: RunFieldsResult;
  runs: Array<Run>;
  runsByRootId: Array<Run>;
  sessionsByEvent: Array<Session>;
  setup: Setup;
  setupBranch?: Maybe<SetupBranch>;
  setupBranchHeadByBranchId: SetupBranchHead;
  setupBranchHeads: SetupBranchHeadsResult;
  setupBranchHeadsByRootId: SetupBranchHeadsResult;
  setupBranches: Array<SetupBranch>;
  setupBranchesByIds: Array<SetupBranch>;
  setupBranchesByRootId: Array<SetupBranch>;
  setupByBranchId?: Maybe<Setup>;
  setupFields: SetupFieldsResult;
  setupHistory: SetupHistoryResult;
  setups: SetupsResult;
  setupsByIds: Array<Setup>;
  setupsByRootId: Array<Setup>;
  sim?: Maybe<Sim>;
  simOutline?: Maybe<SimOutline>;
  simOutlinesByWorksheet: Array<SimOutline>;
  simProgressesByUser: Array<SimProgress>;
  simState?: Maybe<SimStates>;
  simStates: Array<SimStates>;
  simStatusesByUser: Array<SimStatus>;
  simWorksheet?: Maybe<SimWorksheet>;
  simWorksheets: SimWorksheetsResult;
  sims: SimsResult;
  suit?: Maybe<SUIT>;
  suits: SUITsResult;
  sweep?: Maybe<Sweep>;
  sweeps: SweepsResult;
  trackImage?: Maybe<TrackImage>;
  tracks: Array<Track>;
  weather?: Maybe<HistoricalWeather>;
};


export type QuerycommonSetupArgs = {
  setupIdA: Scalars['Int'];
  setupIdB: Scalars['Int'];
};


export type QuerydriveFileArgs = {
  id: Scalars['Int'];
};


export type QuerydriverArgs = {
  id: Scalars['Int'];
};


export type QueryeightPostArgs = {
  id: Scalars['Int'];
};


export type QueryenvironmentArgs = {
  id: Scalars['Int'];
};


export type QueryenvironmentsArgs = {
  input?: InputMaybe<EnvironmentsQueryInput>;
};


export type QueryeventsArgs = {
  series: Scalars['String'];
  year: Scalars['Int'];
};


export type QueryfirstPartConfigByCategoryNameArgs = {
  categoryName: Scalars['String'];
};


export type QuerylapTimeDataArgs = {
  id: Scalars['Int'];
};


export type QuerylapWindDataArgs = {
  id: Scalars['Int'];
};


export type QuerypartArgs = {
  id: Scalars['Int'];
};


export type QuerypartConfigArgs = {
  id: Scalars['Int'];
};


export type QuerypartConfigByTypeNameArgs = {
  typeName: Scalars['String'];
};


export type QuerypartConfigsArgs = {
  input?: InputMaybe<PartConfigsQueryInput>;
};


export type QuerypartConfigsByCategoryNameArgs = {
  categoryName: Scalars['String'];
};


export type QuerypartsArgs = {
  input?: InputMaybe<PartsQueryInput>;
};


export type QuerypropertiesArgs = {
  input?: InputMaybe<PropertiesQueryInput>;
};


export type QueryqueryArgs = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};


export type QueryruitArgs = {
  id: Scalars['Int'];
};


export type QueryruitsArgs = {
  input?: InputMaybe<RUITsQueryInput>;
};


export type QueryrunFieldsArgs = {
  input?: InputMaybe<RunFieldsQueryInput>;
};


export type QueryrunsByRootIdArgs = {
  rootId: Scalars['Int'];
};


export type QuerysessionsByEventArgs = {
  name: Scalars['String'];
  series: Scalars['String'];
  year: Scalars['Int'];
};


export type QuerysetupArgs = {
  id: Scalars['Int'];
};


export type QuerysetupBranchArgs = {
  id: Scalars['Int'];
};


export type QuerysetupBranchHeadByBranchIdArgs = {
  branchId: Scalars['Int'];
};


export type QuerysetupBranchHeadsArgs = {
  input?: InputMaybe<SetupsQueryInput>;
};


export type QuerysetupBranchHeadsByRootIdArgs = {
  input?: InputMaybe<SetupsQueryInput>;
  rootId: Scalars['Int'];
};


export type QuerysetupBranchesByIdsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};


export type QuerysetupBranchesByRootIdArgs = {
  rootId: Scalars['Int'];
};


export type QuerysetupByBranchIdArgs = {
  branchId: Scalars['Int'];
};


export type QuerysetupFieldsArgs = {
  input?: InputMaybe<SetupFieldsQueryInput>;
};


export type QuerysetupHistoryArgs = {
  rootId: Scalars['Int'];
};


export type QuerysetupsArgs = {
  input?: InputMaybe<SetupsQueryInput>;
};


export type QuerysetupsByIdsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};


export type QuerysetupsByRootIdArgs = {
  rootId?: InputMaybe<Scalars['Int']>;
};


export type QuerysimArgs = {
  id: Scalars['Int'];
};


export type QuerysimOutlineArgs = {
  id: Scalars['Int'];
};


export type QuerysimOutlinesByWorksheetArgs = {
  worksheetId: Scalars['Int'];
};


export type QuerysimStateArgs = {
  id: Scalars['Int'];
};


export type QuerysimWorksheetArgs = {
  id: Scalars['Int'];
};


export type QuerysimWorksheetsArgs = {
  input?: InputMaybe<SimWorksheetsQueryInput>;
};


export type QuerysimsArgs = {
  input?: InputMaybe<SimsQueryInput>;
};


export type QuerysuitArgs = {
  id: Scalars['Int'];
};


export type QuerysuitsArgs = {
  input?: InputMaybe<SUITsQueryInput>;
};


export type QuerysweepArgs = {
  id: Scalars['Int'];
};


export type QuerysweepsArgs = {
  input?: InputMaybe<SweepsQueryInput>;
};


export type QuerytrackImageArgs = {
  short_name: Scalars['String'];
};


export type QueryweatherArgs = {
  date: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type RUIT = {
  __typename?: 'RUIT';
  created_at?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  last_modified_by?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  template: Scalars['JSONObject'];
  updated_at?: Maybe<Scalars['Date']>;
  version: Scalars['Float'];
};

export type RUITsFilters = {
  description?: InputMaybe<Scalars['String']>;
  last_modified_by?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type RUITsQueryInput = {
  filters?: InputMaybe<RUITsFilters>;
  pagination?: InputMaybe<Pagination>;
  sorts?: InputMaybe<RUITsSorts>;
};

export type RUITsResult = {
  __typename?: 'RUITsResult';
  rows: Array<RUIT>;
  totalCount: Scalars['Int'];
};

export type RUITsSorts = {
  created_at?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  last_modified_by?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  updated_at?: InputMaybe<SortDirection>;
};

export type RepoDeleteResult = {
  __typename?: 'RepoDeleteResult';
  branches: Scalars['Int'];
  setups: Scalars['Int'];
};

export type Run = {
  __typename?: 'Run';
  branch: SetupBranch;
  data?: Maybe<Scalars['JSONObject']>;
  id: Scalars['Int'];
  session?: Maybe<Scalars['String']>;
  session_id?: Maybe<Scalars['String']>;
};

export type RunField = {
  __typename?: 'RunField';
  id: Scalars['Int'];
  label: Scalars['String'];
  name: Scalars['String'];
  options?: Maybe<Array<Scalars['String']>>;
  positions?: Maybe<Array<RunFieldPosition>>;
  tooltip?: Maybe<Scalars['String']>;
  type: RunFieldType;
};

export type RunFieldPosition = {
  __typename?: 'RunFieldPosition';
  id: Scalars['Int'];
  label: Scalars['String'];
};

export type RunFieldPositionInput = {
  destroy?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  label: Scalars['String'];
};

export enum RunFieldType {
  BOOLEAN = 'BOOLEAN',
  FLOAT = 'FLOAT',
  INT = 'INT',
  STRING = 'STRING',
  TEXT = 'TEXT'
}

export type RunFieldsFilters = {
  label?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Array<InputMaybe<RunFieldType>>>;
};

export type RunFieldsQueryInput = {
  filters?: InputMaybe<RunFieldsFilters>;
  pagination?: InputMaybe<Pagination>;
  sorts?: InputMaybe<RunFieldsSorts>;
};

export type RunFieldsResult = {
  __typename?: 'RunFieldsResult';
  rows: Array<RunField>;
  totalCount: Scalars['Int'];
};

export type RunFieldsSorts = {
  label?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  type?: InputMaybe<SortDirection>;
};

export type SUIT = {
  __typename?: 'SUIT';
  created_at?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  last_modified_by?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  spec: Spec;
  template: Scalars['JSONObject'];
  updated_at?: Maybe<Scalars['Date']>;
  version: Scalars['Float'];
};

export type SUITsFilters = {
  description?: InputMaybe<Scalars['String']>;
  last_modified_by?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  spec?: InputMaybe<Array<InputMaybe<Spec>>>;
};

export type SUITsQueryInput = {
  filters?: InputMaybe<SUITsFilters>;
  pagination?: InputMaybe<Pagination>;
  sorts?: InputMaybe<SUITsSorts>;
};

export type SUITsResult = {
  __typename?: 'SUITsResult';
  rows: Array<SUIT>;
  totalCount: Scalars['Int'];
};

export type SUITsSorts = {
  created_at?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  last_modified_by?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  spec?: InputMaybe<SortDirection>;
  updated_at?: InputMaybe<SortDirection>;
};

export type Session = {
  __typename?: 'Session';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Setup = {
  __typename?: 'Setup';
  car: Scalars['String'];
  children?: Maybe<Array<Setup>>;
  created_at?: Maybe<Scalars['Date']>;
  data?: Maybe<Scalars['JSONObject']>;
  description?: Maybe<Scalars['String']>;
  event: Scalars['String'];
  id: Scalars['Int'];
  meta: SetupMeta;
  name: Scalars['String'];
  owner: Scalars['String'];
  parent?: Maybe<Setup>;
  parts?: Maybe<Array<Part>>;
  root?: Maybe<Setup>;
  runs?: Maybe<Array<Run>>;
  series: Scalars['String'];
  spec: Spec;
  updated_at?: Maybe<Scalars['Date']>;
  year: Scalars['Int'];
};

export type SetupBranch = {
  __typename?: 'SetupBranch';
  created_at?: Maybe<Scalars['Date']>;
  head: Setup;
  id: Scalars['Int'];
  name: Scalars['String'];
  root: Setup;
  tail?: Maybe<Setup>;
};

export type SetupBranchHead = {
  __typename?: 'SetupBranchHead';
  branch: SetupBranch;
  numBranches?: Maybe<Scalars['Int']>;
  setup: Setup;
};

export type SetupBranchHeadsResult = {
  __typename?: 'SetupBranchHeadsResult';
  rows: Array<SetupBranchHead>;
  totalCount: Scalars['Int'];
};

export type SetupBranchInput = {
  head_id: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['String'];
  root_id: Scalars['Int'];
  tail_id?: InputMaybe<Scalars['Int']>;
};

export type SetupField = {
  __typename?: 'SetupField';
  id: Scalars['Int'];
  label: Scalars['String'];
  name: Scalars['String'];
  options?: Maybe<Array<Scalars['String']>>;
  part_config?: Maybe<PartConfig>;
  path: Scalars['String'];
  positions?: Maybe<Array<SetupFieldPosition>>;
  specs?: Maybe<Array<Maybe<Spec>>>;
  tooltip?: Maybe<Scalars['String']>;
  type: SetupFieldType;
  use_in_sim: Scalars['Boolean'];
};

export type SetupFieldPosition = {
  __typename?: 'SetupFieldPosition';
  id: Scalars['Int'];
  label: Scalars['String'];
  path_part?: Maybe<Scalars['String']>;
};

export type SetupFieldPositionInput = {
  destroy?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  label: Scalars['String'];
  path_part?: InputMaybe<Scalars['String']>;
};

export enum SetupFieldType {
  BOOLEAN = 'BOOLEAN',
  FLOAT = 'FLOAT',
  INT = 'INT',
  PART = 'PART',
  STRING = 'STRING'
}

export type SetupFieldsFilters = {
  label?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  part_config?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  path?: InputMaybe<Scalars['String']>;
  specs?: InputMaybe<Array<InputMaybe<Spec>>>;
  type?: InputMaybe<Array<InputMaybe<SetupFieldType>>>;
  use_in_sim?: InputMaybe<Scalars['Boolean']>;
};

export type SetupFieldsQueryInput = {
  filters?: InputMaybe<SetupFieldsFilters>;
  pagination?: InputMaybe<Pagination>;
  sorts?: InputMaybe<SetupFieldsSorts>;
};

export type SetupFieldsResult = {
  __typename?: 'SetupFieldsResult';
  rows: Array<SetupField>;
  totalCount: Scalars['Int'];
};

export type SetupFieldsSorts = {
  label?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  part_config?: InputMaybe<SortDirection>;
  path?: InputMaybe<SortDirection>;
  type?: InputMaybe<SortDirection>;
  use_in_sim?: InputMaybe<SortDirection>;
};

export type SetupHistoryResult = {
  __typename?: 'SetupHistoryResult';
  branches: Array<SetupBranch>;
  setups: Array<Setup>;
};

export type SetupMeta = {
  __typename?: 'SetupMeta';
  cloned_from_setup?: Maybe<Setup>;
  full_merge?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  merged_from_setup?: Maybe<Setup>;
  setup: Setup;
  user: Scalars['String'];
};

export type SetupsFilters = {
  car?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['String']>;
  session?: InputMaybe<Scalars['String']>;
  spec?: InputMaybe<Spec>;
  track?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type SetupsQueryInput = {
  filters?: InputMaybe<SetupsFilters>;
  pagination?: InputMaybe<Pagination>;
  sorts?: InputMaybe<SetupsSorts>;
};

export type SetupsResult = {
  __typename?: 'SetupsResult';
  rows: Array<Setup>;
  totalCount: Scalars['Int'];
};

export type SetupsSorts = {
  car?: InputMaybe<SortDirection>;
  created_at?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  event?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  owner?: InputMaybe<SortDirection>;
  session?: InputMaybe<SortDirection>;
  spec?: InputMaybe<SortDirection>;
  track?: InputMaybe<SortDirection>;
  updated_at?: InputMaybe<SortDirection>;
  year?: InputMaybe<SortDirection>;
};

export type Sim = {
  __typename?: 'Sim';
  created_at?: Maybe<Scalars['Date']>;
  data: Scalars['JSONObject'];
  download_debug?: Maybe<Scalars['Boolean']>;
  download_edf?: Maybe<Scalars['Boolean']>;
  download_json?: Maybe<Scalars['Boolean']>;
  download_metrics?: Maybe<Scalars['Boolean']>;
  download_motec?: Maybe<Scalars['Boolean']>;
  download_vdf?: Maybe<Scalars['Boolean']>;
  drive_file?: Maybe<DriveFile>;
  drive_file_id?: Maybe<Scalars['Int']>;
  driver?: Maybe<Driver>;
  driver_id?: Maybe<Scalars['Int']>;
  eight_post?: Maybe<EightPost>;
  eight_post_id?: Maybe<Scalars['Int']>;
  export_debug?: Maybe<Scalars['Boolean']>;
  export_json?: Maybe<Scalars['Boolean']>;
  export_metrics?: Maybe<Scalars['Boolean']>;
  export_motec?: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  lap_time_data?: Maybe<LapTimeData>;
  lap_time_data_id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  output_directory?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  sim_states?: Maybe<SimStates>;
  sim_states_id?: Maybe<Scalars['Int']>;
  spec: Spec;
  state_option_id?: Maybe<Scalars['Int']>;
  type: SimType;
  updated_at?: Maybe<Scalars['Date']>;
};

export type SimFilters = {
  name?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['String']>;
  spec?: InputMaybe<Array<InputMaybe<Spec>>>;
  type?: InputMaybe<Array<InputMaybe<SimType>>>;
};

export type SimMessageResult = {
  __typename?: 'SimMessageResult';
  messages?: Maybe<Array<Maybe<Message>>>;
  msgCnt?: Maybe<Scalars['Int']>;
  msgId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type SimOutline = {
  __typename?: 'SimOutline';
  environments?: Maybe<Array<Environment>>;
  id: Scalars['Int'];
  name: Scalars['String'];
  order_index: Scalars['Int'];
  setup_branches?: Maybe<Array<SetupBranch>>;
  sims?: Maybe<Array<Sim>>;
  worksheet?: Maybe<SimWorksheet>;
  worksheet_id: Scalars['Int'];
};

export type SimProgress = {
  __typename?: 'SimProgress';
  applyOptimizationResult?: Maybe<Scalars['Boolean']>;
  cancelId?: Maybe<Scalars['String']>;
  curr?: Maybe<Scalars['Int']>;
  displayName?: Maybe<Scalars['String']>;
  finished?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['String']>;
  isBatch?: Maybe<Scalars['Boolean']>;
  percentage?: Maybe<Scalars['Int']>;
  requestId?: Maybe<Scalars['String']>;
  series?: Maybe<Scalars['String']>;
  setupId?: Maybe<Scalars['String']>;
  simEngine?: Maybe<Scalars['String']>;
  started?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  submitted?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  vehicle?: Maybe<Scalars['String']>;
};

export type SimSorts = {
  created_at?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  owner?: InputMaybe<SortDirection>;
  spec?: InputMaybe<SortDirection>;
  type?: InputMaybe<SortDirection>;
  updated_at?: InputMaybe<SortDirection>;
};

export type SimStates = {
  __typename?: 'SimStates';
  created_at?: Maybe<Scalars['Date']>;
  desc: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: Scalars['String'];
  states: Array<SimStatesState>;
  status: Scalars['String'];
  track?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['Date']>;
};

export type SimStatesInput = {
  desc: Scalars['String'];
  name: Scalars['String'];
  track?: InputMaybe<Scalars['String']>;
};

export type SimStatesState = {
  __typename?: 'SimStatesState';
  brake?: Maybe<Scalars['JSONObject']>;
  engine?: Maybe<Scalars['JSONObject']>;
  fuel?: Maybe<Scalars['JSONObject']>;
  id: Scalars['Int'];
  label: Scalars['String'];
  tire?: Maybe<Scalars['JSONObject']>;
};

export type SimStatesStateBrakeInput = {
  rotor_temperature_filtered_lf: Scalars['Float'];
  rotor_temperature_filtered_lr: Scalars['Float'];
  rotor_temperature_filtered_rf: Scalars['Float'];
  rotor_temperature_filtered_rr: Scalars['Float'];
  rotor_temperature_lf: Scalars['Float'];
  rotor_temperature_lr: Scalars['Float'];
  rotor_temperature_rf: Scalars['Float'];
  rotor_temperature_rr: Scalars['Float'];
};

export type SimStatesStateEngineInput = {
  alternator_current: Scalars['Float'];
  water_temp: Scalars['Float'];
};

export type SimStatesStateFuelInput = {
  burn: Scalars['Float'];
};

export type SimStatesStateInput = {
  brake: SimStatesStateBrakeInput;
  engine: SimStatesStateEngineInput;
  fuel: SimStatesStateFuelInput;
  label: Scalars['String'];
  tire: SimStatesStateTireInput;
};

export type SimStatesStateTireInput = {
  global_mu: Scalars['Float'];
  lfxc_lf: Scalars['Float'];
  lfxc_lr: Scalars['Float'];
  lfxc_rf: Scalars['Float'];
  lfxc_rr: Scalars['Float'];
  lfyc_lf: Scalars['Float'];
  lfyc_lr: Scalars['Float'];
  lfyc_rf: Scalars['Float'];
  lfyc_rr: Scalars['Float'];
  lkx_lf: Scalars['Float'];
  lkx_lr: Scalars['Float'];
  lkx_rf: Scalars['Float'];
  lkx_rr: Scalars['Float'];
  lkxb_lf: Scalars['Float'];
  lkxb_lr: Scalars['Float'];
  lkxb_rf: Scalars['Float'];
  lkxb_rr: Scalars['Float'];
  lkxc_lf: Scalars['Float'];
  lkxc_lr: Scalars['Float'];
  lkxc_rf: Scalars['Float'];
  lkxc_rr: Scalars['Float'];
  lkxp_lf: Scalars['Float'];
  lkxp_lr: Scalars['Float'];
  lkxp_rf: Scalars['Float'];
  lkxp_rr: Scalars['Float'];
  lky_lf: Scalars['Float'];
  lky_lr: Scalars['Float'];
  lky_rf: Scalars['Float'];
  lky_rr: Scalars['Float'];
  lkyc_lf: Scalars['Float'];
  lkyc_lr: Scalars['Float'];
  lkyc_rf: Scalars['Float'];
  lkyc_rr: Scalars['Float'];
  lmufzx_lf: Scalars['Float'];
  lmufzx_lr: Scalars['Float'];
  lmufzx_rf: Scalars['Float'];
  lmufzx_rr: Scalars['Float'];
  lmufzy_lf: Scalars['Float'];
  lmufzy_lr: Scalars['Float'];
  lmufzy_rf: Scalars['Float'];
  lmufzy_rr: Scalars['Float'];
  lmuiaoy_lf: Scalars['Float'];
  lmuiaoy_lr: Scalars['Float'];
  lmuiaoy_rf: Scalars['Float'];
  lmuiaoy_rr: Scalars['Float'];
  lmuiax_lf: Scalars['Float'];
  lmuiax_lr: Scalars['Float'];
  lmuiax_rf: Scalars['Float'];
  lmuiax_rr: Scalars['Float'];
  lmuiay_lf: Scalars['Float'];
  lmuiay_lr: Scalars['Float'];
  lmuiay_rf: Scalars['Float'];
  lmuiay_rr: Scalars['Float'];
  lmupoy_lf: Scalars['Float'];
  lmupoy_lr: Scalars['Float'];
  lmupoy_rf: Scalars['Float'];
  lmupoy_rr: Scalars['Float'];
  lmupx_lf: Scalars['Float'];
  lmupx_lr: Scalars['Float'];
  lmupx_rf: Scalars['Float'];
  lmupx_rr: Scalars['Float'];
  lmupy_lf: Scalars['Float'];
  lmupy_lr: Scalars['Float'];
  lmupy_rf: Scalars['Float'];
  lmupy_rr: Scalars['Float'];
  lmux_lf: Scalars['Float'];
  lmux_lr: Scalars['Float'];
  lmux_rf: Scalars['Float'];
  lmux_rr: Scalars['Float'];
  lmuy_lf: Scalars['Float'];
  lmuy_lr: Scalars['Float'];
  lmuy_rf: Scalars['Float'];
  lmuy_rr: Scalars['Float'];
  ltmz_lf: Scalars['Float'];
  ltmz_lr: Scalars['Float'];
  ltmz_rf: Scalars['Float'];
  ltmz_rr: Scalars['Float'];
  lxmx_lf: Scalars['Float'];
  lxmx_lr: Scalars['Float'];
  lxmx_rf: Scalars['Float'];
  lxmx_rr: Scalars['Float'];
  lxmy_lf: Scalars['Float'];
  lxmy_lr: Scalars['Float'];
  lxmy_rf: Scalars['Float'];
  lxmy_rr: Scalars['Float'];
  lyax_lf: Scalars['Float'];
  lyax_lr: Scalars['Float'];
  lyax_rf: Scalars['Float'];
  lyax_rr: Scalars['Float'];
  lyay_lf: Scalars['Float'];
  lyay_lr: Scalars['Float'];
  lyay_rf: Scalars['Float'];
  lyay_rr: Scalars['Float'];
  pressure_build_lf: Scalars['Float'];
  pressure_build_lr: Scalars['Float'];
  pressure_build_rf: Scalars['Float'];
  pressure_build_rr: Scalars['Float'];
  squareness_combined_lf: Scalars['Float'];
  squareness_combined_lr: Scalars['Float'];
  squareness_combined_rf: Scalars['Float'];
  squareness_combined_rr: Scalars['Float'];
  temperature_lf: Scalars['Float'];
  temperature_lr: Scalars['Float'];
  temperature_rf: Scalars['Float'];
  temperature_rr: Scalars['Float'];
  wear_lf: Scalars['Float'];
  wear_lr: Scalars['Float'];
  wear_rf: Scalars['Float'];
  wear_rr: Scalars['Float'];
};

export type SimStatus = {
  __typename?: 'SimStatus';
  applyOptimizationResult?: Maybe<Scalars['Boolean']>;
  cancelId?: Maybe<Scalars['String']>;
  curr?: Maybe<Scalars['Int']>;
  displayName?: Maybe<Scalars['String']>;
  finished?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['String']>;
  isBatch?: Maybe<Scalars['Boolean']>;
  percentage?: Maybe<Scalars['Int']>;
  requestId?: Maybe<Scalars['String']>;
  series?: Maybe<Scalars['String']>;
  setupId?: Maybe<Scalars['String']>;
  simEngine?: Maybe<Scalars['String']>;
  started?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  submitted?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  vehicle?: Maybe<Scalars['String']>;
};

export enum SimType {
  EIGHTPOST = 'EIGHTPOST',
  ELAP = 'ELAP',
  KINEMATICS = 'KINEMATICS',
  RIDEMODEL = 'RIDEMODEL',
  SETUP = 'SETUP',
  SIMULATOR = 'SIMULATOR'
}

export type SimWorksheet = {
  __typename?: 'SimWorksheet';
  car?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  environment?: Maybe<Environment>;
  event?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  output_directory?: Maybe<Scalars['String']>;
  owner: Scalars['String'];
  session?: Maybe<Scalars['String']>;
  sim_outlines?: Maybe<Array<SimOutline>>;
  spec?: Maybe<Spec>;
  track?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['Date']>;
  year?: Maybe<Scalars['Int']>;
};

export type SimWorksheetInput = {
  car?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  environment_id?: InputMaybe<Scalars['Int']>;
  event?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  output_directory?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['String']>;
  session?: InputMaybe<Scalars['String']>;
  spec?: InputMaybe<Spec>;
  track?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type SimWorksheetsFilters = {
  car?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['String']>;
  session?: InputMaybe<Scalars['String']>;
  track?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['Int']>;
};

export type SimWorksheetsQueryInput = {
  filters?: InputMaybe<SimWorksheetsFilters>;
  pagination?: InputMaybe<Pagination>;
  sorts?: InputMaybe<SimWorksheetsSorts>;
};

export type SimWorksheetsResult = {
  __typename?: 'SimWorksheetsResult';
  rows: Array<SimWorksheet>;
  totalCount: Scalars['Int'];
};

export type SimWorksheetsSorts = {
  car?: InputMaybe<SortDirection>;
  created_at?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  event?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  owner?: InputMaybe<SortDirection>;
  session?: InputMaybe<SortDirection>;
  track?: InputMaybe<SortDirection>;
  updated_at?: InputMaybe<SortDirection>;
  year?: InputMaybe<SortDirection>;
};

export type SimsQueryInput = {
  filters?: InputMaybe<SimFilters>;
  pagination?: InputMaybe<Pagination>;
  sorts?: InputMaybe<SimSorts>;
};

export type SimsResult = {
  __typename?: 'SimsResult';
  rows: Array<Sim>;
  totalCount: Scalars['Int'];
};

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum Spec {
  GEN6 = 'GEN6',
  GEN7 = 'GEN7',
  GT3 = 'GT3'
}

export type Subscription = {
  __typename?: 'Subscription';
  batchStatus?: Maybe<Scalars['JSONObject']>;
  simProgress?: Maybe<Scalars['JSONObject']>;
  simResult?: Maybe<Scalars['JSONObject']>;
  simStatus?: Maybe<Scalars['JSONObject']>;
};

export type Sweep = {
  __typename?: 'Sweep';
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  method: SweepMethod;
  name: Scalars['String'];
  num_run?: Maybe<Scalars['Float']>;
  owner: Scalars['String'];
  parameters?: Maybe<Array<SweepParameter>>;
  parts?: Maybe<Array<SweepPart>>;
  sweep_data?: Maybe<Scalars['JSONObject']>;
  sweep_groups?: Maybe<Array<SweepGroup>>;
};

export type SweepFilters = {
  method?: InputMaybe<SweepMethod>;
  name?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['String']>;
};

export type SweepGroup = {
  __typename?: 'SweepGroup';
  enabled: Scalars['Boolean'];
  id: Scalars['Int'];
  name: Scalars['String'];
  parameters?: Maybe<Array<SweepParameter>>;
  parts?: Maybe<Array<SweepPart>>;
};

export type SweepGroupInput = {
  enabled: Scalars['Boolean'];
  name: Scalars['String'];
  parameters?: InputMaybe<Array<SweepParameterInput>>;
  parts?: InputMaybe<Array<SweepPartInput>>;
};

export enum SweepMethod {
  FULLFACTORIAL = 'FULLFACTORIAL',
  RANDOM = 'RANDOM'
}

export type SweepParameter = {
  __typename?: 'SweepParameter';
  enabled: Scalars['Boolean'];
  id: Scalars['Int'];
  inc?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  param_application_method: SweepParameterApplicationMethod;
  param_generation_method: SweepParameterGenerationMethod;
  path: Scalars['String'];
  sweep_group_id?: Maybe<Scalars['Int']>;
  sweep_id?: Maybe<Scalars['Int']>;
};

export enum SweepParameterApplicationMethod {
  ABSOLUTE = 'ABSOLUTE',
  DELTA = 'DELTA'
}

export enum SweepParameterGenerationMethod {
  RANGE = 'RANGE',
  STEP = 'STEP'
}

export type SweepParameterInput = {
  enabled: Scalars['Boolean'];
  id?: InputMaybe<Scalars['Int']>;
  inc?: InputMaybe<Scalars['Float']>;
  max?: InputMaybe<Scalars['Float']>;
  min?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  param_application_method: SweepParameterApplicationMethod;
  param_generation_method: SweepParameterGenerationMethod;
  path: Scalars['String'];
  sweep_group_id?: InputMaybe<Scalars['Int']>;
  sweep_id?: InputMaybe<Scalars['Int']>;
};

export type SweepPart = {
  __typename?: 'SweepPart';
  enabled: Scalars['Boolean'];
  id: Scalars['Int'];
  part_config?: Maybe<PartConfig>;
  part_config_id: Scalars['Int'];
  parts?: Maybe<Array<Part>>;
  position?: Maybe<Scalars['String']>;
  sweep_group_id?: Maybe<Scalars['Int']>;
  sweep_id?: Maybe<Scalars['Int']>;
};

export type SweepPartInput = {
  enabled: Scalars['Boolean'];
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  part_config_id: Scalars['Int'];
  parts: Array<SweepPartPartInput>;
  position: Scalars['String'];
  sweep_group_id?: InputMaybe<Scalars['Int']>;
  sweep_id?: InputMaybe<Scalars['Int']>;
};

export type SweepPartPartInput = {
  id: Scalars['Int'];
};

export type SweepsQueryInput = {
  filters?: InputMaybe<SweepFilters>;
  pagination?: InputMaybe<Pagination>;
  sorts?: InputMaybe<SimSorts>;
};

export type SweepsResult = {
  __typename?: 'SweepsResult';
  rows: Array<Sweep>;
  totalCount: Scalars['Int'];
};

export type Track = {
  __typename?: 'Track';
  city: Scalars['String'];
  created_at?: Maybe<Scalars['Date']>;
  elevation?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  latitude?: Maybe<Scalars['Float']>;
  long_name: Scalars['String'];
  longitude?: Maybe<Scalars['Float']>;
  mini_name: Scalars['String'];
  short_name: Scalars['String'];
  state: Scalars['String'];
  timezone_id: Scalars['String'];
  updated_at?: Maybe<Scalars['Date']>;
};

export type TrackImage = {
  __typename?: 'TrackImage';
  svg_url: Scalars['String'];
};

export type UpdateDriveFileInput = {
  data: DriveFileDataInput;
  desc: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  venue: Scalars['String'];
};

export type UpdateDriverInput = {
  data: Scalars['JSONObject'];
  desc: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  track: Scalars['String'];
};

export type UpdateEightPostInput = {
  data: EightPostDataInput;
  desc: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  venue: Scalars['String'];
};

export type UpdateEnvironmentInput = {
  historical_time?: InputMaybe<Scalars['Date']>;
  historical_weather?: InputMaybe<Scalars['JSONObject']>;
  humidity: Scalars['Float'];
  id: Scalars['Int'];
  lap_wind_data_id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  pressure: Scalars['Float'];
  temperature: Scalars['Float'];
  use_lap_wind_data?: InputMaybe<Scalars['Boolean']>;
  wind_direction: Scalars['Float'];
  wind_scalar: Scalars['Float'];
  wind_speed: Scalars['Float'];
};

export type UpdateLapTimeDataInput = {
  data: Scalars['JSONObject'];
  desc: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type UpdateLapWindDataInput = {
  data: LapWindDataInput;
  desc: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type UpdatePartConfigInput = {
  display_name: Scalars['String'];
  expires: Scalars['Boolean'];
  id: Scalars['Int'];
  mileage_limit?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  part_category_id: Scalars['Int'];
  properties?: InputMaybe<Array<PartConfigPropertyInput>>;
};

export type UpdatePartInput = {
  data: Scalars['JSONObject'];
  description: Scalars['String'];
  exp_date?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  mileage?: InputMaybe<Scalars['Int']>;
  part_config_id: Scalars['Int'];
  part_number?: InputMaybe<Scalars['String']>;
  properties?: InputMaybe<Array<PartPropertyInput>>;
  serial_number?: InputMaybe<Scalars['String']>;
  spec: Spec;
};

export type UpdatePropertyInput = {
  description?: InputMaybe<Scalars['String']>;
  display_name: Scalars['String'];
  id: Scalars['Int'];
  max?: InputMaybe<Scalars['Float']>;
  min?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  path?: InputMaybe<Scalars['String']>;
  type: PropertyType;
  unit?: InputMaybe<Scalars['String']>;
  values?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateRUITInput = {
  created_at?: InputMaybe<Scalars['Date']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  last_modified_by?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  template: Scalars['JSONObject'];
  updated_at?: InputMaybe<Scalars['Date']>;
  version: Scalars['Float'];
};

export type UpdateRunFieldInput = {
  id: Scalars['Int'];
  label: Scalars['String'];
  name: Scalars['String'];
  options?: InputMaybe<Array<Scalars['String']>>;
  positions?: InputMaybe<Array<RunFieldPositionInput>>;
  tooltip?: InputMaybe<Scalars['String']>;
  type: RunFieldType;
};

export type UpdateRunInput = {
  branch?: InputMaybe<Scalars['JSONObject']>;
  data?: InputMaybe<Scalars['JSONObject']>;
  id: Scalars['Int'];
};

export type UpdateSUITInput = {
  created_at?: InputMaybe<Scalars['Date']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  last_modified_by?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  spec: Spec;
  template: Scalars['JSONObject'];
  updated_at?: InputMaybe<Scalars['Date']>;
  version: Scalars['Float'];
};

export type UpdateSetupFieldInput = {
  id: Scalars['Int'];
  label: Scalars['String'];
  name: Scalars['String'];
  options?: InputMaybe<Array<Scalars['String']>>;
  part_config_id?: InputMaybe<Scalars['Int']>;
  path: Scalars['String'];
  positions?: InputMaybe<Array<SetupFieldPositionInput>>;
  specs?: InputMaybe<Array<InputMaybe<Spec>>>;
  tooltip?: InputMaybe<Scalars['String']>;
  type: SetupFieldType;
  use_in_sim: Scalars['Boolean'];
};

export type UpdateSetupInput = {
  car: Scalars['String'];
  created_at?: InputMaybe<Scalars['Date']>;
  data?: InputMaybe<Scalars['JSONObject']>;
  description?: InputMaybe<Scalars['String']>;
  event: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  owner: Scalars['String'];
  parent_id?: InputMaybe<Scalars['Int']>;
  root_id: Scalars['Int'];
  series: Scalars['String'];
  spec: Spec;
  updated_at?: InputMaybe<Scalars['Date']>;
  year: Scalars['Int'];
};

export type UpdateSimInput = {
  data: Scalars['JSONObject'];
  download_debug?: InputMaybe<Scalars['Boolean']>;
  download_edf?: InputMaybe<Scalars['Boolean']>;
  download_json?: InputMaybe<Scalars['Boolean']>;
  download_metrics?: InputMaybe<Scalars['Boolean']>;
  download_motec?: InputMaybe<Scalars['Boolean']>;
  download_vdf?: InputMaybe<Scalars['Boolean']>;
  drive_file_id?: InputMaybe<Scalars['Int']>;
  driver_id?: InputMaybe<Scalars['Int']>;
  eight_post_id?: InputMaybe<Scalars['Int']>;
  export_debug?: InputMaybe<Scalars['Boolean']>;
  export_json?: InputMaybe<Scalars['Boolean']>;
  export_metrics?: InputMaybe<Scalars['Boolean']>;
  export_motec?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  lap_time_data_id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  output_directory?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['String']>;
  sim_states_id?: InputMaybe<Scalars['Int']>;
  spec: Spec;
  state_option_id?: InputMaybe<Scalars['Int']>;
  type: SimType;
};

export type UpdateSimOutlineDetailsInput = {
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type UpdateSimStatesInput = {
  id: Scalars['Int'];
  simStates: SimStatesInput;
  simStatesStates: Array<SimStatesStateInput>;
};

export type UpdateSweepInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  method: SweepMethod;
  name: Scalars['String'];
  num_run?: InputMaybe<Scalars['Float']>;
  owner: Scalars['String'];
  parameters?: InputMaybe<Array<SweepParameterInput>>;
  parts?: InputMaybe<Array<SweepPartInput>>;
  sweep_groups?: InputMaybe<Array<SweepGroupInput>>;
};

export type allPartConfigFieldsFragment = { __typename?: 'PartConfig', id: number, name: string, display_name: string, mileage_limit?: number | null, expires: boolean, category: { __typename?: 'PartCategory', id: number, name: string, display_name: string, description?: string | null } };

export type allSetupFieldFieldsFragment = { __typename?: 'SetupField', id: number, specs?: Array<Spec | null> | null, name: string, type: SetupFieldType, label: string, path: string, tooltip?: string | null, options?: Array<string> | null, use_in_sim: boolean, part_config?: { __typename?: 'PartConfig', id: number, name: string, display_name: string, expires: boolean, mileage_limit?: number | null, category: { __typename?: 'PartCategory', id: number, name: string, display_name: string, description?: string | null } } | null, positions?: Array<{ __typename?: 'SetupFieldPosition', id: number, label: string, path_part?: string | null }> | null };

export type suitFormSetupFieldFieldsFragment = { __typename?: 'SetupField', specs?: Array<Spec | null> | null, name: string, type: SetupFieldType, label: string, path: string, tooltip?: string | null, options?: Array<string> | null, use_in_sim: boolean, part_config?: { __typename?: 'PartConfig', name: string, display_name: string } | null, positions?: Array<{ __typename?: 'SetupFieldPosition', id: number, label: string, path_part?: string | null }> | null };

export type allSUITFieldsFragment = { __typename?: 'SUIT', id: number, spec: Spec, name: string, description?: string | null, last_modified_by?: string | null, version: number, template: any, created_at?: any | null, updated_at?: any | null };

export type mostSetupFieldsFragment = { __typename?: 'Setup', car: string, description?: string | null, event: string, id: number, name: string, spec: Spec, owner: string, data?: any | null, year: number, series: string, created_at?: any | null, updated_at?: any | null, parent?: { __typename?: 'Setup', id: number } | null, parts?: Array<{ __typename?: 'Part', id: number, part_number?: string | null, serial_number?: string | null, description: string, mileage?: number | null }> | null, root?: { __typename?: 'Setup', id: number } | null };

export type mostSetupHeadFieldsFragment = { __typename?: 'SetupBranchHead', numBranches?: number | null, setup: { __typename?: 'Setup', car: string, description?: string | null, event: string, id: number, name: string, spec: Spec, owner: string, data?: any | null, year: number, series: string, created_at?: any | null, updated_at?: any | null, parent?: { __typename?: 'Setup', id: number } | null, root?: { __typename?: 'Setup', id: number } | null }, branch: { __typename?: 'SetupBranch', id: number, name: string } };

export type mostSetupHeadFieldsWithPartsFragment = { __typename?: 'SetupBranchHead', numBranches?: number | null, setup: { __typename?: 'Setup', car: string, description?: string | null, event: string, id: number, name: string, spec: Spec, owner: string, data?: any | null, year: number, series: string, created_at?: any | null, updated_at?: any | null, parent?: { __typename?: 'Setup', id: number } | null, root?: { __typename?: 'Setup', id: number } | null, parts?: Array<{ __typename?: 'Part', id: number, part_number?: string | null, serial_number?: string | null, description: string, mileage?: number | null }> | null }, branch: { __typename?: 'SetupBranch', id: number, name: string } };

export type simOutlineFieldsFragment = { __typename?: 'SimOutline', id: number, order_index: number, name: string, worksheet?: { __typename?: 'SimWorksheet', id: number } | null, sims?: Array<{ __typename?: 'Sim', id: number, name: string }> | null, setup_branches?: Array<{ __typename?: 'SetupBranch', id: number, name: string, head: { __typename?: 'Setup', id: number, name: string } }> | null, environments?: Array<{ __typename?: 'Environment', id: number, name: string }> | null };

export type sweepParameterFieldsFragment = { __typename?: 'SweepParameter', enabled: boolean, name: string, path: string, param_application_method: SweepParameterApplicationMethod, param_generation_method: SweepParameterGenerationMethod, min?: number | null, max?: number | null, inc?: number | null, sweep_id?: number | null, sweep_group_id?: number | null };

export type sweepPartFieldsFragment = { __typename?: 'SweepPart', enabled: boolean, position?: string | null, sweep_id?: number | null, sweep_group_id?: number | null, part_config?: { __typename?: 'PartConfig', name: string, display_name: string } | null, parts?: Array<{ __typename?: 'Part', part_number?: string | null, serial_number?: string | null }> | null };

export type CreatePropertyMutationVariables = Exact<{
  input: CreatePropertyInput;
}>;


export type CreatePropertyMutation = { __typename?: 'Mutation', property?: { __typename?: 'Property', id: number, name: string, display_name: string, description?: string | null, path?: string | null, type: PropertyType, min?: number | null, max?: number | null, values?: Array<string> | null, unit?: string | null } | null };

export type UpdatePropertyMutationVariables = Exact<{
  input: UpdatePropertyInput;
}>;


export type UpdatePropertyMutation = { __typename?: 'Mutation', property?: { __typename?: 'Property', id: number, name: string, display_name: string, description?: string | null, path?: string | null, type: PropertyType, min?: number | null, max?: number | null, values?: Array<string> | null, unit?: string | null } | null };

export type CreatePartCategoryMutationVariables = Exact<{
  input: CreatePartCategoryInput;
}>;


export type CreatePartCategoryMutation = { __typename?: 'Mutation', partCategory?: { __typename?: 'PartCategory', id: number, name: string, display_name: string, description?: string | null } | null };

export type CreatePartConfigMutationVariables = Exact<{
  input: CreatePartConfigInput;
}>;


export type CreatePartConfigMutation = { __typename?: 'Mutation', partConfig?: { __typename?: 'PartConfig', id: number, name: string, display_name: string, mileage_limit?: number | null, expires: boolean, category: { __typename?: 'PartCategory', id: number, name: string, display_name: string, description?: string | null } } | null };

export type UpdatePartConfigMutationVariables = Exact<{
  input: UpdatePartConfigInput;
}>;


export type UpdatePartConfigMutation = { __typename?: 'Mutation', partConfig?: { __typename?: 'PartConfig', id: number, name: string, display_name: string, mileage_limit?: number | null, expires: boolean, category: { __typename?: 'PartCategory', id: number, name: string, display_name: string, description?: string | null } } | null };

export type DeletePartConfigMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeletePartConfigMutation = { __typename?: 'Mutation', deletePartConfig?: number | null };

export type BulkDeletePartConfigsMutationVariables = Exact<{
  ids: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type BulkDeletePartConfigsMutation = { __typename?: 'Mutation', bulkDeletePartConfigs?: number | null };

export type CreatePartMutationVariables = Exact<{
  input: CreatePartInput;
}>;


export type CreatePartMutation = { __typename?: 'Mutation', part?: { __typename?: 'Part', id: number } | null };

export type RequestPartPlottingMutationVariables = Exact<{
  input: Array<PartInput> | PartInput;
  partType: Scalars['String'];
}>;


export type RequestPartPlottingMutation = { __typename?: 'Mutation', PartPlotRequest?: { __typename?: 'PartPlotRequest', success: boolean } | null };

export type RequestPartFitMutationVariables = Exact<{
  input: Array<PartInput> | PartInput;
  partType: Scalars['String'];
}>;


export type RequestPartFitMutation = { __typename?: 'Mutation', PartFitRequest?: { __typename?: 'PartFitRequest', success: boolean } | null };

export type UpdatePartMutationVariables = Exact<{
  input: UpdatePartInput;
}>;


export type UpdatePartMutation = { __typename?: 'Mutation', part?: { __typename?: 'Part', id: number } | null };

export type ImportPartFileMutationVariables = Exact<{
  fileBuffer: Scalars['String'];
  fileName: Scalars['String'];
  pos: Scalars['String'];
  type: Scalars['String'];
}>;


export type ImportPartFileMutation = { __typename?: 'Mutation', importPartFile: { __typename?: 'ImportPartResult', success: boolean, type: string, pos: string, data?: any | null, error?: string | null } };

export type CreateRunMutationVariables = Exact<{
  fromBranchId: Scalars['Int'];
  name: Scalars['String'];
  session?: InputMaybe<Scalars['String']>;
  sessionId?: InputMaybe<Scalars['String']>;
  input: CreateRunInput;
}>;


export type CreateRunMutation = { __typename?: 'Mutation', run?: { __typename?: 'Run', id: number, data?: any | null, session?: string | null, session_id?: string | null, branch: { __typename?: 'SetupBranch', id: number, name: string } } | null };

export type BulkUpdateRunsMutationVariables = Exact<{
  inputs: Array<UpdateRunInput> | UpdateRunInput;
}>;


export type BulkUpdateRunsMutation = { __typename?: 'Mutation', runs?: Array<{ __typename?: 'Run', id: number, data?: any | null, session?: string | null, session_id?: string | null, branch: { __typename?: 'SetupBranch', id: number, name: string } } | null> | null };

export type UpdateRunMutationVariables = Exact<{
  input: UpdateRunInput;
}>;


export type UpdateRunMutation = { __typename?: 'Mutation', run?: { __typename?: 'Run', id: number, data?: any | null, session?: string | null, session_id?: string | null, branch: { __typename?: 'SetupBranch', id: number, name: string } } | null };

export type DeleteRunMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteRunMutation = { __typename?: 'Mutation', deleteCount?: number | null };

export type CloneRunMutationVariables = Exact<{
  id: Scalars['Int'];
  fromBranchId: Scalars['Int'];
}>;


export type CloneRunMutation = { __typename?: 'Mutation', run?: { __typename?: 'Run', id: number, data?: any | null, session?: string | null, session_id?: string | null, branch: { __typename?: 'SetupBranch', id: number, name: string } } | null };

export type CreateSetupFieldMutationVariables = Exact<{
  input: CreateSetupFieldInput;
}>;


export type CreateSetupFieldMutation = { __typename?: 'Mutation', setupField?: { __typename?: 'SetupField', id: number, specs?: Array<Spec | null> | null, name: string, type: SetupFieldType, label: string, path: string, tooltip?: string | null, options?: Array<string> | null, use_in_sim: boolean, part_config?: { __typename?: 'PartConfig', id: number, name: string, display_name: string, expires: boolean, mileage_limit?: number | null, category: { __typename?: 'PartCategory', id: number, name: string, display_name: string, description?: string | null } } | null, positions?: Array<{ __typename?: 'SetupFieldPosition', id: number, label: string, path_part?: string | null }> | null } | null };

export type UpdateSetupFieldMutationVariables = Exact<{
  input: UpdateSetupFieldInput;
}>;


export type UpdateSetupFieldMutation = { __typename?: 'Mutation', setupField?: { __typename?: 'SetupField', id: number, specs?: Array<Spec | null> | null, name: string, type: SetupFieldType, label: string, path: string, tooltip?: string | null, options?: Array<string> | null, use_in_sim: boolean, part_config?: { __typename?: 'PartConfig', id: number, name: string, display_name: string, expires: boolean, mileage_limit?: number | null, category: { __typename?: 'PartCategory', id: number, name: string, display_name: string, description?: string | null } } | null, positions?: Array<{ __typename?: 'SetupFieldPosition', id: number, label: string, path_part?: string | null }> | null } | null };

export type DeleteSetupFieldMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteSetupFieldMutation = { __typename?: 'Mutation', deleteCount?: number | null };

export type BulkDeleteSetupFieldsMutationVariables = Exact<{
  ids: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type BulkDeleteSetupFieldsMutation = { __typename?: 'Mutation', deleteCount?: number | null };

export type CreateSUITMutationVariables = Exact<{
  input: CreateSUITInput;
}>;


export type CreateSUITMutation = { __typename?: 'Mutation', suit?: { __typename?: 'SUIT', id: number, spec: Spec, name: string, description?: string | null, last_modified_by?: string | null, version: number, template: any, created_at?: any | null, updated_at?: any | null } | null };

export type UpdateSUITMutationVariables = Exact<{
  input: UpdateSUITInput;
}>;


export type UpdateSUITMutation = { __typename?: 'Mutation', suit?: { __typename?: 'SUIT', id: number, spec: Spec, name: string, description?: string | null, last_modified_by?: string | null, version: number, template: any, created_at?: any | null, updated_at?: any | null } | null };

export type DeleteSUITMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteSUITMutation = { __typename?: 'Mutation', deleteCount?: number | null };

export type BulkDeleteSUITsMutationVariables = Exact<{
  ids: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type BulkDeleteSUITsMutation = { __typename?: 'Mutation', deleteCount?: number | null };

export type CloneSUITMutationVariables = Exact<{
  id: Scalars['Int'];
  name: Scalars['String'];
}>;


export type CloneSUITMutation = { __typename?: 'Mutation', suit?: { __typename?: 'SUIT', id: number, spec: Spec, name: string, description?: string | null, version: number, last_modified_by?: string | null } | null };

export type CreateSetupMutationVariables = Exact<{
  input: CreateSetupInput;
}>;


export type CreateSetupMutation = { __typename?: 'Mutation', setup?: { __typename?: 'Setup', id: number } | null };

export type CloneSetupMutationVariables = Exact<{
  id: Scalars['Int'];
  name: Scalars['String'];
}>;


export type CloneSetupMutation = { __typename?: 'Mutation', cloneSetup: { __typename?: 'CloneSetupResult', branch: { __typename?: 'SetupBranch', id: number, name: string, root: { __typename?: 'Setup', id: number }, head: { __typename?: 'Setup', id: number }, tail?: { __typename?: 'Setup', id: number } | null }, setup: { __typename?: 'Setup', car: string, description?: string | null, event: string, id: number, name: string, spec: Spec, owner: string, data?: any | null, year: number, series: string, created_at?: any | null, updated_at?: any | null, parent?: { __typename?: 'Setup', id: number } | null, parts?: Array<{ __typename?: 'Part', id: number, part_number?: string | null, serial_number?: string | null, description: string, mileage?: number | null }> | null, root?: { __typename?: 'Setup', id: number } | null } } };

export type ApplyPatchToSetupMutationVariables = Exact<{
  fromBranchId: Scalars['Int'];
  toBranchId: Scalars['Int'];
  ops: Array<Scalars['JSONObject']> | Scalars['JSONObject'];
  fullMerge: Scalars['Boolean'];
}>;


export type ApplyPatchToSetupMutation = { __typename?: 'Mutation', setup?: { __typename?: 'Setup', car: string, description?: string | null, event: string, id: number, name: string, spec: Spec, owner: string, data?: any | null, year: number, series: string, created_at?: any | null, updated_at?: any | null, parent?: { __typename?: 'Setup', id: number } | null, parts?: Array<{ __typename?: 'Part', id: number, part_number?: string | null, serial_number?: string | null, description: string, mileage?: number | null }> | null, root?: { __typename?: 'Setup', id: number } | null } | null };

export type CommitSetupMutationVariables = Exact<{
  branchId: Scalars['Int'];
  setup: CreateSetupInput;
}>;


export type CommitSetupMutation = { __typename?: 'Mutation', commitedSetup?: { __typename?: 'Setup', car: string, description?: string | null, event: string, id: number, name: string, spec: Spec, owner: string, data?: any | null, year: number, series: string, created_at?: any | null, updated_at?: any | null, parent?: { __typename?: 'Setup', id: number } | null, parts?: Array<{ __typename?: 'Part', id: number, part_number?: string | null, serial_number?: string | null, description: string, mileage?: number | null }> | null, root?: { __typename?: 'Setup', id: number } | null } | null };

export type CreateBranchMutationVariables = Exact<{
  fromId: Scalars['Int'];
  name: Scalars['String'];
}>;


export type CreateBranchMutation = { __typename?: 'Mutation', branch?: { __typename?: 'SetupBranch', id: number, name: string, root: { __typename?: 'Setup', id: number }, head: { __typename?: 'Setup', id: number } } | null };

export type UpdateBranchMutationVariables = Exact<{
  input: SetupBranchInput;
}>;


export type UpdateBranchMutation = { __typename?: 'Mutation', updatedIds: number };

export type DeleteBranchMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteBranchMutation = { __typename?: 'Mutation', numDeleted: { __typename?: 'BranchDeleteResult', branches: number, setups: number } };

export type DeleteRepoMutationVariables = Exact<{
  rootId: Scalars['Int'];
}>;


export type DeleteRepoMutation = { __typename?: 'Mutation', numDeleted: { __typename?: 'RepoDeleteResult', branches: number, setups: number } };

export type CreateRunFieldMutationVariables = Exact<{
  input: CreateRunFieldInput;
}>;


export type CreateRunFieldMutation = { __typename?: 'Mutation', runField?: { __typename?: 'RunField', id: number } | null };

export type UpdateRunFieldMutationVariables = Exact<{
  input: UpdateRunFieldInput;
}>;


export type UpdateRunFieldMutation = { __typename?: 'Mutation', runField?: { __typename?: 'RunField', id: number } | null };

export type DeleteRunFieldMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteRunFieldMutation = { __typename?: 'Mutation', deleteCount?: number | null };

export type BulkDeleteRunFieldsMutationVariables = Exact<{
  ids: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type BulkDeleteRunFieldsMutation = { __typename?: 'Mutation', deleteCount?: number | null };

export type CreateRUITMutationVariables = Exact<{
  input: CreateRUITInput;
}>;


export type CreateRUITMutation = { __typename?: 'Mutation', ruit?: { __typename?: 'RUIT', id: number, name: string, description?: string | null, version: number } | null };

export type UpdateRUITMutationVariables = Exact<{
  input: UpdateRUITInput;
}>;


export type UpdateRUITMutation = { __typename?: 'Mutation', ruit?: { __typename?: 'RUIT', id: number, name: string, description?: string | null, version: number } | null };

export type DeleteRUITMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteRUITMutation = { __typename?: 'Mutation', deleteCount?: number | null };

export type BulkDeleteRUITsMutationVariables = Exact<{
  ids: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type BulkDeleteRUITsMutation = { __typename?: 'Mutation', deleteCount?: number | null };

export type CloneRUITMutationVariables = Exact<{
  id: Scalars['Int'];
  name: Scalars['String'];
}>;


export type CloneRUITMutation = { __typename?: 'Mutation', cloneRUIT?: { __typename?: 'RUIT', id: number, name: string, description?: string | null, version: number } | null };

export type DeleteSimMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteSimMutation = { __typename?: 'Mutation', deleteSim?: number | null };

export type CloneSimMutationVariables = Exact<{
  id: Scalars['Int'];
  name: Scalars['String'];
}>;


export type CloneSimMutation = { __typename?: 'Mutation', cloneSim?: { __typename?: 'Sim', id: number } | null };

export type UpdateSimMutationVariables = Exact<{
  input: UpdateSimInput;
}>;


export type UpdateSimMutation = { __typename?: 'Mutation', sim?: { __typename?: 'Sim', id: number, name: string, spec: Spec, type: SimType, owner?: string | null, data: any, output_directory?: string | null, export_motec?: boolean | null, export_json?: boolean | null, export_debug?: boolean | null, download_motec?: boolean | null, download_json?: boolean | null, download_debug?: boolean | null, created_at?: any | null, updated_at?: any | null } | null };

export type DeleteEnvironmentMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteEnvironmentMutation = { __typename?: 'Mutation', deleteEnvironment?: number | null };

export type CloneEnvironmentMutationVariables = Exact<{
  id: Scalars['Int'];
  name: Scalars['String'];
}>;


export type CloneEnvironmentMutation = { __typename?: 'Mutation', cloneEnvironment?: { __typename?: 'Environment', id: number } | null };

export type UpdateEnvironmentMutationVariables = Exact<{
  input: UpdateEnvironmentInput;
}>;


export type UpdateEnvironmentMutation = { __typename?: 'Mutation', sim?: { __typename?: 'Environment', id: number } | null };

export type CreateEnvironmentMutationVariables = Exact<{
  input: CreateEnvironmentInput;
}>;


export type CreateEnvironmentMutation = { __typename?: 'Mutation', createEnvironment?: { __typename?: 'Environment', name: string, track: string, spec: Spec, temperature: number, wind_speed: number, wind_direction: number, elevation: number, use_lap_wind_data?: boolean | null, traffic_wind: number, traffic_wind_angle: number, wind_scalar: number, latitude: number, longitude: number, pressure: number, humidity: number } | null };

export type BulkAddSimToSimOutlineMutationVariables = Exact<{
  simOutlineId: Scalars['Int'];
  simIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type BulkAddSimToSimOutlineMutation = { __typename?: 'Mutation', bulkAddSimToSimOutline?: { __typename?: 'SimOutline', id: number } | null };

export type DeleteSimFromSimOutlineMutationVariables = Exact<{
  simOutlineId: Scalars['Int'];
  simId: Scalars['Int'];
}>;


export type DeleteSimFromSimOutlineMutation = { __typename?: 'Mutation', deleteSimFromSimOutline?: number | null };

export type BulkAddSetupBranchToSimOutlineMutationVariables = Exact<{
  simOutlineId: Scalars['Int'];
  setupBranchIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type BulkAddSetupBranchToSimOutlineMutation = { __typename?: 'Mutation', bulkAddSetupBranchToSimOutline?: { __typename?: 'SimOutline', id: number } | null };

export type DeleteSetupBranchFromSimOutlineMutationVariables = Exact<{
  simOutlineId: Scalars['Int'];
  setupBranchId: Scalars['Int'];
}>;


export type DeleteSetupBranchFromSimOutlineMutation = { __typename?: 'Mutation', deleteSetupBranchFromSimOutline?: number | null };

export type BulkAddEnvToSimOutlineMutationVariables = Exact<{
  simOutlineId: Scalars['Int'];
  envIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type BulkAddEnvToSimOutlineMutation = { __typename?: 'Mutation', bulkAddEnvToSimOutline?: { __typename?: 'SimOutline', id: number } | null };

export type DeleteEnvFromSimOutlineMutationVariables = Exact<{
  simOutlineId: Scalars['Int'];
  envId: Scalars['Int'];
}>;


export type DeleteEnvFromSimOutlineMutation = { __typename?: 'Mutation', deleteEnvFromSimOutline?: number | null };

export type CloneSimOutlineMutationVariables = Exact<{
  simOutlineId: Scalars['Int'];
  name: Scalars['String'];
}>;


export type CloneSimOutlineMutation = { __typename?: 'Mutation', cloneSimOutline?: { __typename?: 'SimOutline', id: number } | null };

export type BulkCloneSimOutlineMutationVariables = Exact<{
  ids: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type BulkCloneSimOutlineMutation = { __typename?: 'Mutation', bulkCloneSimOutline?: Array<{ __typename?: 'SimOutline', id: number } | null> | null };

export type DeleteSimOutlineMutationVariables = Exact<{
  simOutlineId: Scalars['Int'];
}>;


export type DeleteSimOutlineMutation = { __typename?: 'Mutation', deleteSimOutline?: number | null };

export type BulkDeleteSimOutlineMutationVariables = Exact<{
  simOutlineIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type BulkDeleteSimOutlineMutation = { __typename?: 'Mutation', bulkDeleteSimOutline?: number | null };

export type ClearSimOutlineMutationVariables = Exact<{
  simOutlineId: Scalars['Int'];
}>;


export type ClearSimOutlineMutation = { __typename?: 'Mutation', clearSimOutline?: { __typename?: 'SimOutline', id: number } | null };

export type AddSimOutlineToWorksheetMutationVariables = Exact<{
  worksheetId: Scalars['Int'];
  simOutlineName: Scalars['String'];
}>;


export type AddSimOutlineToWorksheetMutation = { __typename?: 'Mutation', addSimOutlineToWorksheet?: { __typename?: 'SimOutline', id: number } | null };

export type UpdateSimOutlineDetailsMutationVariables = Exact<{
  input: UpdateSimOutlineDetailsInput;
}>;


export type UpdateSimOutlineDetailsMutation = { __typename?: 'Mutation', updateSimOutlineDetails?: { __typename?: 'SimOutline', id: number } | null };

export type UpdateSimOutlineOrderMutationVariables = Exact<{
  id: Scalars['Int'];
  oldIndex: Scalars['Int'];
  newIndex: Scalars['Int'];
}>;


export type UpdateSimOutlineOrderMutation = { __typename?: 'Mutation', updateSimOutlineOrder?: { __typename?: 'SimOutline', id: number } | null };

export type UpdateSimWorksheetMutationVariables = Exact<{
  id: Scalars['Int'];
  input: SimWorksheetInput;
}>;


export type UpdateSimWorksheetMutation = { __typename?: 'Mutation', updateSimWorksheet?: { __typename?: 'SimWorksheet', id: number } | null };

export type DeleteSimWorksheetMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteSimWorksheetMutation = { __typename?: 'Mutation', deleteSimWorksheet?: number | null };

export type BulkDeleteSimWorksheetsMutationVariables = Exact<{
  ids: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type BulkDeleteSimWorksheetsMutation = { __typename?: 'Mutation', bulkDeleteSimWorksheets?: number | null };

export type CloneSimWorksheetMutationVariables = Exact<{
  id: Scalars['Int'];
  name: Scalars['String'];
}>;


export type CloneSimWorksheetMutation = { __typename?: 'Mutation', cloneSimWorksheet?: { __typename?: 'SimWorksheet', id: number } | null };

export type CreateSimWorksheetMutationVariables = Exact<{
  input: SimWorksheetInput;
}>;


export type CreateSimWorksheetMutation = { __typename?: 'Mutation', createSimWorksheet?: { __typename?: 'SimWorksheet', id: number } | null };

export type CreateSimMutationVariables = Exact<{
  input: CreateSimInput;
}>;


export type CreateSimMutation = { __typename?: 'Mutation', createSim?: { __typename?: 'Sim', id: number } | null };

export type ClearSimStatusMutationVariables = Exact<{ [key: string]: never; }>;


export type ClearSimStatusMutation = { __typename?: 'Mutation', clearSimStatus?: { __typename?: 'ClearSimStatusResult', success: boolean } | null };

export type ClearSweepStatusMutationVariables = Exact<{ [key: string]: never; }>;


export type ClearSweepStatusMutation = { __typename?: 'Mutation', clearSweepStatus?: { __typename?: 'ClearSimStatusResult', success: boolean } | null };

export type ClearSimProgressMutationVariables = Exact<{ [key: string]: never; }>;


export type ClearSimProgressMutation = { __typename?: 'Mutation', clearSimProgress?: { __typename?: 'ClearSimProgressResult', success: boolean } | null };

export type CreateEightPostMutationVariables = Exact<{
  input: CreateEightPostInput;
}>;


export type CreateEightPostMutation = { __typename?: 'Mutation', createEightPost?: { __typename?: 'EightPost', id: number } | null };

export type CreateDriverMutationVariables = Exact<{
  input: CreateDriverInput;
}>;


export type CreateDriverMutation = { __typename?: 'Mutation', createDriver?: { __typename?: 'Driver', id: number } | null };

export type CreateLapTimeDataMutationVariables = Exact<{
  input: CreateLapTimeDataInput;
}>;


export type CreateLapTimeDataMutation = { __typename?: 'Mutation', createLapTimeData?: { __typename?: 'LapTimeData', id: number } | null };

export type CreateDriveFileMutationVariables = Exact<{
  input: CreateDriveFileInput;
}>;


export type CreateDriveFileMutation = { __typename?: 'Mutation', createDriveFile?: { __typename?: 'DriveFile', id: number } | null };

export type CreateLapWindDataMutationVariables = Exact<{
  input: CreateLapWindDataInput;
}>;


export type CreateLapWindDataMutation = { __typename?: 'Mutation', createLapWindData?: { __typename?: 'LapWindData', id: number } | null };

export type CreateSimStatesMutationVariables = Exact<{
  input: CreateSimStatesInput;
}>;


export type CreateSimStatesMutation = { __typename?: 'Mutation', createSimStates?: { __typename?: 'SimStates', id: number } | null };

export type UpdateEightPostMutationVariables = Exact<{
  input: UpdateEightPostInput;
}>;


export type UpdateEightPostMutation = { __typename?: 'Mutation', part?: { __typename?: 'EightPost', id: number } | null };

export type UpdateDriverMutationVariables = Exact<{
  input: UpdateDriverInput;
}>;


export type UpdateDriverMutation = { __typename?: 'Mutation', updateDriver?: { __typename?: 'Driver', id: number } | null };

export type UpdateLapTimeDataMutationVariables = Exact<{
  input: UpdateLapTimeDataInput;
}>;


export type UpdateLapTimeDataMutation = { __typename?: 'Mutation', updateLapTimeData?: { __typename?: 'LapTimeData', id: number } | null };

export type UpdateDriveFileMutationVariables = Exact<{
  input: UpdateDriveFileInput;
}>;


export type UpdateDriveFileMutation = { __typename?: 'Mutation', updateDriveFile?: { __typename?: 'DriveFile', id: number } | null };

export type UpdateLapWindDataMutationVariables = Exact<{
  input: UpdateLapWindDataInput;
}>;


export type UpdateLapWindDataMutation = { __typename?: 'Mutation', updateLapWindData?: { __typename?: 'LapWindData', id: number } | null };

export type UpdateSimStatesMutationVariables = Exact<{
  input: UpdateSimStatesInput;
}>;


export type UpdateSimStatesMutation = { __typename?: 'Mutation', updateSimStates?: { __typename?: 'SimStates', id: number } | null };

export type CreateSweepMutationVariables = Exact<{
  input: CreateSweepInput;
}>;


export type CreateSweepMutation = { __typename?: 'Mutation', createSweep?: { __typename?: 'Sweep', id: number } | null };

export type UpdateSweepMutationVariables = Exact<{
  input: UpdateSweepInput;
}>;


export type UpdateSweepMutation = { __typename?: 'Mutation', updateSweep?: { __typename?: 'Sweep', id: number } | null };

export type DeleteSweepMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteSweepMutation = { __typename?: 'Mutation', deleteSweep?: number | null };

export type PartManagementQueryVariables = Exact<{ [key: string]: never; }>;


export type PartManagementQuery = { __typename?: 'Query', partCategories: Array<{ __typename?: 'PartCategory', id: number, name: string, display_name: string, description?: string | null }>, partConfigs: { __typename?: 'PartConfigsResult', rows: Array<{ __typename?: 'PartConfig', id: number, name: string, display_name: string, mileage_limit?: number | null, expires: boolean, category: { __typename?: 'PartCategory', id: number, name: string, display_name: string, description?: string | null } }> } };

export type PartPropertiesTableQueryVariables = Exact<{
  input: PropertiesQueryInput;
}>;


export type PartPropertiesTableQuery = { __typename?: 'Query', properties: { __typename?: 'PropertiesResult', totalCount: number, rows: Array<{ __typename?: 'Property', id: number, name: string, display_name: string, description?: string | null, type: PropertyType, min?: number | null, max?: number | null, values?: Array<string> | null, unit?: string | null }> } };

export type PartNavCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type PartNavCategoriesQuery = { __typename?: 'Query', partCategories: Array<{ __typename?: 'PartCategory', id: number, name: string, display_name: string, description?: string | null, configs?: Array<{ __typename?: 'PartConfig', id: number }> | null }> };

export type PartNavConfigsQueryVariables = Exact<{
  categoryName: Scalars['String'];
}>;


export type PartNavConfigsQuery = { __typename?: 'Query', partConfigs: Array<{ __typename?: 'PartConfig', id: number, name: string, display_name: string, category: { __typename?: 'PartCategory', id: number, name: string, display_name: string } }> };

export type PartCategoryQueryVariables = Exact<{
  categoryName: Scalars['String'];
}>;


export type PartCategoryQuery = { __typename?: 'Query', partConfig?: { __typename?: 'PartConfig', id: number, name: string, display_name: string } | null };

export type PartCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type PartCategoriesQuery = { __typename?: 'Query', partCategories: Array<{ __typename?: 'PartCategory', id: number, name: string, display_name: string, description?: string | null }> };

export type PartConfigsQueryVariables = Exact<{ [key: string]: never; }>;


export type PartConfigsQuery = { __typename?: 'Query', partConfigs: { __typename?: 'PartConfigsResult', rows: Array<{ __typename?: 'PartConfig', id: number, name: string, display_name: string, expires: boolean, properties: Array<{ __typename?: 'PartConfigProperty', id: number, specs?: Array<Spec | null> | null, description?: string | null, min?: number | null, max?: number | null, values?: Array<string> | null, required: boolean, property: { __typename?: 'Property', id: number, type: PropertyType, name: string, display_name: string, description?: string | null, min?: number | null, max?: number | null, values?: Array<string> | null } }> }> } };

export type PartConfigsTableQueryVariables = Exact<{
  input: PartConfigsQueryInput;
}>;


export type PartConfigsTableQuery = { __typename?: 'Query', partConfigs: { __typename?: 'PartConfigsResult', totalCount: number, rows: Array<{ __typename?: 'PartConfig', id: number, name: string, display_name: string, mileage_limit?: number | null, expires: boolean, category: { __typename?: 'PartCategory', id: number, name: string, display_name: string, description?: string | null } }> } };

export type PartConfigFormQueryVariables = Exact<{ [key: string]: never; }>;


export type PartConfigFormQuery = { __typename?: 'Query', partCategories: Array<{ __typename?: 'PartCategory', id: number, name: string, display_name: string, description?: string | null }>, properties: { __typename?: 'PropertiesResult', rows: Array<{ __typename?: 'Property', id: number, name: string, display_name: string, type: PropertyType, description?: string | null, path?: string | null, min?: number | null, max?: number | null, values?: Array<string> | null, unit?: string | null }> } };

export type PartConfigByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type PartConfigByIdQuery = { __typename?: 'Query', partConfig?: { __typename?: 'PartConfig', id: number, name: string, display_name: string, mileage_limit?: number | null, expires: boolean, category: { __typename?: 'PartCategory', id: number, name: string, display_name: string, description?: string | null }, properties: Array<{ __typename?: 'PartConfigProperty', id: number, order: number, specs?: Array<Spec | null> | null, description?: string | null, path?: string | null, min?: number | null, max?: number | null, values?: Array<string> | null, required: boolean, property: { __typename?: 'Property', id: number, name: string, display_name: string, type: PropertyType, description?: string | null, path?: string | null, min?: number | null, max?: number | null, values?: Array<string> | null, unit?: string | null } }> } | null };

export type PartConfigByTypeNameQueryVariables = Exact<{
  typeName: Scalars['String'];
}>;


export type PartConfigByTypeNameQuery = { __typename?: 'Query', partConfig?: { __typename?: 'PartConfig', id: number, name: string, display_name: string, mileage_limit?: number | null, expires: boolean, category: { __typename?: 'PartCategory', id: number, name: string, display_name: string, description?: string | null }, properties: Array<{ __typename?: 'PartConfigProperty', id: number, min?: number | null, max?: number | null, values?: Array<string> | null, required: boolean, property: { __typename?: 'Property', id: number, name: string, display_name: string, type: PropertyType, min?: number | null, max?: number | null, values?: Array<string> | null } }> } | null };

export type PartConfigIdByTypeNameQueryVariables = Exact<{
  typeName: Scalars['String'];
}>;


export type PartConfigIdByTypeNameQuery = { __typename?: 'Query', partConfig?: { __typename?: 'PartConfig', id: number } | null };

export type PartConfigPartsQueryVariables = Exact<{
  input: PartsQueryInput;
}>;


export type PartConfigPartsQuery = { __typename?: 'Query', parts: { __typename?: 'PartsResult', totalCount: number, rows: Array<{ __typename?: 'Part', id: number, part_number?: string | null, serial_number?: string | null, description: string, spec: Spec, exp_date?: any | null, mileage?: number | null, properties?: Array<{ __typename?: 'PartProperty', id: number, value?: string | null, config_property: { __typename?: 'PartConfigProperty', id: number, property: { __typename?: 'Property', id: number, name: string, display_name: string } } }> | null }> } };

export type PartByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type PartByIdQuery = { __typename?: 'Query', part?: { __typename?: 'Part', id: number, part_number?: string | null, serial_number?: string | null, description: string, spec: Spec, exp_date?: any | null, mileage?: number | null, data: any, config: { __typename?: 'PartConfig', id: number, display_name: string }, properties?: Array<{ __typename?: 'PartProperty', id: number, value?: string | null, config_property: { __typename?: 'PartConfigProperty', id: number, specs?: Array<Spec | null> | null, min?: number | null, max?: number | null, values?: Array<string> | null, required: boolean, property: { __typename?: 'Property', id: number, type: PropertyType, name: string, display_name: string, min?: number | null, max?: number | null, values?: Array<string> | null } } }> | null } | null };

export type SUITFormPartsQueryVariables = Exact<{
  input: PartsQueryInput;
}>;


export type SUITFormPartsQuery = { __typename?: 'Query', parts: { __typename?: 'PartsResult', rows: Array<{ __typename?: 'Part', id: number, part_number?: string | null, serial_number?: string | null, description: string, spec: Spec, exp_date?: any | null, mileage?: number | null, config: { __typename?: 'PartConfig', name: string } }> } };

export type PartsCompareDataQueryVariables = Exact<{
  input: PartsQueryInput;
}>;


export type PartsCompareDataQuery = { __typename?: 'Query', parts: { __typename?: 'PartsResult', rows: Array<{ __typename?: 'Part', id: number, part_number?: string | null, serial_number?: string | null, description: string, data: any, spec: Spec, exp_date?: any | null, mileage?: number | null, config: { __typename?: 'PartConfig', name: string } }> } };

export type SetupSummaryQueryVariables = Exact<{
  input?: InputMaybe<SetupsQueryInput>;
}>;


export type SetupSummaryQuery = { __typename?: 'Query', setupBranchHeads: { __typename?: 'SetupBranchHeadsResult', totalCount: number, rows: Array<{ __typename?: 'SetupBranchHead', numBranches?: number | null, setup: { __typename?: 'Setup', car: string, description?: string | null, event: string, id: number, name: string, spec: Spec, owner: string, data?: any | null, year: number, series: string, created_at?: any | null, updated_at?: any | null, parent?: { __typename?: 'Setup', id: number } | null, root?: { __typename?: 'Setup', id: number } | null }, branch: { __typename?: 'SetupBranch', id: number, name: string } }> } };

export type SetupSummaryGetBranchesQueryVariables = Exact<{
  rootId: Scalars['Int'];
  input?: InputMaybe<SetupsQueryInput>;
}>;


export type SetupSummaryGetBranchesQuery = { __typename?: 'Query', setupBranchHeadsByRootId: { __typename?: 'SetupBranchHeadsResult', totalCount: number, rows: Array<{ __typename?: 'SetupBranchHead', numBranches?: number | null, setup: { __typename?: 'Setup', car: string, description?: string | null, event: string, id: number, name: string, spec: Spec, owner: string, data?: any | null, year: number, series: string, created_at?: any | null, updated_at?: any | null, parent?: { __typename?: 'Setup', id: number } | null, root?: { __typename?: 'Setup', id: number } | null }, branch: { __typename?: 'SetupBranch', id: number, name: string } }> } };

export type SetupSummaryWithPartsQueryVariables = Exact<{
  input?: InputMaybe<SetupsQueryInput>;
}>;


export type SetupSummaryWithPartsQuery = { __typename?: 'Query', setupBranchHeads: { __typename?: 'SetupBranchHeadsResult', totalCount: number, rows: Array<{ __typename?: 'SetupBranchHead', numBranches?: number | null, setup: { __typename?: 'Setup', car: string, description?: string | null, event: string, id: number, name: string, spec: Spec, owner: string, data?: any | null, year: number, series: string, created_at?: any | null, updated_at?: any | null, parent?: { __typename?: 'Setup', id: number } | null, root?: { __typename?: 'Setup', id: number } | null, parts?: Array<{ __typename?: 'Part', id: number, part_number?: string | null, serial_number?: string | null, description: string, mileage?: number | null }> | null }, branch: { __typename?: 'SetupBranch', id: number, name: string } }> } };

export type SetupSummaryWithPartsGetBranchesQueryVariables = Exact<{
  rootId: Scalars['Int'];
  input?: InputMaybe<SetupsQueryInput>;
}>;


export type SetupSummaryWithPartsGetBranchesQuery = { __typename?: 'Query', setupBranchHeadsByRootId: { __typename?: 'SetupBranchHeadsResult', totalCount: number, rows: Array<{ __typename?: 'SetupBranchHead', numBranches?: number | null, setup: { __typename?: 'Setup', car: string, description?: string | null, event: string, id: number, name: string, spec: Spec, owner: string, data?: any | null, year: number, series: string, created_at?: any | null, updated_at?: any | null, parent?: { __typename?: 'Setup', id: number } | null, root?: { __typename?: 'Setup', id: number } | null, parts?: Array<{ __typename?: 'Part', id: number, part_number?: string | null, serial_number?: string | null, description: string, mileage?: number | null }> | null }, branch: { __typename?: 'SetupBranch', id: number, name: string } }> } };

export type SetupHistoryQueryVariables = Exact<{
  rootId: Scalars['Int'];
}>;


export type SetupHistoryQuery = { __typename?: 'Query', setupHistory: { __typename?: 'SetupHistoryResult', branches: Array<{ __typename?: 'SetupBranch', id: number, name: string, head: { __typename?: 'Setup', id: number }, tail?: { __typename?: 'Setup', id: number } | null }>, setups: Array<{ __typename?: 'Setup', id: number, name: string, created_at?: any | null, updated_at?: any | null, meta: { __typename?: 'SetupMeta', id: number, user: string, full_merge?: boolean | null, merged_from_setup?: { __typename?: 'Setup', id: number } | null }, parent?: { __typename?: 'Setup', id: number } | null }> } };

export type SUITEditQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SUITEditQuery = { __typename?: 'Query', suit?: { __typename?: 'SUIT', id: number, spec: Spec, name: string, description?: string | null, last_modified_by?: string | null, version: number, template: any, created_at?: any | null, updated_at?: any | null } | null };

export type SetupFieldManagementTableQueryVariables = Exact<{
  input?: InputMaybe<SetupFieldsQueryInput>;
}>;


export type SetupFieldManagementTableQuery = { __typename?: 'Query', setupFields: { __typename?: 'SetupFieldsResult', totalCount: number, rows: Array<{ __typename?: 'SetupField', id: number, specs?: Array<Spec | null> | null, name: string, type: SetupFieldType, label: string, path: string, tooltip?: string | null, options?: Array<string> | null, use_in_sim: boolean, part_config?: { __typename?: 'PartConfig', id: number, display_name: string } | null, positions?: Array<{ __typename?: 'SetupFieldPosition', id: number, label: string, path_part?: string | null }> | null }> } };

export type SUITManagementTableQueryVariables = Exact<{
  input?: InputMaybe<SUITsQueryInput>;
}>;


export type SUITManagementTableQuery = { __typename?: 'Query', suits: { __typename?: 'SUITsResult', totalCount: number, rows: Array<{ __typename?: 'SUIT', id: number, spec: Spec, name: string, description?: string | null, last_modified_by?: string | null, version: number, template: any, created_at?: any | null, updated_at?: any | null }> } };

export type SetupBranchesByIdsQueryVariables = Exact<{
  branchIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type SetupBranchesByIdsQuery = { __typename?: 'Query', setupBranches: Array<{ __typename?: 'SetupBranch', id: number, name: string, head: { __typename?: 'Setup', car: string, description?: string | null, event: string, id: number, name: string, spec: Spec, owner: string, data?: any | null, year: number, series: string, created_at?: any | null, updated_at?: any | null, parent?: { __typename?: 'Setup', id: number } | null, parts?: Array<{ __typename?: 'Part', id: number, part_number?: string | null, serial_number?: string | null, description: string, mileage?: number | null }> | null, root?: { __typename?: 'Setup', id: number } | null } }> };

export type SetupMergeCommonAncestorQueryVariables = Exact<{
  setupIdA: Scalars['Int'];
  setupIdB: Scalars['Int'];
}>;


export type SetupMergeCommonAncestorQuery = { __typename?: 'Query', commonAncestor?: { __typename?: 'Setup', car: string, description?: string | null, event: string, id: number, name: string, spec: Spec, owner: string, data?: any | null, year: number, series: string, created_at?: any | null, updated_at?: any | null, parent?: { __typename?: 'Setup', id: number } | null, parts?: Array<{ __typename?: 'Part', id: number, part_number?: string | null, serial_number?: string | null, description: string, mileage?: number | null }> | null, root?: { __typename?: 'Setup', id: number } | null } | null };

export type SUITByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SUITByIdQuery = { __typename?: 'Query', suit?: { __typename?: 'SUIT', id: number, spec: Spec, name: string, description?: string | null, last_modified_by?: string | null, version: number, template: any, created_at?: any | null, updated_at?: any | null } | null };

export type SetupByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SetupByIdQuery = { __typename?: 'Query', setup: { __typename?: 'Setup', car: string, description?: string | null, event: string, id: number, name: string, spec: Spec, owner: string, data?: any | null, year: number, series: string, created_at?: any | null, updated_at?: any | null, parent?: { __typename?: 'Setup', id: number } | null, parts?: Array<{ __typename?: 'Part', id: number, part_number?: string | null, serial_number?: string | null, description: string, mileage?: number | null }> | null, root?: { __typename?: 'Setup', id: number } | null } };

export type SetupByBranchIdQueryVariables = Exact<{
  branchId: Scalars['Int'];
}>;


export type SetupByBranchIdQuery = { __typename?: 'Query', setup?: { __typename?: 'Setup', car: string, description?: string | null, event: string, id: number, name: string, spec: Spec, owner: string, data?: any | null, year: number, series: string, created_at?: any | null, updated_at?: any | null, parent?: { __typename?: 'Setup', id: number } | null, parts?: Array<{ __typename?: 'Part', id: number, part_number?: string | null, serial_number?: string | null, description: string, mileage?: number | null }> | null, root?: { __typename?: 'Setup', id: number } | null } | null };

export type SetupBranchesByRootIdQueryVariables = Exact<{
  rootId: Scalars['Int'];
}>;


export type SetupBranchesByRootIdQuery = { __typename?: 'Query', branches: Array<{ __typename?: 'SetupBranch', id: number, name: string, created_at?: any | null, root: { __typename?: 'Setup', id: number }, head: { __typename?: 'Setup', id: number }, tail?: { __typename?: 'Setup', id: number } | null }> };

export type SetupBranchHeadByBranchIdQueryVariables = Exact<{
  branchId: Scalars['Int'];
}>;


export type SetupBranchHeadByBranchIdQuery = { __typename?: 'Query', branchHead: { __typename?: 'SetupBranchHead', numBranches?: number | null, setup: { __typename?: 'Setup', car: string, description?: string | null, event: string, id: number, name: string, spec: Spec, owner: string, data?: any | null, year: number, series: string, created_at?: any | null, updated_at?: any | null, parent?: { __typename?: 'Setup', id: number } | null, root?: { __typename?: 'Setup', id: number } | null }, branch: { __typename?: 'SetupBranch', id: number, name: string } } };

export type SUITsQueryVariables = Exact<{ [key: string]: never; }>;


export type SUITsQuery = { __typename?: 'Query', suits: { __typename?: 'SUITsResult', rows: Array<{ __typename?: 'SUIT', id: number, spec: Spec, name: string, description?: string | null, version: number }> } };

export type SetupFieldsQueryVariables = Exact<{ [key: string]: never; }>;


export type SetupFieldsQuery = { __typename?: 'Query', setupFields: { __typename?: 'SetupFieldsResult', rows: Array<{ __typename?: 'SetupField', id: number, specs?: Array<Spec | null> | null, name: string, type: SetupFieldType, label: string, path: string, tooltip?: string | null, options?: Array<string> | null, use_in_sim: boolean, part_config?: { __typename?: 'PartConfig', id: number, name: string, display_name: string, expires: boolean, mileage_limit?: number | null, category: { __typename?: 'PartCategory', id: number, name: string, display_name: string, description?: string | null } } | null, positions?: Array<{ __typename?: 'SetupFieldPosition', id: number, label: string, path_part?: string | null }> | null }> } };

export type SetupsByIdsQueryVariables = Exact<{
  ids: Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>;
}>;


export type SetupsByIdsQuery = { __typename?: 'Query', setupsByIds: Array<{ __typename?: 'Setup', car: string, description?: string | null, event: string, id: number, name: string, spec: Spec, owner: string, data?: any | null, year: number, series: string, created_at?: any | null, updated_at?: any | null, parent?: { __typename?: 'Setup', id: number } | null, parts?: Array<{ __typename?: 'Part', id: number, part_number?: string | null, serial_number?: string | null, description: string, mileage?: number | null }> | null, root?: { __typename?: 'Setup', id: number } | null }> };

export type SetupBranchesQueryVariables = Exact<{ [key: string]: never; }>;


export type SetupBranchesQuery = { __typename?: 'Query', setupBranches: Array<{ __typename?: 'SetupBranch', id: number, name: string, head: { __typename?: 'Setup', car: string, description?: string | null, event: string, id: number, name: string, spec: Spec, owner: string, data?: any | null, year: number, series: string, created_at?: any | null, updated_at?: any | null, parent?: { __typename?: 'Setup', id: number } | null, parts?: Array<{ __typename?: 'Part', id: number, part_number?: string | null, serial_number?: string | null, description: string, mileage?: number | null }> | null, root?: { __typename?: 'Setup', id: number } | null }, root: { __typename?: 'Setup', id: number } }> };

export type SetupBranchByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SetupBranchByIdQuery = { __typename?: 'Query', branch?: { __typename?: 'SetupBranch', id: number, name: string, root: { __typename?: 'Setup', id: number }, head: { __typename?: 'Setup', id: number }, tail?: { __typename?: 'Setup', id: number } | null } | null };

export type RunsByRootIdQueryVariables = Exact<{
  rootId: Scalars['Int'];
}>;


export type RunsByRootIdQuery = { __typename?: 'Query', runs: Array<{ __typename?: 'Run', id: number, session?: string | null, session_id?: string | null, data?: any | null, branch: { __typename?: 'SetupBranch', id: number, name: string } }> };

export type RunFieldManagementTableQueryVariables = Exact<{
  input?: InputMaybe<RunFieldsQueryInput>;
}>;


export type RunFieldManagementTableQuery = { __typename?: 'Query', runFields: { __typename?: 'RunFieldsResult', totalCount: number, rows: Array<{ __typename?: 'RunField', id: number, name: string, type: RunFieldType, label: string, tooltip?: string | null, options?: Array<string> | null, positions?: Array<{ __typename?: 'RunFieldPosition', id: number, label: string }> | null }> } };

export type RunFieldsQueryVariables = Exact<{ [key: string]: never; }>;


export type RunFieldsQuery = { __typename?: 'Query', runFields: { __typename?: 'RunFieldsResult', rows: Array<{ __typename?: 'RunField', id: number, name: string, type: RunFieldType, label: string, tooltip?: string | null, options?: Array<string> | null, positions?: Array<{ __typename?: 'RunFieldPosition', id: number, label: string }> | null }> } };

export type RUITsQueryVariables = Exact<{ [key: string]: never; }>;


export type RUITsQuery = { __typename?: 'Query', ruits: { __typename?: 'RUITsResult', rows: Array<{ __typename?: 'RUIT', id: number, name: string, description?: string | null, version: number, template: any }> } };

export type RUITManagementQueryVariables = Exact<{
  input?: InputMaybe<RUITsQueryInput>;
}>;


export type RUITManagementQuery = { __typename?: 'Query', ruits: { __typename?: 'RUITsResult', totalCount: number, rows: Array<{ __typename?: 'RUIT', id: number, name: string, description?: string | null, last_modified_by?: string | null, version: number, template: any, created_at?: any | null, updated_at?: any | null }> } };

export type RUITByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RUITByIdQuery = { __typename?: 'Query', ruit?: { __typename?: 'RUIT', id: number, name: string, description?: string | null, version: number, template: any } | null };

export type SearchRunsQueryVariables = Exact<{ [key: string]: never; }>;


export type SearchRunsQuery = { __typename?: 'Query', runs: Array<{ __typename?: 'Run', id: number, data?: any | null, branch: { __typename?: 'SetupBranch', id: number, name: string, head: { __typename?: 'Setup', id: number, name: string, year: number, car: string, description?: string | null, event: string, owner: string } } }> };

export type SimsQueryVariables = Exact<{
  input?: InputMaybe<SimsQueryInput>;
}>;


export type SimsQuery = { __typename?: 'Query', sims: { __typename?: 'SimsResult', totalCount: number, rows: Array<{ __typename?: 'Sim', id: number, name: string, spec: Spec, type: SimType, owner?: string | null, data: any, output_directory?: string | null, export_motec?: boolean | null, export_json?: boolean | null, export_debug?: boolean | null, download_motec?: boolean | null, download_json?: boolean | null, download_debug?: boolean | null, created_at?: any | null, updated_at?: any | null }> } };

export type SimByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SimByIdQuery = { __typename?: 'Query', sim?: { __typename?: 'Sim', id: number, name: string, spec: Spec, type: SimType, owner?: string | null, data: any, output_directory?: string | null, export_motec?: boolean | null, export_json?: boolean | null, export_metrics?: boolean | null, export_debug?: boolean | null, download_motec?: boolean | null, download_json?: boolean | null, download_metrics?: boolean | null, download_vdf?: boolean | null, download_edf?: boolean | null, download_debug?: boolean | null, drive_file_id?: number | null, sim_states_id?: number | null, state_option_id?: number | null, lap_time_data_id?: number | null, driver_id?: number | null, eight_post_id?: number | null, drive_file?: { __typename?: 'DriveFile', id: number, name: string, desc: string, status: string, owner?: string | null, venue: string } | null, sim_states?: { __typename?: 'SimStates', id: number, name: string, desc: string, status: string, owner: string, track?: string | null, states: Array<{ __typename?: 'SimStatesState', id: number, label: string, tire?: any | null, fuel?: any | null, engine?: any | null }> } | null, lap_time_data?: { __typename?: 'LapTimeData', id: number, name: string, desc: string, status: string, owner: string } | null, driver?: { __typename?: 'Driver', id: number, name?: DriverSimType | null, desc: string, track: string, status: string, owner: string } | null, eight_post?: { __typename?: 'EightPost', id: number, name: string, desc: string, venue: string, status: string, owner: string } | null } | null };

export type DriveFilesQueryVariables = Exact<{ [key: string]: never; }>;


export type DriveFilesQuery = { __typename?: 'Query', driveFiles: Array<{ __typename?: 'DriveFile', id: number, name: string, desc: string, status: string, owner?: string | null, venue: string, created_at?: any | null, updated_at?: any | null, data?: { __typename?: 'DriveFileDataObj', segments?: Array<any | null> | null, laptime?: number | null } | null }> };

export type DriveFileByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DriveFileByIdQuery = { __typename?: 'Query', driveFile?: { __typename?: 'DriveFile', id: number, name: string, desc: string, status: string, owner?: string | null, venue: string, created_at?: any | null, updated_at?: any | null, data?: { __typename?: 'DriveFileDataObj', segments?: Array<any | null> | null, laptime?: number | null, venue?: string | null, controller?: any | null, signalsArray?: any | null, trim_start?: number | null } | null } | null };

export type LapWindDataQueryVariables = Exact<{ [key: string]: never; }>;


export type LapWindDataQuery = { __typename?: 'Query', lapWindDatas: Array<{ __typename?: 'LapWindData', id: number, name: string, desc: string, status: string, owner: string, data?: any | null, created_at?: any | null, updated_at?: any | null }> };

export type LapWindDataByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type LapWindDataByIdQuery = { __typename?: 'Query', lapWindData?: { __typename?: 'LapWindData', id: number, name: string, desc: string, status: string, owner: string, data?: any | null, created_at?: any | null, updated_at?: any | null } | null };

export type EnvironmentsQueryVariables = Exact<{
  input?: InputMaybe<EnvironmentsQueryInput>;
}>;


export type EnvironmentsQuery = { __typename?: 'Query', environments: { __typename?: 'EnvironmentsResult', totalCount: number, rows: Array<{ __typename?: 'Environment', id: number, name: string, track: string, spec: Spec, owner: string, temperature: number, pressure: number, humidity: number, wind_speed: number, wind_direction: number, traffic_wind: number, traffic_wind_angle: number, elevation: number, use_lap_wind_data?: boolean | null, historical_time?: any | null, historical_weather?: any | null, wind_scalar: number, latitude: number, longitude: number, created_at?: any | null, updated_at?: any | null }> } };

export type SimWorksheetSummaryQueryVariables = Exact<{
  input?: InputMaybe<SimWorksheetsQueryInput>;
}>;


export type SimWorksheetSummaryQuery = { __typename?: 'Query', simWorksheets: { __typename?: 'SimWorksheetsResult', totalCount: number, rows: Array<{ __typename?: 'SimWorksheet', id: number, name: string, car?: string | null, event?: string | null, description?: string | null, session?: string | null, spec?: Spec | null, track?: string | null, year?: number | null, owner: string, created_at?: any | null, updated_at?: any | null }> } };

export type SimWorksheetByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SimWorksheetByIdQuery = { __typename?: 'Query', simWorksheet?: { __typename?: 'SimWorksheet', id: number, name: string, car?: string | null, event?: string | null, description?: string | null, session?: string | null, spec?: Spec | null, track?: string | null, year?: number | null, owner: string, created_at?: any | null, updated_at?: any | null, output_directory?: string | null, environment?: { __typename?: 'Environment', id: number, name: string } | null, sim_outlines?: Array<{ __typename?: 'SimOutline', id: number, order_index: number, name: string, worksheet?: { __typename?: 'SimWorksheet', id: number } | null, sims?: Array<{ __typename?: 'Sim', id: number, name: string }> | null, setup_branches?: Array<{ __typename?: 'SetupBranch', id: number, name: string, head: { __typename?: 'Setup', id: number, name: string } }> | null, environments?: Array<{ __typename?: 'Environment', id: number, name: string }> | null }> | null } | null };

export type SimOutlineByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SimOutlineByIdQuery = { __typename?: 'Query', simOutline?: { __typename?: 'SimOutline', id: number, order_index: number, name: string, worksheet?: { __typename?: 'SimWorksheet', id: number } | null, sims?: Array<{ __typename?: 'Sim', id: number, name: string }> | null, setup_branches?: Array<{ __typename?: 'SetupBranch', id: number, name: string, head: { __typename?: 'Setup', id: number, name: string } }> | null, environments?: Array<{ __typename?: 'Environment', id: number, name: string }> | null } | null };

export type EnvironmentByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type EnvironmentByIdQuery = { __typename?: 'Query', environment?: { __typename?: 'Environment', id: number, name: string, track: string, spec: Spec, owner: string, temperature: number, pressure: number, humidity: number, wind_speed: number, wind_direction: number, traffic_wind: number, traffic_wind_angle: number, elevation: number, use_lap_wind_data?: boolean | null, historical_time?: any | null, historical_weather?: any | null, wind_scalar: number, latitude: number, longitude: number, created_at?: any | null, updated_at?: any | null, lap_wind_data?: { __typename?: 'LapWindData', id: number, name: string, desc: string, status: string, owner: string, data?: any | null } | null } | null };

export type GetWeatherQueryVariables = Exact<{
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  date: Scalars['String'];
}>;


export type GetWeatherQuery = { __typename?: 'Query', weather?: { __typename?: 'HistoricalWeather', asOf: string, temperature: number, humidity: number, pressure: number, windSpeed: number, windDirection: number, source: string } | null };

export type GetTrackImageQueryVariables = Exact<{
  short_name: Scalars['String'];
}>;


export type GetTrackImageQuery = { __typename?: 'Query', trackImage?: { __typename?: 'TrackImage', svg_url: string } | null };

export type TracksQueryVariables = Exact<{ [key: string]: never; }>;


export type TracksQuery = { __typename?: 'Query', tracks: Array<{ __typename?: 'Track', id: string, long_name: string, timezone_id: string, city: string, state: string, created_at?: any | null, updated_at?: any | null, short_name: string, mini_name: string, latitude?: number | null, longitude?: number | null, elevation?: number | null }> };

export type LapTimeDataQueryVariables = Exact<{ [key: string]: never; }>;


export type LapTimeDataQuery = { __typename?: 'Query', lapTimeDatas: Array<{ __typename?: 'LapTimeData', id: number, name: string, desc: string, status: string, owner: string, created_at?: any | null, updated_at?: any | null, data?: { __typename?: 'LapTimeDataObj', track_filename?: string | null, brake_aggression?: number | null, throttle_aggression?: number | null, part_throttle_pct?: number | null, saturation_target_pct?: number | null, scouting_speed_pct?: number | null, dlift_dvelocity?: number | null, segments?: Array<{ __typename?: 'LapTimeDataSegment', label: string, begin: number, end: number, loc: string } | null> | null } | null }> };

export type LapTimeDataByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type LapTimeDataByIdQuery = { __typename?: 'Query', lapTimeData?: { __typename?: 'LapTimeData', id: number, name: string, desc: string, status: string, owner: string, data?: { __typename?: 'LapTimeDataObj', brake_aggression?: number | null, bypass_svm?: boolean | null, corner_spd_mode?: boolean | null, dlift_dvelocity?: number | null, part_throttle_pct?: number | null, path_mu?: Array<Array<number | null> | null> | null, path_v?: Array<Array<number | null> | null> | null, path_velocity?: Array<Array<number | null> | null> | null, saturation?: Array<Array<number | null> | null> | null, saturation_target_pct?: number | null, scouting_speed_pct?: number | null, scouting_target?: Array<Array<number | null> | null> | null, start_position?: number | null, start_speed?: number | null, throttle_aggression?: number | null, track_filename?: string | null, traffic_wind_scaling?: Array<Array<number | null> | null> | null, segments?: Array<{ __typename?: 'LapTimeDataSegment', label: string, begin: number, end: number, loc: string } | null> | null } | null } | null };

export type SimStatesQueryVariables = Exact<{ [key: string]: never; }>;


export type SimStatesQuery = { __typename?: 'Query', simStates: Array<{ __typename?: 'SimStates', id: number, name: string, desc: string, status: string, owner: string, track?: string | null, created_at?: any | null, updated_at?: any | null, states: Array<{ __typename?: 'SimStatesState', id: number, label: string, tire?: any | null, engine?: any | null, fuel?: any | null, brake?: any | null }> }> };

export type SimStatesByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SimStatesByIdQuery = { __typename?: 'Query', simState?: { __typename?: 'SimStates', id: number, name: string, desc: string, status: string, owner: string, track?: string | null, created_at?: any | null, updated_at?: any | null, states: Array<{ __typename?: 'SimStatesState', label: string, tire?: any | null, fuel?: any | null, engine?: any | null, brake?: any | null }> } | null };

export type DriversQueryVariables = Exact<{ [key: string]: never; }>;


export type DriversQuery = { __typename?: 'Query', drivers: Array<{ __typename?: 'Driver', id: number, name?: DriverSimType | null, desc: string, track: string, status: string, owner: string, data?: any | null, created_at?: any | null, updated_at?: any | null }> };

export type DriverByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DriverByIdQuery = { __typename?: 'Query', driver?: { __typename?: 'Driver', id: number, name?: DriverSimType | null, desc: string, track: string, status: string, owner: string, data?: any | null, created_at?: any | null, updated_at?: any | null } | null };

export type EightPostsQueryVariables = Exact<{ [key: string]: never; }>;


export type EightPostsQuery = { __typename?: 'Query', eightPosts: Array<{ __typename?: 'EightPost', id: number, name: string, desc: string, venue: string, status: string, owner: string, created_at?: any | null, updated_at?: any | null, data?: { __typename?: 'EightPostDataObj', segments?: Array<any | null> | null, laptime?: number | null } | null }> };

export type EightPostByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type EightPostByIdQuery = { __typename?: 'Query', eightPost?: { __typename?: 'EightPost', id: number, name: string, desc: string, venue: string, status: string, owner: string, created_at?: any | null, updated_at?: any | null, data?: { __typename?: 'EightPostDataObj', segments?: Array<any | null> | null, laptime?: number | null, venue?: string | null, signalsArray?: any | null, trim_start?: number | null } | null } | null };

export type SimProgressesByUserQueryVariables = Exact<{ [key: string]: never; }>;


export type SimProgressesByUserQuery = { __typename?: 'Query', simProgressesByUser: Array<{ __typename?: 'SimProgress', isBatch?: boolean | null, state?: string | null, status?: string | null, cancelId?: string | null, groupId?: string | null, requestId?: string | null, userId?: string | null, simEngine?: string | null, setupId?: string | null, applyOptimizationResult?: boolean | null, vehicle?: string | null, type: string, displayName?: string | null, series?: string | null, submitted?: string | null, started?: string | null, finished?: string | null, curr?: number | null, total?: number | null, percentage?: number | null }> };

export type SimStatusesByUserQueryVariables = Exact<{ [key: string]: never; }>;


export type SimStatusesByUserQuery = { __typename?: 'Query', simStatusesByUser: Array<{ __typename?: 'SimStatus', isBatch?: boolean | null, state?: string | null, cancelId?: string | null, groupId?: string | null, requestId?: string | null, userId?: string | null, simEngine?: string | null, setupId?: string | null, applyOptimizationResult?: boolean | null, vehicle?: string | null, type: string, displayName?: string | null, series?: string | null, submitted?: string | null, started?: string | null, finished?: string | null, curr?: number | null, total?: number | null, percentage?: number | null }> };

export type SweepByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SweepByIdQuery = { __typename?: 'Query', sweep?: { __typename?: 'Sweep', id: number, name: string, description?: string | null, owner: string, method: SweepMethod, sweep_data?: any | null, num_run?: number | null, sweep_groups?: Array<{ __typename?: 'SweepGroup', enabled: boolean, name: string, parameters?: Array<{ __typename?: 'SweepParameter', enabled: boolean, name: string, path: string, param_application_method: SweepParameterApplicationMethod, param_generation_method: SweepParameterGenerationMethod, min?: number | null, max?: number | null, inc?: number | null, sweep_id?: number | null, sweep_group_id?: number | null }> | null, parts?: Array<{ __typename?: 'SweepPart', enabled: boolean, position?: string | null, sweep_id?: number | null, sweep_group_id?: number | null, part_config?: { __typename?: 'PartConfig', name: string, display_name: string } | null, parts?: Array<{ __typename?: 'Part', part_number?: string | null, serial_number?: string | null }> | null }> | null }> | null, parameters?: Array<{ __typename?: 'SweepParameter', enabled: boolean, name: string, path: string, param_application_method: SweepParameterApplicationMethod, param_generation_method: SweepParameterGenerationMethod, min?: number | null, max?: number | null, inc?: number | null, sweep_id?: number | null, sweep_group_id?: number | null }> | null, parts?: Array<{ __typename?: 'SweepPart', enabled: boolean, position?: string | null, sweep_id?: number | null, sweep_group_id?: number | null, part_config?: { __typename?: 'PartConfig', name: string, display_name: string } | null, parts?: Array<{ __typename?: 'Part', part_number?: string | null, serial_number?: string | null }> | null }> | null } | null };

export type SweepsQueryVariables = Exact<{
  input?: InputMaybe<SweepsQueryInput>;
}>;


export type SweepsQuery = { __typename?: 'Query', sweeps: { __typename?: 'SweepsResult', totalCount: number, rows: Array<{ __typename?: 'Sweep', id: number, name: string, description?: string | null, owner: string, method: SweepMethod }> } };

export type EventsQueryVariables = Exact<{
  year: Scalars['Int'];
  series: Scalars['String'];
}>;


export type EventsQuery = { __typename?: 'Query', events: Array<{ __typename?: 'Event', event_id: string, name: string }> };

export type SessionsByEventQueryVariables = Exact<{
  year: Scalars['Int'];
  series: Scalars['String'];
  name: Scalars['String'];
}>;


export type SessionsByEventQuery = { __typename?: 'Query', sessionsByEvent: Array<{ __typename?: 'Session', id: string, name: string }> };

export type GetSimStatusSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GetSimStatusSubscription = { __typename?: 'Subscription', simStatus?: any | null };

export type GetSimResultSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GetSimResultSubscription = { __typename?: 'Subscription', simResult?: any | null };

export type GetSimProgressSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GetSimProgressSubscription = { __typename?: 'Subscription', simProgress?: any | null };

export type GetBatchStatusSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GetBatchStatusSubscription = { __typename?: 'Subscription', batchStatus?: any | null };

export const allPartConfigFieldsFragmentDoc = gql`
    fragment allPartConfigFields on PartConfig {
  id
  category {
    id
    name
    display_name
    description
  }
  name
  display_name
  mileage_limit
  expires
}
    `;
export const allSetupFieldFieldsFragmentDoc = gql`
    fragment allSetupFieldFields on SetupField {
  id
  specs
  name
  type
  part_config {
    id
    name
    display_name
    expires
    mileage_limit
    category {
      id
      name
      display_name
      description
    }
  }
  label
  path
  tooltip
  positions {
    id
    label
    path_part
  }
  options
  use_in_sim
}
    `;
export const suitFormSetupFieldFieldsFragmentDoc = gql`
    fragment suitFormSetupFieldFields on SetupField {
  specs
  name
  type
  part_config {
    name
    display_name
  }
  label
  path
  tooltip
  positions {
    id
    label
    path_part
  }
  options
  use_in_sim
}
    `;
export const allSUITFieldsFragmentDoc = gql`
    fragment allSUITFields on SUIT {
  id
  spec
  name
  description
  last_modified_by
  version
  template
  created_at
  updated_at
}
    `;
export const mostSetupFieldsFragmentDoc = gql`
    fragment mostSetupFields on Setup {
  car
  description
  event
  id
  name
  spec
  owner
  parent {
    id
  }
  parts {
    id
    part_number
    serial_number
    description
    mileage
  }
  root {
    id
  }
  data
  year
  series
  created_at
  updated_at
}
    `;
export const mostSetupHeadFieldsFragmentDoc = gql`
    fragment mostSetupHeadFields on SetupBranchHead {
  setup {
    car
    description
    event
    id
    name
    spec
    owner
    parent {
      id
    }
    root {
      id
    }
    data
    year
    series
    created_at
    updated_at
  }
  branch {
    id
    name
  }
  numBranches
}
    `;
export const mostSetupHeadFieldsWithPartsFragmentDoc = gql`
    fragment mostSetupHeadFieldsWithParts on SetupBranchHead {
  setup {
    car
    description
    event
    id
    name
    spec
    owner
    parent {
      id
    }
    root {
      id
    }
    data
    year
    series
    created_at
    updated_at
    parts {
      id
      part_number
      serial_number
      description
      mileage
    }
  }
  branch {
    id
    name
  }
  numBranches
}
    `;
export const simOutlineFieldsFragmentDoc = gql`
    fragment simOutlineFields on SimOutline {
  id
  order_index
  name
  worksheet {
    id
  }
  sims {
    id
    name
  }
  setup_branches {
    id
    name
    head {
      id
      name
    }
  }
  environments {
    id
    name
  }
}
    `;
export const sweepParameterFieldsFragmentDoc = gql`
    fragment sweepParameterFields on SweepParameter {
  enabled
  name
  path
  param_application_method
  param_generation_method
  min
  max
  inc
  sweep_id
  sweep_group_id
}
    `;
export const sweepPartFieldsFragmentDoc = gql`
    fragment sweepPartFields on SweepPart {
  enabled
  part_config {
    name
    display_name
  }
  parts {
    part_number
    serial_number
  }
  position
  sweep_id
  sweep_group_id
}
    `;
export const CreatePropertyDocument = gql`
    mutation CreateProperty($input: CreatePropertyInput!) {
  property: createProperty(input: $input) {
    id
    name
    display_name
    description
    path
    type
    min
    max
    values
    unit
  }
}
    `;
export type CreatePropertyMutationFn = Apollo.MutationFunction<CreatePropertyMutation, CreatePropertyMutationVariables>;

/**
 * __useCreatePropertyMutation__
 *
 * To run a mutation, you first call `useCreatePropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPropertyMutation, { data, loading, error }] = useCreatePropertyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePropertyMutation(baseOptions?: Apollo.MutationHookOptions<CreatePropertyMutation, CreatePropertyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePropertyMutation, CreatePropertyMutationVariables>(CreatePropertyDocument, options);
      }
export type CreatePropertyMutationHookResult = ReturnType<typeof useCreatePropertyMutation>;
export type CreatePropertyMutationResult = Apollo.MutationResult<CreatePropertyMutation>;
export type CreatePropertyMutationOptions = Apollo.BaseMutationOptions<CreatePropertyMutation, CreatePropertyMutationVariables>;
export const UpdatePropertyDocument = gql`
    mutation UpdateProperty($input: UpdatePropertyInput!) {
  property: updateProperty(input: $input) {
    id
    name
    display_name
    description
    path
    type
    min
    max
    values
    unit
  }
}
    `;
export type UpdatePropertyMutationFn = Apollo.MutationFunction<UpdatePropertyMutation, UpdatePropertyMutationVariables>;

/**
 * __useUpdatePropertyMutation__
 *
 * To run a mutation, you first call `useUpdatePropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePropertyMutation, { data, loading, error }] = useUpdatePropertyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePropertyMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePropertyMutation, UpdatePropertyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePropertyMutation, UpdatePropertyMutationVariables>(UpdatePropertyDocument, options);
      }
export type UpdatePropertyMutationHookResult = ReturnType<typeof useUpdatePropertyMutation>;
export type UpdatePropertyMutationResult = Apollo.MutationResult<UpdatePropertyMutation>;
export type UpdatePropertyMutationOptions = Apollo.BaseMutationOptions<UpdatePropertyMutation, UpdatePropertyMutationVariables>;
export const CreatePartCategoryDocument = gql`
    mutation CreatePartCategory($input: CreatePartCategoryInput!) {
  partCategory: createPartCategory(input: $input) {
    id
    name
    display_name
    description
  }
}
    `;
export type CreatePartCategoryMutationFn = Apollo.MutationFunction<CreatePartCategoryMutation, CreatePartCategoryMutationVariables>;

/**
 * __useCreatePartCategoryMutation__
 *
 * To run a mutation, you first call `useCreatePartCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePartCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPartCategoryMutation, { data, loading, error }] = useCreatePartCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePartCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreatePartCategoryMutation, CreatePartCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePartCategoryMutation, CreatePartCategoryMutationVariables>(CreatePartCategoryDocument, options);
      }
export type CreatePartCategoryMutationHookResult = ReturnType<typeof useCreatePartCategoryMutation>;
export type CreatePartCategoryMutationResult = Apollo.MutationResult<CreatePartCategoryMutation>;
export type CreatePartCategoryMutationOptions = Apollo.BaseMutationOptions<CreatePartCategoryMutation, CreatePartCategoryMutationVariables>;
export const CreatePartConfigDocument = gql`
    mutation CreatePartConfig($input: CreatePartConfigInput!) {
  partConfig: createPartConfig(input: $input) {
    ...allPartConfigFields
  }
}
    ${allPartConfigFieldsFragmentDoc}`;
export type CreatePartConfigMutationFn = Apollo.MutationFunction<CreatePartConfigMutation, CreatePartConfigMutationVariables>;

/**
 * __useCreatePartConfigMutation__
 *
 * To run a mutation, you first call `useCreatePartConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePartConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPartConfigMutation, { data, loading, error }] = useCreatePartConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePartConfigMutation(baseOptions?: Apollo.MutationHookOptions<CreatePartConfigMutation, CreatePartConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePartConfigMutation, CreatePartConfigMutationVariables>(CreatePartConfigDocument, options);
      }
export type CreatePartConfigMutationHookResult = ReturnType<typeof useCreatePartConfigMutation>;
export type CreatePartConfigMutationResult = Apollo.MutationResult<CreatePartConfigMutation>;
export type CreatePartConfigMutationOptions = Apollo.BaseMutationOptions<CreatePartConfigMutation, CreatePartConfigMutationVariables>;
export const UpdatePartConfigDocument = gql`
    mutation UpdatePartConfig($input: UpdatePartConfigInput!) {
  partConfig: updatePartConfig(input: $input) {
    ...allPartConfigFields
  }
}
    ${allPartConfigFieldsFragmentDoc}`;
export type UpdatePartConfigMutationFn = Apollo.MutationFunction<UpdatePartConfigMutation, UpdatePartConfigMutationVariables>;

/**
 * __useUpdatePartConfigMutation__
 *
 * To run a mutation, you first call `useUpdatePartConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartConfigMutation, { data, loading, error }] = useUpdatePartConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePartConfigMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePartConfigMutation, UpdatePartConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePartConfigMutation, UpdatePartConfigMutationVariables>(UpdatePartConfigDocument, options);
      }
export type UpdatePartConfigMutationHookResult = ReturnType<typeof useUpdatePartConfigMutation>;
export type UpdatePartConfigMutationResult = Apollo.MutationResult<UpdatePartConfigMutation>;
export type UpdatePartConfigMutationOptions = Apollo.BaseMutationOptions<UpdatePartConfigMutation, UpdatePartConfigMutationVariables>;
export const DeletePartConfigDocument = gql`
    mutation DeletePartConfig($id: Int!) {
  deletePartConfig(id: $id)
}
    `;
export type DeletePartConfigMutationFn = Apollo.MutationFunction<DeletePartConfigMutation, DeletePartConfigMutationVariables>;

/**
 * __useDeletePartConfigMutation__
 *
 * To run a mutation, you first call `useDeletePartConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePartConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePartConfigMutation, { data, loading, error }] = useDeletePartConfigMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePartConfigMutation(baseOptions?: Apollo.MutationHookOptions<DeletePartConfigMutation, DeletePartConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePartConfigMutation, DeletePartConfigMutationVariables>(DeletePartConfigDocument, options);
      }
export type DeletePartConfigMutationHookResult = ReturnType<typeof useDeletePartConfigMutation>;
export type DeletePartConfigMutationResult = Apollo.MutationResult<DeletePartConfigMutation>;
export type DeletePartConfigMutationOptions = Apollo.BaseMutationOptions<DeletePartConfigMutation, DeletePartConfigMutationVariables>;
export const BulkDeletePartConfigsDocument = gql`
    mutation BulkDeletePartConfigs($ids: [Int!]!) {
  bulkDeletePartConfigs(ids: $ids)
}
    `;
export type BulkDeletePartConfigsMutationFn = Apollo.MutationFunction<BulkDeletePartConfigsMutation, BulkDeletePartConfigsMutationVariables>;

/**
 * __useBulkDeletePartConfigsMutation__
 *
 * To run a mutation, you first call `useBulkDeletePartConfigsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkDeletePartConfigsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkDeletePartConfigsMutation, { data, loading, error }] = useBulkDeletePartConfigsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useBulkDeletePartConfigsMutation(baseOptions?: Apollo.MutationHookOptions<BulkDeletePartConfigsMutation, BulkDeletePartConfigsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkDeletePartConfigsMutation, BulkDeletePartConfigsMutationVariables>(BulkDeletePartConfigsDocument, options);
      }
export type BulkDeletePartConfigsMutationHookResult = ReturnType<typeof useBulkDeletePartConfigsMutation>;
export type BulkDeletePartConfigsMutationResult = Apollo.MutationResult<BulkDeletePartConfigsMutation>;
export type BulkDeletePartConfigsMutationOptions = Apollo.BaseMutationOptions<BulkDeletePartConfigsMutation, BulkDeletePartConfigsMutationVariables>;
export const CreatePartDocument = gql`
    mutation CreatePart($input: CreatePartInput!) {
  part: createPart(input: $input) {
    id
  }
}
    `;
export type CreatePartMutationFn = Apollo.MutationFunction<CreatePartMutation, CreatePartMutationVariables>;

/**
 * __useCreatePartMutation__
 *
 * To run a mutation, you first call `useCreatePartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPartMutation, { data, loading, error }] = useCreatePartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePartMutation(baseOptions?: Apollo.MutationHookOptions<CreatePartMutation, CreatePartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePartMutation, CreatePartMutationVariables>(CreatePartDocument, options);
      }
export type CreatePartMutationHookResult = ReturnType<typeof useCreatePartMutation>;
export type CreatePartMutationResult = Apollo.MutationResult<CreatePartMutation>;
export type CreatePartMutationOptions = Apollo.BaseMutationOptions<CreatePartMutation, CreatePartMutationVariables>;
export const RequestPartPlottingDocument = gql`
    mutation RequestPartPlotting($input: [PartInput!]!, $partType: String!) {
  PartPlotRequest: requestPartPlotting(input: $input, partType: $partType) {
    success
  }
}
    `;
export type RequestPartPlottingMutationFn = Apollo.MutationFunction<RequestPartPlottingMutation, RequestPartPlottingMutationVariables>;

/**
 * __useRequestPartPlottingMutation__
 *
 * To run a mutation, you first call `useRequestPartPlottingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPartPlottingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPartPlottingMutation, { data, loading, error }] = useRequestPartPlottingMutation({
 *   variables: {
 *      input: // value for 'input'
 *      partType: // value for 'partType'
 *   },
 * });
 */
export function useRequestPartPlottingMutation(baseOptions?: Apollo.MutationHookOptions<RequestPartPlottingMutation, RequestPartPlottingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestPartPlottingMutation, RequestPartPlottingMutationVariables>(RequestPartPlottingDocument, options);
      }
export type RequestPartPlottingMutationHookResult = ReturnType<typeof useRequestPartPlottingMutation>;
export type RequestPartPlottingMutationResult = Apollo.MutationResult<RequestPartPlottingMutation>;
export type RequestPartPlottingMutationOptions = Apollo.BaseMutationOptions<RequestPartPlottingMutation, RequestPartPlottingMutationVariables>;
export const RequestPartFitDocument = gql`
    mutation RequestPartFit($input: [PartInput!]!, $partType: String!) {
  PartFitRequest: requestPartFit(input: $input, partType: $partType) {
    success
  }
}
    `;
export type RequestPartFitMutationFn = Apollo.MutationFunction<RequestPartFitMutation, RequestPartFitMutationVariables>;

/**
 * __useRequestPartFitMutation__
 *
 * To run a mutation, you first call `useRequestPartFitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPartFitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPartFitMutation, { data, loading, error }] = useRequestPartFitMutation({
 *   variables: {
 *      input: // value for 'input'
 *      partType: // value for 'partType'
 *   },
 * });
 */
export function useRequestPartFitMutation(baseOptions?: Apollo.MutationHookOptions<RequestPartFitMutation, RequestPartFitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestPartFitMutation, RequestPartFitMutationVariables>(RequestPartFitDocument, options);
      }
export type RequestPartFitMutationHookResult = ReturnType<typeof useRequestPartFitMutation>;
export type RequestPartFitMutationResult = Apollo.MutationResult<RequestPartFitMutation>;
export type RequestPartFitMutationOptions = Apollo.BaseMutationOptions<RequestPartFitMutation, RequestPartFitMutationVariables>;
export const UpdatePartDocument = gql`
    mutation UpdatePart($input: UpdatePartInput!) {
  part: updatePart(input: $input) {
    id
  }
}
    `;
export type UpdatePartMutationFn = Apollo.MutationFunction<UpdatePartMutation, UpdatePartMutationVariables>;

/**
 * __useUpdatePartMutation__
 *
 * To run a mutation, you first call `useUpdatePartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartMutation, { data, loading, error }] = useUpdatePartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePartMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePartMutation, UpdatePartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePartMutation, UpdatePartMutationVariables>(UpdatePartDocument, options);
      }
export type UpdatePartMutationHookResult = ReturnType<typeof useUpdatePartMutation>;
export type UpdatePartMutationResult = Apollo.MutationResult<UpdatePartMutation>;
export type UpdatePartMutationOptions = Apollo.BaseMutationOptions<UpdatePartMutation, UpdatePartMutationVariables>;
export const ImportPartFileDocument = gql`
    mutation ImportPartFile($fileBuffer: String!, $fileName: String!, $pos: String!, $type: String!) {
  importPartFile(
    fileBuffer: $fileBuffer
    fileName: $fileName
    pos: $pos
    type: $type
  ) {
    success
    type
    pos
    data
    error
  }
}
    `;
export type ImportPartFileMutationFn = Apollo.MutationFunction<ImportPartFileMutation, ImportPartFileMutationVariables>;

/**
 * __useImportPartFileMutation__
 *
 * To run a mutation, you first call `useImportPartFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportPartFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importPartFileMutation, { data, loading, error }] = useImportPartFileMutation({
 *   variables: {
 *      fileBuffer: // value for 'fileBuffer'
 *      fileName: // value for 'fileName'
 *      pos: // value for 'pos'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useImportPartFileMutation(baseOptions?: Apollo.MutationHookOptions<ImportPartFileMutation, ImportPartFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportPartFileMutation, ImportPartFileMutationVariables>(ImportPartFileDocument, options);
      }
export type ImportPartFileMutationHookResult = ReturnType<typeof useImportPartFileMutation>;
export type ImportPartFileMutationResult = Apollo.MutationResult<ImportPartFileMutation>;
export type ImportPartFileMutationOptions = Apollo.BaseMutationOptions<ImportPartFileMutation, ImportPartFileMutationVariables>;
export const CreateRunDocument = gql`
    mutation CreateRun($fromBranchId: Int!, $name: String!, $session: String, $sessionId: String, $input: CreateRunInput!) {
  run: createRun(
    fromBranchId: $fromBranchId
    name: $name
    session: $session
    sessionId: $sessionId
    input: $input
  ) {
    id
    branch {
      id
      name
    }
    data
    session
    session_id
  }
}
    `;
export type CreateRunMutationFn = Apollo.MutationFunction<CreateRunMutation, CreateRunMutationVariables>;

/**
 * __useCreateRunMutation__
 *
 * To run a mutation, you first call `useCreateRunMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRunMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRunMutation, { data, loading, error }] = useCreateRunMutation({
 *   variables: {
 *      fromBranchId: // value for 'fromBranchId'
 *      name: // value for 'name'
 *      session: // value for 'session'
 *      sessionId: // value for 'sessionId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRunMutation(baseOptions?: Apollo.MutationHookOptions<CreateRunMutation, CreateRunMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRunMutation, CreateRunMutationVariables>(CreateRunDocument, options);
      }
export type CreateRunMutationHookResult = ReturnType<typeof useCreateRunMutation>;
export type CreateRunMutationResult = Apollo.MutationResult<CreateRunMutation>;
export type CreateRunMutationOptions = Apollo.BaseMutationOptions<CreateRunMutation, CreateRunMutationVariables>;
export const BulkUpdateRunsDocument = gql`
    mutation BulkUpdateRuns($inputs: [UpdateRunInput!]!) {
  runs: bulkUpdateRuns(inputs: $inputs) {
    id
    data
    branch {
      id
      name
    }
    session
    session_id
  }
}
    `;
export type BulkUpdateRunsMutationFn = Apollo.MutationFunction<BulkUpdateRunsMutation, BulkUpdateRunsMutationVariables>;

/**
 * __useBulkUpdateRunsMutation__
 *
 * To run a mutation, you first call `useBulkUpdateRunsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateRunsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateRunsMutation, { data, loading, error }] = useBulkUpdateRunsMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useBulkUpdateRunsMutation(baseOptions?: Apollo.MutationHookOptions<BulkUpdateRunsMutation, BulkUpdateRunsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkUpdateRunsMutation, BulkUpdateRunsMutationVariables>(BulkUpdateRunsDocument, options);
      }
export type BulkUpdateRunsMutationHookResult = ReturnType<typeof useBulkUpdateRunsMutation>;
export type BulkUpdateRunsMutationResult = Apollo.MutationResult<BulkUpdateRunsMutation>;
export type BulkUpdateRunsMutationOptions = Apollo.BaseMutationOptions<BulkUpdateRunsMutation, BulkUpdateRunsMutationVariables>;
export const UpdateRunDocument = gql`
    mutation UpdateRun($input: UpdateRunInput!) {
  run: updateRun(input: $input) {
    id
    data
    branch {
      id
      name
    }
    session
    session_id
  }
}
    `;
export type UpdateRunMutationFn = Apollo.MutationFunction<UpdateRunMutation, UpdateRunMutationVariables>;

/**
 * __useUpdateRunMutation__
 *
 * To run a mutation, you first call `useUpdateRunMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRunMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRunMutation, { data, loading, error }] = useUpdateRunMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRunMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRunMutation, UpdateRunMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRunMutation, UpdateRunMutationVariables>(UpdateRunDocument, options);
      }
export type UpdateRunMutationHookResult = ReturnType<typeof useUpdateRunMutation>;
export type UpdateRunMutationResult = Apollo.MutationResult<UpdateRunMutation>;
export type UpdateRunMutationOptions = Apollo.BaseMutationOptions<UpdateRunMutation, UpdateRunMutationVariables>;
export const DeleteRunDocument = gql`
    mutation DeleteRun($id: Int!) {
  deleteCount: deleteRun(id: $id)
}
    `;
export type DeleteRunMutationFn = Apollo.MutationFunction<DeleteRunMutation, DeleteRunMutationVariables>;

/**
 * __useDeleteRunMutation__
 *
 * To run a mutation, you first call `useDeleteRunMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRunMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRunMutation, { data, loading, error }] = useDeleteRunMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRunMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRunMutation, DeleteRunMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRunMutation, DeleteRunMutationVariables>(DeleteRunDocument, options);
      }
export type DeleteRunMutationHookResult = ReturnType<typeof useDeleteRunMutation>;
export type DeleteRunMutationResult = Apollo.MutationResult<DeleteRunMutation>;
export type DeleteRunMutationOptions = Apollo.BaseMutationOptions<DeleteRunMutation, DeleteRunMutationVariables>;
export const CloneRunDocument = gql`
    mutation CloneRun($id: Int!, $fromBranchId: Int!) {
  run: cloneRun(id: $id, fromBranchId: $fromBranchId) {
    id
    branch {
      id
      name
    }
    data
    session
    session_id
  }
}
    `;
export type CloneRunMutationFn = Apollo.MutationFunction<CloneRunMutation, CloneRunMutationVariables>;

/**
 * __useCloneRunMutation__
 *
 * To run a mutation, you first call `useCloneRunMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneRunMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneRunMutation, { data, loading, error }] = useCloneRunMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fromBranchId: // value for 'fromBranchId'
 *   },
 * });
 */
export function useCloneRunMutation(baseOptions?: Apollo.MutationHookOptions<CloneRunMutation, CloneRunMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloneRunMutation, CloneRunMutationVariables>(CloneRunDocument, options);
      }
export type CloneRunMutationHookResult = ReturnType<typeof useCloneRunMutation>;
export type CloneRunMutationResult = Apollo.MutationResult<CloneRunMutation>;
export type CloneRunMutationOptions = Apollo.BaseMutationOptions<CloneRunMutation, CloneRunMutationVariables>;
export const CreateSetupFieldDocument = gql`
    mutation CreateSetupField($input: CreateSetupFieldInput!) {
  setupField: createSetupField(input: $input) {
    ...allSetupFieldFields
  }
}
    ${allSetupFieldFieldsFragmentDoc}`;
export type CreateSetupFieldMutationFn = Apollo.MutationFunction<CreateSetupFieldMutation, CreateSetupFieldMutationVariables>;

/**
 * __useCreateSetupFieldMutation__
 *
 * To run a mutation, you first call `useCreateSetupFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSetupFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSetupFieldMutation, { data, loading, error }] = useCreateSetupFieldMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSetupFieldMutation(baseOptions?: Apollo.MutationHookOptions<CreateSetupFieldMutation, CreateSetupFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSetupFieldMutation, CreateSetupFieldMutationVariables>(CreateSetupFieldDocument, options);
      }
export type CreateSetupFieldMutationHookResult = ReturnType<typeof useCreateSetupFieldMutation>;
export type CreateSetupFieldMutationResult = Apollo.MutationResult<CreateSetupFieldMutation>;
export type CreateSetupFieldMutationOptions = Apollo.BaseMutationOptions<CreateSetupFieldMutation, CreateSetupFieldMutationVariables>;
export const UpdateSetupFieldDocument = gql`
    mutation UpdateSetupField($input: UpdateSetupFieldInput!) {
  setupField: updateSetupField(input: $input) {
    ...allSetupFieldFields
  }
}
    ${allSetupFieldFieldsFragmentDoc}`;
export type UpdateSetupFieldMutationFn = Apollo.MutationFunction<UpdateSetupFieldMutation, UpdateSetupFieldMutationVariables>;

/**
 * __useUpdateSetupFieldMutation__
 *
 * To run a mutation, you first call `useUpdateSetupFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSetupFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSetupFieldMutation, { data, loading, error }] = useUpdateSetupFieldMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSetupFieldMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSetupFieldMutation, UpdateSetupFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSetupFieldMutation, UpdateSetupFieldMutationVariables>(UpdateSetupFieldDocument, options);
      }
export type UpdateSetupFieldMutationHookResult = ReturnType<typeof useUpdateSetupFieldMutation>;
export type UpdateSetupFieldMutationResult = Apollo.MutationResult<UpdateSetupFieldMutation>;
export type UpdateSetupFieldMutationOptions = Apollo.BaseMutationOptions<UpdateSetupFieldMutation, UpdateSetupFieldMutationVariables>;
export const DeleteSetupFieldDocument = gql`
    mutation DeleteSetupField($id: Int!) {
  deleteCount: deleteSetupField(id: $id)
}
    `;
export type DeleteSetupFieldMutationFn = Apollo.MutationFunction<DeleteSetupFieldMutation, DeleteSetupFieldMutationVariables>;

/**
 * __useDeleteSetupFieldMutation__
 *
 * To run a mutation, you first call `useDeleteSetupFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSetupFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSetupFieldMutation, { data, loading, error }] = useDeleteSetupFieldMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSetupFieldMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSetupFieldMutation, DeleteSetupFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSetupFieldMutation, DeleteSetupFieldMutationVariables>(DeleteSetupFieldDocument, options);
      }
export type DeleteSetupFieldMutationHookResult = ReturnType<typeof useDeleteSetupFieldMutation>;
export type DeleteSetupFieldMutationResult = Apollo.MutationResult<DeleteSetupFieldMutation>;
export type DeleteSetupFieldMutationOptions = Apollo.BaseMutationOptions<DeleteSetupFieldMutation, DeleteSetupFieldMutationVariables>;
export const BulkDeleteSetupFieldsDocument = gql`
    mutation BulkDeleteSetupFields($ids: [Int!]!) {
  deleteCount: bulkDeleteSetupFields(ids: $ids)
}
    `;
export type BulkDeleteSetupFieldsMutationFn = Apollo.MutationFunction<BulkDeleteSetupFieldsMutation, BulkDeleteSetupFieldsMutationVariables>;

/**
 * __useBulkDeleteSetupFieldsMutation__
 *
 * To run a mutation, you first call `useBulkDeleteSetupFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkDeleteSetupFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkDeleteSetupFieldsMutation, { data, loading, error }] = useBulkDeleteSetupFieldsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useBulkDeleteSetupFieldsMutation(baseOptions?: Apollo.MutationHookOptions<BulkDeleteSetupFieldsMutation, BulkDeleteSetupFieldsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkDeleteSetupFieldsMutation, BulkDeleteSetupFieldsMutationVariables>(BulkDeleteSetupFieldsDocument, options);
      }
export type BulkDeleteSetupFieldsMutationHookResult = ReturnType<typeof useBulkDeleteSetupFieldsMutation>;
export type BulkDeleteSetupFieldsMutationResult = Apollo.MutationResult<BulkDeleteSetupFieldsMutation>;
export type BulkDeleteSetupFieldsMutationOptions = Apollo.BaseMutationOptions<BulkDeleteSetupFieldsMutation, BulkDeleteSetupFieldsMutationVariables>;
export const CreateSUITDocument = gql`
    mutation CreateSUIT($input: CreateSUITInput!) {
  suit: createSUIT(input: $input) {
    ...allSUITFields
  }
}
    ${allSUITFieldsFragmentDoc}`;
export type CreateSUITMutationFn = Apollo.MutationFunction<CreateSUITMutation, CreateSUITMutationVariables>;

/**
 * __useCreateSUITMutation__
 *
 * To run a mutation, you first call `useCreateSUITMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSUITMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSuitMutation, { data, loading, error }] = useCreateSUITMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSUITMutation(baseOptions?: Apollo.MutationHookOptions<CreateSUITMutation, CreateSUITMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSUITMutation, CreateSUITMutationVariables>(CreateSUITDocument, options);
      }
export type CreateSUITMutationHookResult = ReturnType<typeof useCreateSUITMutation>;
export type CreateSUITMutationResult = Apollo.MutationResult<CreateSUITMutation>;
export type CreateSUITMutationOptions = Apollo.BaseMutationOptions<CreateSUITMutation, CreateSUITMutationVariables>;
export const UpdateSUITDocument = gql`
    mutation UpdateSUIT($input: UpdateSUITInput!) {
  suit: updateSUIT(input: $input) {
    ...allSUITFields
  }
}
    ${allSUITFieldsFragmentDoc}`;
export type UpdateSUITMutationFn = Apollo.MutationFunction<UpdateSUITMutation, UpdateSUITMutationVariables>;

/**
 * __useUpdateSUITMutation__
 *
 * To run a mutation, you first call `useUpdateSUITMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSUITMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSuitMutation, { data, loading, error }] = useUpdateSUITMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSUITMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSUITMutation, UpdateSUITMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSUITMutation, UpdateSUITMutationVariables>(UpdateSUITDocument, options);
      }
export type UpdateSUITMutationHookResult = ReturnType<typeof useUpdateSUITMutation>;
export type UpdateSUITMutationResult = Apollo.MutationResult<UpdateSUITMutation>;
export type UpdateSUITMutationOptions = Apollo.BaseMutationOptions<UpdateSUITMutation, UpdateSUITMutationVariables>;
export const DeleteSUITDocument = gql`
    mutation DeleteSUIT($id: Int!) {
  deleteCount: deleteSUIT(id: $id)
}
    `;
export type DeleteSUITMutationFn = Apollo.MutationFunction<DeleteSUITMutation, DeleteSUITMutationVariables>;

/**
 * __useDeleteSUITMutation__
 *
 * To run a mutation, you first call `useDeleteSUITMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSUITMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSuitMutation, { data, loading, error }] = useDeleteSUITMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSUITMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSUITMutation, DeleteSUITMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSUITMutation, DeleteSUITMutationVariables>(DeleteSUITDocument, options);
      }
export type DeleteSUITMutationHookResult = ReturnType<typeof useDeleteSUITMutation>;
export type DeleteSUITMutationResult = Apollo.MutationResult<DeleteSUITMutation>;
export type DeleteSUITMutationOptions = Apollo.BaseMutationOptions<DeleteSUITMutation, DeleteSUITMutationVariables>;
export const BulkDeleteSUITsDocument = gql`
    mutation BulkDeleteSUITs($ids: [Int!]!) {
  deleteCount: bulkDeleteSUITs(ids: $ids)
}
    `;
export type BulkDeleteSUITsMutationFn = Apollo.MutationFunction<BulkDeleteSUITsMutation, BulkDeleteSUITsMutationVariables>;

/**
 * __useBulkDeleteSUITsMutation__
 *
 * To run a mutation, you first call `useBulkDeleteSUITsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkDeleteSUITsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkDeleteSuiTsMutation, { data, loading, error }] = useBulkDeleteSUITsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useBulkDeleteSUITsMutation(baseOptions?: Apollo.MutationHookOptions<BulkDeleteSUITsMutation, BulkDeleteSUITsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkDeleteSUITsMutation, BulkDeleteSUITsMutationVariables>(BulkDeleteSUITsDocument, options);
      }
export type BulkDeleteSUITsMutationHookResult = ReturnType<typeof useBulkDeleteSUITsMutation>;
export type BulkDeleteSUITsMutationResult = Apollo.MutationResult<BulkDeleteSUITsMutation>;
export type BulkDeleteSUITsMutationOptions = Apollo.BaseMutationOptions<BulkDeleteSUITsMutation, BulkDeleteSUITsMutationVariables>;
export const CloneSUITDocument = gql`
    mutation CloneSUIT($id: Int!, $name: String!) {
  suit: cloneSUIT(id: $id, name: $name) {
    id
    spec
    name
    description
    version
    last_modified_by
  }
}
    `;
export type CloneSUITMutationFn = Apollo.MutationFunction<CloneSUITMutation, CloneSUITMutationVariables>;

/**
 * __useCloneSUITMutation__
 *
 * To run a mutation, you first call `useCloneSUITMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneSUITMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneSuitMutation, { data, loading, error }] = useCloneSUITMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCloneSUITMutation(baseOptions?: Apollo.MutationHookOptions<CloneSUITMutation, CloneSUITMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloneSUITMutation, CloneSUITMutationVariables>(CloneSUITDocument, options);
      }
export type CloneSUITMutationHookResult = ReturnType<typeof useCloneSUITMutation>;
export type CloneSUITMutationResult = Apollo.MutationResult<CloneSUITMutation>;
export type CloneSUITMutationOptions = Apollo.BaseMutationOptions<CloneSUITMutation, CloneSUITMutationVariables>;
export const CreateSetupDocument = gql`
    mutation CreateSetup($input: CreateSetupInput!) {
  setup: createSetup(input: $input) {
    id
  }
}
    `;
export type CreateSetupMutationFn = Apollo.MutationFunction<CreateSetupMutation, CreateSetupMutationVariables>;

/**
 * __useCreateSetupMutation__
 *
 * To run a mutation, you first call `useCreateSetupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSetupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSetupMutation, { data, loading, error }] = useCreateSetupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSetupMutation(baseOptions?: Apollo.MutationHookOptions<CreateSetupMutation, CreateSetupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSetupMutation, CreateSetupMutationVariables>(CreateSetupDocument, options);
      }
export type CreateSetupMutationHookResult = ReturnType<typeof useCreateSetupMutation>;
export type CreateSetupMutationResult = Apollo.MutationResult<CreateSetupMutation>;
export type CreateSetupMutationOptions = Apollo.BaseMutationOptions<CreateSetupMutation, CreateSetupMutationVariables>;
export const CloneSetupDocument = gql`
    mutation CloneSetup($id: Int!, $name: String!) {
  cloneSetup(id: $id, name: $name) {
    branch {
      id
      name
      root {
        id
      }
      head {
        id
      }
      tail {
        id
      }
    }
    setup {
      ...mostSetupFields
    }
  }
}
    ${mostSetupFieldsFragmentDoc}`;
export type CloneSetupMutationFn = Apollo.MutationFunction<CloneSetupMutation, CloneSetupMutationVariables>;

/**
 * __useCloneSetupMutation__
 *
 * To run a mutation, you first call `useCloneSetupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneSetupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneSetupMutation, { data, loading, error }] = useCloneSetupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCloneSetupMutation(baseOptions?: Apollo.MutationHookOptions<CloneSetupMutation, CloneSetupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloneSetupMutation, CloneSetupMutationVariables>(CloneSetupDocument, options);
      }
export type CloneSetupMutationHookResult = ReturnType<typeof useCloneSetupMutation>;
export type CloneSetupMutationResult = Apollo.MutationResult<CloneSetupMutation>;
export type CloneSetupMutationOptions = Apollo.BaseMutationOptions<CloneSetupMutation, CloneSetupMutationVariables>;
export const ApplyPatchToSetupDocument = gql`
    mutation ApplyPatchToSetup($fromBranchId: Int!, $toBranchId: Int!, $ops: [JSONObject!]!, $fullMerge: Boolean!) {
  setup: applyPatchToSetup(
    fromBranchId: $fromBranchId
    toBranchId: $toBranchId
    ops: $ops
    fullMerge: $fullMerge
  ) {
    ...mostSetupFields
  }
}
    ${mostSetupFieldsFragmentDoc}`;
export type ApplyPatchToSetupMutationFn = Apollo.MutationFunction<ApplyPatchToSetupMutation, ApplyPatchToSetupMutationVariables>;

/**
 * __useApplyPatchToSetupMutation__
 *
 * To run a mutation, you first call `useApplyPatchToSetupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyPatchToSetupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyPatchToSetupMutation, { data, loading, error }] = useApplyPatchToSetupMutation({
 *   variables: {
 *      fromBranchId: // value for 'fromBranchId'
 *      toBranchId: // value for 'toBranchId'
 *      ops: // value for 'ops'
 *      fullMerge: // value for 'fullMerge'
 *   },
 * });
 */
export function useApplyPatchToSetupMutation(baseOptions?: Apollo.MutationHookOptions<ApplyPatchToSetupMutation, ApplyPatchToSetupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyPatchToSetupMutation, ApplyPatchToSetupMutationVariables>(ApplyPatchToSetupDocument, options);
      }
export type ApplyPatchToSetupMutationHookResult = ReturnType<typeof useApplyPatchToSetupMutation>;
export type ApplyPatchToSetupMutationResult = Apollo.MutationResult<ApplyPatchToSetupMutation>;
export type ApplyPatchToSetupMutationOptions = Apollo.BaseMutationOptions<ApplyPatchToSetupMutation, ApplyPatchToSetupMutationVariables>;
export const CommitSetupDocument = gql`
    mutation CommitSetup($branchId: Int!, $setup: CreateSetupInput!) {
  commitedSetup: commitSetup(branchId: $branchId, setup: $setup) {
    ...mostSetupFields
  }
}
    ${mostSetupFieldsFragmentDoc}`;
export type CommitSetupMutationFn = Apollo.MutationFunction<CommitSetupMutation, CommitSetupMutationVariables>;

/**
 * __useCommitSetupMutation__
 *
 * To run a mutation, you first call `useCommitSetupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommitSetupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [commitSetupMutation, { data, loading, error }] = useCommitSetupMutation({
 *   variables: {
 *      branchId: // value for 'branchId'
 *      setup: // value for 'setup'
 *   },
 * });
 */
export function useCommitSetupMutation(baseOptions?: Apollo.MutationHookOptions<CommitSetupMutation, CommitSetupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CommitSetupMutation, CommitSetupMutationVariables>(CommitSetupDocument, options);
      }
export type CommitSetupMutationHookResult = ReturnType<typeof useCommitSetupMutation>;
export type CommitSetupMutationResult = Apollo.MutationResult<CommitSetupMutation>;
export type CommitSetupMutationOptions = Apollo.BaseMutationOptions<CommitSetupMutation, CommitSetupMutationVariables>;
export const CreateBranchDocument = gql`
    mutation CreateBranch($fromId: Int!, $name: String!) {
  branch: createBranch(fromId: $fromId, name: $name) {
    id
    name
    root {
      id
    }
    head {
      id
    }
  }
}
    `;
export type CreateBranchMutationFn = Apollo.MutationFunction<CreateBranchMutation, CreateBranchMutationVariables>;

/**
 * __useCreateBranchMutation__
 *
 * To run a mutation, you first call `useCreateBranchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBranchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBranchMutation, { data, loading, error }] = useCreateBranchMutation({
 *   variables: {
 *      fromId: // value for 'fromId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateBranchMutation(baseOptions?: Apollo.MutationHookOptions<CreateBranchMutation, CreateBranchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBranchMutation, CreateBranchMutationVariables>(CreateBranchDocument, options);
      }
export type CreateBranchMutationHookResult = ReturnType<typeof useCreateBranchMutation>;
export type CreateBranchMutationResult = Apollo.MutationResult<CreateBranchMutation>;
export type CreateBranchMutationOptions = Apollo.BaseMutationOptions<CreateBranchMutation, CreateBranchMutationVariables>;
export const UpdateBranchDocument = gql`
    mutation UpdateBranch($input: SetupBranchInput!) {
  updatedIds: updateBranch(input: $input)
}
    `;
export type UpdateBranchMutationFn = Apollo.MutationFunction<UpdateBranchMutation, UpdateBranchMutationVariables>;

/**
 * __useUpdateBranchMutation__
 *
 * To run a mutation, you first call `useUpdateBranchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBranchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBranchMutation, { data, loading, error }] = useUpdateBranchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBranchMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBranchMutation, UpdateBranchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBranchMutation, UpdateBranchMutationVariables>(UpdateBranchDocument, options);
      }
export type UpdateBranchMutationHookResult = ReturnType<typeof useUpdateBranchMutation>;
export type UpdateBranchMutationResult = Apollo.MutationResult<UpdateBranchMutation>;
export type UpdateBranchMutationOptions = Apollo.BaseMutationOptions<UpdateBranchMutation, UpdateBranchMutationVariables>;
export const DeleteBranchDocument = gql`
    mutation DeleteBranch($id: Int!) {
  numDeleted: deleteBranch(id: $id) {
    branches
    setups
  }
}
    `;
export type DeleteBranchMutationFn = Apollo.MutationFunction<DeleteBranchMutation, DeleteBranchMutationVariables>;

/**
 * __useDeleteBranchMutation__
 *
 * To run a mutation, you first call `useDeleteBranchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBranchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBranchMutation, { data, loading, error }] = useDeleteBranchMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBranchMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBranchMutation, DeleteBranchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBranchMutation, DeleteBranchMutationVariables>(DeleteBranchDocument, options);
      }
export type DeleteBranchMutationHookResult = ReturnType<typeof useDeleteBranchMutation>;
export type DeleteBranchMutationResult = Apollo.MutationResult<DeleteBranchMutation>;
export type DeleteBranchMutationOptions = Apollo.BaseMutationOptions<DeleteBranchMutation, DeleteBranchMutationVariables>;
export const DeleteRepoDocument = gql`
    mutation DeleteRepo($rootId: Int!) {
  numDeleted: deleteRepo(rootId: $rootId) {
    branches
    setups
  }
}
    `;
export type DeleteRepoMutationFn = Apollo.MutationFunction<DeleteRepoMutation, DeleteRepoMutationVariables>;

/**
 * __useDeleteRepoMutation__
 *
 * To run a mutation, you first call `useDeleteRepoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRepoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRepoMutation, { data, loading, error }] = useDeleteRepoMutation({
 *   variables: {
 *      rootId: // value for 'rootId'
 *   },
 * });
 */
export function useDeleteRepoMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRepoMutation, DeleteRepoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRepoMutation, DeleteRepoMutationVariables>(DeleteRepoDocument, options);
      }
export type DeleteRepoMutationHookResult = ReturnType<typeof useDeleteRepoMutation>;
export type DeleteRepoMutationResult = Apollo.MutationResult<DeleteRepoMutation>;
export type DeleteRepoMutationOptions = Apollo.BaseMutationOptions<DeleteRepoMutation, DeleteRepoMutationVariables>;
export const CreateRunFieldDocument = gql`
    mutation CreateRunField($input: CreateRunFieldInput!) {
  runField: createRunField(input: $input) {
    id
  }
}
    `;
export type CreateRunFieldMutationFn = Apollo.MutationFunction<CreateRunFieldMutation, CreateRunFieldMutationVariables>;

/**
 * __useCreateRunFieldMutation__
 *
 * To run a mutation, you first call `useCreateRunFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRunFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRunFieldMutation, { data, loading, error }] = useCreateRunFieldMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRunFieldMutation(baseOptions?: Apollo.MutationHookOptions<CreateRunFieldMutation, CreateRunFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRunFieldMutation, CreateRunFieldMutationVariables>(CreateRunFieldDocument, options);
      }
export type CreateRunFieldMutationHookResult = ReturnType<typeof useCreateRunFieldMutation>;
export type CreateRunFieldMutationResult = Apollo.MutationResult<CreateRunFieldMutation>;
export type CreateRunFieldMutationOptions = Apollo.BaseMutationOptions<CreateRunFieldMutation, CreateRunFieldMutationVariables>;
export const UpdateRunFieldDocument = gql`
    mutation UpdateRunField($input: UpdateRunFieldInput!) {
  runField: updateRunField(input: $input) {
    id
  }
}
    `;
export type UpdateRunFieldMutationFn = Apollo.MutationFunction<UpdateRunFieldMutation, UpdateRunFieldMutationVariables>;

/**
 * __useUpdateRunFieldMutation__
 *
 * To run a mutation, you first call `useUpdateRunFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRunFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRunFieldMutation, { data, loading, error }] = useUpdateRunFieldMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRunFieldMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRunFieldMutation, UpdateRunFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRunFieldMutation, UpdateRunFieldMutationVariables>(UpdateRunFieldDocument, options);
      }
export type UpdateRunFieldMutationHookResult = ReturnType<typeof useUpdateRunFieldMutation>;
export type UpdateRunFieldMutationResult = Apollo.MutationResult<UpdateRunFieldMutation>;
export type UpdateRunFieldMutationOptions = Apollo.BaseMutationOptions<UpdateRunFieldMutation, UpdateRunFieldMutationVariables>;
export const DeleteRunFieldDocument = gql`
    mutation DeleteRunField($id: Int!) {
  deleteCount: deleteRunField(id: $id)
}
    `;
export type DeleteRunFieldMutationFn = Apollo.MutationFunction<DeleteRunFieldMutation, DeleteRunFieldMutationVariables>;

/**
 * __useDeleteRunFieldMutation__
 *
 * To run a mutation, you first call `useDeleteRunFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRunFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRunFieldMutation, { data, loading, error }] = useDeleteRunFieldMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRunFieldMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRunFieldMutation, DeleteRunFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRunFieldMutation, DeleteRunFieldMutationVariables>(DeleteRunFieldDocument, options);
      }
export type DeleteRunFieldMutationHookResult = ReturnType<typeof useDeleteRunFieldMutation>;
export type DeleteRunFieldMutationResult = Apollo.MutationResult<DeleteRunFieldMutation>;
export type DeleteRunFieldMutationOptions = Apollo.BaseMutationOptions<DeleteRunFieldMutation, DeleteRunFieldMutationVariables>;
export const BulkDeleteRunFieldsDocument = gql`
    mutation BulkDeleteRunFields($ids: [Int!]!) {
  deleteCount: bulkDeleteRunFields(ids: $ids)
}
    `;
export type BulkDeleteRunFieldsMutationFn = Apollo.MutationFunction<BulkDeleteRunFieldsMutation, BulkDeleteRunFieldsMutationVariables>;

/**
 * __useBulkDeleteRunFieldsMutation__
 *
 * To run a mutation, you first call `useBulkDeleteRunFieldsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkDeleteRunFieldsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkDeleteRunFieldsMutation, { data, loading, error }] = useBulkDeleteRunFieldsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useBulkDeleteRunFieldsMutation(baseOptions?: Apollo.MutationHookOptions<BulkDeleteRunFieldsMutation, BulkDeleteRunFieldsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkDeleteRunFieldsMutation, BulkDeleteRunFieldsMutationVariables>(BulkDeleteRunFieldsDocument, options);
      }
export type BulkDeleteRunFieldsMutationHookResult = ReturnType<typeof useBulkDeleteRunFieldsMutation>;
export type BulkDeleteRunFieldsMutationResult = Apollo.MutationResult<BulkDeleteRunFieldsMutation>;
export type BulkDeleteRunFieldsMutationOptions = Apollo.BaseMutationOptions<BulkDeleteRunFieldsMutation, BulkDeleteRunFieldsMutationVariables>;
export const CreateRUITDocument = gql`
    mutation CreateRUIT($input: CreateRUITInput!) {
  ruit: createRUIT(input: $input) {
    id
    name
    description
    version
  }
}
    `;
export type CreateRUITMutationFn = Apollo.MutationFunction<CreateRUITMutation, CreateRUITMutationVariables>;

/**
 * __useCreateRUITMutation__
 *
 * To run a mutation, you first call `useCreateRUITMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRUITMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRuitMutation, { data, loading, error }] = useCreateRUITMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRUITMutation(baseOptions?: Apollo.MutationHookOptions<CreateRUITMutation, CreateRUITMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRUITMutation, CreateRUITMutationVariables>(CreateRUITDocument, options);
      }
export type CreateRUITMutationHookResult = ReturnType<typeof useCreateRUITMutation>;
export type CreateRUITMutationResult = Apollo.MutationResult<CreateRUITMutation>;
export type CreateRUITMutationOptions = Apollo.BaseMutationOptions<CreateRUITMutation, CreateRUITMutationVariables>;
export const UpdateRUITDocument = gql`
    mutation UpdateRUIT($input: UpdateRUITInput!) {
  ruit: updateRUIT(input: $input) {
    id
    name
    description
    version
  }
}
    `;
export type UpdateRUITMutationFn = Apollo.MutationFunction<UpdateRUITMutation, UpdateRUITMutationVariables>;

/**
 * __useUpdateRUITMutation__
 *
 * To run a mutation, you first call `useUpdateRUITMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRUITMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRuitMutation, { data, loading, error }] = useUpdateRUITMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRUITMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRUITMutation, UpdateRUITMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRUITMutation, UpdateRUITMutationVariables>(UpdateRUITDocument, options);
      }
export type UpdateRUITMutationHookResult = ReturnType<typeof useUpdateRUITMutation>;
export type UpdateRUITMutationResult = Apollo.MutationResult<UpdateRUITMutation>;
export type UpdateRUITMutationOptions = Apollo.BaseMutationOptions<UpdateRUITMutation, UpdateRUITMutationVariables>;
export const DeleteRUITDocument = gql`
    mutation DeleteRUIT($id: Int!) {
  deleteCount: deleteRUIT(id: $id)
}
    `;
export type DeleteRUITMutationFn = Apollo.MutationFunction<DeleteRUITMutation, DeleteRUITMutationVariables>;

/**
 * __useDeleteRUITMutation__
 *
 * To run a mutation, you first call `useDeleteRUITMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRUITMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRuitMutation, { data, loading, error }] = useDeleteRUITMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRUITMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRUITMutation, DeleteRUITMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRUITMutation, DeleteRUITMutationVariables>(DeleteRUITDocument, options);
      }
export type DeleteRUITMutationHookResult = ReturnType<typeof useDeleteRUITMutation>;
export type DeleteRUITMutationResult = Apollo.MutationResult<DeleteRUITMutation>;
export type DeleteRUITMutationOptions = Apollo.BaseMutationOptions<DeleteRUITMutation, DeleteRUITMutationVariables>;
export const BulkDeleteRUITsDocument = gql`
    mutation BulkDeleteRUITs($ids: [Int!]!) {
  deleteCount: bulkDeleteRUITs(ids: $ids)
}
    `;
export type BulkDeleteRUITsMutationFn = Apollo.MutationFunction<BulkDeleteRUITsMutation, BulkDeleteRUITsMutationVariables>;

/**
 * __useBulkDeleteRUITsMutation__
 *
 * To run a mutation, you first call `useBulkDeleteRUITsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkDeleteRUITsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkDeleteRuiTsMutation, { data, loading, error }] = useBulkDeleteRUITsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useBulkDeleteRUITsMutation(baseOptions?: Apollo.MutationHookOptions<BulkDeleteRUITsMutation, BulkDeleteRUITsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkDeleteRUITsMutation, BulkDeleteRUITsMutationVariables>(BulkDeleteRUITsDocument, options);
      }
export type BulkDeleteRUITsMutationHookResult = ReturnType<typeof useBulkDeleteRUITsMutation>;
export type BulkDeleteRUITsMutationResult = Apollo.MutationResult<BulkDeleteRUITsMutation>;
export type BulkDeleteRUITsMutationOptions = Apollo.BaseMutationOptions<BulkDeleteRUITsMutation, BulkDeleteRUITsMutationVariables>;
export const CloneRUITDocument = gql`
    mutation CloneRUIT($id: Int!, $name: String!) {
  cloneRUIT(id: $id, name: $name) {
    id
    name
    description
    version
  }
}
    `;
export type CloneRUITMutationFn = Apollo.MutationFunction<CloneRUITMutation, CloneRUITMutationVariables>;

/**
 * __useCloneRUITMutation__
 *
 * To run a mutation, you first call `useCloneRUITMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneRUITMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneRuitMutation, { data, loading, error }] = useCloneRUITMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCloneRUITMutation(baseOptions?: Apollo.MutationHookOptions<CloneRUITMutation, CloneRUITMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloneRUITMutation, CloneRUITMutationVariables>(CloneRUITDocument, options);
      }
export type CloneRUITMutationHookResult = ReturnType<typeof useCloneRUITMutation>;
export type CloneRUITMutationResult = Apollo.MutationResult<CloneRUITMutation>;
export type CloneRUITMutationOptions = Apollo.BaseMutationOptions<CloneRUITMutation, CloneRUITMutationVariables>;
export const DeleteSimDocument = gql`
    mutation DeleteSim($id: Int!) {
  deleteSim: deleteSim(id: $id)
}
    `;
export type DeleteSimMutationFn = Apollo.MutationFunction<DeleteSimMutation, DeleteSimMutationVariables>;

/**
 * __useDeleteSimMutation__
 *
 * To run a mutation, you first call `useDeleteSimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSimMutation, { data, loading, error }] = useDeleteSimMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSimMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSimMutation, DeleteSimMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSimMutation, DeleteSimMutationVariables>(DeleteSimDocument, options);
      }
export type DeleteSimMutationHookResult = ReturnType<typeof useDeleteSimMutation>;
export type DeleteSimMutationResult = Apollo.MutationResult<DeleteSimMutation>;
export type DeleteSimMutationOptions = Apollo.BaseMutationOptions<DeleteSimMutation, DeleteSimMutationVariables>;
export const CloneSimDocument = gql`
    mutation CloneSim($id: Int!, $name: String!) {
  cloneSim(id: $id, name: $name) {
    id
  }
}
    `;
export type CloneSimMutationFn = Apollo.MutationFunction<CloneSimMutation, CloneSimMutationVariables>;

/**
 * __useCloneSimMutation__
 *
 * To run a mutation, you first call `useCloneSimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneSimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneSimMutation, { data, loading, error }] = useCloneSimMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCloneSimMutation(baseOptions?: Apollo.MutationHookOptions<CloneSimMutation, CloneSimMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloneSimMutation, CloneSimMutationVariables>(CloneSimDocument, options);
      }
export type CloneSimMutationHookResult = ReturnType<typeof useCloneSimMutation>;
export type CloneSimMutationResult = Apollo.MutationResult<CloneSimMutation>;
export type CloneSimMutationOptions = Apollo.BaseMutationOptions<CloneSimMutation, CloneSimMutationVariables>;
export const UpdateSimDocument = gql`
    mutation UpdateSim($input: UpdateSimInput!) {
  sim: updateSim(input: $input) {
    id
    name
    spec
    type
    owner
    data
    output_directory
    export_motec
    export_json
    export_debug
    download_motec
    download_json
    download_debug
    created_at
    updated_at
  }
}
    `;
export type UpdateSimMutationFn = Apollo.MutationFunction<UpdateSimMutation, UpdateSimMutationVariables>;

/**
 * __useUpdateSimMutation__
 *
 * To run a mutation, you first call `useUpdateSimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSimMutation, { data, loading, error }] = useUpdateSimMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSimMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSimMutation, UpdateSimMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSimMutation, UpdateSimMutationVariables>(UpdateSimDocument, options);
      }
export type UpdateSimMutationHookResult = ReturnType<typeof useUpdateSimMutation>;
export type UpdateSimMutationResult = Apollo.MutationResult<UpdateSimMutation>;
export type UpdateSimMutationOptions = Apollo.BaseMutationOptions<UpdateSimMutation, UpdateSimMutationVariables>;
export const DeleteEnvironmentDocument = gql`
    mutation DeleteEnvironment($id: Int!) {
  deleteEnvironment: deleteEnvironment(id: $id)
}
    `;
export type DeleteEnvironmentMutationFn = Apollo.MutationFunction<DeleteEnvironmentMutation, DeleteEnvironmentMutationVariables>;

/**
 * __useDeleteEnvironmentMutation__
 *
 * To run a mutation, you first call `useDeleteEnvironmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEnvironmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEnvironmentMutation, { data, loading, error }] = useDeleteEnvironmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEnvironmentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEnvironmentMutation, DeleteEnvironmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEnvironmentMutation, DeleteEnvironmentMutationVariables>(DeleteEnvironmentDocument, options);
      }
export type DeleteEnvironmentMutationHookResult = ReturnType<typeof useDeleteEnvironmentMutation>;
export type DeleteEnvironmentMutationResult = Apollo.MutationResult<DeleteEnvironmentMutation>;
export type DeleteEnvironmentMutationOptions = Apollo.BaseMutationOptions<DeleteEnvironmentMutation, DeleteEnvironmentMutationVariables>;
export const CloneEnvironmentDocument = gql`
    mutation CloneEnvironment($id: Int!, $name: String!) {
  cloneEnvironment(id: $id, name: $name) {
    id
  }
}
    `;
export type CloneEnvironmentMutationFn = Apollo.MutationFunction<CloneEnvironmentMutation, CloneEnvironmentMutationVariables>;

/**
 * __useCloneEnvironmentMutation__
 *
 * To run a mutation, you first call `useCloneEnvironmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneEnvironmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneEnvironmentMutation, { data, loading, error }] = useCloneEnvironmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCloneEnvironmentMutation(baseOptions?: Apollo.MutationHookOptions<CloneEnvironmentMutation, CloneEnvironmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloneEnvironmentMutation, CloneEnvironmentMutationVariables>(CloneEnvironmentDocument, options);
      }
export type CloneEnvironmentMutationHookResult = ReturnType<typeof useCloneEnvironmentMutation>;
export type CloneEnvironmentMutationResult = Apollo.MutationResult<CloneEnvironmentMutation>;
export type CloneEnvironmentMutationOptions = Apollo.BaseMutationOptions<CloneEnvironmentMutation, CloneEnvironmentMutationVariables>;
export const UpdateEnvironmentDocument = gql`
    mutation UpdateEnvironment($input: UpdateEnvironmentInput!) {
  sim: updateEnvironment(input: $input) {
    id
  }
}
    `;
export type UpdateEnvironmentMutationFn = Apollo.MutationFunction<UpdateEnvironmentMutation, UpdateEnvironmentMutationVariables>;

/**
 * __useUpdateEnvironmentMutation__
 *
 * To run a mutation, you first call `useUpdateEnvironmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEnvironmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEnvironmentMutation, { data, loading, error }] = useUpdateEnvironmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEnvironmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEnvironmentMutation, UpdateEnvironmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEnvironmentMutation, UpdateEnvironmentMutationVariables>(UpdateEnvironmentDocument, options);
      }
export type UpdateEnvironmentMutationHookResult = ReturnType<typeof useUpdateEnvironmentMutation>;
export type UpdateEnvironmentMutationResult = Apollo.MutationResult<UpdateEnvironmentMutation>;
export type UpdateEnvironmentMutationOptions = Apollo.BaseMutationOptions<UpdateEnvironmentMutation, UpdateEnvironmentMutationVariables>;
export const CreateEnvironmentDocument = gql`
    mutation CreateEnvironment($input: CreateEnvironmentInput!) {
  createEnvironment: createEnvironment(input: $input) {
    name
    track
    spec
    temperature
    wind_speed
    wind_direction
    elevation
    use_lap_wind_data
    traffic_wind
    traffic_wind_angle
    wind_scalar
    latitude
    longitude
    pressure
    humidity
  }
}
    `;
export type CreateEnvironmentMutationFn = Apollo.MutationFunction<CreateEnvironmentMutation, CreateEnvironmentMutationVariables>;

/**
 * __useCreateEnvironmentMutation__
 *
 * To run a mutation, you first call `useCreateEnvironmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEnvironmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEnvironmentMutation, { data, loading, error }] = useCreateEnvironmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEnvironmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateEnvironmentMutation, CreateEnvironmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEnvironmentMutation, CreateEnvironmentMutationVariables>(CreateEnvironmentDocument, options);
      }
export type CreateEnvironmentMutationHookResult = ReturnType<typeof useCreateEnvironmentMutation>;
export type CreateEnvironmentMutationResult = Apollo.MutationResult<CreateEnvironmentMutation>;
export type CreateEnvironmentMutationOptions = Apollo.BaseMutationOptions<CreateEnvironmentMutation, CreateEnvironmentMutationVariables>;
export const BulkAddSimToSimOutlineDocument = gql`
    mutation BulkAddSimToSimOutline($simOutlineId: Int!, $simIds: [Int!]!) {
  bulkAddSimToSimOutline(id: $simOutlineId, simIds: $simIds) {
    id
  }
}
    `;
export type BulkAddSimToSimOutlineMutationFn = Apollo.MutationFunction<BulkAddSimToSimOutlineMutation, BulkAddSimToSimOutlineMutationVariables>;

/**
 * __useBulkAddSimToSimOutlineMutation__
 *
 * To run a mutation, you first call `useBulkAddSimToSimOutlineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkAddSimToSimOutlineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkAddSimToSimOutlineMutation, { data, loading, error }] = useBulkAddSimToSimOutlineMutation({
 *   variables: {
 *      simOutlineId: // value for 'simOutlineId'
 *      simIds: // value for 'simIds'
 *   },
 * });
 */
export function useBulkAddSimToSimOutlineMutation(baseOptions?: Apollo.MutationHookOptions<BulkAddSimToSimOutlineMutation, BulkAddSimToSimOutlineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkAddSimToSimOutlineMutation, BulkAddSimToSimOutlineMutationVariables>(BulkAddSimToSimOutlineDocument, options);
      }
export type BulkAddSimToSimOutlineMutationHookResult = ReturnType<typeof useBulkAddSimToSimOutlineMutation>;
export type BulkAddSimToSimOutlineMutationResult = Apollo.MutationResult<BulkAddSimToSimOutlineMutation>;
export type BulkAddSimToSimOutlineMutationOptions = Apollo.BaseMutationOptions<BulkAddSimToSimOutlineMutation, BulkAddSimToSimOutlineMutationVariables>;
export const DeleteSimFromSimOutlineDocument = gql`
    mutation DeleteSimFromSimOutline($simOutlineId: Int!, $simId: Int!) {
  deleteSimFromSimOutline(id: $simOutlineId, simId: $simId)
}
    `;
export type DeleteSimFromSimOutlineMutationFn = Apollo.MutationFunction<DeleteSimFromSimOutlineMutation, DeleteSimFromSimOutlineMutationVariables>;

/**
 * __useDeleteSimFromSimOutlineMutation__
 *
 * To run a mutation, you first call `useDeleteSimFromSimOutlineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSimFromSimOutlineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSimFromSimOutlineMutation, { data, loading, error }] = useDeleteSimFromSimOutlineMutation({
 *   variables: {
 *      simOutlineId: // value for 'simOutlineId'
 *      simId: // value for 'simId'
 *   },
 * });
 */
export function useDeleteSimFromSimOutlineMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSimFromSimOutlineMutation, DeleteSimFromSimOutlineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSimFromSimOutlineMutation, DeleteSimFromSimOutlineMutationVariables>(DeleteSimFromSimOutlineDocument, options);
      }
export type DeleteSimFromSimOutlineMutationHookResult = ReturnType<typeof useDeleteSimFromSimOutlineMutation>;
export type DeleteSimFromSimOutlineMutationResult = Apollo.MutationResult<DeleteSimFromSimOutlineMutation>;
export type DeleteSimFromSimOutlineMutationOptions = Apollo.BaseMutationOptions<DeleteSimFromSimOutlineMutation, DeleteSimFromSimOutlineMutationVariables>;
export const BulkAddSetupBranchToSimOutlineDocument = gql`
    mutation BulkAddSetupBranchToSimOutline($simOutlineId: Int!, $setupBranchIds: [Int!]!) {
  bulkAddSetupBranchToSimOutline(
    id: $simOutlineId
    setupBranchIds: $setupBranchIds
  ) {
    id
  }
}
    `;
export type BulkAddSetupBranchToSimOutlineMutationFn = Apollo.MutationFunction<BulkAddSetupBranchToSimOutlineMutation, BulkAddSetupBranchToSimOutlineMutationVariables>;

/**
 * __useBulkAddSetupBranchToSimOutlineMutation__
 *
 * To run a mutation, you first call `useBulkAddSetupBranchToSimOutlineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkAddSetupBranchToSimOutlineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkAddSetupBranchToSimOutlineMutation, { data, loading, error }] = useBulkAddSetupBranchToSimOutlineMutation({
 *   variables: {
 *      simOutlineId: // value for 'simOutlineId'
 *      setupBranchIds: // value for 'setupBranchIds'
 *   },
 * });
 */
export function useBulkAddSetupBranchToSimOutlineMutation(baseOptions?: Apollo.MutationHookOptions<BulkAddSetupBranchToSimOutlineMutation, BulkAddSetupBranchToSimOutlineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkAddSetupBranchToSimOutlineMutation, BulkAddSetupBranchToSimOutlineMutationVariables>(BulkAddSetupBranchToSimOutlineDocument, options);
      }
export type BulkAddSetupBranchToSimOutlineMutationHookResult = ReturnType<typeof useBulkAddSetupBranchToSimOutlineMutation>;
export type BulkAddSetupBranchToSimOutlineMutationResult = Apollo.MutationResult<BulkAddSetupBranchToSimOutlineMutation>;
export type BulkAddSetupBranchToSimOutlineMutationOptions = Apollo.BaseMutationOptions<BulkAddSetupBranchToSimOutlineMutation, BulkAddSetupBranchToSimOutlineMutationVariables>;
export const DeleteSetupBranchFromSimOutlineDocument = gql`
    mutation DeleteSetupBranchFromSimOutline($simOutlineId: Int!, $setupBranchId: Int!) {
  deleteSetupBranchFromSimOutline(
    id: $simOutlineId
    setupBranchId: $setupBranchId
  )
}
    `;
export type DeleteSetupBranchFromSimOutlineMutationFn = Apollo.MutationFunction<DeleteSetupBranchFromSimOutlineMutation, DeleteSetupBranchFromSimOutlineMutationVariables>;

/**
 * __useDeleteSetupBranchFromSimOutlineMutation__
 *
 * To run a mutation, you first call `useDeleteSetupBranchFromSimOutlineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSetupBranchFromSimOutlineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSetupBranchFromSimOutlineMutation, { data, loading, error }] = useDeleteSetupBranchFromSimOutlineMutation({
 *   variables: {
 *      simOutlineId: // value for 'simOutlineId'
 *      setupBranchId: // value for 'setupBranchId'
 *   },
 * });
 */
export function useDeleteSetupBranchFromSimOutlineMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSetupBranchFromSimOutlineMutation, DeleteSetupBranchFromSimOutlineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSetupBranchFromSimOutlineMutation, DeleteSetupBranchFromSimOutlineMutationVariables>(DeleteSetupBranchFromSimOutlineDocument, options);
      }
export type DeleteSetupBranchFromSimOutlineMutationHookResult = ReturnType<typeof useDeleteSetupBranchFromSimOutlineMutation>;
export type DeleteSetupBranchFromSimOutlineMutationResult = Apollo.MutationResult<DeleteSetupBranchFromSimOutlineMutation>;
export type DeleteSetupBranchFromSimOutlineMutationOptions = Apollo.BaseMutationOptions<DeleteSetupBranchFromSimOutlineMutation, DeleteSetupBranchFromSimOutlineMutationVariables>;
export const BulkAddEnvToSimOutlineDocument = gql`
    mutation BulkAddEnvToSimOutline($simOutlineId: Int!, $envIds: [Int!]!) {
  bulkAddEnvToSimOutline(id: $simOutlineId, envIds: $envIds) {
    id
  }
}
    `;
export type BulkAddEnvToSimOutlineMutationFn = Apollo.MutationFunction<BulkAddEnvToSimOutlineMutation, BulkAddEnvToSimOutlineMutationVariables>;

/**
 * __useBulkAddEnvToSimOutlineMutation__
 *
 * To run a mutation, you first call `useBulkAddEnvToSimOutlineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkAddEnvToSimOutlineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkAddEnvToSimOutlineMutation, { data, loading, error }] = useBulkAddEnvToSimOutlineMutation({
 *   variables: {
 *      simOutlineId: // value for 'simOutlineId'
 *      envIds: // value for 'envIds'
 *   },
 * });
 */
export function useBulkAddEnvToSimOutlineMutation(baseOptions?: Apollo.MutationHookOptions<BulkAddEnvToSimOutlineMutation, BulkAddEnvToSimOutlineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkAddEnvToSimOutlineMutation, BulkAddEnvToSimOutlineMutationVariables>(BulkAddEnvToSimOutlineDocument, options);
      }
export type BulkAddEnvToSimOutlineMutationHookResult = ReturnType<typeof useBulkAddEnvToSimOutlineMutation>;
export type BulkAddEnvToSimOutlineMutationResult = Apollo.MutationResult<BulkAddEnvToSimOutlineMutation>;
export type BulkAddEnvToSimOutlineMutationOptions = Apollo.BaseMutationOptions<BulkAddEnvToSimOutlineMutation, BulkAddEnvToSimOutlineMutationVariables>;
export const DeleteEnvFromSimOutlineDocument = gql`
    mutation DeleteEnvFromSimOutline($simOutlineId: Int!, $envId: Int!) {
  deleteEnvFromSimOutline(id: $simOutlineId, envId: $envId)
}
    `;
export type DeleteEnvFromSimOutlineMutationFn = Apollo.MutationFunction<DeleteEnvFromSimOutlineMutation, DeleteEnvFromSimOutlineMutationVariables>;

/**
 * __useDeleteEnvFromSimOutlineMutation__
 *
 * To run a mutation, you first call `useDeleteEnvFromSimOutlineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEnvFromSimOutlineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEnvFromSimOutlineMutation, { data, loading, error }] = useDeleteEnvFromSimOutlineMutation({
 *   variables: {
 *      simOutlineId: // value for 'simOutlineId'
 *      envId: // value for 'envId'
 *   },
 * });
 */
export function useDeleteEnvFromSimOutlineMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEnvFromSimOutlineMutation, DeleteEnvFromSimOutlineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEnvFromSimOutlineMutation, DeleteEnvFromSimOutlineMutationVariables>(DeleteEnvFromSimOutlineDocument, options);
      }
export type DeleteEnvFromSimOutlineMutationHookResult = ReturnType<typeof useDeleteEnvFromSimOutlineMutation>;
export type DeleteEnvFromSimOutlineMutationResult = Apollo.MutationResult<DeleteEnvFromSimOutlineMutation>;
export type DeleteEnvFromSimOutlineMutationOptions = Apollo.BaseMutationOptions<DeleteEnvFromSimOutlineMutation, DeleteEnvFromSimOutlineMutationVariables>;
export const CloneSimOutlineDocument = gql`
    mutation CloneSimOutline($simOutlineId: Int!, $name: String!) {
  cloneSimOutline(id: $simOutlineId, name: $name) {
    id
  }
}
    `;
export type CloneSimOutlineMutationFn = Apollo.MutationFunction<CloneSimOutlineMutation, CloneSimOutlineMutationVariables>;

/**
 * __useCloneSimOutlineMutation__
 *
 * To run a mutation, you first call `useCloneSimOutlineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneSimOutlineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneSimOutlineMutation, { data, loading, error }] = useCloneSimOutlineMutation({
 *   variables: {
 *      simOutlineId: // value for 'simOutlineId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCloneSimOutlineMutation(baseOptions?: Apollo.MutationHookOptions<CloneSimOutlineMutation, CloneSimOutlineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloneSimOutlineMutation, CloneSimOutlineMutationVariables>(CloneSimOutlineDocument, options);
      }
export type CloneSimOutlineMutationHookResult = ReturnType<typeof useCloneSimOutlineMutation>;
export type CloneSimOutlineMutationResult = Apollo.MutationResult<CloneSimOutlineMutation>;
export type CloneSimOutlineMutationOptions = Apollo.BaseMutationOptions<CloneSimOutlineMutation, CloneSimOutlineMutationVariables>;
export const BulkCloneSimOutlineDocument = gql`
    mutation BulkCloneSimOutline($ids: [Int!]!) {
  bulkCloneSimOutline(ids: $ids) {
    id
  }
}
    `;
export type BulkCloneSimOutlineMutationFn = Apollo.MutationFunction<BulkCloneSimOutlineMutation, BulkCloneSimOutlineMutationVariables>;

/**
 * __useBulkCloneSimOutlineMutation__
 *
 * To run a mutation, you first call `useBulkCloneSimOutlineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkCloneSimOutlineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkCloneSimOutlineMutation, { data, loading, error }] = useBulkCloneSimOutlineMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useBulkCloneSimOutlineMutation(baseOptions?: Apollo.MutationHookOptions<BulkCloneSimOutlineMutation, BulkCloneSimOutlineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkCloneSimOutlineMutation, BulkCloneSimOutlineMutationVariables>(BulkCloneSimOutlineDocument, options);
      }
export type BulkCloneSimOutlineMutationHookResult = ReturnType<typeof useBulkCloneSimOutlineMutation>;
export type BulkCloneSimOutlineMutationResult = Apollo.MutationResult<BulkCloneSimOutlineMutation>;
export type BulkCloneSimOutlineMutationOptions = Apollo.BaseMutationOptions<BulkCloneSimOutlineMutation, BulkCloneSimOutlineMutationVariables>;
export const DeleteSimOutlineDocument = gql`
    mutation DeleteSimOutline($simOutlineId: Int!) {
  deleteSimOutline(id: $simOutlineId)
}
    `;
export type DeleteSimOutlineMutationFn = Apollo.MutationFunction<DeleteSimOutlineMutation, DeleteSimOutlineMutationVariables>;

/**
 * __useDeleteSimOutlineMutation__
 *
 * To run a mutation, you first call `useDeleteSimOutlineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSimOutlineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSimOutlineMutation, { data, loading, error }] = useDeleteSimOutlineMutation({
 *   variables: {
 *      simOutlineId: // value for 'simOutlineId'
 *   },
 * });
 */
export function useDeleteSimOutlineMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSimOutlineMutation, DeleteSimOutlineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSimOutlineMutation, DeleteSimOutlineMutationVariables>(DeleteSimOutlineDocument, options);
      }
export type DeleteSimOutlineMutationHookResult = ReturnType<typeof useDeleteSimOutlineMutation>;
export type DeleteSimOutlineMutationResult = Apollo.MutationResult<DeleteSimOutlineMutation>;
export type DeleteSimOutlineMutationOptions = Apollo.BaseMutationOptions<DeleteSimOutlineMutation, DeleteSimOutlineMutationVariables>;
export const BulkDeleteSimOutlineDocument = gql`
    mutation BulkDeleteSimOutline($simOutlineIds: [Int!]!) {
  bulkDeleteSimOutline(ids: $simOutlineIds)
}
    `;
export type BulkDeleteSimOutlineMutationFn = Apollo.MutationFunction<BulkDeleteSimOutlineMutation, BulkDeleteSimOutlineMutationVariables>;

/**
 * __useBulkDeleteSimOutlineMutation__
 *
 * To run a mutation, you first call `useBulkDeleteSimOutlineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkDeleteSimOutlineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkDeleteSimOutlineMutation, { data, loading, error }] = useBulkDeleteSimOutlineMutation({
 *   variables: {
 *      simOutlineIds: // value for 'simOutlineIds'
 *   },
 * });
 */
export function useBulkDeleteSimOutlineMutation(baseOptions?: Apollo.MutationHookOptions<BulkDeleteSimOutlineMutation, BulkDeleteSimOutlineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkDeleteSimOutlineMutation, BulkDeleteSimOutlineMutationVariables>(BulkDeleteSimOutlineDocument, options);
      }
export type BulkDeleteSimOutlineMutationHookResult = ReturnType<typeof useBulkDeleteSimOutlineMutation>;
export type BulkDeleteSimOutlineMutationResult = Apollo.MutationResult<BulkDeleteSimOutlineMutation>;
export type BulkDeleteSimOutlineMutationOptions = Apollo.BaseMutationOptions<BulkDeleteSimOutlineMutation, BulkDeleteSimOutlineMutationVariables>;
export const ClearSimOutlineDocument = gql`
    mutation ClearSimOutline($simOutlineId: Int!) {
  clearSimOutline(id: $simOutlineId) {
    id
  }
}
    `;
export type ClearSimOutlineMutationFn = Apollo.MutationFunction<ClearSimOutlineMutation, ClearSimOutlineMutationVariables>;

/**
 * __useClearSimOutlineMutation__
 *
 * To run a mutation, you first call `useClearSimOutlineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearSimOutlineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearSimOutlineMutation, { data, loading, error }] = useClearSimOutlineMutation({
 *   variables: {
 *      simOutlineId: // value for 'simOutlineId'
 *   },
 * });
 */
export function useClearSimOutlineMutation(baseOptions?: Apollo.MutationHookOptions<ClearSimOutlineMutation, ClearSimOutlineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearSimOutlineMutation, ClearSimOutlineMutationVariables>(ClearSimOutlineDocument, options);
      }
export type ClearSimOutlineMutationHookResult = ReturnType<typeof useClearSimOutlineMutation>;
export type ClearSimOutlineMutationResult = Apollo.MutationResult<ClearSimOutlineMutation>;
export type ClearSimOutlineMutationOptions = Apollo.BaseMutationOptions<ClearSimOutlineMutation, ClearSimOutlineMutationVariables>;
export const AddSimOutlineToWorksheetDocument = gql`
    mutation AddSimOutlineToWorksheet($worksheetId: Int!, $simOutlineName: String!) {
  addSimOutlineToWorksheet(
    worksheetId: $worksheetId
    simOutlineName: $simOutlineName
  ) {
    id
  }
}
    `;
export type AddSimOutlineToWorksheetMutationFn = Apollo.MutationFunction<AddSimOutlineToWorksheetMutation, AddSimOutlineToWorksheetMutationVariables>;

/**
 * __useAddSimOutlineToWorksheetMutation__
 *
 * To run a mutation, you first call `useAddSimOutlineToWorksheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSimOutlineToWorksheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSimOutlineToWorksheetMutation, { data, loading, error }] = useAddSimOutlineToWorksheetMutation({
 *   variables: {
 *      worksheetId: // value for 'worksheetId'
 *      simOutlineName: // value for 'simOutlineName'
 *   },
 * });
 */
export function useAddSimOutlineToWorksheetMutation(baseOptions?: Apollo.MutationHookOptions<AddSimOutlineToWorksheetMutation, AddSimOutlineToWorksheetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSimOutlineToWorksheetMutation, AddSimOutlineToWorksheetMutationVariables>(AddSimOutlineToWorksheetDocument, options);
      }
export type AddSimOutlineToWorksheetMutationHookResult = ReturnType<typeof useAddSimOutlineToWorksheetMutation>;
export type AddSimOutlineToWorksheetMutationResult = Apollo.MutationResult<AddSimOutlineToWorksheetMutation>;
export type AddSimOutlineToWorksheetMutationOptions = Apollo.BaseMutationOptions<AddSimOutlineToWorksheetMutation, AddSimOutlineToWorksheetMutationVariables>;
export const UpdateSimOutlineDetailsDocument = gql`
    mutation UpdateSimOutlineDetails($input: UpdateSimOutlineDetailsInput!) {
  updateSimOutlineDetails(input: $input) {
    id
  }
}
    `;
export type UpdateSimOutlineDetailsMutationFn = Apollo.MutationFunction<UpdateSimOutlineDetailsMutation, UpdateSimOutlineDetailsMutationVariables>;

/**
 * __useUpdateSimOutlineDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateSimOutlineDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSimOutlineDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSimOutlineDetailsMutation, { data, loading, error }] = useUpdateSimOutlineDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSimOutlineDetailsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSimOutlineDetailsMutation, UpdateSimOutlineDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSimOutlineDetailsMutation, UpdateSimOutlineDetailsMutationVariables>(UpdateSimOutlineDetailsDocument, options);
      }
export type UpdateSimOutlineDetailsMutationHookResult = ReturnType<typeof useUpdateSimOutlineDetailsMutation>;
export type UpdateSimOutlineDetailsMutationResult = Apollo.MutationResult<UpdateSimOutlineDetailsMutation>;
export type UpdateSimOutlineDetailsMutationOptions = Apollo.BaseMutationOptions<UpdateSimOutlineDetailsMutation, UpdateSimOutlineDetailsMutationVariables>;
export const UpdateSimOutlineOrderDocument = gql`
    mutation UpdateSimOutlineOrder($id: Int!, $oldIndex: Int!, $newIndex: Int!) {
  updateSimOutlineOrder(id: $id, oldIndex: $oldIndex, newIndex: $newIndex) {
    id
  }
}
    `;
export type UpdateSimOutlineOrderMutationFn = Apollo.MutationFunction<UpdateSimOutlineOrderMutation, UpdateSimOutlineOrderMutationVariables>;

/**
 * __useUpdateSimOutlineOrderMutation__
 *
 * To run a mutation, you first call `useUpdateSimOutlineOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSimOutlineOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSimOutlineOrderMutation, { data, loading, error }] = useUpdateSimOutlineOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      oldIndex: // value for 'oldIndex'
 *      newIndex: // value for 'newIndex'
 *   },
 * });
 */
export function useUpdateSimOutlineOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSimOutlineOrderMutation, UpdateSimOutlineOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSimOutlineOrderMutation, UpdateSimOutlineOrderMutationVariables>(UpdateSimOutlineOrderDocument, options);
      }
export type UpdateSimOutlineOrderMutationHookResult = ReturnType<typeof useUpdateSimOutlineOrderMutation>;
export type UpdateSimOutlineOrderMutationResult = Apollo.MutationResult<UpdateSimOutlineOrderMutation>;
export type UpdateSimOutlineOrderMutationOptions = Apollo.BaseMutationOptions<UpdateSimOutlineOrderMutation, UpdateSimOutlineOrderMutationVariables>;
export const UpdateSimWorksheetDocument = gql`
    mutation UpdateSimWorksheet($id: Int!, $input: SimWorksheetInput!) {
  updateSimWorksheet(id: $id, input: $input) {
    id
  }
}
    `;
export type UpdateSimWorksheetMutationFn = Apollo.MutationFunction<UpdateSimWorksheetMutation, UpdateSimWorksheetMutationVariables>;

/**
 * __useUpdateSimWorksheetMutation__
 *
 * To run a mutation, you first call `useUpdateSimWorksheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSimWorksheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSimWorksheetMutation, { data, loading, error }] = useUpdateSimWorksheetMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSimWorksheetMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSimWorksheetMutation, UpdateSimWorksheetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSimWorksheetMutation, UpdateSimWorksheetMutationVariables>(UpdateSimWorksheetDocument, options);
      }
export type UpdateSimWorksheetMutationHookResult = ReturnType<typeof useUpdateSimWorksheetMutation>;
export type UpdateSimWorksheetMutationResult = Apollo.MutationResult<UpdateSimWorksheetMutation>;
export type UpdateSimWorksheetMutationOptions = Apollo.BaseMutationOptions<UpdateSimWorksheetMutation, UpdateSimWorksheetMutationVariables>;
export const DeleteSimWorksheetDocument = gql`
    mutation DeleteSimWorksheet($id: Int!) {
  deleteSimWorksheet(id: $id)
}
    `;
export type DeleteSimWorksheetMutationFn = Apollo.MutationFunction<DeleteSimWorksheetMutation, DeleteSimWorksheetMutationVariables>;

/**
 * __useDeleteSimWorksheetMutation__
 *
 * To run a mutation, you first call `useDeleteSimWorksheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSimWorksheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSimWorksheetMutation, { data, loading, error }] = useDeleteSimWorksheetMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSimWorksheetMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSimWorksheetMutation, DeleteSimWorksheetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSimWorksheetMutation, DeleteSimWorksheetMutationVariables>(DeleteSimWorksheetDocument, options);
      }
export type DeleteSimWorksheetMutationHookResult = ReturnType<typeof useDeleteSimWorksheetMutation>;
export type DeleteSimWorksheetMutationResult = Apollo.MutationResult<DeleteSimWorksheetMutation>;
export type DeleteSimWorksheetMutationOptions = Apollo.BaseMutationOptions<DeleteSimWorksheetMutation, DeleteSimWorksheetMutationVariables>;
export const BulkDeleteSimWorksheetsDocument = gql`
    mutation BulkDeleteSimWorksheets($ids: [Int!]!) {
  bulkDeleteSimWorksheets(ids: $ids)
}
    `;
export type BulkDeleteSimWorksheetsMutationFn = Apollo.MutationFunction<BulkDeleteSimWorksheetsMutation, BulkDeleteSimWorksheetsMutationVariables>;

/**
 * __useBulkDeleteSimWorksheetsMutation__
 *
 * To run a mutation, you first call `useBulkDeleteSimWorksheetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkDeleteSimWorksheetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkDeleteSimWorksheetsMutation, { data, loading, error }] = useBulkDeleteSimWorksheetsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useBulkDeleteSimWorksheetsMutation(baseOptions?: Apollo.MutationHookOptions<BulkDeleteSimWorksheetsMutation, BulkDeleteSimWorksheetsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkDeleteSimWorksheetsMutation, BulkDeleteSimWorksheetsMutationVariables>(BulkDeleteSimWorksheetsDocument, options);
      }
export type BulkDeleteSimWorksheetsMutationHookResult = ReturnType<typeof useBulkDeleteSimWorksheetsMutation>;
export type BulkDeleteSimWorksheetsMutationResult = Apollo.MutationResult<BulkDeleteSimWorksheetsMutation>;
export type BulkDeleteSimWorksheetsMutationOptions = Apollo.BaseMutationOptions<BulkDeleteSimWorksheetsMutation, BulkDeleteSimWorksheetsMutationVariables>;
export const CloneSimWorksheetDocument = gql`
    mutation CloneSimWorksheet($id: Int!, $name: String!) {
  cloneSimWorksheet(id: $id, name: $name) {
    id
  }
}
    `;
export type CloneSimWorksheetMutationFn = Apollo.MutationFunction<CloneSimWorksheetMutation, CloneSimWorksheetMutationVariables>;

/**
 * __useCloneSimWorksheetMutation__
 *
 * To run a mutation, you first call `useCloneSimWorksheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneSimWorksheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneSimWorksheetMutation, { data, loading, error }] = useCloneSimWorksheetMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCloneSimWorksheetMutation(baseOptions?: Apollo.MutationHookOptions<CloneSimWorksheetMutation, CloneSimWorksheetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloneSimWorksheetMutation, CloneSimWorksheetMutationVariables>(CloneSimWorksheetDocument, options);
      }
export type CloneSimWorksheetMutationHookResult = ReturnType<typeof useCloneSimWorksheetMutation>;
export type CloneSimWorksheetMutationResult = Apollo.MutationResult<CloneSimWorksheetMutation>;
export type CloneSimWorksheetMutationOptions = Apollo.BaseMutationOptions<CloneSimWorksheetMutation, CloneSimWorksheetMutationVariables>;
export const CreateSimWorksheetDocument = gql`
    mutation CreateSimWorksheet($input: SimWorksheetInput!) {
  createSimWorksheet(input: $input) {
    id
  }
}
    `;
export type CreateSimWorksheetMutationFn = Apollo.MutationFunction<CreateSimWorksheetMutation, CreateSimWorksheetMutationVariables>;

/**
 * __useCreateSimWorksheetMutation__
 *
 * To run a mutation, you first call `useCreateSimWorksheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSimWorksheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSimWorksheetMutation, { data, loading, error }] = useCreateSimWorksheetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSimWorksheetMutation(baseOptions?: Apollo.MutationHookOptions<CreateSimWorksheetMutation, CreateSimWorksheetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSimWorksheetMutation, CreateSimWorksheetMutationVariables>(CreateSimWorksheetDocument, options);
      }
export type CreateSimWorksheetMutationHookResult = ReturnType<typeof useCreateSimWorksheetMutation>;
export type CreateSimWorksheetMutationResult = Apollo.MutationResult<CreateSimWorksheetMutation>;
export type CreateSimWorksheetMutationOptions = Apollo.BaseMutationOptions<CreateSimWorksheetMutation, CreateSimWorksheetMutationVariables>;
export const CreateSimDocument = gql`
    mutation CreateSim($input: CreateSimInput!) {
  createSim(input: $input) {
    id
  }
}
    `;
export type CreateSimMutationFn = Apollo.MutationFunction<CreateSimMutation, CreateSimMutationVariables>;

/**
 * __useCreateSimMutation__
 *
 * To run a mutation, you first call `useCreateSimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSimMutation, { data, loading, error }] = useCreateSimMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSimMutation(baseOptions?: Apollo.MutationHookOptions<CreateSimMutation, CreateSimMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSimMutation, CreateSimMutationVariables>(CreateSimDocument, options);
      }
export type CreateSimMutationHookResult = ReturnType<typeof useCreateSimMutation>;
export type CreateSimMutationResult = Apollo.MutationResult<CreateSimMutation>;
export type CreateSimMutationOptions = Apollo.BaseMutationOptions<CreateSimMutation, CreateSimMutationVariables>;
export const ClearSimStatusDocument = gql`
    mutation ClearSimStatus {
  clearSimStatus {
    success
  }
}
    `;
export type ClearSimStatusMutationFn = Apollo.MutationFunction<ClearSimStatusMutation, ClearSimStatusMutationVariables>;

/**
 * __useClearSimStatusMutation__
 *
 * To run a mutation, you first call `useClearSimStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearSimStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearSimStatusMutation, { data, loading, error }] = useClearSimStatusMutation({
 *   variables: {
 *   },
 * });
 */
export function useClearSimStatusMutation(baseOptions?: Apollo.MutationHookOptions<ClearSimStatusMutation, ClearSimStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearSimStatusMutation, ClearSimStatusMutationVariables>(ClearSimStatusDocument, options);
      }
export type ClearSimStatusMutationHookResult = ReturnType<typeof useClearSimStatusMutation>;
export type ClearSimStatusMutationResult = Apollo.MutationResult<ClearSimStatusMutation>;
export type ClearSimStatusMutationOptions = Apollo.BaseMutationOptions<ClearSimStatusMutation, ClearSimStatusMutationVariables>;
export const ClearSweepStatusDocument = gql`
    mutation ClearSweepStatus {
  clearSweepStatus {
    success
  }
}
    `;
export type ClearSweepStatusMutationFn = Apollo.MutationFunction<ClearSweepStatusMutation, ClearSweepStatusMutationVariables>;

/**
 * __useClearSweepStatusMutation__
 *
 * To run a mutation, you first call `useClearSweepStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearSweepStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearSweepStatusMutation, { data, loading, error }] = useClearSweepStatusMutation({
 *   variables: {
 *   },
 * });
 */
export function useClearSweepStatusMutation(baseOptions?: Apollo.MutationHookOptions<ClearSweepStatusMutation, ClearSweepStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearSweepStatusMutation, ClearSweepStatusMutationVariables>(ClearSweepStatusDocument, options);
      }
export type ClearSweepStatusMutationHookResult = ReturnType<typeof useClearSweepStatusMutation>;
export type ClearSweepStatusMutationResult = Apollo.MutationResult<ClearSweepStatusMutation>;
export type ClearSweepStatusMutationOptions = Apollo.BaseMutationOptions<ClearSweepStatusMutation, ClearSweepStatusMutationVariables>;
export const ClearSimProgressDocument = gql`
    mutation ClearSimProgress {
  clearSimProgress {
    success
  }
}
    `;
export type ClearSimProgressMutationFn = Apollo.MutationFunction<ClearSimProgressMutation, ClearSimProgressMutationVariables>;

/**
 * __useClearSimProgressMutation__
 *
 * To run a mutation, you first call `useClearSimProgressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearSimProgressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearSimProgressMutation, { data, loading, error }] = useClearSimProgressMutation({
 *   variables: {
 *   },
 * });
 */
export function useClearSimProgressMutation(baseOptions?: Apollo.MutationHookOptions<ClearSimProgressMutation, ClearSimProgressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearSimProgressMutation, ClearSimProgressMutationVariables>(ClearSimProgressDocument, options);
      }
export type ClearSimProgressMutationHookResult = ReturnType<typeof useClearSimProgressMutation>;
export type ClearSimProgressMutationResult = Apollo.MutationResult<ClearSimProgressMutation>;
export type ClearSimProgressMutationOptions = Apollo.BaseMutationOptions<ClearSimProgressMutation, ClearSimProgressMutationVariables>;
export const CreateEightPostDocument = gql`
    mutation CreateEightPost($input: CreateEightPostInput!) {
  createEightPost(input: $input) {
    id
  }
}
    `;
export type CreateEightPostMutationFn = Apollo.MutationFunction<CreateEightPostMutation, CreateEightPostMutationVariables>;

/**
 * __useCreateEightPostMutation__
 *
 * To run a mutation, you first call `useCreateEightPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEightPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEightPostMutation, { data, loading, error }] = useCreateEightPostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEightPostMutation(baseOptions?: Apollo.MutationHookOptions<CreateEightPostMutation, CreateEightPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEightPostMutation, CreateEightPostMutationVariables>(CreateEightPostDocument, options);
      }
export type CreateEightPostMutationHookResult = ReturnType<typeof useCreateEightPostMutation>;
export type CreateEightPostMutationResult = Apollo.MutationResult<CreateEightPostMutation>;
export type CreateEightPostMutationOptions = Apollo.BaseMutationOptions<CreateEightPostMutation, CreateEightPostMutationVariables>;
export const CreateDriverDocument = gql`
    mutation CreateDriver($input: CreateDriverInput!) {
  createDriver(input: $input) {
    id
  }
}
    `;
export type CreateDriverMutationFn = Apollo.MutationFunction<CreateDriverMutation, CreateDriverMutationVariables>;

/**
 * __useCreateDriverMutation__
 *
 * To run a mutation, you first call `useCreateDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDriverMutation, { data, loading, error }] = useCreateDriverMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDriverMutation(baseOptions?: Apollo.MutationHookOptions<CreateDriverMutation, CreateDriverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDriverMutation, CreateDriverMutationVariables>(CreateDriverDocument, options);
      }
export type CreateDriverMutationHookResult = ReturnType<typeof useCreateDriverMutation>;
export type CreateDriverMutationResult = Apollo.MutationResult<CreateDriverMutation>;
export type CreateDriverMutationOptions = Apollo.BaseMutationOptions<CreateDriverMutation, CreateDriverMutationVariables>;
export const CreateLapTimeDataDocument = gql`
    mutation CreateLapTimeData($input: CreateLapTimeDataInput!) {
  createLapTimeData(input: $input) {
    id
  }
}
    `;
export type CreateLapTimeDataMutationFn = Apollo.MutationFunction<CreateLapTimeDataMutation, CreateLapTimeDataMutationVariables>;

/**
 * __useCreateLapTimeDataMutation__
 *
 * To run a mutation, you first call `useCreateLapTimeDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLapTimeDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLapTimeDataMutation, { data, loading, error }] = useCreateLapTimeDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLapTimeDataMutation(baseOptions?: Apollo.MutationHookOptions<CreateLapTimeDataMutation, CreateLapTimeDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLapTimeDataMutation, CreateLapTimeDataMutationVariables>(CreateLapTimeDataDocument, options);
      }
export type CreateLapTimeDataMutationHookResult = ReturnType<typeof useCreateLapTimeDataMutation>;
export type CreateLapTimeDataMutationResult = Apollo.MutationResult<CreateLapTimeDataMutation>;
export type CreateLapTimeDataMutationOptions = Apollo.BaseMutationOptions<CreateLapTimeDataMutation, CreateLapTimeDataMutationVariables>;
export const CreateDriveFileDocument = gql`
    mutation CreateDriveFile($input: CreateDriveFileInput!) {
  createDriveFile(input: $input) {
    id
  }
}
    `;
export type CreateDriveFileMutationFn = Apollo.MutationFunction<CreateDriveFileMutation, CreateDriveFileMutationVariables>;

/**
 * __useCreateDriveFileMutation__
 *
 * To run a mutation, you first call `useCreateDriveFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDriveFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDriveFileMutation, { data, loading, error }] = useCreateDriveFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDriveFileMutation(baseOptions?: Apollo.MutationHookOptions<CreateDriveFileMutation, CreateDriveFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDriveFileMutation, CreateDriveFileMutationVariables>(CreateDriveFileDocument, options);
      }
export type CreateDriveFileMutationHookResult = ReturnType<typeof useCreateDriveFileMutation>;
export type CreateDriveFileMutationResult = Apollo.MutationResult<CreateDriveFileMutation>;
export type CreateDriveFileMutationOptions = Apollo.BaseMutationOptions<CreateDriveFileMutation, CreateDriveFileMutationVariables>;
export const CreateLapWindDataDocument = gql`
    mutation CreateLapWindData($input: CreateLapWindDataInput!) {
  createLapWindData(input: $input) {
    id
  }
}
    `;
export type CreateLapWindDataMutationFn = Apollo.MutationFunction<CreateLapWindDataMutation, CreateLapWindDataMutationVariables>;

/**
 * __useCreateLapWindDataMutation__
 *
 * To run a mutation, you first call `useCreateLapWindDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLapWindDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLapWindDataMutation, { data, loading, error }] = useCreateLapWindDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLapWindDataMutation(baseOptions?: Apollo.MutationHookOptions<CreateLapWindDataMutation, CreateLapWindDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLapWindDataMutation, CreateLapWindDataMutationVariables>(CreateLapWindDataDocument, options);
      }
export type CreateLapWindDataMutationHookResult = ReturnType<typeof useCreateLapWindDataMutation>;
export type CreateLapWindDataMutationResult = Apollo.MutationResult<CreateLapWindDataMutation>;
export type CreateLapWindDataMutationOptions = Apollo.BaseMutationOptions<CreateLapWindDataMutation, CreateLapWindDataMutationVariables>;
export const CreateSimStatesDocument = gql`
    mutation CreateSimStates($input: CreateSimStatesInput!) {
  createSimStates(input: $input) {
    id
  }
}
    `;
export type CreateSimStatesMutationFn = Apollo.MutationFunction<CreateSimStatesMutation, CreateSimStatesMutationVariables>;

/**
 * __useCreateSimStatesMutation__
 *
 * To run a mutation, you first call `useCreateSimStatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSimStatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSimStatesMutation, { data, loading, error }] = useCreateSimStatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSimStatesMutation(baseOptions?: Apollo.MutationHookOptions<CreateSimStatesMutation, CreateSimStatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSimStatesMutation, CreateSimStatesMutationVariables>(CreateSimStatesDocument, options);
      }
export type CreateSimStatesMutationHookResult = ReturnType<typeof useCreateSimStatesMutation>;
export type CreateSimStatesMutationResult = Apollo.MutationResult<CreateSimStatesMutation>;
export type CreateSimStatesMutationOptions = Apollo.BaseMutationOptions<CreateSimStatesMutation, CreateSimStatesMutationVariables>;
export const UpdateEightPostDocument = gql`
    mutation UpdateEightPost($input: UpdateEightPostInput!) {
  part: updateEightPost(input: $input) {
    id
  }
}
    `;
export type UpdateEightPostMutationFn = Apollo.MutationFunction<UpdateEightPostMutation, UpdateEightPostMutationVariables>;

/**
 * __useUpdateEightPostMutation__
 *
 * To run a mutation, you first call `useUpdateEightPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEightPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEightPostMutation, { data, loading, error }] = useUpdateEightPostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEightPostMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEightPostMutation, UpdateEightPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEightPostMutation, UpdateEightPostMutationVariables>(UpdateEightPostDocument, options);
      }
export type UpdateEightPostMutationHookResult = ReturnType<typeof useUpdateEightPostMutation>;
export type UpdateEightPostMutationResult = Apollo.MutationResult<UpdateEightPostMutation>;
export type UpdateEightPostMutationOptions = Apollo.BaseMutationOptions<UpdateEightPostMutation, UpdateEightPostMutationVariables>;
export const UpdateDriverDocument = gql`
    mutation UpdateDriver($input: UpdateDriverInput!) {
  updateDriver(input: $input) {
    id
  }
}
    `;
export type UpdateDriverMutationFn = Apollo.MutationFunction<UpdateDriverMutation, UpdateDriverMutationVariables>;

/**
 * __useUpdateDriverMutation__
 *
 * To run a mutation, you first call `useUpdateDriverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDriverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDriverMutation, { data, loading, error }] = useUpdateDriverMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDriverMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDriverMutation, UpdateDriverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDriverMutation, UpdateDriverMutationVariables>(UpdateDriverDocument, options);
      }
export type UpdateDriverMutationHookResult = ReturnType<typeof useUpdateDriverMutation>;
export type UpdateDriverMutationResult = Apollo.MutationResult<UpdateDriverMutation>;
export type UpdateDriverMutationOptions = Apollo.BaseMutationOptions<UpdateDriverMutation, UpdateDriverMutationVariables>;
export const UpdateLapTimeDataDocument = gql`
    mutation UpdateLapTimeData($input: UpdateLapTimeDataInput!) {
  updateLapTimeData(input: $input) {
    id
  }
}
    `;
export type UpdateLapTimeDataMutationFn = Apollo.MutationFunction<UpdateLapTimeDataMutation, UpdateLapTimeDataMutationVariables>;

/**
 * __useUpdateLapTimeDataMutation__
 *
 * To run a mutation, you first call `useUpdateLapTimeDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLapTimeDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLapTimeDataMutation, { data, loading, error }] = useUpdateLapTimeDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLapTimeDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLapTimeDataMutation, UpdateLapTimeDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLapTimeDataMutation, UpdateLapTimeDataMutationVariables>(UpdateLapTimeDataDocument, options);
      }
export type UpdateLapTimeDataMutationHookResult = ReturnType<typeof useUpdateLapTimeDataMutation>;
export type UpdateLapTimeDataMutationResult = Apollo.MutationResult<UpdateLapTimeDataMutation>;
export type UpdateLapTimeDataMutationOptions = Apollo.BaseMutationOptions<UpdateLapTimeDataMutation, UpdateLapTimeDataMutationVariables>;
export const UpdateDriveFileDocument = gql`
    mutation UpdateDriveFile($input: UpdateDriveFileInput!) {
  updateDriveFile(input: $input) {
    id
  }
}
    `;
export type UpdateDriveFileMutationFn = Apollo.MutationFunction<UpdateDriveFileMutation, UpdateDriveFileMutationVariables>;

/**
 * __useUpdateDriveFileMutation__
 *
 * To run a mutation, you first call `useUpdateDriveFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDriveFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDriveFileMutation, { data, loading, error }] = useUpdateDriveFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDriveFileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDriveFileMutation, UpdateDriveFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDriveFileMutation, UpdateDriveFileMutationVariables>(UpdateDriveFileDocument, options);
      }
export type UpdateDriveFileMutationHookResult = ReturnType<typeof useUpdateDriveFileMutation>;
export type UpdateDriveFileMutationResult = Apollo.MutationResult<UpdateDriveFileMutation>;
export type UpdateDriveFileMutationOptions = Apollo.BaseMutationOptions<UpdateDriveFileMutation, UpdateDriveFileMutationVariables>;
export const UpdateLapWindDataDocument = gql`
    mutation UpdateLapWindData($input: UpdateLapWindDataInput!) {
  updateLapWindData(input: $input) {
    id
  }
}
    `;
export type UpdateLapWindDataMutationFn = Apollo.MutationFunction<UpdateLapWindDataMutation, UpdateLapWindDataMutationVariables>;

/**
 * __useUpdateLapWindDataMutation__
 *
 * To run a mutation, you first call `useUpdateLapWindDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLapWindDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLapWindDataMutation, { data, loading, error }] = useUpdateLapWindDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLapWindDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLapWindDataMutation, UpdateLapWindDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLapWindDataMutation, UpdateLapWindDataMutationVariables>(UpdateLapWindDataDocument, options);
      }
export type UpdateLapWindDataMutationHookResult = ReturnType<typeof useUpdateLapWindDataMutation>;
export type UpdateLapWindDataMutationResult = Apollo.MutationResult<UpdateLapWindDataMutation>;
export type UpdateLapWindDataMutationOptions = Apollo.BaseMutationOptions<UpdateLapWindDataMutation, UpdateLapWindDataMutationVariables>;
export const UpdateSimStatesDocument = gql`
    mutation UpdateSimStates($input: UpdateSimStatesInput!) {
  updateSimStates(input: $input) {
    id
  }
}
    `;
export type UpdateSimStatesMutationFn = Apollo.MutationFunction<UpdateSimStatesMutation, UpdateSimStatesMutationVariables>;

/**
 * __useUpdateSimStatesMutation__
 *
 * To run a mutation, you first call `useUpdateSimStatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSimStatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSimStatesMutation, { data, loading, error }] = useUpdateSimStatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSimStatesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSimStatesMutation, UpdateSimStatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSimStatesMutation, UpdateSimStatesMutationVariables>(UpdateSimStatesDocument, options);
      }
export type UpdateSimStatesMutationHookResult = ReturnType<typeof useUpdateSimStatesMutation>;
export type UpdateSimStatesMutationResult = Apollo.MutationResult<UpdateSimStatesMutation>;
export type UpdateSimStatesMutationOptions = Apollo.BaseMutationOptions<UpdateSimStatesMutation, UpdateSimStatesMutationVariables>;
export const CreateSweepDocument = gql`
    mutation CreateSweep($input: CreateSweepInput!) {
  createSweep(input: $input) {
    id
  }
}
    `;
export type CreateSweepMutationFn = Apollo.MutationFunction<CreateSweepMutation, CreateSweepMutationVariables>;

/**
 * __useCreateSweepMutation__
 *
 * To run a mutation, you first call `useCreateSweepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSweepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSweepMutation, { data, loading, error }] = useCreateSweepMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSweepMutation(baseOptions?: Apollo.MutationHookOptions<CreateSweepMutation, CreateSweepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSweepMutation, CreateSweepMutationVariables>(CreateSweepDocument, options);
      }
export type CreateSweepMutationHookResult = ReturnType<typeof useCreateSweepMutation>;
export type CreateSweepMutationResult = Apollo.MutationResult<CreateSweepMutation>;
export type CreateSweepMutationOptions = Apollo.BaseMutationOptions<CreateSweepMutation, CreateSweepMutationVariables>;
export const UpdateSweepDocument = gql`
    mutation UpdateSweep($input: UpdateSweepInput!) {
  updateSweep(input: $input) {
    id
  }
}
    `;
export type UpdateSweepMutationFn = Apollo.MutationFunction<UpdateSweepMutation, UpdateSweepMutationVariables>;

/**
 * __useUpdateSweepMutation__
 *
 * To run a mutation, you first call `useUpdateSweepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSweepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSweepMutation, { data, loading, error }] = useUpdateSweepMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSweepMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSweepMutation, UpdateSweepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSweepMutation, UpdateSweepMutationVariables>(UpdateSweepDocument, options);
      }
export type UpdateSweepMutationHookResult = ReturnType<typeof useUpdateSweepMutation>;
export type UpdateSweepMutationResult = Apollo.MutationResult<UpdateSweepMutation>;
export type UpdateSweepMutationOptions = Apollo.BaseMutationOptions<UpdateSweepMutation, UpdateSweepMutationVariables>;
export const DeleteSweepDocument = gql`
    mutation DeleteSweep($id: Int!) {
  deleteSweep(id: $id)
}
    `;
export type DeleteSweepMutationFn = Apollo.MutationFunction<DeleteSweepMutation, DeleteSweepMutationVariables>;

/**
 * __useDeleteSweepMutation__
 *
 * To run a mutation, you first call `useDeleteSweepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSweepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSweepMutation, { data, loading, error }] = useDeleteSweepMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSweepMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSweepMutation, DeleteSweepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSweepMutation, DeleteSweepMutationVariables>(DeleteSweepDocument, options);
      }
export type DeleteSweepMutationHookResult = ReturnType<typeof useDeleteSweepMutation>;
export type DeleteSweepMutationResult = Apollo.MutationResult<DeleteSweepMutation>;
export type DeleteSweepMutationOptions = Apollo.BaseMutationOptions<DeleteSweepMutation, DeleteSweepMutationVariables>;
export const PartManagementDocument = gql`
    query PartManagement {
  partCategories {
    id
    name
    display_name
    description
  }
  partConfigs {
    rows {
      ...allPartConfigFields
    }
  }
}
    ${allPartConfigFieldsFragmentDoc}`;

/**
 * __usePartManagementQuery__
 *
 * To run a query within a React component, call `usePartManagementQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartManagementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartManagementQuery({
 *   variables: {
 *   },
 * });
 */
export function usePartManagementQuery(baseOptions?: Apollo.QueryHookOptions<PartManagementQuery, PartManagementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PartManagementQuery, PartManagementQueryVariables>(PartManagementDocument, options);
      }
export function usePartManagementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PartManagementQuery, PartManagementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PartManagementQuery, PartManagementQueryVariables>(PartManagementDocument, options);
        }
export type PartManagementQueryHookResult = ReturnType<typeof usePartManagementQuery>;
export type PartManagementLazyQueryHookResult = ReturnType<typeof usePartManagementLazyQuery>;
export type PartManagementQueryResult = Apollo.QueryResult<PartManagementQuery, PartManagementQueryVariables>;
export const PartPropertiesTableDocument = gql`
    query PartPropertiesTable($input: PropertiesQueryInput!) {
  properties(input: $input) {
    totalCount
    rows {
      id
      name
      display_name
      description
      type
      min
      max
      values
      unit
    }
  }
}
    `;

/**
 * __usePartPropertiesTableQuery__
 *
 * To run a query within a React component, call `usePartPropertiesTableQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartPropertiesTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartPropertiesTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePartPropertiesTableQuery(baseOptions: Apollo.QueryHookOptions<PartPropertiesTableQuery, PartPropertiesTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PartPropertiesTableQuery, PartPropertiesTableQueryVariables>(PartPropertiesTableDocument, options);
      }
export function usePartPropertiesTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PartPropertiesTableQuery, PartPropertiesTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PartPropertiesTableQuery, PartPropertiesTableQueryVariables>(PartPropertiesTableDocument, options);
        }
export type PartPropertiesTableQueryHookResult = ReturnType<typeof usePartPropertiesTableQuery>;
export type PartPropertiesTableLazyQueryHookResult = ReturnType<typeof usePartPropertiesTableLazyQuery>;
export type PartPropertiesTableQueryResult = Apollo.QueryResult<PartPropertiesTableQuery, PartPropertiesTableQueryVariables>;
export const PartNavCategoriesDocument = gql`
    query PartNavCategories {
  partCategories {
    id
    name
    display_name
    description
    configs {
      id
    }
  }
}
    `;

/**
 * __usePartNavCategoriesQuery__
 *
 * To run a query within a React component, call `usePartNavCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartNavCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartNavCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePartNavCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<PartNavCategoriesQuery, PartNavCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PartNavCategoriesQuery, PartNavCategoriesQueryVariables>(PartNavCategoriesDocument, options);
      }
export function usePartNavCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PartNavCategoriesQuery, PartNavCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PartNavCategoriesQuery, PartNavCategoriesQueryVariables>(PartNavCategoriesDocument, options);
        }
export type PartNavCategoriesQueryHookResult = ReturnType<typeof usePartNavCategoriesQuery>;
export type PartNavCategoriesLazyQueryHookResult = ReturnType<typeof usePartNavCategoriesLazyQuery>;
export type PartNavCategoriesQueryResult = Apollo.QueryResult<PartNavCategoriesQuery, PartNavCategoriesQueryVariables>;
export const PartNavConfigsDocument = gql`
    query PartNavConfigs($categoryName: String!) {
  partConfigs: partConfigsByCategoryName(categoryName: $categoryName) {
    id
    name
    display_name
    category {
      id
      name
      display_name
    }
  }
}
    `;

/**
 * __usePartNavConfigsQuery__
 *
 * To run a query within a React component, call `usePartNavConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartNavConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartNavConfigsQuery({
 *   variables: {
 *      categoryName: // value for 'categoryName'
 *   },
 * });
 */
export function usePartNavConfigsQuery(baseOptions: Apollo.QueryHookOptions<PartNavConfigsQuery, PartNavConfigsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PartNavConfigsQuery, PartNavConfigsQueryVariables>(PartNavConfigsDocument, options);
      }
export function usePartNavConfigsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PartNavConfigsQuery, PartNavConfigsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PartNavConfigsQuery, PartNavConfigsQueryVariables>(PartNavConfigsDocument, options);
        }
export type PartNavConfigsQueryHookResult = ReturnType<typeof usePartNavConfigsQuery>;
export type PartNavConfigsLazyQueryHookResult = ReturnType<typeof usePartNavConfigsLazyQuery>;
export type PartNavConfigsQueryResult = Apollo.QueryResult<PartNavConfigsQuery, PartNavConfigsQueryVariables>;
export const PartCategoryDocument = gql`
    query PartCategory($categoryName: String!) {
  partConfig: firstPartConfigByCategoryName(categoryName: $categoryName) {
    id
    name
    display_name
  }
}
    `;

/**
 * __usePartCategoryQuery__
 *
 * To run a query within a React component, call `usePartCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartCategoryQuery({
 *   variables: {
 *      categoryName: // value for 'categoryName'
 *   },
 * });
 */
export function usePartCategoryQuery(baseOptions: Apollo.QueryHookOptions<PartCategoryQuery, PartCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PartCategoryQuery, PartCategoryQueryVariables>(PartCategoryDocument, options);
      }
export function usePartCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PartCategoryQuery, PartCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PartCategoryQuery, PartCategoryQueryVariables>(PartCategoryDocument, options);
        }
export type PartCategoryQueryHookResult = ReturnType<typeof usePartCategoryQuery>;
export type PartCategoryLazyQueryHookResult = ReturnType<typeof usePartCategoryLazyQuery>;
export type PartCategoryQueryResult = Apollo.QueryResult<PartCategoryQuery, PartCategoryQueryVariables>;
export const PartCategoriesDocument = gql`
    query PartCategories {
  partCategories {
    id
    name
    display_name
    description
  }
}
    `;

/**
 * __usePartCategoriesQuery__
 *
 * To run a query within a React component, call `usePartCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePartCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<PartCategoriesQuery, PartCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PartCategoriesQuery, PartCategoriesQueryVariables>(PartCategoriesDocument, options);
      }
export function usePartCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PartCategoriesQuery, PartCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PartCategoriesQuery, PartCategoriesQueryVariables>(PartCategoriesDocument, options);
        }
export type PartCategoriesQueryHookResult = ReturnType<typeof usePartCategoriesQuery>;
export type PartCategoriesLazyQueryHookResult = ReturnType<typeof usePartCategoriesLazyQuery>;
export type PartCategoriesQueryResult = Apollo.QueryResult<PartCategoriesQuery, PartCategoriesQueryVariables>;
export const PartConfigsDocument = gql`
    query PartConfigs {
  partConfigs {
    rows {
      id
      name
      display_name
      expires
      properties {
        id
        property {
          id
          type
          name
          display_name
          description
          min
          max
          values
        }
        specs
        description
        min
        max
        values
        required
      }
    }
  }
}
    `;

/**
 * __usePartConfigsQuery__
 *
 * To run a query within a React component, call `usePartConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartConfigsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePartConfigsQuery(baseOptions?: Apollo.QueryHookOptions<PartConfigsQuery, PartConfigsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PartConfigsQuery, PartConfigsQueryVariables>(PartConfigsDocument, options);
      }
export function usePartConfigsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PartConfigsQuery, PartConfigsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PartConfigsQuery, PartConfigsQueryVariables>(PartConfigsDocument, options);
        }
export type PartConfigsQueryHookResult = ReturnType<typeof usePartConfigsQuery>;
export type PartConfigsLazyQueryHookResult = ReturnType<typeof usePartConfigsLazyQuery>;
export type PartConfigsQueryResult = Apollo.QueryResult<PartConfigsQuery, PartConfigsQueryVariables>;
export const PartConfigsTableDocument = gql`
    query PartConfigsTable($input: PartConfigsQueryInput!) {
  partConfigs(input: $input) {
    totalCount
    rows {
      ...allPartConfigFields
    }
  }
}
    ${allPartConfigFieldsFragmentDoc}`;

/**
 * __usePartConfigsTableQuery__
 *
 * To run a query within a React component, call `usePartConfigsTableQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartConfigsTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartConfigsTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePartConfigsTableQuery(baseOptions: Apollo.QueryHookOptions<PartConfigsTableQuery, PartConfigsTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PartConfigsTableQuery, PartConfigsTableQueryVariables>(PartConfigsTableDocument, options);
      }
export function usePartConfigsTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PartConfigsTableQuery, PartConfigsTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PartConfigsTableQuery, PartConfigsTableQueryVariables>(PartConfigsTableDocument, options);
        }
export type PartConfigsTableQueryHookResult = ReturnType<typeof usePartConfigsTableQuery>;
export type PartConfigsTableLazyQueryHookResult = ReturnType<typeof usePartConfigsTableLazyQuery>;
export type PartConfigsTableQueryResult = Apollo.QueryResult<PartConfigsTableQuery, PartConfigsTableQueryVariables>;
export const PartConfigFormDocument = gql`
    query PartConfigForm {
  partCategories {
    id
    name
    display_name
    description
  }
  properties {
    rows {
      id
      name
      display_name
      type
      description
      path
      min
      max
      values
      unit
    }
  }
}
    `;

/**
 * __usePartConfigFormQuery__
 *
 * To run a query within a React component, call `usePartConfigFormQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartConfigFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartConfigFormQuery({
 *   variables: {
 *   },
 * });
 */
export function usePartConfigFormQuery(baseOptions?: Apollo.QueryHookOptions<PartConfigFormQuery, PartConfigFormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PartConfigFormQuery, PartConfigFormQueryVariables>(PartConfigFormDocument, options);
      }
export function usePartConfigFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PartConfigFormQuery, PartConfigFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PartConfigFormQuery, PartConfigFormQueryVariables>(PartConfigFormDocument, options);
        }
export type PartConfigFormQueryHookResult = ReturnType<typeof usePartConfigFormQuery>;
export type PartConfigFormLazyQueryHookResult = ReturnType<typeof usePartConfigFormLazyQuery>;
export type PartConfigFormQueryResult = Apollo.QueryResult<PartConfigFormQuery, PartConfigFormQueryVariables>;
export const PartConfigByIdDocument = gql`
    query PartConfigById($id: Int!) {
  partConfig(id: $id) {
    id
    category {
      id
      name
      display_name
      description
    }
    name
    display_name
    mileage_limit
    expires
    properties {
      property {
        id
        name
        display_name
        type
        description
        path
        min
        max
        values
        unit
      }
      id
      order
      specs
      description
      path
      min
      max
      values
      required
    }
  }
}
    `;

/**
 * __usePartConfigByIdQuery__
 *
 * To run a query within a React component, call `usePartConfigByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartConfigByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartConfigByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePartConfigByIdQuery(baseOptions: Apollo.QueryHookOptions<PartConfigByIdQuery, PartConfigByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PartConfigByIdQuery, PartConfigByIdQueryVariables>(PartConfigByIdDocument, options);
      }
export function usePartConfigByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PartConfigByIdQuery, PartConfigByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PartConfigByIdQuery, PartConfigByIdQueryVariables>(PartConfigByIdDocument, options);
        }
export type PartConfigByIdQueryHookResult = ReturnType<typeof usePartConfigByIdQuery>;
export type PartConfigByIdLazyQueryHookResult = ReturnType<typeof usePartConfigByIdLazyQuery>;
export type PartConfigByIdQueryResult = Apollo.QueryResult<PartConfigByIdQuery, PartConfigByIdQueryVariables>;
export const PartConfigByTypeNameDocument = gql`
    query PartConfigByTypeName($typeName: String!) {
  partConfig: partConfigByTypeName(typeName: $typeName) {
    id
    category {
      id
      name
      display_name
      description
    }
    name
    display_name
    mileage_limit
    expires
    properties {
      id
      property {
        id
        name
        display_name
        type
        min
        max
        values
      }
      min
      max
      values
      required
    }
  }
}
    `;

/**
 * __usePartConfigByTypeNameQuery__
 *
 * To run a query within a React component, call `usePartConfigByTypeNameQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartConfigByTypeNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartConfigByTypeNameQuery({
 *   variables: {
 *      typeName: // value for 'typeName'
 *   },
 * });
 */
export function usePartConfigByTypeNameQuery(baseOptions: Apollo.QueryHookOptions<PartConfigByTypeNameQuery, PartConfigByTypeNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PartConfigByTypeNameQuery, PartConfigByTypeNameQueryVariables>(PartConfigByTypeNameDocument, options);
      }
export function usePartConfigByTypeNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PartConfigByTypeNameQuery, PartConfigByTypeNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PartConfigByTypeNameQuery, PartConfigByTypeNameQueryVariables>(PartConfigByTypeNameDocument, options);
        }
export type PartConfigByTypeNameQueryHookResult = ReturnType<typeof usePartConfigByTypeNameQuery>;
export type PartConfigByTypeNameLazyQueryHookResult = ReturnType<typeof usePartConfigByTypeNameLazyQuery>;
export type PartConfigByTypeNameQueryResult = Apollo.QueryResult<PartConfigByTypeNameQuery, PartConfigByTypeNameQueryVariables>;
export const PartConfigIdByTypeNameDocument = gql`
    query PartConfigIdByTypeName($typeName: String!) {
  partConfig: partConfigByTypeName(typeName: $typeName) {
    id
  }
}
    `;

/**
 * __usePartConfigIdByTypeNameQuery__
 *
 * To run a query within a React component, call `usePartConfigIdByTypeNameQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartConfigIdByTypeNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartConfigIdByTypeNameQuery({
 *   variables: {
 *      typeName: // value for 'typeName'
 *   },
 * });
 */
export function usePartConfigIdByTypeNameQuery(baseOptions: Apollo.QueryHookOptions<PartConfigIdByTypeNameQuery, PartConfigIdByTypeNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PartConfigIdByTypeNameQuery, PartConfigIdByTypeNameQueryVariables>(PartConfigIdByTypeNameDocument, options);
      }
export function usePartConfigIdByTypeNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PartConfigIdByTypeNameQuery, PartConfigIdByTypeNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PartConfigIdByTypeNameQuery, PartConfigIdByTypeNameQueryVariables>(PartConfigIdByTypeNameDocument, options);
        }
export type PartConfigIdByTypeNameQueryHookResult = ReturnType<typeof usePartConfigIdByTypeNameQuery>;
export type PartConfigIdByTypeNameLazyQueryHookResult = ReturnType<typeof usePartConfigIdByTypeNameLazyQuery>;
export type PartConfigIdByTypeNameQueryResult = Apollo.QueryResult<PartConfigIdByTypeNameQuery, PartConfigIdByTypeNameQueryVariables>;
export const PartConfigPartsDocument = gql`
    query PartConfigParts($input: PartsQueryInput!) {
  parts(input: $input) {
    totalCount
    rows {
      id
      part_number
      serial_number
      description
      spec
      exp_date
      mileage
      properties {
        id
        config_property {
          id
          property {
            id
            name
            display_name
          }
        }
        value
      }
    }
  }
}
    `;

/**
 * __usePartConfigPartsQuery__
 *
 * To run a query within a React component, call `usePartConfigPartsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartConfigPartsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartConfigPartsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePartConfigPartsQuery(baseOptions: Apollo.QueryHookOptions<PartConfigPartsQuery, PartConfigPartsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PartConfigPartsQuery, PartConfigPartsQueryVariables>(PartConfigPartsDocument, options);
      }
export function usePartConfigPartsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PartConfigPartsQuery, PartConfigPartsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PartConfigPartsQuery, PartConfigPartsQueryVariables>(PartConfigPartsDocument, options);
        }
export type PartConfigPartsQueryHookResult = ReturnType<typeof usePartConfigPartsQuery>;
export type PartConfigPartsLazyQueryHookResult = ReturnType<typeof usePartConfigPartsLazyQuery>;
export type PartConfigPartsQueryResult = Apollo.QueryResult<PartConfigPartsQuery, PartConfigPartsQueryVariables>;
export const PartByIdDocument = gql`
    query PartById($id: Int!) {
  part(id: $id) {
    id
    part_number
    serial_number
    description
    spec
    exp_date
    mileage
    data
    config {
      id
      display_name
    }
    properties {
      id
      config_property {
        id
        specs
        min
        max
        values
        required
        property {
          id
          type
          name
          display_name
          min
          max
          values
        }
      }
      value
    }
  }
}
    `;

/**
 * __usePartByIdQuery__
 *
 * To run a query within a React component, call `usePartByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePartByIdQuery(baseOptions: Apollo.QueryHookOptions<PartByIdQuery, PartByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PartByIdQuery, PartByIdQueryVariables>(PartByIdDocument, options);
      }
export function usePartByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PartByIdQuery, PartByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PartByIdQuery, PartByIdQueryVariables>(PartByIdDocument, options);
        }
export type PartByIdQueryHookResult = ReturnType<typeof usePartByIdQuery>;
export type PartByIdLazyQueryHookResult = ReturnType<typeof usePartByIdLazyQuery>;
export type PartByIdQueryResult = Apollo.QueryResult<PartByIdQuery, PartByIdQueryVariables>;
export const SUITFormPartsDocument = gql`
    query SUITFormParts($input: PartsQueryInput!) {
  parts(input: $input) {
    rows {
      id
      part_number
      serial_number
      description
      spec
      exp_date
      mileage
      config {
        name
      }
    }
  }
}
    `;

/**
 * __useSUITFormPartsQuery__
 *
 * To run a query within a React component, call `useSUITFormPartsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSUITFormPartsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSUITFormPartsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSUITFormPartsQuery(baseOptions: Apollo.QueryHookOptions<SUITFormPartsQuery, SUITFormPartsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SUITFormPartsQuery, SUITFormPartsQueryVariables>(SUITFormPartsDocument, options);
      }
export function useSUITFormPartsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SUITFormPartsQuery, SUITFormPartsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SUITFormPartsQuery, SUITFormPartsQueryVariables>(SUITFormPartsDocument, options);
        }
export type SUITFormPartsQueryHookResult = ReturnType<typeof useSUITFormPartsQuery>;
export type SUITFormPartsLazyQueryHookResult = ReturnType<typeof useSUITFormPartsLazyQuery>;
export type SUITFormPartsQueryResult = Apollo.QueryResult<SUITFormPartsQuery, SUITFormPartsQueryVariables>;
export const PartsCompareDataDocument = gql`
    query PartsCompareData($input: PartsQueryInput!) {
  parts(input: $input) {
    rows {
      id
      part_number
      serial_number
      description
      data
      spec
      exp_date
      mileage
      config {
        name
      }
    }
  }
}
    `;

/**
 * __usePartsCompareDataQuery__
 *
 * To run a query within a React component, call `usePartsCompareDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartsCompareDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartsCompareDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePartsCompareDataQuery(baseOptions: Apollo.QueryHookOptions<PartsCompareDataQuery, PartsCompareDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PartsCompareDataQuery, PartsCompareDataQueryVariables>(PartsCompareDataDocument, options);
      }
export function usePartsCompareDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PartsCompareDataQuery, PartsCompareDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PartsCompareDataQuery, PartsCompareDataQueryVariables>(PartsCompareDataDocument, options);
        }
export type PartsCompareDataQueryHookResult = ReturnType<typeof usePartsCompareDataQuery>;
export type PartsCompareDataLazyQueryHookResult = ReturnType<typeof usePartsCompareDataLazyQuery>;
export type PartsCompareDataQueryResult = Apollo.QueryResult<PartsCompareDataQuery, PartsCompareDataQueryVariables>;
export const SetupSummaryDocument = gql`
    query SetupSummary($input: SetupsQueryInput) {
  setupBranchHeads(input: $input) {
    totalCount
    rows {
      ...mostSetupHeadFields
    }
  }
}
    ${mostSetupHeadFieldsFragmentDoc}`;

/**
 * __useSetupSummaryQuery__
 *
 * To run a query within a React component, call `useSetupSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSetupSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSetupSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetupSummaryQuery(baseOptions?: Apollo.QueryHookOptions<SetupSummaryQuery, SetupSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SetupSummaryQuery, SetupSummaryQueryVariables>(SetupSummaryDocument, options);
      }
export function useSetupSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SetupSummaryQuery, SetupSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SetupSummaryQuery, SetupSummaryQueryVariables>(SetupSummaryDocument, options);
        }
export type SetupSummaryQueryHookResult = ReturnType<typeof useSetupSummaryQuery>;
export type SetupSummaryLazyQueryHookResult = ReturnType<typeof useSetupSummaryLazyQuery>;
export type SetupSummaryQueryResult = Apollo.QueryResult<SetupSummaryQuery, SetupSummaryQueryVariables>;
export const SetupSummaryGetBranchesDocument = gql`
    query SetupSummaryGetBranches($rootId: Int!, $input: SetupsQueryInput) {
  setupBranchHeadsByRootId(rootId: $rootId, input: $input) {
    totalCount
    rows {
      ...mostSetupHeadFields
    }
  }
}
    ${mostSetupHeadFieldsFragmentDoc}`;

/**
 * __useSetupSummaryGetBranchesQuery__
 *
 * To run a query within a React component, call `useSetupSummaryGetBranchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSetupSummaryGetBranchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSetupSummaryGetBranchesQuery({
 *   variables: {
 *      rootId: // value for 'rootId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetupSummaryGetBranchesQuery(baseOptions: Apollo.QueryHookOptions<SetupSummaryGetBranchesQuery, SetupSummaryGetBranchesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SetupSummaryGetBranchesQuery, SetupSummaryGetBranchesQueryVariables>(SetupSummaryGetBranchesDocument, options);
      }
export function useSetupSummaryGetBranchesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SetupSummaryGetBranchesQuery, SetupSummaryGetBranchesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SetupSummaryGetBranchesQuery, SetupSummaryGetBranchesQueryVariables>(SetupSummaryGetBranchesDocument, options);
        }
export type SetupSummaryGetBranchesQueryHookResult = ReturnType<typeof useSetupSummaryGetBranchesQuery>;
export type SetupSummaryGetBranchesLazyQueryHookResult = ReturnType<typeof useSetupSummaryGetBranchesLazyQuery>;
export type SetupSummaryGetBranchesQueryResult = Apollo.QueryResult<SetupSummaryGetBranchesQuery, SetupSummaryGetBranchesQueryVariables>;
export const SetupSummaryWithPartsDocument = gql`
    query SetupSummaryWithParts($input: SetupsQueryInput) {
  setupBranchHeads(input: $input) {
    totalCount
    rows {
      ...mostSetupHeadFieldsWithParts
    }
  }
}
    ${mostSetupHeadFieldsWithPartsFragmentDoc}`;

/**
 * __useSetupSummaryWithPartsQuery__
 *
 * To run a query within a React component, call `useSetupSummaryWithPartsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSetupSummaryWithPartsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSetupSummaryWithPartsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetupSummaryWithPartsQuery(baseOptions?: Apollo.QueryHookOptions<SetupSummaryWithPartsQuery, SetupSummaryWithPartsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SetupSummaryWithPartsQuery, SetupSummaryWithPartsQueryVariables>(SetupSummaryWithPartsDocument, options);
      }
export function useSetupSummaryWithPartsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SetupSummaryWithPartsQuery, SetupSummaryWithPartsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SetupSummaryWithPartsQuery, SetupSummaryWithPartsQueryVariables>(SetupSummaryWithPartsDocument, options);
        }
export type SetupSummaryWithPartsQueryHookResult = ReturnType<typeof useSetupSummaryWithPartsQuery>;
export type SetupSummaryWithPartsLazyQueryHookResult = ReturnType<typeof useSetupSummaryWithPartsLazyQuery>;
export type SetupSummaryWithPartsQueryResult = Apollo.QueryResult<SetupSummaryWithPartsQuery, SetupSummaryWithPartsQueryVariables>;
export const SetupSummaryWithPartsGetBranchesDocument = gql`
    query SetupSummaryWithPartsGetBranches($rootId: Int!, $input: SetupsQueryInput) {
  setupBranchHeadsByRootId(rootId: $rootId, input: $input) {
    totalCount
    rows {
      ...mostSetupHeadFieldsWithParts
    }
  }
}
    ${mostSetupHeadFieldsWithPartsFragmentDoc}`;

/**
 * __useSetupSummaryWithPartsGetBranchesQuery__
 *
 * To run a query within a React component, call `useSetupSummaryWithPartsGetBranchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSetupSummaryWithPartsGetBranchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSetupSummaryWithPartsGetBranchesQuery({
 *   variables: {
 *      rootId: // value for 'rootId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetupSummaryWithPartsGetBranchesQuery(baseOptions: Apollo.QueryHookOptions<SetupSummaryWithPartsGetBranchesQuery, SetupSummaryWithPartsGetBranchesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SetupSummaryWithPartsGetBranchesQuery, SetupSummaryWithPartsGetBranchesQueryVariables>(SetupSummaryWithPartsGetBranchesDocument, options);
      }
export function useSetupSummaryWithPartsGetBranchesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SetupSummaryWithPartsGetBranchesQuery, SetupSummaryWithPartsGetBranchesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SetupSummaryWithPartsGetBranchesQuery, SetupSummaryWithPartsGetBranchesQueryVariables>(SetupSummaryWithPartsGetBranchesDocument, options);
        }
export type SetupSummaryWithPartsGetBranchesQueryHookResult = ReturnType<typeof useSetupSummaryWithPartsGetBranchesQuery>;
export type SetupSummaryWithPartsGetBranchesLazyQueryHookResult = ReturnType<typeof useSetupSummaryWithPartsGetBranchesLazyQuery>;
export type SetupSummaryWithPartsGetBranchesQueryResult = Apollo.QueryResult<SetupSummaryWithPartsGetBranchesQuery, SetupSummaryWithPartsGetBranchesQueryVariables>;
export const SetupHistoryDocument = gql`
    query SetupHistory($rootId: Int!) {
  setupHistory(rootId: $rootId) {
    branches {
      id
      head {
        id
      }
      tail {
        id
      }
      name
    }
    setups {
      id
      meta {
        id
        user
        merged_from_setup {
          id
        }
        full_merge
      }
      parent {
        id
      }
      name
      created_at
      updated_at
    }
  }
}
    `;

/**
 * __useSetupHistoryQuery__
 *
 * To run a query within a React component, call `useSetupHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSetupHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSetupHistoryQuery({
 *   variables: {
 *      rootId: // value for 'rootId'
 *   },
 * });
 */
export function useSetupHistoryQuery(baseOptions: Apollo.QueryHookOptions<SetupHistoryQuery, SetupHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SetupHistoryQuery, SetupHistoryQueryVariables>(SetupHistoryDocument, options);
      }
export function useSetupHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SetupHistoryQuery, SetupHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SetupHistoryQuery, SetupHistoryQueryVariables>(SetupHistoryDocument, options);
        }
export type SetupHistoryQueryHookResult = ReturnType<typeof useSetupHistoryQuery>;
export type SetupHistoryLazyQueryHookResult = ReturnType<typeof useSetupHistoryLazyQuery>;
export type SetupHistoryQueryResult = Apollo.QueryResult<SetupHistoryQuery, SetupHistoryQueryVariables>;
export const SUITEditDocument = gql`
    query SUITEdit($id: Int!) {
  suit(id: $id) {
    ...allSUITFields
  }
}
    ${allSUITFieldsFragmentDoc}`;

/**
 * __useSUITEditQuery__
 *
 * To run a query within a React component, call `useSUITEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useSUITEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSUITEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSUITEditQuery(baseOptions: Apollo.QueryHookOptions<SUITEditQuery, SUITEditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SUITEditQuery, SUITEditQueryVariables>(SUITEditDocument, options);
      }
export function useSUITEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SUITEditQuery, SUITEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SUITEditQuery, SUITEditQueryVariables>(SUITEditDocument, options);
        }
export type SUITEditQueryHookResult = ReturnType<typeof useSUITEditQuery>;
export type SUITEditLazyQueryHookResult = ReturnType<typeof useSUITEditLazyQuery>;
export type SUITEditQueryResult = Apollo.QueryResult<SUITEditQuery, SUITEditQueryVariables>;
export const SetupFieldManagementTableDocument = gql`
    query SetupFieldManagementTable($input: SetupFieldsQueryInput) {
  setupFields(input: $input) {
    totalCount
    rows {
      id
      specs
      name
      type
      part_config {
        id
        display_name
      }
      label
      path
      tooltip
      positions {
        id
        label
        path_part
      }
      options
      use_in_sim
    }
  }
}
    `;

/**
 * __useSetupFieldManagementTableQuery__
 *
 * To run a query within a React component, call `useSetupFieldManagementTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useSetupFieldManagementTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSetupFieldManagementTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetupFieldManagementTableQuery(baseOptions?: Apollo.QueryHookOptions<SetupFieldManagementTableQuery, SetupFieldManagementTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SetupFieldManagementTableQuery, SetupFieldManagementTableQueryVariables>(SetupFieldManagementTableDocument, options);
      }
export function useSetupFieldManagementTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SetupFieldManagementTableQuery, SetupFieldManagementTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SetupFieldManagementTableQuery, SetupFieldManagementTableQueryVariables>(SetupFieldManagementTableDocument, options);
        }
export type SetupFieldManagementTableQueryHookResult = ReturnType<typeof useSetupFieldManagementTableQuery>;
export type SetupFieldManagementTableLazyQueryHookResult = ReturnType<typeof useSetupFieldManagementTableLazyQuery>;
export type SetupFieldManagementTableQueryResult = Apollo.QueryResult<SetupFieldManagementTableQuery, SetupFieldManagementTableQueryVariables>;
export const SUITManagementTableDocument = gql`
    query SUITManagementTable($input: SUITsQueryInput) {
  suits(input: $input) {
    totalCount
    rows {
      ...allSUITFields
    }
  }
}
    ${allSUITFieldsFragmentDoc}`;

/**
 * __useSUITManagementTableQuery__
 *
 * To run a query within a React component, call `useSUITManagementTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useSUITManagementTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSUITManagementTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSUITManagementTableQuery(baseOptions?: Apollo.QueryHookOptions<SUITManagementTableQuery, SUITManagementTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SUITManagementTableQuery, SUITManagementTableQueryVariables>(SUITManagementTableDocument, options);
      }
export function useSUITManagementTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SUITManagementTableQuery, SUITManagementTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SUITManagementTableQuery, SUITManagementTableQueryVariables>(SUITManagementTableDocument, options);
        }
export type SUITManagementTableQueryHookResult = ReturnType<typeof useSUITManagementTableQuery>;
export type SUITManagementTableLazyQueryHookResult = ReturnType<typeof useSUITManagementTableLazyQuery>;
export type SUITManagementTableQueryResult = Apollo.QueryResult<SUITManagementTableQuery, SUITManagementTableQueryVariables>;
export const SetupBranchesByIdsDocument = gql`
    query SetupBranchesByIds($branchIds: [Int!]!) {
  setupBranches: setupBranchesByIds(ids: $branchIds) {
    id
    name
    head {
      ...mostSetupFields
    }
  }
}
    ${mostSetupFieldsFragmentDoc}`;

/**
 * __useSetupBranchesByIdsQuery__
 *
 * To run a query within a React component, call `useSetupBranchesByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSetupBranchesByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSetupBranchesByIdsQuery({
 *   variables: {
 *      branchIds: // value for 'branchIds'
 *   },
 * });
 */
export function useSetupBranchesByIdsQuery(baseOptions: Apollo.QueryHookOptions<SetupBranchesByIdsQuery, SetupBranchesByIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SetupBranchesByIdsQuery, SetupBranchesByIdsQueryVariables>(SetupBranchesByIdsDocument, options);
      }
export function useSetupBranchesByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SetupBranchesByIdsQuery, SetupBranchesByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SetupBranchesByIdsQuery, SetupBranchesByIdsQueryVariables>(SetupBranchesByIdsDocument, options);
        }
export type SetupBranchesByIdsQueryHookResult = ReturnType<typeof useSetupBranchesByIdsQuery>;
export type SetupBranchesByIdsLazyQueryHookResult = ReturnType<typeof useSetupBranchesByIdsLazyQuery>;
export type SetupBranchesByIdsQueryResult = Apollo.QueryResult<SetupBranchesByIdsQuery, SetupBranchesByIdsQueryVariables>;
export const SetupMergeCommonAncestorDocument = gql`
    query SetupMergeCommonAncestor($setupIdA: Int!, $setupIdB: Int!) {
  commonAncestor: commonSetup(setupIdA: $setupIdA, setupIdB: $setupIdB) {
    ...mostSetupFields
  }
}
    ${mostSetupFieldsFragmentDoc}`;

/**
 * __useSetupMergeCommonAncestorQuery__
 *
 * To run a query within a React component, call `useSetupMergeCommonAncestorQuery` and pass it any options that fit your needs.
 * When your component renders, `useSetupMergeCommonAncestorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSetupMergeCommonAncestorQuery({
 *   variables: {
 *      setupIdA: // value for 'setupIdA'
 *      setupIdB: // value for 'setupIdB'
 *   },
 * });
 */
export function useSetupMergeCommonAncestorQuery(baseOptions: Apollo.QueryHookOptions<SetupMergeCommonAncestorQuery, SetupMergeCommonAncestorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SetupMergeCommonAncestorQuery, SetupMergeCommonAncestorQueryVariables>(SetupMergeCommonAncestorDocument, options);
      }
export function useSetupMergeCommonAncestorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SetupMergeCommonAncestorQuery, SetupMergeCommonAncestorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SetupMergeCommonAncestorQuery, SetupMergeCommonAncestorQueryVariables>(SetupMergeCommonAncestorDocument, options);
        }
export type SetupMergeCommonAncestorQueryHookResult = ReturnType<typeof useSetupMergeCommonAncestorQuery>;
export type SetupMergeCommonAncestorLazyQueryHookResult = ReturnType<typeof useSetupMergeCommonAncestorLazyQuery>;
export type SetupMergeCommonAncestorQueryResult = Apollo.QueryResult<SetupMergeCommonAncestorQuery, SetupMergeCommonAncestorQueryVariables>;
export const SUITByIdDocument = gql`
    query SUITById($id: Int!) {
  suit(id: $id) {
    ...allSUITFields
  }
}
    ${allSUITFieldsFragmentDoc}`;

/**
 * __useSUITByIdQuery__
 *
 * To run a query within a React component, call `useSUITByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSUITByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSUITByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSUITByIdQuery(baseOptions: Apollo.QueryHookOptions<SUITByIdQuery, SUITByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SUITByIdQuery, SUITByIdQueryVariables>(SUITByIdDocument, options);
      }
export function useSUITByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SUITByIdQuery, SUITByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SUITByIdQuery, SUITByIdQueryVariables>(SUITByIdDocument, options);
        }
export type SUITByIdQueryHookResult = ReturnType<typeof useSUITByIdQuery>;
export type SUITByIdLazyQueryHookResult = ReturnType<typeof useSUITByIdLazyQuery>;
export type SUITByIdQueryResult = Apollo.QueryResult<SUITByIdQuery, SUITByIdQueryVariables>;
export const SetupByIdDocument = gql`
    query SetupById($id: Int!) {
  setup(id: $id) {
    ...mostSetupFields
  }
}
    ${mostSetupFieldsFragmentDoc}`;

/**
 * __useSetupByIdQuery__
 *
 * To run a query within a React component, call `useSetupByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSetupByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSetupByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSetupByIdQuery(baseOptions: Apollo.QueryHookOptions<SetupByIdQuery, SetupByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SetupByIdQuery, SetupByIdQueryVariables>(SetupByIdDocument, options);
      }
export function useSetupByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SetupByIdQuery, SetupByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SetupByIdQuery, SetupByIdQueryVariables>(SetupByIdDocument, options);
        }
export type SetupByIdQueryHookResult = ReturnType<typeof useSetupByIdQuery>;
export type SetupByIdLazyQueryHookResult = ReturnType<typeof useSetupByIdLazyQuery>;
export type SetupByIdQueryResult = Apollo.QueryResult<SetupByIdQuery, SetupByIdQueryVariables>;
export const SetupByBranchIdDocument = gql`
    query SetupByBranchId($branchId: Int!) {
  setup: setupByBranchId(branchId: $branchId) {
    ...mostSetupFields
  }
}
    ${mostSetupFieldsFragmentDoc}`;

/**
 * __useSetupByBranchIdQuery__
 *
 * To run a query within a React component, call `useSetupByBranchIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSetupByBranchIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSetupByBranchIdQuery({
 *   variables: {
 *      branchId: // value for 'branchId'
 *   },
 * });
 */
export function useSetupByBranchIdQuery(baseOptions: Apollo.QueryHookOptions<SetupByBranchIdQuery, SetupByBranchIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SetupByBranchIdQuery, SetupByBranchIdQueryVariables>(SetupByBranchIdDocument, options);
      }
export function useSetupByBranchIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SetupByBranchIdQuery, SetupByBranchIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SetupByBranchIdQuery, SetupByBranchIdQueryVariables>(SetupByBranchIdDocument, options);
        }
export type SetupByBranchIdQueryHookResult = ReturnType<typeof useSetupByBranchIdQuery>;
export type SetupByBranchIdLazyQueryHookResult = ReturnType<typeof useSetupByBranchIdLazyQuery>;
export type SetupByBranchIdQueryResult = Apollo.QueryResult<SetupByBranchIdQuery, SetupByBranchIdQueryVariables>;
export const SetupBranchesByRootIdDocument = gql`
    query SetupBranchesByRootId($rootId: Int!) {
  branches: setupBranchesByRootId(rootId: $rootId) {
    id
    name
    created_at
    root {
      id
    }
    head {
      id
    }
    tail {
      id
    }
  }
}
    `;

/**
 * __useSetupBranchesByRootIdQuery__
 *
 * To run a query within a React component, call `useSetupBranchesByRootIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSetupBranchesByRootIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSetupBranchesByRootIdQuery({
 *   variables: {
 *      rootId: // value for 'rootId'
 *   },
 * });
 */
export function useSetupBranchesByRootIdQuery(baseOptions: Apollo.QueryHookOptions<SetupBranchesByRootIdQuery, SetupBranchesByRootIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SetupBranchesByRootIdQuery, SetupBranchesByRootIdQueryVariables>(SetupBranchesByRootIdDocument, options);
      }
export function useSetupBranchesByRootIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SetupBranchesByRootIdQuery, SetupBranchesByRootIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SetupBranchesByRootIdQuery, SetupBranchesByRootIdQueryVariables>(SetupBranchesByRootIdDocument, options);
        }
export type SetupBranchesByRootIdQueryHookResult = ReturnType<typeof useSetupBranchesByRootIdQuery>;
export type SetupBranchesByRootIdLazyQueryHookResult = ReturnType<typeof useSetupBranchesByRootIdLazyQuery>;
export type SetupBranchesByRootIdQueryResult = Apollo.QueryResult<SetupBranchesByRootIdQuery, SetupBranchesByRootIdQueryVariables>;
export const SetupBranchHeadByBranchIdDocument = gql`
    query SetupBranchHeadByBranchId($branchId: Int!) {
  branchHead: setupBranchHeadByBranchId(branchId: $branchId) {
    ...mostSetupHeadFields
  }
}
    ${mostSetupHeadFieldsFragmentDoc}`;

/**
 * __useSetupBranchHeadByBranchIdQuery__
 *
 * To run a query within a React component, call `useSetupBranchHeadByBranchIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSetupBranchHeadByBranchIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSetupBranchHeadByBranchIdQuery({
 *   variables: {
 *      branchId: // value for 'branchId'
 *   },
 * });
 */
export function useSetupBranchHeadByBranchIdQuery(baseOptions: Apollo.QueryHookOptions<SetupBranchHeadByBranchIdQuery, SetupBranchHeadByBranchIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SetupBranchHeadByBranchIdQuery, SetupBranchHeadByBranchIdQueryVariables>(SetupBranchHeadByBranchIdDocument, options);
      }
export function useSetupBranchHeadByBranchIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SetupBranchHeadByBranchIdQuery, SetupBranchHeadByBranchIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SetupBranchHeadByBranchIdQuery, SetupBranchHeadByBranchIdQueryVariables>(SetupBranchHeadByBranchIdDocument, options);
        }
export type SetupBranchHeadByBranchIdQueryHookResult = ReturnType<typeof useSetupBranchHeadByBranchIdQuery>;
export type SetupBranchHeadByBranchIdLazyQueryHookResult = ReturnType<typeof useSetupBranchHeadByBranchIdLazyQuery>;
export type SetupBranchHeadByBranchIdQueryResult = Apollo.QueryResult<SetupBranchHeadByBranchIdQuery, SetupBranchHeadByBranchIdQueryVariables>;
export const SUITsDocument = gql`
    query SUITs {
  suits {
    rows {
      id
      spec
      name
      description
      version
    }
  }
}
    `;

/**
 * __useSUITsQuery__
 *
 * To run a query within a React component, call `useSUITsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSUITsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSUITsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSUITsQuery(baseOptions?: Apollo.QueryHookOptions<SUITsQuery, SUITsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SUITsQuery, SUITsQueryVariables>(SUITsDocument, options);
      }
export function useSUITsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SUITsQuery, SUITsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SUITsQuery, SUITsQueryVariables>(SUITsDocument, options);
        }
export type SUITsQueryHookResult = ReturnType<typeof useSUITsQuery>;
export type SUITsLazyQueryHookResult = ReturnType<typeof useSUITsLazyQuery>;
export type SUITsQueryResult = Apollo.QueryResult<SUITsQuery, SUITsQueryVariables>;
export const SetupFieldsDocument = gql`
    query SetupFields {
  setupFields {
    rows {
      ...allSetupFieldFields
    }
  }
}
    ${allSetupFieldFieldsFragmentDoc}`;

/**
 * __useSetupFieldsQuery__
 *
 * To run a query within a React component, call `useSetupFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSetupFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSetupFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSetupFieldsQuery(baseOptions?: Apollo.QueryHookOptions<SetupFieldsQuery, SetupFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SetupFieldsQuery, SetupFieldsQueryVariables>(SetupFieldsDocument, options);
      }
export function useSetupFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SetupFieldsQuery, SetupFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SetupFieldsQuery, SetupFieldsQueryVariables>(SetupFieldsDocument, options);
        }
export type SetupFieldsQueryHookResult = ReturnType<typeof useSetupFieldsQuery>;
export type SetupFieldsLazyQueryHookResult = ReturnType<typeof useSetupFieldsLazyQuery>;
export type SetupFieldsQueryResult = Apollo.QueryResult<SetupFieldsQuery, SetupFieldsQueryVariables>;
export const SetupsByIdsDocument = gql`
    query SetupsByIds($ids: [Int]!) {
  setupsByIds(ids: $ids) {
    ...mostSetupFields
  }
}
    ${mostSetupFieldsFragmentDoc}`;

/**
 * __useSetupsByIdsQuery__
 *
 * To run a query within a React component, call `useSetupsByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSetupsByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSetupsByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useSetupsByIdsQuery(baseOptions: Apollo.QueryHookOptions<SetupsByIdsQuery, SetupsByIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SetupsByIdsQuery, SetupsByIdsQueryVariables>(SetupsByIdsDocument, options);
      }
export function useSetupsByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SetupsByIdsQuery, SetupsByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SetupsByIdsQuery, SetupsByIdsQueryVariables>(SetupsByIdsDocument, options);
        }
export type SetupsByIdsQueryHookResult = ReturnType<typeof useSetupsByIdsQuery>;
export type SetupsByIdsLazyQueryHookResult = ReturnType<typeof useSetupsByIdsLazyQuery>;
export type SetupsByIdsQueryResult = Apollo.QueryResult<SetupsByIdsQuery, SetupsByIdsQueryVariables>;
export const SetupBranchesDocument = gql`
    query SetupBranches {
  setupBranches {
    id
    name
    head {
      ...mostSetupFields
    }
    root {
      id
    }
  }
}
    ${mostSetupFieldsFragmentDoc}`;

/**
 * __useSetupBranchesQuery__
 *
 * To run a query within a React component, call `useSetupBranchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSetupBranchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSetupBranchesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSetupBranchesQuery(baseOptions?: Apollo.QueryHookOptions<SetupBranchesQuery, SetupBranchesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SetupBranchesQuery, SetupBranchesQueryVariables>(SetupBranchesDocument, options);
      }
export function useSetupBranchesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SetupBranchesQuery, SetupBranchesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SetupBranchesQuery, SetupBranchesQueryVariables>(SetupBranchesDocument, options);
        }
export type SetupBranchesQueryHookResult = ReturnType<typeof useSetupBranchesQuery>;
export type SetupBranchesLazyQueryHookResult = ReturnType<typeof useSetupBranchesLazyQuery>;
export type SetupBranchesQueryResult = Apollo.QueryResult<SetupBranchesQuery, SetupBranchesQueryVariables>;
export const SetupBranchByIdDocument = gql`
    query SetupBranchById($id: Int!) {
  branch: setupBranch(id: $id) {
    id
    name
    root {
      id
    }
    head {
      id
    }
    tail {
      id
    }
  }
}
    `;

/**
 * __useSetupBranchByIdQuery__
 *
 * To run a query within a React component, call `useSetupBranchByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSetupBranchByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSetupBranchByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSetupBranchByIdQuery(baseOptions: Apollo.QueryHookOptions<SetupBranchByIdQuery, SetupBranchByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SetupBranchByIdQuery, SetupBranchByIdQueryVariables>(SetupBranchByIdDocument, options);
      }
export function useSetupBranchByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SetupBranchByIdQuery, SetupBranchByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SetupBranchByIdQuery, SetupBranchByIdQueryVariables>(SetupBranchByIdDocument, options);
        }
export type SetupBranchByIdQueryHookResult = ReturnType<typeof useSetupBranchByIdQuery>;
export type SetupBranchByIdLazyQueryHookResult = ReturnType<typeof useSetupBranchByIdLazyQuery>;
export type SetupBranchByIdQueryResult = Apollo.QueryResult<SetupBranchByIdQuery, SetupBranchByIdQueryVariables>;
export const RunsByRootIdDocument = gql`
    query RunsByRootId($rootId: Int!) {
  runs: runsByRootId(rootId: $rootId) {
    id
    session
    session_id
    data
    branch {
      id
      name
    }
  }
}
    `;

/**
 * __useRunsByRootIdQuery__
 *
 * To run a query within a React component, call `useRunsByRootIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunsByRootIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunsByRootIdQuery({
 *   variables: {
 *      rootId: // value for 'rootId'
 *   },
 * });
 */
export function useRunsByRootIdQuery(baseOptions: Apollo.QueryHookOptions<RunsByRootIdQuery, RunsByRootIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RunsByRootIdQuery, RunsByRootIdQueryVariables>(RunsByRootIdDocument, options);
      }
export function useRunsByRootIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RunsByRootIdQuery, RunsByRootIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RunsByRootIdQuery, RunsByRootIdQueryVariables>(RunsByRootIdDocument, options);
        }
export type RunsByRootIdQueryHookResult = ReturnType<typeof useRunsByRootIdQuery>;
export type RunsByRootIdLazyQueryHookResult = ReturnType<typeof useRunsByRootIdLazyQuery>;
export type RunsByRootIdQueryResult = Apollo.QueryResult<RunsByRootIdQuery, RunsByRootIdQueryVariables>;
export const RunFieldManagementTableDocument = gql`
    query RunFieldManagementTable($input: RunFieldsQueryInput) {
  runFields(input: $input) {
    totalCount
    rows {
      id
      name
      type
      label
      tooltip
      positions {
        id
        label
      }
      options
    }
  }
}
    `;

/**
 * __useRunFieldManagementTableQuery__
 *
 * To run a query within a React component, call `useRunFieldManagementTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunFieldManagementTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunFieldManagementTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRunFieldManagementTableQuery(baseOptions?: Apollo.QueryHookOptions<RunFieldManagementTableQuery, RunFieldManagementTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RunFieldManagementTableQuery, RunFieldManagementTableQueryVariables>(RunFieldManagementTableDocument, options);
      }
export function useRunFieldManagementTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RunFieldManagementTableQuery, RunFieldManagementTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RunFieldManagementTableQuery, RunFieldManagementTableQueryVariables>(RunFieldManagementTableDocument, options);
        }
export type RunFieldManagementTableQueryHookResult = ReturnType<typeof useRunFieldManagementTableQuery>;
export type RunFieldManagementTableLazyQueryHookResult = ReturnType<typeof useRunFieldManagementTableLazyQuery>;
export type RunFieldManagementTableQueryResult = Apollo.QueryResult<RunFieldManagementTableQuery, RunFieldManagementTableQueryVariables>;
export const RunFieldsDocument = gql`
    query RunFields {
  runFields {
    rows {
      id
      name
      type
      label
      tooltip
      positions {
        id
        label
      }
      options
    }
  }
}
    `;

/**
 * __useRunFieldsQuery__
 *
 * To run a query within a React component, call `useRunFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRunFieldsQuery(baseOptions?: Apollo.QueryHookOptions<RunFieldsQuery, RunFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RunFieldsQuery, RunFieldsQueryVariables>(RunFieldsDocument, options);
      }
export function useRunFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RunFieldsQuery, RunFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RunFieldsQuery, RunFieldsQueryVariables>(RunFieldsDocument, options);
        }
export type RunFieldsQueryHookResult = ReturnType<typeof useRunFieldsQuery>;
export type RunFieldsLazyQueryHookResult = ReturnType<typeof useRunFieldsLazyQuery>;
export type RunFieldsQueryResult = Apollo.QueryResult<RunFieldsQuery, RunFieldsQueryVariables>;
export const RUITsDocument = gql`
    query RUITs {
  ruits {
    rows {
      id
      name
      description
      version
      template
    }
  }
}
    `;

/**
 * __useRUITsQuery__
 *
 * To run a query within a React component, call `useRUITsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRUITsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRUITsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRUITsQuery(baseOptions?: Apollo.QueryHookOptions<RUITsQuery, RUITsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RUITsQuery, RUITsQueryVariables>(RUITsDocument, options);
      }
export function useRUITsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RUITsQuery, RUITsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RUITsQuery, RUITsQueryVariables>(RUITsDocument, options);
        }
export type RUITsQueryHookResult = ReturnType<typeof useRUITsQuery>;
export type RUITsLazyQueryHookResult = ReturnType<typeof useRUITsLazyQuery>;
export type RUITsQueryResult = Apollo.QueryResult<RUITsQuery, RUITsQueryVariables>;
export const RUITManagementDocument = gql`
    query RUITManagement($input: RUITsQueryInput) {
  ruits(input: $input) {
    totalCount
    rows {
      id
      name
      description
      last_modified_by
      version
      template
      created_at
      updated_at
    }
  }
}
    `;

/**
 * __useRUITManagementQuery__
 *
 * To run a query within a React component, call `useRUITManagementQuery` and pass it any options that fit your needs.
 * When your component renders, `useRUITManagementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRUITManagementQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRUITManagementQuery(baseOptions?: Apollo.QueryHookOptions<RUITManagementQuery, RUITManagementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RUITManagementQuery, RUITManagementQueryVariables>(RUITManagementDocument, options);
      }
export function useRUITManagementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RUITManagementQuery, RUITManagementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RUITManagementQuery, RUITManagementQueryVariables>(RUITManagementDocument, options);
        }
export type RUITManagementQueryHookResult = ReturnType<typeof useRUITManagementQuery>;
export type RUITManagementLazyQueryHookResult = ReturnType<typeof useRUITManagementLazyQuery>;
export type RUITManagementQueryResult = Apollo.QueryResult<RUITManagementQuery, RUITManagementQueryVariables>;
export const RUITByIdDocument = gql`
    query RUITById($id: Int!) {
  ruit(id: $id) {
    id
    name
    description
    version
    template
  }
}
    `;

/**
 * __useRUITByIdQuery__
 *
 * To run a query within a React component, call `useRUITByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useRUITByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRUITByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRUITByIdQuery(baseOptions: Apollo.QueryHookOptions<RUITByIdQuery, RUITByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RUITByIdQuery, RUITByIdQueryVariables>(RUITByIdDocument, options);
      }
export function useRUITByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RUITByIdQuery, RUITByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RUITByIdQuery, RUITByIdQueryVariables>(RUITByIdDocument, options);
        }
export type RUITByIdQueryHookResult = ReturnType<typeof useRUITByIdQuery>;
export type RUITByIdLazyQueryHookResult = ReturnType<typeof useRUITByIdLazyQuery>;
export type RUITByIdQueryResult = Apollo.QueryResult<RUITByIdQuery, RUITByIdQueryVariables>;
export const SearchRunsDocument = gql`
    query SearchRuns {
  runs {
    id
    data
    branch {
      id
      name
      head {
        id
        name
        year
        car
        description
        event
        owner
      }
    }
  }
}
    `;

/**
 * __useSearchRunsQuery__
 *
 * To run a query within a React component, call `useSearchRunsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchRunsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchRunsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSearchRunsQuery(baseOptions?: Apollo.QueryHookOptions<SearchRunsQuery, SearchRunsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchRunsQuery, SearchRunsQueryVariables>(SearchRunsDocument, options);
      }
export function useSearchRunsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchRunsQuery, SearchRunsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchRunsQuery, SearchRunsQueryVariables>(SearchRunsDocument, options);
        }
export type SearchRunsQueryHookResult = ReturnType<typeof useSearchRunsQuery>;
export type SearchRunsLazyQueryHookResult = ReturnType<typeof useSearchRunsLazyQuery>;
export type SearchRunsQueryResult = Apollo.QueryResult<SearchRunsQuery, SearchRunsQueryVariables>;
export const SimsDocument = gql`
    query Sims($input: SimsQueryInput) {
  sims(input: $input) {
    totalCount
    rows {
      id
      name
      spec
      type
      owner
      data
      output_directory
      export_motec
      export_json
      export_debug
      download_motec
      download_json
      download_debug
      created_at
      updated_at
    }
  }
}
    `;

/**
 * __useSimsQuery__
 *
 * To run a query within a React component, call `useSimsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSimsQuery(baseOptions?: Apollo.QueryHookOptions<SimsQuery, SimsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SimsQuery, SimsQueryVariables>(SimsDocument, options);
      }
export function useSimsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SimsQuery, SimsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SimsQuery, SimsQueryVariables>(SimsDocument, options);
        }
export type SimsQueryHookResult = ReturnType<typeof useSimsQuery>;
export type SimsLazyQueryHookResult = ReturnType<typeof useSimsLazyQuery>;
export type SimsQueryResult = Apollo.QueryResult<SimsQuery, SimsQueryVariables>;
export const SimByIdDocument = gql`
    query SimById($id: Int!) {
  sim(id: $id) {
    id
    name
    spec
    type
    owner
    data
    output_directory
    export_motec
    export_json
    export_metrics
    export_debug
    download_motec
    download_json
    download_metrics
    download_vdf
    download_edf
    download_debug
    drive_file_id
    drive_file {
      id
      name
      desc
      status
      owner
      venue
    }
    sim_states_id
    sim_states {
      id
      name
      desc
      status
      owner
      track
      states {
        id
        label
        tire
        fuel
        engine
      }
    }
    state_option_id
    lap_time_data_id
    lap_time_data {
      id
      name
      desc
      status
      owner
    }
    driver_id
    driver {
      id
      name
      desc
      track
      status
      owner
    }
    eight_post_id
    eight_post {
      id
      name
      desc
      venue
      status
      owner
    }
  }
}
    `;

/**
 * __useSimByIdQuery__
 *
 * To run a query within a React component, call `useSimByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSimByIdQuery(baseOptions: Apollo.QueryHookOptions<SimByIdQuery, SimByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SimByIdQuery, SimByIdQueryVariables>(SimByIdDocument, options);
      }
export function useSimByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SimByIdQuery, SimByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SimByIdQuery, SimByIdQueryVariables>(SimByIdDocument, options);
        }
export type SimByIdQueryHookResult = ReturnType<typeof useSimByIdQuery>;
export type SimByIdLazyQueryHookResult = ReturnType<typeof useSimByIdLazyQuery>;
export type SimByIdQueryResult = Apollo.QueryResult<SimByIdQuery, SimByIdQueryVariables>;
export const DriveFilesDocument = gql`
    query DriveFiles {
  driveFiles {
    id
    name
    desc
    status
    owner
    venue
    data {
      segments
      laptime
    }
    created_at
    updated_at
  }
}
    `;

/**
 * __useDriveFilesQuery__
 *
 * To run a query within a React component, call `useDriveFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDriveFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDriveFilesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDriveFilesQuery(baseOptions?: Apollo.QueryHookOptions<DriveFilesQuery, DriveFilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DriveFilesQuery, DriveFilesQueryVariables>(DriveFilesDocument, options);
      }
export function useDriveFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DriveFilesQuery, DriveFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DriveFilesQuery, DriveFilesQueryVariables>(DriveFilesDocument, options);
        }
export type DriveFilesQueryHookResult = ReturnType<typeof useDriveFilesQuery>;
export type DriveFilesLazyQueryHookResult = ReturnType<typeof useDriveFilesLazyQuery>;
export type DriveFilesQueryResult = Apollo.QueryResult<DriveFilesQuery, DriveFilesQueryVariables>;
export const DriveFileByIdDocument = gql`
    query DriveFileById($id: Int!) {
  driveFile(id: $id) {
    id
    name
    desc
    status
    owner
    venue
    data {
      segments
      laptime
      venue
      controller
      signalsArray
      trim_start
    }
    created_at
    updated_at
  }
}
    `;

/**
 * __useDriveFileByIdQuery__
 *
 * To run a query within a React component, call `useDriveFileByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDriveFileByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDriveFileByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDriveFileByIdQuery(baseOptions: Apollo.QueryHookOptions<DriveFileByIdQuery, DriveFileByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DriveFileByIdQuery, DriveFileByIdQueryVariables>(DriveFileByIdDocument, options);
      }
export function useDriveFileByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DriveFileByIdQuery, DriveFileByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DriveFileByIdQuery, DriveFileByIdQueryVariables>(DriveFileByIdDocument, options);
        }
export type DriveFileByIdQueryHookResult = ReturnType<typeof useDriveFileByIdQuery>;
export type DriveFileByIdLazyQueryHookResult = ReturnType<typeof useDriveFileByIdLazyQuery>;
export type DriveFileByIdQueryResult = Apollo.QueryResult<DriveFileByIdQuery, DriveFileByIdQueryVariables>;
export const LapWindDataDocument = gql`
    query LapWindData {
  lapWindDatas {
    id
    name
    desc
    status
    owner
    data
    created_at
    updated_at
  }
}
    `;

/**
 * __useLapWindDataQuery__
 *
 * To run a query within a React component, call `useLapWindDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useLapWindDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLapWindDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useLapWindDataQuery(baseOptions?: Apollo.QueryHookOptions<LapWindDataQuery, LapWindDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LapWindDataQuery, LapWindDataQueryVariables>(LapWindDataDocument, options);
      }
export function useLapWindDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LapWindDataQuery, LapWindDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LapWindDataQuery, LapWindDataQueryVariables>(LapWindDataDocument, options);
        }
export type LapWindDataQueryHookResult = ReturnType<typeof useLapWindDataQuery>;
export type LapWindDataLazyQueryHookResult = ReturnType<typeof useLapWindDataLazyQuery>;
export type LapWindDataQueryResult = Apollo.QueryResult<LapWindDataQuery, LapWindDataQueryVariables>;
export const LapWindDataByIdDocument = gql`
    query LapWindDataById($id: Int!) {
  lapWindData(id: $id) {
    id
    name
    desc
    status
    owner
    data
    created_at
    updated_at
  }
}
    `;

/**
 * __useLapWindDataByIdQuery__
 *
 * To run a query within a React component, call `useLapWindDataByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLapWindDataByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLapWindDataByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLapWindDataByIdQuery(baseOptions: Apollo.QueryHookOptions<LapWindDataByIdQuery, LapWindDataByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LapWindDataByIdQuery, LapWindDataByIdQueryVariables>(LapWindDataByIdDocument, options);
      }
export function useLapWindDataByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LapWindDataByIdQuery, LapWindDataByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LapWindDataByIdQuery, LapWindDataByIdQueryVariables>(LapWindDataByIdDocument, options);
        }
export type LapWindDataByIdQueryHookResult = ReturnType<typeof useLapWindDataByIdQuery>;
export type LapWindDataByIdLazyQueryHookResult = ReturnType<typeof useLapWindDataByIdLazyQuery>;
export type LapWindDataByIdQueryResult = Apollo.QueryResult<LapWindDataByIdQuery, LapWindDataByIdQueryVariables>;
export const EnvironmentsDocument = gql`
    query Environments($input: EnvironmentsQueryInput) {
  environments(input: $input) {
    totalCount
    rows {
      id
      name
      track
      spec
      owner
      temperature
      pressure
      humidity
      wind_speed
      wind_direction
      traffic_wind
      traffic_wind_angle
      elevation
      use_lap_wind_data
      historical_time
      historical_weather
      wind_scalar
      latitude
      longitude
      created_at
      updated_at
    }
  }
}
    `;

/**
 * __useEnvironmentsQuery__
 *
 * To run a query within a React component, call `useEnvironmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnvironmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnvironmentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnvironmentsQuery(baseOptions?: Apollo.QueryHookOptions<EnvironmentsQuery, EnvironmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EnvironmentsQuery, EnvironmentsQueryVariables>(EnvironmentsDocument, options);
      }
export function useEnvironmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EnvironmentsQuery, EnvironmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EnvironmentsQuery, EnvironmentsQueryVariables>(EnvironmentsDocument, options);
        }
export type EnvironmentsQueryHookResult = ReturnType<typeof useEnvironmentsQuery>;
export type EnvironmentsLazyQueryHookResult = ReturnType<typeof useEnvironmentsLazyQuery>;
export type EnvironmentsQueryResult = Apollo.QueryResult<EnvironmentsQuery, EnvironmentsQueryVariables>;
export const SimWorksheetSummaryDocument = gql`
    query SimWorksheetSummary($input: SimWorksheetsQueryInput) {
  simWorksheets(input: $input) {
    totalCount
    rows {
      id
      name
      car
      event
      description
      session
      spec
      track
      year
      owner
      created_at
      updated_at
    }
  }
}
    `;

/**
 * __useSimWorksheetSummaryQuery__
 *
 * To run a query within a React component, call `useSimWorksheetSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimWorksheetSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimWorksheetSummaryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSimWorksheetSummaryQuery(baseOptions?: Apollo.QueryHookOptions<SimWorksheetSummaryQuery, SimWorksheetSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SimWorksheetSummaryQuery, SimWorksheetSummaryQueryVariables>(SimWorksheetSummaryDocument, options);
      }
export function useSimWorksheetSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SimWorksheetSummaryQuery, SimWorksheetSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SimWorksheetSummaryQuery, SimWorksheetSummaryQueryVariables>(SimWorksheetSummaryDocument, options);
        }
export type SimWorksheetSummaryQueryHookResult = ReturnType<typeof useSimWorksheetSummaryQuery>;
export type SimWorksheetSummaryLazyQueryHookResult = ReturnType<typeof useSimWorksheetSummaryLazyQuery>;
export type SimWorksheetSummaryQueryResult = Apollo.QueryResult<SimWorksheetSummaryQuery, SimWorksheetSummaryQueryVariables>;
export const SimWorksheetByIdDocument = gql`
    query SimWorksheetById($id: Int!) {
  simWorksheet(id: $id) {
    id
    name
    car
    event
    description
    session
    spec
    track
    year
    owner
    created_at
    updated_at
    output_directory
    environment {
      id
      name
    }
    sim_outlines {
      ...simOutlineFields
    }
  }
}
    ${simOutlineFieldsFragmentDoc}`;

/**
 * __useSimWorksheetByIdQuery__
 *
 * To run a query within a React component, call `useSimWorksheetByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimWorksheetByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimWorksheetByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSimWorksheetByIdQuery(baseOptions: Apollo.QueryHookOptions<SimWorksheetByIdQuery, SimWorksheetByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SimWorksheetByIdQuery, SimWorksheetByIdQueryVariables>(SimWorksheetByIdDocument, options);
      }
export function useSimWorksheetByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SimWorksheetByIdQuery, SimWorksheetByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SimWorksheetByIdQuery, SimWorksheetByIdQueryVariables>(SimWorksheetByIdDocument, options);
        }
export type SimWorksheetByIdQueryHookResult = ReturnType<typeof useSimWorksheetByIdQuery>;
export type SimWorksheetByIdLazyQueryHookResult = ReturnType<typeof useSimWorksheetByIdLazyQuery>;
export type SimWorksheetByIdQueryResult = Apollo.QueryResult<SimWorksheetByIdQuery, SimWorksheetByIdQueryVariables>;
export const SimOutlineByIdDocument = gql`
    query SimOutlineById($id: Int!) {
  simOutline(id: $id) {
    ...simOutlineFields
  }
}
    ${simOutlineFieldsFragmentDoc}`;

/**
 * __useSimOutlineByIdQuery__
 *
 * To run a query within a React component, call `useSimOutlineByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimOutlineByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimOutlineByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSimOutlineByIdQuery(baseOptions: Apollo.QueryHookOptions<SimOutlineByIdQuery, SimOutlineByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SimOutlineByIdQuery, SimOutlineByIdQueryVariables>(SimOutlineByIdDocument, options);
      }
export function useSimOutlineByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SimOutlineByIdQuery, SimOutlineByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SimOutlineByIdQuery, SimOutlineByIdQueryVariables>(SimOutlineByIdDocument, options);
        }
export type SimOutlineByIdQueryHookResult = ReturnType<typeof useSimOutlineByIdQuery>;
export type SimOutlineByIdLazyQueryHookResult = ReturnType<typeof useSimOutlineByIdLazyQuery>;
export type SimOutlineByIdQueryResult = Apollo.QueryResult<SimOutlineByIdQuery, SimOutlineByIdQueryVariables>;
export const EnvironmentByIdDocument = gql`
    query EnvironmentById($id: Int!) {
  environment(id: $id) {
    id
    name
    track
    spec
    owner
    temperature
    pressure
    humidity
    wind_speed
    wind_direction
    traffic_wind
    traffic_wind_angle
    elevation
    use_lap_wind_data
    historical_time
    historical_weather
    wind_scalar
    latitude
    longitude
    lap_wind_data {
      id
      name
      desc
      status
      owner
      data
    }
    created_at
    updated_at
  }
}
    `;

/**
 * __useEnvironmentByIdQuery__
 *
 * To run a query within a React component, call `useEnvironmentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnvironmentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnvironmentByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnvironmentByIdQuery(baseOptions: Apollo.QueryHookOptions<EnvironmentByIdQuery, EnvironmentByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EnvironmentByIdQuery, EnvironmentByIdQueryVariables>(EnvironmentByIdDocument, options);
      }
export function useEnvironmentByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EnvironmentByIdQuery, EnvironmentByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EnvironmentByIdQuery, EnvironmentByIdQueryVariables>(EnvironmentByIdDocument, options);
        }
export type EnvironmentByIdQueryHookResult = ReturnType<typeof useEnvironmentByIdQuery>;
export type EnvironmentByIdLazyQueryHookResult = ReturnType<typeof useEnvironmentByIdLazyQuery>;
export type EnvironmentByIdQueryResult = Apollo.QueryResult<EnvironmentByIdQuery, EnvironmentByIdQueryVariables>;
export const GetWeatherDocument = gql`
    query GetWeather($latitude: Float!, $longitude: Float!, $date: String!) {
  weather(latitude: $latitude, longitude: $longitude, date: $date) {
    asOf
    temperature
    humidity
    pressure
    windSpeed
    windDirection
    source
  }
}
    `;

/**
 * __useGetWeatherQuery__
 *
 * To run a query within a React component, call `useGetWeatherQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWeatherQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWeatherQuery({
 *   variables: {
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetWeatherQuery(baseOptions: Apollo.QueryHookOptions<GetWeatherQuery, GetWeatherQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWeatherQuery, GetWeatherQueryVariables>(GetWeatherDocument, options);
      }
export function useGetWeatherLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWeatherQuery, GetWeatherQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWeatherQuery, GetWeatherQueryVariables>(GetWeatherDocument, options);
        }
export type GetWeatherQueryHookResult = ReturnType<typeof useGetWeatherQuery>;
export type GetWeatherLazyQueryHookResult = ReturnType<typeof useGetWeatherLazyQuery>;
export type GetWeatherQueryResult = Apollo.QueryResult<GetWeatherQuery, GetWeatherQueryVariables>;
export const GetTrackImageDocument = gql`
    query GetTrackImage($short_name: String!) {
  trackImage(short_name: $short_name) {
    svg_url
  }
}
    `;

/**
 * __useGetTrackImageQuery__
 *
 * To run a query within a React component, call `useGetTrackImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrackImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrackImageQuery({
 *   variables: {
 *      short_name: // value for 'short_name'
 *   },
 * });
 */
export function useGetTrackImageQuery(baseOptions: Apollo.QueryHookOptions<GetTrackImageQuery, GetTrackImageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTrackImageQuery, GetTrackImageQueryVariables>(GetTrackImageDocument, options);
      }
export function useGetTrackImageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTrackImageQuery, GetTrackImageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTrackImageQuery, GetTrackImageQueryVariables>(GetTrackImageDocument, options);
        }
export type GetTrackImageQueryHookResult = ReturnType<typeof useGetTrackImageQuery>;
export type GetTrackImageLazyQueryHookResult = ReturnType<typeof useGetTrackImageLazyQuery>;
export type GetTrackImageQueryResult = Apollo.QueryResult<GetTrackImageQuery, GetTrackImageQueryVariables>;
export const TracksDocument = gql`
    query Tracks {
  tracks {
    id
    long_name
    timezone_id
    city
    state
    created_at
    updated_at
    short_name
    mini_name
    latitude
    longitude
    elevation
  }
}
    `;

/**
 * __useTracksQuery__
 *
 * To run a query within a React component, call `useTracksQuery` and pass it any options that fit your needs.
 * When your component renders, `useTracksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTracksQuery({
 *   variables: {
 *   },
 * });
 */
export function useTracksQuery(baseOptions?: Apollo.QueryHookOptions<TracksQuery, TracksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TracksQuery, TracksQueryVariables>(TracksDocument, options);
      }
export function useTracksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TracksQuery, TracksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TracksQuery, TracksQueryVariables>(TracksDocument, options);
        }
export type TracksQueryHookResult = ReturnType<typeof useTracksQuery>;
export type TracksLazyQueryHookResult = ReturnType<typeof useTracksLazyQuery>;
export type TracksQueryResult = Apollo.QueryResult<TracksQuery, TracksQueryVariables>;
export const LapTimeDataDocument = gql`
    query LapTimeData {
  lapTimeDatas {
    id
    name
    desc
    status
    owner
    data {
      track_filename
      brake_aggression
      throttle_aggression
      part_throttle_pct
      saturation_target_pct
      scouting_speed_pct
      dlift_dvelocity
      segments {
        label
        begin
        end
        loc
      }
    }
    created_at
    updated_at
  }
}
    `;

/**
 * __useLapTimeDataQuery__
 *
 * To run a query within a React component, call `useLapTimeDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useLapTimeDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLapTimeDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useLapTimeDataQuery(baseOptions?: Apollo.QueryHookOptions<LapTimeDataQuery, LapTimeDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LapTimeDataQuery, LapTimeDataQueryVariables>(LapTimeDataDocument, options);
      }
export function useLapTimeDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LapTimeDataQuery, LapTimeDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LapTimeDataQuery, LapTimeDataQueryVariables>(LapTimeDataDocument, options);
        }
export type LapTimeDataQueryHookResult = ReturnType<typeof useLapTimeDataQuery>;
export type LapTimeDataLazyQueryHookResult = ReturnType<typeof useLapTimeDataLazyQuery>;
export type LapTimeDataQueryResult = Apollo.QueryResult<LapTimeDataQuery, LapTimeDataQueryVariables>;
export const LapTimeDataByIdDocument = gql`
    query LapTimeDataById($id: Int!) {
  lapTimeData(id: $id) {
    id
    name
    desc
    status
    owner
    data {
      brake_aggression
      bypass_svm
      corner_spd_mode
      dlift_dvelocity
      part_throttle_pct
      path_mu
      path_v
      path_velocity
      saturation
      saturation_target_pct
      scouting_speed_pct
      scouting_target
      start_position
      start_speed
      throttle_aggression
      track_filename
      traffic_wind_scaling
      segments {
        label
        begin
        end
        loc
      }
    }
  }
}
    `;

/**
 * __useLapTimeDataByIdQuery__
 *
 * To run a query within a React component, call `useLapTimeDataByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLapTimeDataByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLapTimeDataByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLapTimeDataByIdQuery(baseOptions: Apollo.QueryHookOptions<LapTimeDataByIdQuery, LapTimeDataByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LapTimeDataByIdQuery, LapTimeDataByIdQueryVariables>(LapTimeDataByIdDocument, options);
      }
export function useLapTimeDataByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LapTimeDataByIdQuery, LapTimeDataByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LapTimeDataByIdQuery, LapTimeDataByIdQueryVariables>(LapTimeDataByIdDocument, options);
        }
export type LapTimeDataByIdQueryHookResult = ReturnType<typeof useLapTimeDataByIdQuery>;
export type LapTimeDataByIdLazyQueryHookResult = ReturnType<typeof useLapTimeDataByIdLazyQuery>;
export type LapTimeDataByIdQueryResult = Apollo.QueryResult<LapTimeDataByIdQuery, LapTimeDataByIdQueryVariables>;
export const SimStatesDocument = gql`
    query SimStates {
  simStates {
    id
    name
    desc
    status
    owner
    track
    created_at
    updated_at
    states {
      id
      label
      tire
      engine
      fuel
      brake
    }
  }
}
    `;

/**
 * __useSimStatesQuery__
 *
 * To run a query within a React component, call `useSimStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimStatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSimStatesQuery(baseOptions?: Apollo.QueryHookOptions<SimStatesQuery, SimStatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SimStatesQuery, SimStatesQueryVariables>(SimStatesDocument, options);
      }
export function useSimStatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SimStatesQuery, SimStatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SimStatesQuery, SimStatesQueryVariables>(SimStatesDocument, options);
        }
export type SimStatesQueryHookResult = ReturnType<typeof useSimStatesQuery>;
export type SimStatesLazyQueryHookResult = ReturnType<typeof useSimStatesLazyQuery>;
export type SimStatesQueryResult = Apollo.QueryResult<SimStatesQuery, SimStatesQueryVariables>;
export const SimStatesByIdDocument = gql`
    query SimStatesById($id: Int!) {
  simState(id: $id) {
    id
    name
    desc
    status
    owner
    track
    created_at
    updated_at
    states {
      label
      tire
      fuel
      engine
      brake
    }
  }
}
    `;

/**
 * __useSimStatesByIdQuery__
 *
 * To run a query within a React component, call `useSimStatesByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimStatesByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimStatesByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSimStatesByIdQuery(baseOptions: Apollo.QueryHookOptions<SimStatesByIdQuery, SimStatesByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SimStatesByIdQuery, SimStatesByIdQueryVariables>(SimStatesByIdDocument, options);
      }
export function useSimStatesByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SimStatesByIdQuery, SimStatesByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SimStatesByIdQuery, SimStatesByIdQueryVariables>(SimStatesByIdDocument, options);
        }
export type SimStatesByIdQueryHookResult = ReturnType<typeof useSimStatesByIdQuery>;
export type SimStatesByIdLazyQueryHookResult = ReturnType<typeof useSimStatesByIdLazyQuery>;
export type SimStatesByIdQueryResult = Apollo.QueryResult<SimStatesByIdQuery, SimStatesByIdQueryVariables>;
export const DriversDocument = gql`
    query Drivers {
  drivers {
    id
    name
    desc
    track
    status
    owner
    data
    created_at
    updated_at
  }
}
    `;

/**
 * __useDriversQuery__
 *
 * To run a query within a React component, call `useDriversQuery` and pass it any options that fit your needs.
 * When your component renders, `useDriversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDriversQuery({
 *   variables: {
 *   },
 * });
 */
export function useDriversQuery(baseOptions?: Apollo.QueryHookOptions<DriversQuery, DriversQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DriversQuery, DriversQueryVariables>(DriversDocument, options);
      }
export function useDriversLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DriversQuery, DriversQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DriversQuery, DriversQueryVariables>(DriversDocument, options);
        }
export type DriversQueryHookResult = ReturnType<typeof useDriversQuery>;
export type DriversLazyQueryHookResult = ReturnType<typeof useDriversLazyQuery>;
export type DriversQueryResult = Apollo.QueryResult<DriversQuery, DriversQueryVariables>;
export const DriverByIdDocument = gql`
    query DriverById($id: Int!) {
  driver(id: $id) {
    id
    name
    desc
    track
    status
    owner
    data
    created_at
    updated_at
  }
}
    `;

/**
 * __useDriverByIdQuery__
 *
 * To run a query within a React component, call `useDriverByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDriverByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDriverByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDriverByIdQuery(baseOptions: Apollo.QueryHookOptions<DriverByIdQuery, DriverByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DriverByIdQuery, DriverByIdQueryVariables>(DriverByIdDocument, options);
      }
export function useDriverByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DriverByIdQuery, DriverByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DriverByIdQuery, DriverByIdQueryVariables>(DriverByIdDocument, options);
        }
export type DriverByIdQueryHookResult = ReturnType<typeof useDriverByIdQuery>;
export type DriverByIdLazyQueryHookResult = ReturnType<typeof useDriverByIdLazyQuery>;
export type DriverByIdQueryResult = Apollo.QueryResult<DriverByIdQuery, DriverByIdQueryVariables>;
export const EightPostsDocument = gql`
    query EightPosts {
  eightPosts {
    id
    name
    desc
    venue
    status
    owner
    data {
      segments
      laptime
    }
    created_at
    updated_at
  }
}
    `;

/**
 * __useEightPostsQuery__
 *
 * To run a query within a React component, call `useEightPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEightPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEightPostsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEightPostsQuery(baseOptions?: Apollo.QueryHookOptions<EightPostsQuery, EightPostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EightPostsQuery, EightPostsQueryVariables>(EightPostsDocument, options);
      }
export function useEightPostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EightPostsQuery, EightPostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EightPostsQuery, EightPostsQueryVariables>(EightPostsDocument, options);
        }
export type EightPostsQueryHookResult = ReturnType<typeof useEightPostsQuery>;
export type EightPostsLazyQueryHookResult = ReturnType<typeof useEightPostsLazyQuery>;
export type EightPostsQueryResult = Apollo.QueryResult<EightPostsQuery, EightPostsQueryVariables>;
export const EightPostByIdDocument = gql`
    query EightPostById($id: Int!) {
  eightPost(id: $id) {
    id
    name
    desc
    venue
    status
    owner
    data {
      segments
      laptime
      venue
      signalsArray
      trim_start
    }
    created_at
    updated_at
  }
}
    `;

/**
 * __useEightPostByIdQuery__
 *
 * To run a query within a React component, call `useEightPostByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useEightPostByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEightPostByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEightPostByIdQuery(baseOptions: Apollo.QueryHookOptions<EightPostByIdQuery, EightPostByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EightPostByIdQuery, EightPostByIdQueryVariables>(EightPostByIdDocument, options);
      }
export function useEightPostByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EightPostByIdQuery, EightPostByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EightPostByIdQuery, EightPostByIdQueryVariables>(EightPostByIdDocument, options);
        }
export type EightPostByIdQueryHookResult = ReturnType<typeof useEightPostByIdQuery>;
export type EightPostByIdLazyQueryHookResult = ReturnType<typeof useEightPostByIdLazyQuery>;
export type EightPostByIdQueryResult = Apollo.QueryResult<EightPostByIdQuery, EightPostByIdQueryVariables>;
export const SimProgressesByUserDocument = gql`
    query SimProgressesByUser {
  simProgressesByUser {
    isBatch
    state
    status
    cancelId
    groupId
    requestId
    userId
    simEngine
    setupId
    applyOptimizationResult
    vehicle
    type
    displayName
    series
    submitted
    started
    finished
    curr
    total
    percentage
  }
}
    `;

/**
 * __useSimProgressesByUserQuery__
 *
 * To run a query within a React component, call `useSimProgressesByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimProgressesByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimProgressesByUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useSimProgressesByUserQuery(baseOptions?: Apollo.QueryHookOptions<SimProgressesByUserQuery, SimProgressesByUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SimProgressesByUserQuery, SimProgressesByUserQueryVariables>(SimProgressesByUserDocument, options);
      }
export function useSimProgressesByUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SimProgressesByUserQuery, SimProgressesByUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SimProgressesByUserQuery, SimProgressesByUserQueryVariables>(SimProgressesByUserDocument, options);
        }
export type SimProgressesByUserQueryHookResult = ReturnType<typeof useSimProgressesByUserQuery>;
export type SimProgressesByUserLazyQueryHookResult = ReturnType<typeof useSimProgressesByUserLazyQuery>;
export type SimProgressesByUserQueryResult = Apollo.QueryResult<SimProgressesByUserQuery, SimProgressesByUserQueryVariables>;
export const SimStatusesByUserDocument = gql`
    query SimStatusesByUser {
  simStatusesByUser {
    isBatch
    state
    cancelId
    groupId
    requestId
    userId
    simEngine
    setupId
    applyOptimizationResult
    vehicle
    type
    displayName
    series
    submitted
    started
    finished
    curr
    total
    percentage
  }
}
    `;

/**
 * __useSimStatusesByUserQuery__
 *
 * To run a query within a React component, call `useSimStatusesByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimStatusesByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimStatusesByUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useSimStatusesByUserQuery(baseOptions?: Apollo.QueryHookOptions<SimStatusesByUserQuery, SimStatusesByUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SimStatusesByUserQuery, SimStatusesByUserQueryVariables>(SimStatusesByUserDocument, options);
      }
export function useSimStatusesByUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SimStatusesByUserQuery, SimStatusesByUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SimStatusesByUserQuery, SimStatusesByUserQueryVariables>(SimStatusesByUserDocument, options);
        }
export type SimStatusesByUserQueryHookResult = ReturnType<typeof useSimStatusesByUserQuery>;
export type SimStatusesByUserLazyQueryHookResult = ReturnType<typeof useSimStatusesByUserLazyQuery>;
export type SimStatusesByUserQueryResult = Apollo.QueryResult<SimStatusesByUserQuery, SimStatusesByUserQueryVariables>;
export const SweepByIdDocument = gql`
    query SweepById($id: Int!) {
  sweep(id: $id) {
    id
    name
    description
    owner
    method
    sweep_data
    num_run
    sweep_groups {
      enabled
      name
      parameters {
        ...sweepParameterFields
      }
      parts {
        ...sweepPartFields
      }
    }
    parameters {
      ...sweepParameterFields
    }
    parts {
      ...sweepPartFields
    }
  }
}
    ${sweepParameterFieldsFragmentDoc}
${sweepPartFieldsFragmentDoc}`;

/**
 * __useSweepByIdQuery__
 *
 * To run a query within a React component, call `useSweepByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSweepByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSweepByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSweepByIdQuery(baseOptions: Apollo.QueryHookOptions<SweepByIdQuery, SweepByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SweepByIdQuery, SweepByIdQueryVariables>(SweepByIdDocument, options);
      }
export function useSweepByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SweepByIdQuery, SweepByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SweepByIdQuery, SweepByIdQueryVariables>(SweepByIdDocument, options);
        }
export type SweepByIdQueryHookResult = ReturnType<typeof useSweepByIdQuery>;
export type SweepByIdLazyQueryHookResult = ReturnType<typeof useSweepByIdLazyQuery>;
export type SweepByIdQueryResult = Apollo.QueryResult<SweepByIdQuery, SweepByIdQueryVariables>;
export const SweepsDocument = gql`
    query Sweeps($input: SweepsQueryInput) {
  sweeps(input: $input) {
    totalCount
    rows {
      id
      name
      description
      owner
      method
    }
  }
}
    `;

/**
 * __useSweepsQuery__
 *
 * To run a query within a React component, call `useSweepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSweepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSweepsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSweepsQuery(baseOptions?: Apollo.QueryHookOptions<SweepsQuery, SweepsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SweepsQuery, SweepsQueryVariables>(SweepsDocument, options);
      }
export function useSweepsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SweepsQuery, SweepsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SweepsQuery, SweepsQueryVariables>(SweepsDocument, options);
        }
export type SweepsQueryHookResult = ReturnType<typeof useSweepsQuery>;
export type SweepsLazyQueryHookResult = ReturnType<typeof useSweepsLazyQuery>;
export type SweepsQueryResult = Apollo.QueryResult<SweepsQuery, SweepsQueryVariables>;
export const EventsDocument = gql`
    query Events($year: Int!, $series: String!) {
  events(year: $year, series: $series) {
    event_id
    name
  }
}
    `;

/**
 * __useEventsQuery__
 *
 * To run a query within a React component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsQuery({
 *   variables: {
 *      year: // value for 'year'
 *      series: // value for 'series'
 *   },
 * });
 */
export function useEventsQuery(baseOptions: Apollo.QueryHookOptions<EventsQuery, EventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
      }
export function useEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventsQuery, EventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
        }
export type EventsQueryHookResult = ReturnType<typeof useEventsQuery>;
export type EventsLazyQueryHookResult = ReturnType<typeof useEventsLazyQuery>;
export type EventsQueryResult = Apollo.QueryResult<EventsQuery, EventsQueryVariables>;
export const SessionsByEventDocument = gql`
    query SessionsByEvent($year: Int!, $series: String!, $name: String!) {
  sessionsByEvent(year: $year, series: $series, name: $name) {
    id
    name
  }
}
    `;

/**
 * __useSessionsByEventQuery__
 *
 * To run a query within a React component, call `useSessionsByEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionsByEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionsByEventQuery({
 *   variables: {
 *      year: // value for 'year'
 *      series: // value for 'series'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useSessionsByEventQuery(baseOptions: Apollo.QueryHookOptions<SessionsByEventQuery, SessionsByEventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SessionsByEventQuery, SessionsByEventQueryVariables>(SessionsByEventDocument, options);
      }
export function useSessionsByEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SessionsByEventQuery, SessionsByEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SessionsByEventQuery, SessionsByEventQueryVariables>(SessionsByEventDocument, options);
        }
export type SessionsByEventQueryHookResult = ReturnType<typeof useSessionsByEventQuery>;
export type SessionsByEventLazyQueryHookResult = ReturnType<typeof useSessionsByEventLazyQuery>;
export type SessionsByEventQueryResult = Apollo.QueryResult<SessionsByEventQuery, SessionsByEventQueryVariables>;
export const GetSimStatusDocument = gql`
    subscription GetSimStatus {
  simStatus
}
    `;

/**
 * __useGetSimStatusSubscription__
 *
 * To run a query within a React component, call `useGetSimStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetSimStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSimStatusSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetSimStatusSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetSimStatusSubscription, GetSimStatusSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetSimStatusSubscription, GetSimStatusSubscriptionVariables>(GetSimStatusDocument, options);
      }
export type GetSimStatusSubscriptionHookResult = ReturnType<typeof useGetSimStatusSubscription>;
export type GetSimStatusSubscriptionResult = Apollo.SubscriptionResult<GetSimStatusSubscription>;
export const GetSimResultDocument = gql`
    subscription GetSimResult {
  simResult
}
    `;

/**
 * __useGetSimResultSubscription__
 *
 * To run a query within a React component, call `useGetSimResultSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetSimResultSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSimResultSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetSimResultSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetSimResultSubscription, GetSimResultSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetSimResultSubscription, GetSimResultSubscriptionVariables>(GetSimResultDocument, options);
      }
export type GetSimResultSubscriptionHookResult = ReturnType<typeof useGetSimResultSubscription>;
export type GetSimResultSubscriptionResult = Apollo.SubscriptionResult<GetSimResultSubscription>;
export const GetSimProgressDocument = gql`
    subscription GetSimProgress {
  simProgress
}
    `;

/**
 * __useGetSimProgressSubscription__
 *
 * To run a query within a React component, call `useGetSimProgressSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetSimProgressSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSimProgressSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetSimProgressSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetSimProgressSubscription, GetSimProgressSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetSimProgressSubscription, GetSimProgressSubscriptionVariables>(GetSimProgressDocument, options);
      }
export type GetSimProgressSubscriptionHookResult = ReturnType<typeof useGetSimProgressSubscription>;
export type GetSimProgressSubscriptionResult = Apollo.SubscriptionResult<GetSimProgressSubscription>;
export const GetBatchStatusDocument = gql`
    subscription GetBatchStatus {
  batchStatus
}
    `;

/**
 * __useGetBatchStatusSubscription__
 *
 * To run a query within a React component, call `useGetBatchStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetBatchStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBatchStatusSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetBatchStatusSubscription(baseOptions?: Apollo.SubscriptionHookOptions<GetBatchStatusSubscription, GetBatchStatusSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetBatchStatusSubscription, GetBatchStatusSubscriptionVariables>(GetBatchStatusDocument, options);
      }
export type GetBatchStatusSubscriptionHookResult = ReturnType<typeof useGetBatchStatusSubscription>;
export type GetBatchStatusSubscriptionResult = Apollo.SubscriptionResult<GetBatchStatusSubscription>;