import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { H3, Intent, Dialog, DialogBody, DialogFooter, Button, InputGroup } from '@blueprintjs/core';
import classNames from 'classnames';
import { format } from 'date-fns';
import _ from 'lodash';

import { SPECS, specSelectItems } from '../../constants';
import Table, { BulkRowActions, ParamsChangeFn, RowActions } from 'components/Table';
import {
  SUIT,
  useBulkDeleteSUITsMutation,
  useDeleteSUITMutation,
  useSUITManagementTableLazyQuery,
  useCloneSUITMutation,
  useCreateSUITMutation,
  SUITsDocument,
  CreateSUITInput,
} from 'graphql/generated/graphql';
import AppToaster from 'helpers/toaster';
import { FilterType } from 'types';
import { useAlert } from 'components/Alert';
import { selectDarkMode } from 'reducers/ui';

import styles from './index.module.css';
import CreateSUITDialog from './createSUITDialog';

const columnHelper = createColumnHelper<SUIT>();
const columns = [
  columnHelper.accessor('spec', {
    header: 'Spec',
    cell: info => SPECS[info.getValue()],
    enableColumnFilter: true,
    meta: {
      filter: {
        type: FilterType.SELECT,
        selectItems: specSelectItems,
        multiSelect: true,
      },
    },
    size: 125,
  }),
  columnHelper.accessor('name', {
    header: 'Name',
    cell: info => info.getValue(),
    enableColumnFilter: true,
  }),
  columnHelper.accessor('description', {
    header: 'Description',
    cell: info => info.getValue(),
    enableColumnFilter: true,
  }),
  columnHelper.accessor('created_at', {
    header: 'Created',
    cell: info => {
      const value = info.getValue() as string;
      return format(new Date(value), 'MM/dd/yy HH:mm:ss');
    },
  }),
  columnHelper.accessor('updated_at', {
    header: 'Modified',
    cell: info => {
      const value = info.getValue() as string;
      return format(new Date(value), 'MM/dd/yy HH:mm:ss');
    },
  }),
  columnHelper.accessor('last_modified_by', {
    header: 'Last Modified By',
    cell: info => info.getValue(),
    enableColumnFilter: true,
  }),
] as ColumnDef<SUIT>[];

export default () => {
  const [tableData, setTableData] = useState<SUIT[]>([]);
  const [isCloneModalOpen, setCloneModalOpen] = useState(false);
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const cloneName = useRef<HTMLInputElement>(null);
  const [cloneSource, setCloneSource] = useState<SUIT>();
  const navigate = useNavigate();
  const alert = useAlert();
  const darkMode = useSelector(selectDarkMode);

  const [getSUITs, { data, refetch }] = useSUITManagementTableLazyQuery({
    fetchPolicy: 'cache-and-network',
  });
  useEffect(() => {
    if (data) setTableData(data.suits.rows);
  }, [data]);

  const [deleteSUIT] = useDeleteSUITMutation();
  const [bulkDeleteSUITs] = useBulkDeleteSUITsMutation();
  const [cloneSUIT] = useCloneSUITMutation();
  const [createSUIT] = useCreateSUITMutation();

  const bulkRowActions: BulkRowActions<SUIT> = [{
    intent: Intent.DANGER,
    label: 'Delete',
    value: rows => {
      const content = (
        <>
          <p>Delete these?</p>
          <ul>
            {rows.map(r => <li>{r.original.name}</li>)}
          </ul>
        </>
      );
      alert.showAlert(content, {
        intent: Intent.DANGER,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
      }).then((yes) => {
        if (!yes) return;

        bulkDeleteSUITs({
          variables: { ids: rows.map(r => r.original.id) },
          onCompleted: () => {
            AppToaster.show({
              intent: Intent.SUCCESS,
              message: 'SUIT(s) successfully deleted',
            });
            rows.forEach(r => r.toggleSelected());
            refetch();
          },
          onError: e => {
            AppToaster.show({
              intent: Intent.DANGER,
              message: `Error deleting SUIT(s): ${e.message}`,
            });
          },
          update: (cache, { data: mutationData }) => {
            if (mutationData?.deleteCount) {
              // Evicts all references to the deleted objects from the cache
              const ids = rows.map(r => cache.identify(r.original));
              ids.forEach(id => cache.evict({ id }));
              cache.gc();
            }
          },
        });
      });
    },
  }];
  const rowActions: RowActions<SUIT> = [{
    label: 'Edit',
    value: row => navigate(`/setups/suit/${row.original.id}`),
  }, {
    label: 'Clone',
    value: row => {
      setCloneSource(row.original);
      setCloneModalOpen(true);
      cloneName.current?.focus();
    },
  }, {
    intent: Intent.DANGER,
    label: 'Delete',
    value: row => {
      alert.showAlert(`Delete "${row.original.name}"?`, {
        intent: Intent.DANGER,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
      }).then((yes) => {
        if (!yes) return;

        deleteSUIT({
          variables: { id: row.original.id },
          onCompleted: () => {
            AppToaster.show({
              intent: Intent.SUCCESS,
              message: 'SUIT successfully deleted',
            });
            if (row.getIsSelected()) row.toggleSelected();
            refetch();
          },
          onError: e => {
            AppToaster.show({
              intent: Intent.DANGER,
              message: `Error deleting SUIT: ${e.message}`,
            });
          },
          update: (cache, { data: mutationData }) => {
            if (mutationData?.deleteCount) {
              // Evicts all references to the deleted object from the cache
              cache.evict({ id: cache.identify(row.original) });
              cache.gc();
            }
          },
        });
      });
    },
  }];

  const onTableParamsChange: ParamsChangeFn = async (filters, pagination, sorting) => {
    let sorts = {};
    if (sorting.length > 0) {
      sorts = { [sorting[0].id]: sorting[0].desc ? 'DESC' : 'ASC' };
    }

    getSUITs({
      variables: {
        input: {
          filters: _.mapValues(_.keyBy(filters, 'id'), 'value'),
          pagination: {
            offset: pagination.pageIndex * pagination.pageSize,
            limit: pagination.pageSize,
          },
          sorts,
        },
      },
    });
  };

  const createSuit = (input: CreateSUITInput) => {
    createSUIT({
      variables: { input },
      onCompleted: data => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'SUIT successfully created',
        });
        navigate(`/setups/suit/${data.suit?.id}`);
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Error deleting SUIT: ${e.message}`,
        });
      },
    });
  };

  const handleClone = () => {
    if (!cloneSource) return;
    cloneSUIT({
      variables: {
        id: cloneSource.id,
        name: cloneName.current?.value || `${cloneSource.name}@${Date.now()}`,
      },
      onCompleted: () => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Successfully cloned SUIT',
        });
        refetch();
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Failed to clone SUIT: ${e.message}`,
        });
      },
      update: (cache, { data: mutationData }) => {
        if (mutationData?.suit) {
          cache.updateQuery({
            query: SUITsDocument,
          }, queryData => {
            if (!queryData) return undefined;
            return {
              suits: {
                rows: [
                  mutationData.suit,
                  ...queryData.suits.rows,
                ],
              },
            };
          });
        }
      },
    });
    setCloneModalOpen(false);
  };

  return (
    <>
      <div className={styles.actionsHeader}>
        <H3>SUITs</H3>
        <Button
          icon="plus"
          intent={Intent.PRIMARY}
          onClick={() => setCreateModalOpen(true)}
          text="Create SUIT"
        />
      </div>
      <Table
        id="suit-summary"
        bulkRowActions={bulkRowActions}
        columns={columns}
        data={tableData}
        persistColumnVisibility
        enableHiding
        enablePagination
        enableRowSelection
        manualFiltering
        manualPagination
        manualSorting
        onParamsChange={onTableParamsChange}
        rowActions={rowActions}
        totalRowCount={data?.suits.totalCount}
      />
      <Dialog
        className={classNames({ 'bp4-dark': darkMode })}
        isOpen={isCloneModalOpen}
        onClose={() => setCloneModalOpen(false)}
        title={`Cloning from "${cloneSource?.name}"`}
      >
        <DialogBody>
          <InputGroup
            placeholder="Enter new SUIT name"
            inputRef={cloneName}
            defaultValue={`${cloneSource?.name} CLONE`}
          />
        </DialogBody>
        <DialogFooter
          actions={(
            <>
              <Button
                text="Cancel"
                onClick={() => setCloneModalOpen(false)}
              />
              <Button
                intent="primary"
                text="Clone"
                onClick={() => handleClone()}
              />
            </>
          )}
        />
      </Dialog>
      <CreateSUITDialog
        isOpen={isCreateModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onOk={createSuit}
      />
    </>
  );
};
