import { Switch } from '@blueprintjs/core';
import { faLightbulb } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';

import { selectDarkMode, uiSlice } from 'reducers/ui';

import styles from './index.module.css';

export default () => {
  const dispatch = useDispatch();
  const darkMode = useSelector(selectDarkMode);

  return (
    <ul className={styles.settingsList}>
      <li className={styles.settingsListItem}>
        <div>
          <FontAwesomeIcon
            className={styles.settingsIcon}
            icon={faLightbulb}
          />
          <span>Dark Mode</span>
        </div>
        <Switch
          checked={darkMode}
          onChange={() => dispatch(uiSlice.actions.toggleDarkMode())}
        />
      </li>
    </ul>
  );
};
