import { PartProperty } from 'graphql/generated/graphql';
import { isNil, omit } from 'lodash';

export const defaultPart = {
  part_config_id: undefined,
  spec: undefined,
  description: '',
  part_number: '',
  serial_number: '',
  exp_date: undefined,
  mileage: undefined,
  data: {},
  properties: [],
};

export const transformPartPropertyValues = (properties: PartProperty[] = []) => {
  return properties.map(p => ({
    ...omit(p, 'config_property'),
    value: !isNil(p.value) ? JSON.stringify(p.value) : undefined,
  }));
};

export const loadPartPropertyValues = (properties: PartProperty[] = []) => {
  return properties.map(p => ({
    ...p,
    value: !isNil(p.value) ? JSON.parse(p.value) : undefined,
  }));
};
