import { H3, Intent, Button } from '@blueprintjs/core';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import _ from 'lodash';

import { useAlert } from 'components/Alert';
import Table, { BulkRowActions, ParamsChangeFn, RowActions } from 'components/Table';
import {
  useSimWorksheetSummaryLazyQuery,
  useBulkDeleteSimWorksheetsMutation,
  useCloneSimWorksheetMutation,
  useDeleteSimWorksheetMutation,
  useCreateSimWorksheetMutation,
} from 'graphql/generated/graphql';
import { GQLSimWorksheet } from 'types';
import AppToaster from 'helpers/toaster';
import SimpleInputDialog from 'pages/SimWorksheet/SimpleInputDialog';
import styles from './index.module.css';
import AddSimWorksheetDialog from './addSimWorksheetDialog';
import { SimWorksheetToSimWorksheetInput } from 'helpers/converter';

const columnHelper = createColumnHelper<GQLSimWorksheet>();
const columns = [
  columnHelper.accessor('name', {
    header: 'Name',
    cell: info => info.getValue(),
    enableColumnFilter: true,
  }),
  columnHelper.accessor('year', {
    header: 'Year',
    cell: info => info.getValue(),
    enableColumnFilter: true,
    size: 100,
  }),
  columnHelper.accessor('track', {
    header: 'Track',
    cell: info => info.getValue(),
    enableColumnFilter: true,
  }),
  columnHelper.accessor('event', {
    header: 'Event',
    cell: info => info.getValue(),
    enableColumnFilter: true,
    size: 100,
  }),
  columnHelper.accessor('session', {
    header: 'Session',
    cell: info => info.getValue(),
    enableColumnFilter: true,
    size: 100,
  }),
  columnHelper.accessor('car', {
    header: 'Car',
    cell: info => info.getValue(),
    enableColumnFilter: true,
    size: 100,
  }),
  columnHelper.accessor('description', {
    header: 'Description',
    cell: info => info.getValue(),
    enableColumnFilter: true,
    enableSorting: true,
  }),
  columnHelper.accessor('owner', {
    header: 'Owner',
    cell: info => info.getValue(),
    enableColumnFilter: true,
  }),
  columnHelper.accessor('created_at', {
    header: 'Created',
    cell: info => {
      const value = info.getValue() as string;
      return format(new Date(value), 'MM/dd/yy HH:mm:ss');
    },
  }),
  columnHelper.accessor('updated_at', {
    header: 'Modified',
    cell: info => {
      const value = info.getValue() as string;
      return format(new Date(value), 'MM/dd/yy HH:mm:ss');
    },
  }),
] as ColumnDef<GQLSimWorksheet>[];

export default () => {
  const [isCloneDialogOpen, setCloneDialogOpen] = useState(false);
  const [isAddSimWorksheetOpen, setAddSimWorksheetOpen] = useState(false);
  const [currentWorksheet, setCurrentWorksheet] = useState<GQLSimWorksheet>();
  const [tableData, setTableData] = useState<GQLSimWorksheet[]>([]);
  const [getSimWorksheets, { data, refetch }] = useSimWorksheetSummaryLazyQuery();
  const [deleteWorksheet] = useDeleteSimWorksheetMutation();
  const [cloneWorksheet] = useCloneSimWorksheetMutation();
  const [bulkDeleteSimWorksheets] = useBulkDeleteSimWorksheetsMutation();
  const [createSimWorksheet] = useCreateSimWorksheetMutation();
  const alert = useAlert();
  const navigate = useNavigate();

  useEffect(() => {
    if (data) setTableData(data.simWorksheets.rows as GQLSimWorksheet[]);
  }, [data]);

  const bulkRowActions: BulkRowActions<GQLSimWorksheet> = [{
    intent: Intent.DANGER,
    label: 'Delete',
    value: rows => {
      const content = (
        <>
          <p>Delete these?</p>
          <ul>
            {rows.map(r => <li>{r.original.name}</li>)}
          </ul>
        </>
      );
      alert.showAlert(content, {
        intent: Intent.DANGER,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
      }).then((yes) => {
        if (!yes) return;
        bulkDeleteSimWorksheets({
          variables: { ids: rows.map(r => r.original.id) },
          onCompleted: () => {
            AppToaster.show({
              intent: Intent.SUCCESS,
              message: 'Sim worksheet(s) successfully deleted',
            });
            rows.forEach(r => r.toggleSelected());
            refetch();
          },
          onError: e => {
            AppToaster.show({
              intent: Intent.DANGER,
              message: `Error deleting sim worksheet(s): ${e.message}`,
            });
          },
        });
      });
    },
  }];
  const rowActions: RowActions<GQLSimWorksheet> = [{
    label: 'Edit',
    value: row => {
      navigate(`/sim-worksheets/${row.original.id}`);
    },
  }, {
    label: 'Clone',
    value: row => {
      setCurrentWorksheet(row.original);
      setCloneDialogOpen(true);
    },
  }, {
    intent: Intent.DANGER,
    label: 'Delete',
    value: row => {
      alert.showAlert(`Delete "${row.original.name}"?`, {
        intent: Intent.DANGER,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
      }).then((yes) => {
        if (!yes) return;
        deleteWorksheet({
          variables: { id: row.original.id },
          onCompleted: () => {
            AppToaster.show({
              intent: Intent.SUCCESS,
              message: 'Sim worksheet successfully deleted',
            });
            if (row.getIsSelected()) row.toggleSelected();
            refetch();
          },
          onError: e => {
            AppToaster.show({
              intent: Intent.DANGER,
              message: `Error deleting sim worksheet: ${e.message}`,
            });
          },
        });
      });
    },
  }];

  const onTableParamsChange: ParamsChangeFn = async (filters, pagination, sorting) => {
    let sorts = {};
    if (sorting.length > 0) {
      sorts = { [sorting[0].id]: sorting[0].desc ? 'DESC' : 'ASC' };
    }

    getSimWorksheets({
      variables: {
        input: {
          filters: _.mapValues(_.keyBy(filters, 'id'), 'value'),
          pagination: {
            offset: pagination.pageIndex * pagination.pageSize,
            limit: pagination.pageSize,
          },
          sorts,
        },
      },
    });
  };
  const debouncedOnTableParamsChange = _.debounce(onTableParamsChange, 200);

  const onOkCloneSimWorksheet = (text: string) => {
    setCloneDialogOpen(false);
    if (!currentWorksheet) return;
    cloneWorksheet({
      variables: {
        id: currentWorksheet.id,
        name: text || `${currentWorksheet.name}@${Date.now()}`,
      },
      onCompleted: () => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Successfully cloned sim worksheet',
        });
        refetch();
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Failed to clone sim worksheet: ${e.message}`,
        });
      },
    });
  };

  const onOkAddSimWorksheet = (input: GQLSimWorksheet) => {
    createSimWorksheet({
      variables: {
        input: SimWorksheetToSimWorksheetInput(input),
      },
      onCompleted: () => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Successfully created sim worksheet',
        });
        refetch();
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Failed to create sim worksheet: ${e.message}`,
        });
      },
    });
  };

  return (
    <>
      <div className={styles.actionsHeader}>
        <H3>Sim Worksheets</H3>
        <Button
          icon="plus"
          intent={Intent.PRIMARY}
          onClick={() => setAddSimWorksheetOpen(true)}
          text="Create Sim Worksheet"
        />
      </div>
      <Table
        bulkRowActions={bulkRowActions}
        columns={columns}
        data={tableData}
        enableHiding
        enablePagination
        enableRowSelection
        id="sim_worksheet_summary"
        manualFiltering
        manualPagination
        manualSorting
        onParamsChange={debouncedOnTableParamsChange as ParamsChangeFn}
        persistColumnVisibility
        rowActions={rowActions}
        totalRowCount={data?.simWorksheets.totalCount}
      />

      {/* -------- modals --------- */}
      <SimpleInputDialog
        isOpen={isCloneDialogOpen}
        isCloseButtonShown
        onClose={() => setCloneDialogOpen(false)}
        title="Clone sim worksheet"
        inputPlaceholder={`${currentWorksheet?.name} CLONE`}
        onOk={onOkCloneSimWorksheet}
      />

      <AddSimWorksheetDialog
        isOpen={isAddSimWorksheetOpen}
        onClose={() => setAddSimWorksheetOpen(false)}
        onOk={onOkAddSimWorksheet}
      />
    </>
  );
};
