import { CSSProperties, ReactNode, useEffect, useMemo, useState, FocusEvent } from 'react';
import { Alignment, Checkbox, InputGroup, Overlay, Spinner } from '@blueprintjs/core';
import classNames from 'classnames';
import { get, groupBy, isNil, snakeCase, startCase } from 'lodash';

import {
  SelectItem,
  SetupUITemplate,
  SetupUITemplateContainerItem,
  SetupUITemplateFieldItem,
  SetupUITemplateItem,
  SetupUITemplateItemType,
} from 'types';
import Accordion from 'components/Accordion';
import Select from 'components/Select';
import {
  Part,
  SetupField,
  SetupFieldPosition,
  SetupFieldType,
  useSUITFormPartsLazyQuery,
} from 'graphql/generated/graphql';
import { suitPathToKey } from 'helpers/suit';
import PartSelector from './PartSelector';

import styles from './index.module.css';
import IconTooltip from 'components/IconTooltip';
import { useRouteLoaderData } from 'react-router-dom';
import hasPermission from 'helpers/permissions';
import { PermissionName } from '../../constants';

export type SetupFieldHandler = (field: SetupField, value: boolean | number | string | Part) => void;

interface SetupViewProps {
  template: SetupUITemplate;
  setupFields: SetupField[];
  initialSetup?: object;
  onChange?: SetupFieldHandler;
}

const SUITFormComponent = (props: SetupViewProps) => {
  const { template, setupFields, initialSetup, onChange } = props;

  const [parts, setParts] = useState<Part[]>([]);
  const [getParts, { loading: partsLoading }] = useSUITFormPartsLazyQuery({
    onCompleted: data => setParts(data.parts.rows as Part[]),
  });

  const permissions = useRouteLoaderData('root') as string[];

  const partsByConfig = useMemo(() => {
    if (!parts) return {};
    return groupBy(parts, p => p.config.name);
  }, [parts]);

  useEffect(() => {
    const partConfigIds: number[] = [];
    const handleSetupField = (field: SetupUITemplateFieldItem) => {
      const setupField = setupFields.find(sf => sf.name === field.setup_field);
      if (setupField?.part_config) partConfigIds.push(setupField.part_config.id);
    };

    const processTemplateItem = (item: SetupUITemplateItem) => {
      if (item.type === SetupUITemplateItemType.CONTAINER) {
        item.items.forEach(processTemplateItem);
      } else {
        handleSetupField(item);
      }
    };
    template.items.forEach(processTemplateItem);

    getParts({
      variables: {
        input: {
          filters: {
            part_config: partConfigIds,
          },
        },
      },
    });
  }, [template]);

  const getFieldPath = (path: string, position?: SetupFieldPosition) => {
    if (!position) return path;
    if (!position.path_part) return `${path}_${snakeCase(position.label)}`;

    const pathParts = path.split('.');
    pathParts.splice(-1, 1, position.path_part);
    return pathParts.join('.');
  };

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => e.target.select();

  const renderSetupField = (field: SetupField, readOnly: boolean, position?: SetupFieldPosition, initialSetup?: object, onChange?: SetupFieldHandler): ReactNode => {
    let initialVal = get(initialSetup, field.path);
    if (isNil(initialVal)) initialVal = '';

    switch (field.type) {
      case SetupFieldType.PART: {
        const configParts = partsByConfig[field.part_config!.name] ?? [];  // eslint-disable-line @typescript-eslint/no-non-null-assertion
        const selectedPart = configParts?.find(p => p.id === initialVal);
        return (
          <PartSelector
            initialPart={selectedPart}
            key={field.path}
            onChange={partId => onChange?.(field, partId)}
            parts={configParts}
            position={position}
            disabled={readOnly}
          />
        );
      }
      case SetupFieldType.STRING: {
        if (field.options && field.options.length > 0) {
          const options: SelectItem<string>[] = field.options.map(o => {
            return { label: o, value: o };
          });
          const initialOption = options.find(o => o.value === initialVal);
          const noSelectionText = position ? `${position.label} Option` : 'Select Option';
          return (
            <Select
              value={initialOption}
              buttonProps={{ className: styles.selectButton, fill: true }}
              fill
              items={options}
              key={field.path}
              noSelectionText={noSelectionText}
              onChange={item => onChange?.(field, item.value)}
              disabled={readOnly}
            />
          );
        }

        return (
          <InputGroup
            fill
            key={field.path}
            placeholder={position?.label}
            onChange={e => onChange?.(field, e.target.value)}
            value={initialVal}
            onFocus={handleFocus}
            disabled={readOnly}
          />
        );
      }
      case SetupFieldType.INT: {
        if (field.options && field.options.length > 0) {
          const options: SelectItem<string>[] = field.options.map(o => {
            return { label: o, value: o };
          });
          const initialOption = options.find(o => o.value === initialVal);
          const noSelectionText = position ? `${position.label} Option` : 'Select Option';
          return (
            <Select
              value={initialOption}
              buttonProps={{ className: styles.selectButton, fill: true }}
              fill
              items={options}
              key={field.path}
              noSelectionText={noSelectionText}
              onChange={item => onChange?.(field, item.value)}
              disabled={readOnly}
            />
          );
        }
        return (
          <InputGroup
            value={initialVal}
            key={field.path}
            fill
            placeholder={position?.label}
            onChange={e => onChange?.(field, e.target.value)}
            onFocus={handleFocus}
            disabled={readOnly}
          />
        );
      }
      case SetupFieldType.FLOAT: {
        return (
          // Use an InputGroup (Text Input) rather than Numeric Input to support
          // expressions
          <InputGroup
            value={initialVal}
            key={field.path}
            fill
            placeholder={position?.label}
            onChange={e => onChange?.(field, e.target.value)}
            onFocus={handleFocus}
            disabled={readOnly}
          />
        );
      }
      case SetupFieldType.BOOLEAN: {
        return (
          <Checkbox
            alignIndicator={Alignment.RIGHT}
            className={styles.booleanInput}
            checked={initialVal}
            key={field.path}
            label={position && position.label}
            onChange={() => onChange?.(field, !initialVal)}
            disabled={readOnly}
          />
        );
      }
      default:
        return null;
    }
  };
  const renderTemplateFieldItem = (
    item: SetupUITemplateFieldItem,
    setupFields: SetupField[],
    readOnly: boolean,
    initialSetup?: object,
    onChange?: SetupFieldHandler,
  ) => {
    const field = setupFields.find(f => f.name === item.setup_field);
    if (!field) return null;

    let inputs: ReactNode[];
    let inputClass;
    let inputStyles: CSSProperties = {};
    // If this field has `positions`, generates one field for each, passing the
    // position to the field render function and modifies the label for relevant
    // display
    if (field.positions && field.positions.length > 0) {
      inputs = field.positions.map(pos => {
        const expandedField = {
          ...field,
          path: getFieldPath(field.path, pos),
        };
        if (field.label) expandedField.label += ` ${pos}`;
        return renderSetupField(expandedField, readOnly, pos, initialSetup, onChange);
      });
      inputClass = classNames({
        [styles.grid]: true,
        [styles[`positions${field.positions.length}`]]: isNil(item.num_columns),
      });

      if (!isNil(item.num_columns)) {
        inputStyles = {
          gridTemplateColumns: `repeat(${item.num_columns}, 1fr)`,
        };
      }
    } else {
      inputs = [renderSetupField(field, readOnly, undefined, initialSetup, onChange)];
    }

    return (
      <div key={field.path} className={styles.inputRow}>
        <div>
          <span className="bp4-ui-text">{item.label ?? field.label}</span>
          {field.tooltip && (
            <IconTooltip
              className={styles.tooltipIcon}
              content={field.tooltip}
            />
          )}
        </div>
        <div className={classNames(styles.inputs, inputClass)} style={inputStyles}>{inputs}</div>
      </div>
    );
  };

  const renderTemplateItem = (
    path: SetupUITemplateItem[],
    setupFields: SetupField[],
    initialSetup?: object,
    onChange?: SetupFieldHandler
  ): ReactNode => {
    const item = path[path.length - 1];
    if (item.type === SetupUITemplateItemType.CONTAINER) {
      // Filter what's viewable by permissions
      if (!hasPermission(PermissionName.SETUP_WRITE, permissions) && !hasPermission(PermissionName.SETUP_READ, permissions)) {
        if (item.name.toLowerCase() !== 'car') {
          if (!hasPermission(`setup_${item.name.toLowerCase()}_read`, permissions) && !hasPermission(`setup_${item.name.toLowerCase()}_write`, permissions)) return null;
        }
      }
      const id = suitPathToKey(path as SetupUITemplateContainerItem[]);
      return (
        <Accordion
          className={styles.itemContainer}
          id={id}
          initialOpen
          key={item.name}
          title={item.label ?? startCase(item.name)}
          buttonProps={{
            className: styles.accordionHeader,
          }}
        >
          <div className={styles.sectionContainer}>
            {item.items.map(innerItem => renderTemplateItem([...path, innerItem], setupFields, initialSetup, onChange))}
          </div>
        </Accordion>
      );
    }
    // Check permission to see if field should be read only
    const parentContainer = path[path.length - 2] as SetupUITemplateContainerItem;
    let readOnly = false;
    if (hasPermission(PermissionName.SETUP_READ, permissions)) {
      readOnly = true;
    } else {
      readOnly = hasPermission(`setup_${parentContainer.name.toLowerCase()}_read`, permissions);
    }

    return renderTemplateFieldItem(item, setupFields, readOnly, initialSetup, onChange);
  };

  return (
    <div className={styles.containerRelative}>
      {template?.items.map(item => renderTemplateItem([item], setupFields, initialSetup, onChange))}
      <div className={styles.containerAbsolute}>
        <Overlay isOpen={partsLoading} className="bp3-overlay-scroll-container">
          <div className={styles.loadingSpinner}>
            <Spinner size={50} />
          </div>
        </Overlay>
      </div>
    </div>
  );
};

export default SUITFormComponent;
