import { ReactNode, forwardRef, Ref, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, EditableText } from '@blueprintjs/core';
import classNames from 'classnames';
import { union, map } from 'lodash';

import {
  useEventsLazyQuery,
  Event,
} from 'graphql/generated/graphql';
import { GQLSetup, SetupSelection, SelectItem } from 'types';
import Select from 'components/Select';
import { specSelectItems, yearItems, seriesItems } from '../../constants';
import AddSetupsModal from '../../components/SelectorModal/setup';
import { SetupCompareSlice, selectSetupCompareState } from 'reducers/setupCompare';

import styles from './index.module.css';

interface TitleProps {
  children?: ReactNode;
  forwardedRef?: Ref<HTMLDivElement>;
  onChange?: (key: string, value: string | number) => void;
  setup: GQLSetup;
  branchId: number;
  hideAddSetupsButton: boolean;
}

export default forwardRef<HTMLDivElement, TitleProps>((props: TitleProps, ref: Ref<HTMLDivElement>) => {
  const { setup, children, forwardedRef, branchId, hideAddSetupsButton } = props;
  const dispatch = useDispatch();
  const currentSetupCompareState = useSelector(selectSetupCompareState);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [eventItems, setEventItems] = useState<Event[]>([]);
  const [eventSelectItems, setEventSelectItems] = useState<SelectItem<string>[]>([]);

  const [getEventsData] = useEventsLazyQuery({
    onCompleted: data => setEventItems(data?.events as Event[]),
  });

  useEffect(() => {
    getEventsData({
      variables: {
        year: setup.year,
        series: setup.series,
      },
    });
  }, [setup.year, setup.series, getEventsData]);

  useEffect(() => {
    setEventSelectItems(map(eventItems, (eventItem) => ({ label: eventItem.name, value: eventItem.name })));
  }, [eventItems]);

  const onModalSuccess = (rows: SetupSelection[]) => {
    // save current selection to setup compare settings in local storage
    const currentState = currentSetupCompareState[branchId];
    const compareIds = rows.map(row => row.branch.id);
    const filterIds = union(currentState, compareIds);
    const compareState = { [branchId]: [...filterIds || []] };
    dispatch(SetupCompareSlice.actions.setSetupCompareState(compareState));
    setIsDialogOpen(false);
  };

  return (
    <div className={styles.titleBar} ref={forwardedRef ?? ref}>
      <div className={styles.titleContainer}>
        <div className={styles.titleColumn}>
          <div className={styles.titleLabel}>Spec</div>
          <Select
            disabled={!props.onChange}
            initialItem={specSelectItems.find(i => setup.spec === i.value)}
            items={specSelectItems}
            noSelectionText="Spec"
            onChange={item => props.onChange?.('spec', item.value)}
          />
        </div>
        <div className={styles.titleColumn}>
          <div className={styles.titleLabel}>Name</div>
          <EditableText
            className={classNames(styles.titleValue)}
            defaultValue={setup.name}
            disabled={!props.onChange}
            onChange={value => props.onChange?.('name', value)}
            placeholder="Name"
          />
        </div>
        <div className={styles.titleColumn}>
          <div className={styles.titleLabel}>Year</div>
          <Select
            disabled={!props.onChange}
            initialItem={yearItems.find(i => setup.year === i.value)}
            items={yearItems}
            noSelectionText="Year"
            onChange={item => props.onChange?.('year', item.value)}
          />
        </div>
        <div className={styles.titleColumn}>
          <div className={styles.titleLabel}>Series</div>
          <Select
            disabled={!props.onChange}
            initialItem={seriesItems.find(i => setup.series === i.value)}
            items={seriesItems}
            noSelectionText="Series"
            onChange={item => props.onChange?.('series', item.value)}
          />
        </div>
        <div className={styles.titleColumn}>
          <div className={styles.titleLabel}>Event</div>
          <Select
            disabled={!props.onChange}
            initialItem={eventSelectItems.find(i => setup.event === i.value)}
            items={eventSelectItems}
            noSelectionText="Event"
            onChange={item => props.onChange?.('event', item.value)}
          />
        </div>
        <div className={styles.titleColumn}>
          <div className={styles.titleLabel}>Car</div>
          <EditableText
            className={classNames(styles.titleValue)}
            defaultValue={setup.car}
            disabled={!props.onChange}
            onChange={value => props.onChange?.('car', value)}
            minWidth={2}
            placeholder="Car"
          />
        </div>
        <div className={styles.titleColumnDesc}>
          <div className={styles.titleLabel}>Description</div>
          <EditableText
            className={classNames(styles.titleValue)}
            defaultValue={setup.description || ''}
            disabled={!props.onChange}
            onChange={value => props.onChange?.('description', value)}
            minWidth={2}
            placeholder="Description"
          />
        </div>
      </div>
      {children}
      {!hideAddSetupsButton && (
        <div className={styles.addSetupsDiv}>
          <Button
            icon="plus"
            className={styles.addSetupsContainer}
            text="Add Setup(s)"
            onClick={() => setIsDialogOpen(true)}
            hidden={hideAddSetupsButton}
          />
        </div>
      )}
      <AddSetupsModal
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onSuccess={onModalSuccess}
      />
    </div>
  );
});
