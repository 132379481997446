import { useSelector } from 'react-redux';
import { useFormContext, useWatch } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { FormGroup, Intent } from '@blueprintjs/core';
import { Required } from 'utility-types';
import classNames from 'classnames';

import { setupFieldTypeSelectItems, specSelectItems } from '../../constants';
import RHFCheckbox from 'components/RHFInputs/Checkbox';
import RHFMultiSelect from 'components/RHFInputs/MultiSelect';
import RHFSelect from 'components/RHFInputs/Select';
import RHFTagInput from 'components/RHFInputs/TagInput';
import RHFTextInput from 'components/RHFInputs/TextInput';
import { PartConfig, SetupFieldType } from 'graphql/generated/graphql';
import { selectDarkMode } from 'reducers/ui';
import Positions from './Positions';

import styles from './index.module.css';
import IconTooltip from 'components/IconTooltip';

interface Props {
  partConfigs: Required<Partial<PartConfig>, 'id' | 'display_name'>[];
}

export default (props: Props) => {
  const darkMode = useSelector(selectDarkMode);
  const { control, formState: { errors } } = useFormContext();
  const type = useWatch({ control, name: 'type' });
  const useInSim = useWatch({ control, name: 'use_in_sim' });

  const partTypeItems = props.partConfigs.map(config => ({
    label: config.display_name,
    value: config.id,
  }));

  return (
    <>
      <FormGroup
        contentClassName={styles.selectFormGroup}
        helperText={<ErrorMessage errors={errors} name="type" />}
        label="Type"
        labelInfo="(required)"
      >
        <RHFSelect
          controllerProps={{
            control,
            name: 'type',
            rules: { required: 'Type is required' },
          }}
          intent={errors.type && Intent.DANGER}
          items={setupFieldTypeSelectItems}
        />
      </FormGroup>
      {type === SetupFieldType.PART && (
        <FormGroup
          contentClassName={styles.selectFormGroup}
          helperText={<ErrorMessage errors={errors} name="part_config_id" />}
          label="Part Type"
          labelInfo="(required)"
        >
          <RHFSelect
            controllerProps={{
              control,
              name: 'part_config_id',
              rules: { required: 'Part Type is required' },
            }}
            intent={errors.part_type && Intent.DANGER}
            items={partTypeItems}
          />
        </FormGroup>
      )}
      <FormGroup
        helperText={<ErrorMessage errors={errors} name="specs" />}
        label="Specs"
        labelInfo="(leave empty for all specs)"
      >
        <RHFMultiSelect
          controllerProps={{
            control,
            name: 'specs',
          }}
          items={specSelectItems}
          selectProps={{ fill: true }}
        />
      </FormGroup>
      <FormGroup
        helperText={<ErrorMessage errors={errors} name="name" />}
        label="Name"
        labelInfo={(
          <>
            <IconTooltip
              content={`
                The name that will be specified in SUITs to include this
                field. It should be lowercase with no spaces, ideally using
                underscores between words.
              `}
            />
            <span> (required)</span>
          </>
        )}
      >
        <RHFTextInput
          controllerProps={{
            control,
            name: 'name',
            rules: {
              required: 'Name is required',
              pattern: {
                value: /^\S*$/,
                message: 'Name must not contain spaces',
              },
            },
          }}
          inputProps={{
            intent: errors.name && Intent.DANGER,
          }}
        />
      </FormGroup>
      <FormGroup
        helperText={<ErrorMessage errors={errors} name="label" />}
        label="Label"
        labelInfo="(required)"
      >
        <RHFTextInput
          controllerProps={{
            control,
            name: 'label',
            rules: { required: 'Label is required' },
          }}
          inputProps={{
            intent: errors.label && Intent.DANGER,
          }}
        />
      </FormGroup>
      <FormGroup
        helperText={<ErrorMessage errors={errors} name="path" />}
        label="Path"
        labelInfo={(
          <>
            <IconTooltip
              content={`
                When this field is used in simulations, this path should reflect
                the value's location in the simulation plan. Otherwise, this
                path specifies where the value resides in the setup document.
              `}
            />
            <span> (required)</span>
          </>
        )}
      >
        <RHFTextInput
          controllerProps={{
            control,
            name: 'path',
            rules: {
              required: 'Path is required',
              pattern: {
                value: /^\S*$/,
                message: 'Path must not contain spaces',
              },
            },
          }}
          inputProps={{
            intent: errors.path && Intent.DANGER,
          }}
        />
      </FormGroup>
      <FormGroup
        helperText={<ErrorMessage errors={errors} name="tooltip" />}
        label="Tooltip"
        labelInfo={(
          <IconTooltip
            content="Text shown in a tooltip when hovering this field"
          />
        )}
      >
        <RHFTextInput
          controllerProps={{
            control,
            name: 'tooltip',
          }}
          inputProps={{
            intent: errors.tooltip && Intent.DANGER,
          }}
        />
      </FormGroup>
      <FormGroup
        label="Positions"
        labelInfo={(
          <IconTooltip
            content={(
              <div className={classNames(styles.tooltipContent, { [styles.dark]: darkMode })}>
                <p>
                  Optional. <strong>Each position represents a separate input</strong> for e.g. LF,
                  Front, Upper Left, etc. By default, if a path part is not provided,
                  the label will be appended to the path above. If path part is
                  provided, it will replace the final section of the path above.
                </p>
                <p>
                  For example, with a path of <code>vehicle.aerodynamics.downforce_offset</code> and
                  a position with path part <code>fdf_offset</code>, the position's
                  input's path will be <code>vehicle.aerodynamics.fdf_offset</code>.
                </p>
                <p>
                  When a path part is not provided, for example with a path
                  of <code>vehicle.chassis.front_clip_shim</code> and a label of
                  "Upper Left", the position's input's path will
                  be <code>vehicle.chassis.front_clip_shim_upper_left</code>.
                </p>
              </div>
            )}
          />
        )}
      >
        <Positions />
      </FormGroup>
      {(type === SetupFieldType.STRING || type === SetupFieldType.INT) && (
        <FormGroup
          label="Options"
          labelInfo={(
            <IconTooltip
              content={`
                Optional. When specified, options limit the values an input can
                have by rendering a dropdown with the specified values rather
                than a text input.
              `}
            />
          )}
        >
          <RHFTagInput
            controllerProps={{
              control,
              name: 'options',
            }}
          />
        </FormGroup>
      )}
      <FormGroup label="Used in sim" inline>
        <RHFCheckbox
          controllerProps={{
            name: 'use_in_sim',
          }}
          checkboxProps={{
            checked: useInSim,
          }}
        />
      </FormGroup>
    </>
  );
};
