import { useState, useEffect } from 'react';
import {
  useLocation,
  useParams,
  unstable_useBlocker,      // eslint-disable-line camelcase
  unstable_BlockerFunction, // eslint-disable-line camelcase
  useSearchParams,
  useRouteLoaderData,
} from 'react-router-dom';
import { SimResult } from 'context/SubscriptionContext';
import { Overlay, Spinner, Intent, Button } from '@blueprintjs/core';
import { get, find, cloneDeep, set, isEqual, isEmpty, join } from 'lodash';
import {
  Part,
  SetupBranch,
  useCommitSetupMutation,
  SetupByBranchIdDocument,
  useSUITFormPartsLazyQuery,
  usePartConfigIdByTypeNameQuery,
  SetupByIdDocument,
  SetupBranchesByRootIdDocument,
  usePartsCompareDataLazyQuery,
  useSetupBranchesByIdsQuery,
  useRequestPartPlottingMutation,
  Scalars,
  Spec, useGetSimResultSubscription,
} from 'graphql/generated/graphql';
import { SetupSelection } from 'types';
import TitleBar from '../TitleBar';
import { useSelector } from 'react-redux';
import BumpStopChart, { ChartDataItem, PartInput } from './BumpStopChart';
import { SetupToSetupInput } from 'helpers/converter';
import { excludeSetupMeta } from 'helpers/setup';
import { addWarningListener, removeWarningListener } from 'helpers/browserCloseWarning';
import AppToaster from 'helpers/toaster';
import styles from './index.module.css';
import { baseName } from '../../../config';
import { selectSetupCompareState  } from '../../../reducers/setupCompare';
import hasPermission from 'helpers/permissions';
import { PermissionName } from '../../../constants';

const bumpstopTypeName = 'bumpstop';

// For Now, I will set the Colors from this Array, baseline get's 0, and each setup in it's param index get's 1,2,3,...
// Later this value could come from the DB as a saved setup field.
const COLORS = ['orange', 'steelblue', 'magenta', 'darkgreen', 'green', 'brown', 'red', 'yellow', 'white', 'blue'];

interface SetupParts {
  [key: string]: Part;
}

export default () => {
  const location = useLocation();
  const params = useParams();
  const permissions = useRouteLoaderData('root') as string[];
  const [searchParams] = useSearchParams();
  const { data: bumpstopPlotData } = useGetSimResultSubscription();
  const branchId = Number(params.branchId);
  const compareIds = searchParams.get('ids');
  const [branchIds, setBranchIds] = useState<number []>([branchId, ...compareIds?.split(',').map(Number) ?? []]);
  const [cleanBaselineBranch, setCleanBaselineBranch] = useState<SetupSelection>();
  const [branches, setBranches] = useState<SetupSelection[]>([]);
  const [parts, setParts] = useState<Part[]>([]);
  const [partConfigId, setPartConfigId] = useState<number>();
  const [customPlottingParts, setCustomPlottingParts] = useState<PartInput []>([]);
  const [fetchParts, setFetchParts] = useState<boolean>(true);
  const [baselineLF, setBaselineLF] = useState<Part>();
  const [baselineRF, setBaselineRF] = useState<Part>();
  const [baselineLR, setBaselineLR] = useState<Part>();
  const [baselineRR, setBaselineRR] = useState<Part>();
  const [typeLF, setTypeLF] = useState<string>('');
  const [typeRF, setTypeRF] = useState<string>('');
  const [typeLR, setTypeLR] = useState<string>('');
  const [typeRR, setTypeRR] = useState<string>('');
  const [setupPartsData, setSetupPartsData] = useState<SetupParts>();
  const [chartDataLF, setChartDataLF] = useState<ChartDataItem[]>([]); // ChartDataLF Contains charting data for all setups
  const [chartDataRF, setChartDataRF] = useState<ChartDataItem[]>([]); // ChartDataRF Contains charting data for all setups
  const [chartDataLR, setChartDataLR] = useState<ChartDataItem[]>([]); // ChartDataLR Contains charting data for all setups
  const [chartDataRR, setChartDataRR] = useState<ChartDataItem[]>([]); // ChartDataRR Contains charting data for all setups
  const [isDirty, setIsDirty] = useState(false);
  const selectedSetups = useSelector(selectSetupCompareState);

  const { loading: partConfigLoading } = usePartConfigIdByTypeNameQuery({
    variables: { typeName: bumpstopTypeName },
    onCompleted: data => setPartConfigId(data.partConfig?.id),
  });

  // Query for COEFF plotting api request
  const [requestPartPlotting] = useRequestPartPlottingMutation();

  // Query to fetch new Branches/setups
  const { loading: setupsLoading, data: branchData } = useSetupBranchesByIdsQuery({
    variables: { branchIds },
    fetchPolicy: 'network-only',
  });

  // Query for parts used for Part selection component.
  const [getParts, { loading: partsLoading }] = useSUITFormPartsLazyQuery({ fetchPolicy: 'network-only', onCompleted: data => setParts(data.parts.rows as Part[]) });

  // Query for part charting data used for graph plotting
  const [getPartData, { called, loading, data: partData }] = usePartsCompareDataLazyQuery({
    fetchPolicy: 'network-only', // Ensures we always fetch the latest data
  });

  const [commitSetup] = useCommitSetupMutation();

  // Get ChartDataItems for Coeff plot data
  const createCoeffChartDataItems = (plotName: string, setupId: number, setupColor: string, setupName: string, plotData?: number[][]) => {
    const plotArray = plotData || [];

    const ChartDataItems = plotArray.map((item: number[]) => ({
      x: item[0],
      y: item[1],
      name: plotName,
      displayName: setupName,
      id: setupId,
      color: setupColor,
    }));

    return ChartDataItems;
  };

  // Get Linear Rate data from LinearRate and Range - TODO later this max range value can be determined based on all ChartDataItems in the array
  const getLinearOverrideData = (linearRate: number | undefined, range: number): number[][] => {
    const linearData: number[][] = [[0, 0]];

    if (linearRate !== undefined && range) {
      for (let i = 1; i < range; i += 1) {
        linearData.push([i, i * linearRate]);
      }

      // add last linear data point to array (to accommodate range being a float)
      linearData.push([range, range * linearRate]);
    }

    return linearData;
  };

  // Get ChartDataItems for LinearRate
  const getChartDataItems = (linearRate: number, partName: string, setupId: number, setupColor: string, setupName: string, forceData?: number[][]) => {
    let plottingArray: number [][];
    if (forceData) {
      plottingArray = forceData;
    } else {
      plottingArray = getLinearOverrideData(linearRate, linearRate === 0 ? 0 : 5);
    }

    const ChartDataItems = plottingArray.map((item: number[]) => ({
      x: item[0],
      y: item[1],
      name: partName,
      displayName: setupName,
      id: setupId,
      color: setupColor,
    }));

    return ChartDataItems;
  };

  // COEFF Plotting PartInput
  const getPlotPartInput = (partId: number, setupId: number, pos: string, partFormat: string, description: string, spec: Spec, data: Scalars) => {
    return {
      partId,
      setupId,
      pos,
      partFormat,
      description,
      spec,
      data,
    };
  };

  // This function locates existing chartDataItems for a setup in a position LR/RF/LR/RR of the car with returned api plotting data
  const updateCoeffChartData = (updatedChartData: ChartDataItem[], result: SimResult): ChartDataItem[] => {
    const foundCD: ChartDataItem | undefined = updatedChartData.find(cdi => cdi.id.toString() === result.setupId);

    let updatedArray: ChartDataItem[] = [];

    if (foundCD) {
      const clonedFoundCD = JSON.parse(JSON.stringify(foundCD));
      updatedArray = [
        ...updatedChartData.filter(item => item.id !== foundCD.id),
        ...createCoeffChartDataItems(clonedFoundCD.name, clonedFoundCD.id, clonedFoundCD.color, get(clonedFoundCD, 'displayName', ''), get(result, 'output.displacement', [])),
      ];
    } else {
      // If foundCD is undefined, simply return the original array
      updatedArray = [...updatedChartData];
    }

    return updatedArray;
  };

  // This useEffect updates the graph chartdata with VRP/Coeff results.
  useEffect(() => {
    if (bumpstopPlotData && bumpstopPlotData.simResult && bumpstopPlotData.simResult.dataType === 'spring_model_plot') {
      switch (bumpstopPlotData.simResult.pos) {
        case 'LF':
          setChartDataLF(prevState => updateCoeffChartData([...prevState], bumpstopPlotData.simResult));
          break;
        case 'RF':
          setChartDataRF(prevState => updateCoeffChartData([...prevState], bumpstopPlotData.simResult));
          break;
        case 'LR':
          setChartDataLR(prevState => updateCoeffChartData([...prevState], bumpstopPlotData.simResult));
          break;
        case 'RR':
          setChartDataRR(prevState => updateCoeffChartData([...prevState], bumpstopPlotData.simResult));
          break;
        default:
          break;
      }
    }
  }, [bumpstopPlotData]);

  // Use effect to query server for plotting coeff bumpstop request
  useEffect(() => {
    if (customPlottingParts.length > 0) {
      requestPartPlotting({
        variables: { input: customPlottingParts, partType: 'bumpstop' },
        fetchPolicy: 'network-only',
      })
        .catch(error => {
          AppToaster.show({
            intent: Intent.DANGER,
            message: `Error submitted plotting request: ${error.message}`,
          });
        });
    }
  }, [customPlottingParts, requestPartPlotting]);

  // Appends selected setup IDs to the URL query params
  useEffect(() => {
    if (selectedSetups && selectedSetups[branchId]) {
      let newCompareIds: number [] = [];
      let compareUrl = baseName === '/'
        ? `${window.location.origin}${location.pathname}`
        : `${window.location.origin}${baseName}${location.pathname}`;
      if (selectedSetups[branchId].length > 0) {
        newCompareIds = selectedSetups[branchId];
        if (!isEmpty(newCompareIds)) compareUrl += `?ids=${join(newCompareIds)}`;
        window.history.replaceState(null, '', compareUrl);
      }
      setBranchIds([branchId, ...newCompareIds]);
    }
  }, [selectedSetups]);

  useEffect(() => {
    if (branchData && branchIds.length > 0) {
      // Process the data fetched by the hook
      const branchHeads = branchData.setupBranches.map(b => ({
        branch: {
          id: b.id,
          name: b.name,
        },
        setup: { ...b.head },
      }));

      const [...branches] = branchHeads.reduce((acc, b) => {
        if (b.branch.id === branchId) acc.unshift(b);
        else acc.push(b);
        return acc;
      }, [] as SetupSelection[]);

      const baseline = branches.find(b => b.branch.id === branchId);
      setCleanBaselineBranch(baseline);
      setBranches([...branches]);
      setChartingState([], [], [], []);
    }
  }, [branchIds, branchData]);

  // This useEffect responds to partData being loaded and setupsPartsData needing to be returned for ChartData plotting.
  // In the future this foreach look can be updated to a single request with variable of Ids instead of single api requests
  useEffect(() => {
    // Get unique list of parts, if multiple setups own the same part, only fetch 1 of that part.
    if (setupPartsData && Object.keys(setupPartsData).length > 0) {
      const uniqueParts = Object.values(setupPartsData).filter((part, index, self) => index === self.findIndex((t) => t.id === part.id));
      const uniquePartIds = uniqueParts.map(p => p.id);
      getPartData({
        variables: {
          input: {
            ids: uniquePartIds,
          },
        },
        fetchPolicy: 'network-only',
      });
    }
  }, [setupPartsData, getPartData, called]);

  // Gets bumpstop fields from Branch/Setup
  const getSetupBumpstopFields = (branch: SetupSelection) => {
    return {
      Id_LF: get(branch, 'setup.data.vehicle.bumpstops.lf'),
      Id_RF: get(branch, 'setup.data.vehicle.bumpstops.rf'),
      Id_LR: get(branch, 'setup.data.vehicle.bumpstops.lr'),
      Id_RR: get(branch, 'setup.data.vehicle.bumpstops.rr'),
      override_LF: get(branch, 'setup.data.vehicle.bumpstops.switch_linear_override_lf', false),
      override_RF: get(branch, 'setup.data.vehicle.bumpstops.switch_linear_override_rf', false),
      override_LR: get(branch, 'setup.data.vehicle.bumpstops.switch_linear_override_lr', false),
      override_RR: get(branch, 'setup.data.vehicle.bumpstops.switch_linear_override_rr', false),
      /* eslint-disable no-eval */
      linearRate_LF: eval(get(branch, 'setup.data.vehicle.bumpstops.linear_rate_override_lf', 0)),
      linearRate_RF: eval(get(branch, 'setup.data.vehicle.bumpstops.linear_rate_override_rf', 0)),
      linearRate_LR: eval(get(branch, 'setup.data.vehicle.bumpstops.linear_rate_override_lr', 0)),
      linearRate_RR: eval(get(branch, 'setup.data.vehicle.bumpstops.linear_rate_override_rr', 0)),
      strRate_LF: get(branch, 'setup.data.vehicle.bumpstops.linear_rate_override_lf', ''),
      strRate_RF: get(branch, 'setup.data.vehicle.bumpstops.linear_rate_override_rf', ''),
      strRate_LR: get(branch, 'setup.data.vehicle.bumpstops.linear_rate_override_lr', ''),
      strRate_RR: get(branch, 'setup.data.vehicle.bumpstops.linear_rate_override_rr', ''),
    };
  };

  // This useEffect responds to loaded branches/setups, and gathers bumpstop data needed
  // It will update setChartDataXX state with plotting data if it's a linear rate, and gather all parts needed in separate useEffect
  useEffect(() => {
    if (branches.length > 0) {
      let updatedChartDataLF: ChartDataItem[] = [...chartDataLF];
      let updatedChartDataRF: ChartDataItem[] = [...chartDataRF];
      let updatedChartDataLR: ChartDataItem[] = [...chartDataLR];
      let updatedChartDataRR: ChartDataItem[] = [...chartDataRR];
      const setupParts: SetupParts = {};
      branches.forEach(branch => {
        // Extracting part IDs and linear rate overrides for each setup
        const setupBumpstopFields = getSetupBumpstopFields(branch);

        // Finding all position setup parts
        const partLF = find(parts, (o) => (o.id === setupBumpstopFields.Id_LF));
        if (partLF) setupParts[`${branch.branch.id.toString()}_LF`] = partLF;

        const partRF = find(parts, (o) => (o.id === setupBumpstopFields.Id_RF));
        if (partRF) setupParts[`${branch.branch.id.toString()}_RF`] = partRF;

        const partLR = find(parts, (o) => (o.id === setupBumpstopFields.Id_LR));
        if (partLR) setupParts[`${branch.branch.id.toString()}_LR`] = partLR;

        const partRR = find(parts, (o) => (o.id === setupBumpstopFields.Id_RR));
        if (partRR) setupParts[`${branch.branch.id.toString()}_RR`] = partRR;

        if (setupBumpstopFields.override_LF) { // No Part, Plot Linear Rate
          updatedChartDataLF = [...updatedChartDataLF.filter(item => item.id !== branch.branch.id),
            ...getChartDataItems(setupBumpstopFields.linearRate_LF, `${setupBumpstopFields.strRate_LF} - Linear Rate`, branch.branch.id, COLORS[Number(branchIds.indexOf(branch.branch.id))], branch.setup.name)];
        } else if (!setupBumpstopFields.override_LF) {
          // Plot a placeholder per ticket 224 - Add Place holder entry
          updatedChartDataLF = [...updatedChartDataLF.filter(item => item.id !== branch.branch.id),
            { x: 0, y: 0,  name: (partLF ? partLF.description :  '-0- None'), id: branch.branch.id, color: COLORS[Number(branchIds.indexOf(branch.branch.id))] }];
        }

        if (setupBumpstopFields.override_RF) { // No Part, Plot Linear Rate
          updatedChartDataRF = [...updatedChartDataRF.filter(item => item.id !== branch.branch.id),
            ...getChartDataItems(setupBumpstopFields.linearRate_RF, `${setupBumpstopFields.strRate_RF} - Linear Rate`, branch.branch.id, COLORS[Number(branchIds.indexOf(branch.branch.id))], branch.setup.name)];
        } else if (!setupBumpstopFields.override_RF) {
          // Plot a placeholder per ticket 224 - Add Place holder entry
          updatedChartDataRF = [...updatedChartDataRF.filter(item => item.id !== branch.branch.id),
            { x: 0, y: 0,  name: (partRF ? partRF.description :  '-0- None'), id: branch.branch.id, color: COLORS[Number(branchIds.indexOf(branch.branch.id))] }];
        }

        if (setupBumpstopFields.override_LR) { // No Part, Plot Linear Rate
          updatedChartDataLR = [...updatedChartDataLR.filter(item => item.id !== branch.branch.id),
            ...getChartDataItems(setupBumpstopFields.linearRate_LR, `${setupBumpstopFields.strRate_LR} - Linear Rate`, branch.branch.id, COLORS[Number(branchIds.indexOf(branch.branch.id))], branch.setup.name)];
        } else if (!setupBumpstopFields.override_LR) {
          // Plot a placeholder per ticket 224 - Add Place holder entry
          updatedChartDataLR = [...updatedChartDataLR.filter(item => item.id !== branch.branch.id),
            { x: 0, y: 0,  name: (partLR ? partLR.description :  '-0- None'), id: branch.branch.id, color: COLORS[Number(branchIds.indexOf(branch.branch.id))] }];
        }

        if (setupBumpstopFields.override_RR) { // No Part, Plot Linear Rate
          updatedChartDataRR = [...updatedChartDataRR.filter(item => item.id !== branch.branch.id),
            ...getChartDataItems(setupBumpstopFields.linearRate_RR, `${setupBumpstopFields.strRate_RR} - Linear Rate`, branch.branch.id, COLORS[Number(branchIds.indexOf(branch.branch.id))], branch.setup.name)];
        } else if (!setupBumpstopFields.override_RR) {
          // Plot a placeholder per ticket 224 - Add Place holder entry
          updatedChartDataRR = [...updatedChartDataRR.filter(item => item.id !== branch.branch.id),
            { x: 0, y: 0,  name: (partRR ? partRR.description :  '-0- None'), id: branch.branch.id, color: COLORS[Number(branchIds.indexOf(branch.branch.id))] }];
        }
      });

      // Updated chart data and setup parts using state update functions
      setChartDataLF(updatedChartDataLF);
      setChartDataRF(updatedChartDataRF);
      setChartDataLR(updatedChartDataLR);
      setChartDataRR(updatedChartDataRR);
      setSetupPartsData(setupParts);
    }
  }, [branches, parts, partData]);

  // getPartFormat function determines the format Tabular or Coeff currently
  const getPartFormat = (part: Part, override: boolean): string => {
    if (override) return '';

    if (part.data.force_data) {
      return 'tabular';
    } else if (part.data && part.data.coeff) {
      return 'coeff';
    }
    return '';
  };

  // Get ChartDataItems for tabular plot data
  const applyTabularChartDataItems = (plotName: string, setupId: number, setupColor: string, setupName: string, plotData?: number[][]) => {
    const tabularArray = plotData || [];

    const ChartDataItems = tabularArray.map((item: number[]) => ({
      x: Math.abs(item[0]),
      y: item[1],
      name: plotName,
      displayName: setupName,
      id: setupId,
      color: setupColor,
    }));

    return ChartDataItems;
  };

  // Update Chart data for Coeff
  const updateChartData = (chartArray: ChartDataItem[], chartType: string, desc: string, branchId: number, colorIndex: number, setupName: string,  plotData?: number [][]) => {
    const filteredData = chartArray.filter(item => item.id !== branchId);
    const newChartDataItem: ChartDataItem [] = applyTabularChartDataItems(desc, branchId, COLORS[colorIndex], setupName, plotData);
    return [...filteredData, ...newChartDataItem];
  };

  // useEffect responds to part data returned from Graphql requests.
  // Determine which setup/position data will be applied to.
  useEffect(() => {
    if (branches && partData && partData.parts && partData.parts.rows.length > 0) {
      const customPlottingParts: PartInput[] = [];
      let updatedChartDataLF: ChartDataItem[] =  [...chartDataLF];
      let updatedChartDataRF: ChartDataItem[] =  [...chartDataRF];
      let updatedChartDataLR: ChartDataItem[] =  [...chartDataLR];
      let updatedChartDataRR: ChartDataItem[] =  [...chartDataRR];

      if (branches) {
        branches.forEach(branch => {
          // Extracting part IDs and linear rate overrides for each setup
          const foundParts = partData.parts.rows;
          const isBaseline = branch.branch.id === branchId;
          foundParts.forEach(part => {
            const setupBumpstopFields = getSetupBumpstopFields(branch);
            if (setupBumpstopFields.Id_LF && setupBumpstopFields.Id_LF === part.id) {
              if (part && isBaseline) {
                setBaselineLF(part as Part);
                setTypeLF(getPartFormat(part as Part, setupBumpstopFields.override_LF));
              }
              if (!setupBumpstopFields.override_LF && part.data.force_data) { // Is tabular, Plot force_data
                updatedChartDataLF = updateChartData(updatedChartDataLF, 'LF', `${part.description} - Tabular`, branch.branch.id, Number(branchIds.indexOf(branch.branch.id)), branch.setup.name, part.data.force_data);
              } else if (!setupBumpstopFields.override_LF && part.data.coeff) { // Is Coeff part type, Add part to list for api requests of Coeff Plotting data
                customPlottingParts.push(getPlotPartInput(part.id, branch.branch.id, 'LF', 'coeff', part.description, part.spec, part.data) as PartInput);
              }
            }
            if (setupBumpstopFields.Id_RF && setupBumpstopFields.Id_RF === part.id) {
              if (part && isBaseline) {
                setBaselineRF(part as Part);
                setTypeRF(getPartFormat(part as Part, setupBumpstopFields.override_RF));
              }
              if (!setupBumpstopFields.override_RF && part.data.force_data) {  // Is tabular, Plot force_data
                updatedChartDataRF = updateChartData(updatedChartDataRF, 'RF', `${part.description} - Tabular`, branch.branch.id, Number(branchIds.indexOf(branch.branch.id)), branch.setup.name, part.data.force_data);
              } else if (!setupBumpstopFields.override_RF && part.data.coeff) { // Is Coeff part type, Add part to list for api requests of Coeff Plotting data
                customPlottingParts.push(getPlotPartInput(part.id, branch.branch.id, 'RF', 'coeff', part.description, part.spec, part.data) as PartInput);
              }
            }
            if (setupBumpstopFields.Id_LR && setupBumpstopFields.Id_LR === part.id) {
              if (part && isBaseline) {
                setBaselineLR(part as Part);
                setTypeLR(getPartFormat(part as Part, setupBumpstopFields.override_LR));
              }
              if (!setupBumpstopFields.override_LR  && part.data.force_data) { // Is tabular, Plot force_data
                updatedChartDataLR = updateChartData(updatedChartDataLR, 'LR', `${part.description} - Tabular`, branch.branch.id, Number(branchIds.indexOf(branch.branch.id)), branch.setup.name, part.data.force_data);
              } else if (!setupBumpstopFields.override_LR && part.data.coeff) { // Is Coeff part type, Add part to list for api requests of Coeff Plotting data
                customPlottingParts.push(getPlotPartInput(part.id, branch.branch.id, 'LR', 'coeff', part.description, part.spec, part.data) as PartInput);
              }
            }
            if (setupBumpstopFields.Id_RR && setupBumpstopFields.Id_RR === part.id) {
              if (part && isBaseline) {
                setBaselineRR(part as Part);
                setTypeRR(getPartFormat(part as Part, setupBumpstopFields.override_RR));
              }
              if (!setupBumpstopFields.override_RR && part.data.force_data) { // Is tabular, Plot force_data
                updatedChartDataRR = updateChartData(updatedChartDataRR, 'RR', `${part.description} - Tabular`, branch.branch.id, Number(branchIds.indexOf(branch.branch.id)), branch.setup.name, part.data.force_data);
              }  else if (!setupBumpstopFields.override_RR && part.data.coeff) { // Is Coeff part type, Add part to list for api requests of Coeff Plotting data
                customPlottingParts.push(getPlotPartInput(part.id, branch.branch.id, 'RR', 'coeff', part.description, part.spec, part.data) as PartInput);
              }
            }
          });
        });

        // Set Custom Plotting Data for Coeff/VRP request.
        setCustomPlottingParts(customPlottingParts);

        // Set's plotting and type states for positions
        setChartDataLF(updatedChartDataLF);
        setChartDataRF(updatedChartDataRF);
        setChartDataLR(updatedChartDataLR);
        setChartDataRR(updatedChartDataRR);
      }
    }
  }, [partData, setupPartsData]);

  const setChartingState = (cdlf: ChartDataItem[], cdrf: ChartDataItem[], cdlr: ChartDataItem[], cdrr: ChartDataItem[]) => {
    // Set Plotting data for position/graph
    setChartDataLF(cdlf);
    setChartDataRF(cdrf);
    setChartDataLR(cdlr);
    setChartDataRR(cdrr);
  };

  // Get Parts based on part Config id to determine the type
  useEffect(() => {
    if (partConfigId && fetchParts) {
      getParts({
        variables: {
          input: {
            filters: {
              part_config: [partConfigId],
            },
          },
        },
      })
        .then((data) => {
          if (data.data?.parts.__typename === 'PartsResult') {
            setFetchParts(false);
          }
        });
    }
  }, [partConfigId, getParts, fetchParts]);

  // If Baseline setup is not dirty/edited compare with Clean and Warn user.
  useEffect(() => {
    if (!branches || !branches.length || !cleanBaselineBranch || !cleanBaselineBranch.setup) return;
    const baselineBranch = branches.find(b => b.branch.id === branchId);
    if (baselineBranch && baselineBranch.setup) {
      const isDirtyCheck = !isEqual(excludeSetupMeta(cleanBaselineBranch.setup), excludeSetupMeta(baselineBranch.setup));
      const prevIsDirty = isDirty;
      setIsDirty(isDirtyCheck);
      if (isDirtyCheck !== prevIsDirty) {
        if (isDirtyCheck) addWarningListener();
        else removeWarningListener();
      }
    }
  }, [branches, cleanBaselineBranch]);

  // Removes the listener when deconstructing this component
  useEffect(() => removeWarningListener, []);

  const clearStatePositionFormat = (pos: string) => {
    switch (pos) {
      case 'LF':
        setTypeLF('');
        break;
      case 'RF':
        setTypeRF('');
        break;
      case 'LR':
        setTypeLR('');
        break;
      case 'RR':
        setTypeRR('');
        break;
      default:
        break;
    }
  };

  // eslint-disable-next-line
  const blockerFunc: unstable_BlockerFunction = () => {
    if (isDirty) {
      // eslint-disable-next-line
      return !window.confirm(
        'There are unsaved changes. Navigate away from this view?'
      );
    }
    return false;
  };
  unstable_useBlocker(blockerFunc);

  // On change baseline setup handler   DOES THIS CHANGE THE BASELINE SETUP OR THE BASELINE XX Baseline Part
  const onChangeHandler = (path: string, value: number, pos: string, newPart?: Part) => {
    if (!branches || !branches.length) return;

    const updatedBranches = cloneDeep(branches);
    const baselineBranch = updatedBranches.find(b => b.branch.id === branchId);
    if (baselineBranch && baselineBranch.setup) {
      // Update the Baseline Branch with the change.
      set(baselineBranch.setup, `data.${path}`, value);

      // Update the Override to false
      const pos = path.slice(-2);
      const overridePath = `${path.slice(0, -2)}switch_linear_override_${pos}`;
      set(baselineBranch.setup, `data.${overridePath}`, false);

      setBranches(updatedBranches);
      clearStatePositionFormat(pos);
      // if new part, issue refetch of parts
      if (newPart) {
        setFetchParts(true);
      }
    }
  };

  const onUpdatePlotHandler = (part: PartInput) => {
    // Check if a part with the same pos already exists in the array
    const existingPartIndex = customPlottingParts.findIndex(p => p.pos === part.pos);

    if (existingPartIndex !== -1) {
      // If found, update the existing part
      customPlottingParts[existingPartIndex] = part;
    } else {
      // If not found, push the new part into the array
      customPlottingParts.push(part);
    }

    // Use a functional update to ensure the effect runs after setting the state
    setCustomPlottingParts(prevState => [...prevState]);
  };

  // Handler for Saving Baseline Setup
  const onSave = async () => {
    if (!baselineBranch) return;

    await commitSetup({
      variables: {
        branchId,
        setup: SetupToSetupInput(baselineBranch.setup),
      },
      onCompleted: () => {
        const updatedCleanBranch = branches.find(b => b.branch.id === branchId);
        if (updatedCleanBranch) {
          setCleanBaselineBranch(updatedCleanBranch);
        }
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Successfully saved setup',
        });
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Error saving setup: ${e.message}`,
        });
      },
      update: (cache, { data: mutationData }, { variables }) => {
        if (mutationData?.commitedSetup) {
          cache.writeQuery({
            query: SetupByBranchIdDocument,
            variables: { branchId: variables?.branchId },
            data: {
              setup: { ...mutationData.commitedSetup },
            },
          });

          cache.writeQuery({
            query: SetupByIdDocument,
            variables: { id: mutationData.commitedSetup.id },
            data: {
              setup: mutationData.commitedSetup,
            },
          });

          cache.updateQuery({
            query: SetupBranchesByRootIdDocument,
            variables: { rootId: baselineBranch?.setup.root?.id },
          }, queryData => {
            if (!queryData) return undefined;
            return {
              branches: queryData.branches.map((b: SetupBranch) => {
                if (b.id !== branchId) return b;
                return {
                  ...b,
                  head: {
                    ...b.head,
                    id: mutationData.commitedSetup?.id,
                  },
                };
              }),
            };
          });
        }
      },
    });
  };

  if (!branches || !branches.length) return null;
  const baselineBranch = branches.find(b => b.branch.id === branchId);
  if (!baselineBranch) return null;

  if (!hasPermission(PermissionName.SETUP_WRITE, permissions)
    && !hasPermission(PermissionName.SETUP_READ, permissions)
    && !hasPermission(PermissionName.SETUP_BUMPSTOPS_READ, permissions)
    && !hasPermission(PermissionName.SETUP_BUMPSTOPS_WRITE, permissions)) return null;

  let readOnly = true;
  if (hasPermission(PermissionName.SETUP_WRITE, permissions) || hasPermission(PermissionName.SETUP_BUMPSTOPS_WRITE, permissions)) readOnly = false;

  return (
    <div className={styles.mainContainer}>
      <TitleBar setup={baselineBranch.setup} branchId={branchId} hideAddSetupsButton={false}>
        <div className={styles.controlsContainer}>
          <Button
            disabled={!isDirty}
            icon="floppy-disk"
            intent={Intent.PRIMARY}
            onClick={onSave}
            text="Save"
          />
        </div>
      </TitleBar>
      <div className={styles.chartContainer}>
        <div className={styles.chartRow}>
          <BumpStopChart
            position="LF"
            chartData={chartDataLF ? chartDataLF.sort((a, b) => {
              return branchIds.indexOf(a.id) - branchIds.indexOf(b.id);
            }) : []}
            selectedPart={baselineLF}
            setupId={branchId}
            partConfigId={partConfigId}
            spec={baselineBranch.setup.spec}
            parts={parts}
            onChangeHandler={onChangeHandler}
            onUpdatePlotData={onUpdatePlotHandler}
            path="vehicle.bumpstops.lf"
            partType={typeLF}
            readOnly={readOnly}
          />
          <BumpStopChart
            position="RF"
            chartData={chartDataRF ? chartDataRF.sort((a, b) => {
              return branchIds.indexOf(a.id) - branchIds.indexOf(b.id);
            }) : []}
            setupId={branchId}
            partConfigId={partConfigId}
            spec={baselineBranch.setup.spec}
            selectedPart={baselineRF}
            parts={parts}
            onChangeHandler={onChangeHandler}
            onUpdatePlotData={onUpdatePlotHandler}
            path="vehicle.bumpstops.rf"
            partType={typeRF}
            readOnly={readOnly}
          />
        </div>
        <div className={styles.chartRow}>
          <BumpStopChart
            position="LR"
            chartData={chartDataLR ? chartDataLR.sort((a, b) => {
              return branchIds.indexOf(a.id) - branchIds.indexOf(b.id);
            }) : []}
            setupId={branchId}
            partConfigId={partConfigId}
            spec={baselineBranch.setup.spec}
            selectedPart={baselineLR}
            parts={parts}
            onChangeHandler={onChangeHandler}
            onUpdatePlotData={onUpdatePlotHandler}
            path="vehicle.bumpstops.lr"
            partType={typeLR}
            readOnly={readOnly}
          />
          <BumpStopChart
            position="RR"
            chartData={chartDataRR ? chartDataRR.sort((a, b) => {
              return branchIds.indexOf(a.id) - branchIds.indexOf(b.id);
            }) : []}
            setupId={branchId}
            partConfigId={partConfigId}
            spec={baselineBranch.setup.spec}
            selectedPart={baselineRR}
            parts={parts}
            onChangeHandler={onChangeHandler}
            onUpdatePlotData={onUpdatePlotHandler}
            path="vehicle.bumpstops.rr"
            partType={typeRR}
            readOnly={readOnly}
          />
        </div>
      </div>
      <Overlay
        isOpen={setupsLoading || loading || partConfigLoading || partsLoading}
        className="bp3-overlay-scroll-container"
      >
        <div className={styles.loadingSpinner}>
          <Spinner size={50} />
        </div>
      </Overlay>
    </div>
  );
};
