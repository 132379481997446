import { CSSProperties, ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import { Alignment, Card } from '@blueprintjs/core';
import classNames from 'classnames';
import { isNil, snakeCase, get, isEmpty } from 'lodash';

import {
  SelectItem,
  RunUITemplate,
  RunUITemplateFieldItem,
  RunUITemplateItem,
  RunUITemplateItemType,
  RunUITemplateColumnItem,
} from 'types';
import {
  RHFCheckbox,
  RHFSelect,
  RHFTextArea,
  RHFTextInput,
} from 'components/RHFInputs';
import { RunField, RunFieldPosition, RunFieldType, Run } from 'graphql/generated/graphql';

import styles from './index.module.css';
import IconTooltip from 'components/IconTooltip';

interface SetupViewProps {
  runFields: RunField[];
  runIndex: number;
  template: RunUITemplate;
  baseline?: Run;
  readOnly?: boolean;
}

const getColItems = (item: RunUITemplate | RunUITemplateColumnItem): RunUITemplateColumnItem[] => {
  return item.items.filter(i => i.type === RunUITemplateItemType.COLUMN) as RunUITemplateColumnItem[];
};

const getColumnGridStyles = (item: RunUITemplate | RunUITemplateColumnItem): CSSProperties => {
  const colItems = getColItems(item);
  return {
    gridTemplateColumns: colItems.map(i => `${i.col_span ?? 1}fr`).join(' '),
  };
};

export default (props: SetupViewProps) => {
  const { runFields, runIndex, template, baseline, readOnly } = props;
  const { control, getValues } = useFormContext();

  const getFieldKey = (fieldName: string, position?: RunFieldPosition) => {
    const prefix = `runs.${runIndex}.data`;
    if (!position) return `${prefix}.${fieldName}`;
    return `${prefix}.${fieldName}_${snakeCase(position.label)}`;
  };

  const getBaselineValue = (fieldName: string, position?: RunFieldPosition) => {
    let baselineValue = '';
    if (!position) {
      baselineValue = get(baseline, `data.${fieldName}`, '');
    } else {
      baselineValue = get(baseline, `data.${fieldName}_${snakeCase(position.label)}`, '');
    }
    return baselineValue;
  };

  const renderRunField = (field: RunField, position?: RunFieldPosition): ReactNode => {
    const key = getFieldKey(field.name, position);

    const baselineValue: unknown = (baseline && runIndex !== 0) ? getBaselineValue(field.name, position) : undefined;
    const highlightClass = baselineValue !== undefined && baselineValue !== getValues(key) ? styles.highlight : undefined;

    switch (field.type) {
      case RunFieldType.STRING: {
        if (field.options && field.options.length > 0) {
          const options: SelectItem<string>[] = field.options.map(o => {
            return { label: o, value: o };
          });
          return (
            <RHFSelect
              key={key}
              controllerProps={{
                control,
                name: key,
              }}
              fill
              items={options}
              noSelectionText={position && `${`${position.label} `}Option`}
              disabled={readOnly}
              buttonProps={{
                className: highlightClass,
              }}
            />
          );
        }

        return (
          <RHFTextInput
            key={key}
            controllerProps={{
              control,
              name: key,
            }}
            inputProps={{
              fill: true,
              placeholder: position?.label,
              small: true,
              readOnly,
              className: highlightClass,
            }}
          />
        );
      }
      case RunFieldType.TEXT: {
        return (
          <RHFTextArea
            key={key}
            controllerProps={{
              control,
              name: key,
            }}
            textAreaProps={{
              fill: true,
              placeholder: position?.label,
              small: true,
              readOnly,
              className: highlightClass,
            }}
          />
        );
      }
      case RunFieldType.INT: {
        return (
          <RHFTextInput
            key={key}
            controllerProps={{
              control,
              name: key,
            }}
            inputProps={{
              fill: true,
              placeholder: position?.label,
              small: true,
              readOnly,
              className: highlightClass,
            }}
          />
        );
      }
      case RunFieldType.FLOAT: {
        return (
          // Use an InputGroup (Text Input) rather than Numeric Input to support
          // expressions
          <RHFTextInput
            key={key}
            controllerProps={{
              control,
              name: key,
            }}
            inputProps={{
              fill: true,
              placeholder: position?.label,
              small: true,
              readOnly,
              className: highlightClass,
            }}
          />
        );
      }
      case RunFieldType.BOOLEAN: {
        const cbHighlightClass = baselineValue !== undefined && !!baselineValue !== !!getValues(key) ? styles.highlight : undefined;
        return (
          <RHFCheckbox
            key={key}
            controllerProps={{
              control,
              name: key,
            }}
            checkboxProps={{
              alignIndicator: Alignment.RIGHT,
              label: position && position.label,
              className: classNames(styles.booleanInput, cbHighlightClass),
              disabled: readOnly,
            }}
          />
        );
      }
      default:
        return null;
    }
  };
  const renderTemplateFieldItem = (
    item: RunUITemplateFieldItem,
    runFields: RunField[],
  ) => {
    const field = runFields.find(f => f.name === item.run_field);
    if (!field) return null;

    let inputs: ReactNode[];
    let inputClass;
    let inputStyles: CSSProperties = {};
    // If this field has `positions`, generates one field for each, passing the
    // position to the field render function and modifies the label for relevant
    // display
    if (field.positions && field.positions.length > 0) {
      inputs = field.positions.map(pos => {
        const expandedField = {
          ...field,
          path: getFieldKey(field.name, pos),
        };
        if (field.label) expandedField.label += ` ${pos}`;
        return renderRunField(expandedField, pos);
      });
      inputClass = classNames({
        [styles.grid]: true,
        [styles[`positions${field.positions.length}`]]: isNil(item.num_columns),
      });

      if (!isNil(item.num_columns)) {
        inputStyles = {
          gridTemplateColumns: `repeat(${item.num_columns}, 1fr)`,
        };
      }
    } else {
      inputs = [renderRunField(field, undefined)];
    }

    return (
      <div key={field.name} className={styles.inputRow}>
        <div>
          <span className="bp4-ui-text">{item.label ?? field.label}</span>
          {field.tooltip && (
            <IconTooltip
              className={styles.tooltipIcon}
              content={field.tooltip}
            />
          )}
        </div>
        <div className={classNames(styles.inputs, inputClass)} style={inputStyles}>{inputs}</div>
      </div>
    );
  };

  const renderTemplateItem = (
    path: RunUITemplateItem[],
    runFields: RunField[],
  ): ReactNode => {
    const item = path[path.length - 1];
    if (item.type === RunUITemplateItemType.COLUMN) {
      return (
        <div
          className={styles.sectionContainer}
          key={JSON.stringify(path)} // TODO: Fix this junk?
          style={getColumnGridStyles(item)}
        >
          {item.items.map(innerItem => renderTemplateItem([...path, innerItem], runFields))}
        </div>
      );
    }
    return renderTemplateFieldItem(item, runFields);
  };

  return (
    <Card>
      {!isEmpty(control._defaultValues.runs)
        ? (
          <div className={styles.runHeaderRow}>
            <div className={styles.runNameInput}>
              <span>Run Name</span>
              <RHFTextInput
                key={runIndex}
                controllerProps={{
                  control,
                  name: `runs.${runIndex}.branch.name`,
                }}
                inputProps={{
                  fill: true,
                  placeholder: 'Run Name',
                  small: true,
                  readOnly,
                }}
              />
            </div>
            <div className={styles.runSessionInput}>
              <span>Session</span>
              <RHFTextInput
                key={runIndex}
                controllerProps={{
                  control,
                  name: `runs.${runIndex}.session`,
                }}
                inputProps={{
                  fill: true,
                  placeholder: 'Session',
                  small: true,
                  readOnly: true,
                }}
              />
            </div>
          </div>
        ) : undefined}
      <div
        className={styles.innerContainer}
        style={getColumnGridStyles(template)}
      >
        {template?.items.map(item => renderTemplateItem([item], runFields))}
      </div>
    </Card>
  );
};
