import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Dialog, DialogBody, DialogFooter, Intent } from '@blueprintjs/core';
import classNames from 'classnames';

import {
  CreateRunFieldInput,
  RunField,
  RunFieldPosition,
  RunFieldType,
  RunFieldsDocument,
  UpdateRunFieldInput,
  useCreateRunFieldMutation,
  useUpdateRunFieldMutation,
} from 'graphql/generated/graphql';
import AppToaster from 'helpers/toaster';
import { selectDarkMode } from 'reducers/ui';
import { RunFieldFormInput, RunFieldInput } from 'types';
import RunFieldForm from 'components/RunFieldForm';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (runField: RunField) => void;
  runField?: RunFieldFormInput | null;
}

const defaultRunField: RunFieldInput = {
  type: RunFieldType.STRING,
  name: '',
  label: '',
  tooltip: '',
  positions: [] as RunFieldPosition[],
  options: [] as string[],
};

export default (props: Props) => {
  let defaultValues = defaultRunField;
  if (props.runField) {
    defaultValues = { ...props.runField };
  }

  const darkMode = useSelector(selectDarkMode);
  const form = useForm<RunFieldInput>({ defaultValues });
  useEffect(() => {
    form.reset(defaultValues);
  }, [props.runField]);
  const isEdit = Boolean(props.runField?.id);

  const [createRunField] = useCreateRunFieldMutation();
  const [updateRunField] = useUpdateRunFieldMutation();

  const handleCreateRunField = (input: CreateRunFieldInput) => {
    createRunField({
      variables: { input },
      onCompleted: ({ runField }) => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Run field successfully created',
        });
        form.reset(defaultRunField);
        props.onSuccess(runField as RunField);
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Error creating run field: ${e.message}`,
        });
      },
      update: (cache, { data: mutationData }) => {
        if (mutationData?.runField) {
          cache.updateQuery({
            query: RunFieldsDocument,
          }, queryData => {
            if (!queryData) return undefined;
            return {
              runFields: {
                rows: [
                  ...queryData.runFields.rows,
                  mutationData.runField,
                ],
              },
            };
          });
        }
      },
    });
  };
  const handleUpdateRunField = (input: UpdateRunFieldInput) => {
    updateRunField({
      variables: { input },
      onCompleted: ({ runField }) => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Run field successfully updated',
        });
        form.reset(defaultRunField);
        props.onSuccess(runField as RunField);
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Error updating run field: ${e.message}`,
        });
      },
    });
  };

  const onClose = () => {
    form.reset(defaultRunField);
    props.onClose();
  };
  const onSubmit = (input: RunFieldInput) => {
    if (isEdit) handleUpdateRunField(input as UpdateRunFieldInput);
    else handleCreateRunField(input as CreateRunFieldInput);
  };

  return (
    <Dialog
      className={classNames({ 'bp4-dark': darkMode })}
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={`${isEdit ? 'Edit' : 'Create'} Run Field`}
    >
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <DialogBody>
            <RunFieldForm />
          </DialogBody>
        </form>
      </FormProvider>
      <DialogFooter
        actions={(
          <>
            <Button
              onClick={onClose}
              text="Cancel"
            />
            <Button
              intent={Intent.PRIMARY}
              onClick={form.handleSubmit(onSubmit)}
              key="submit"
              text="Save"
            />
          </>
        )}
      />
    </Dialog>
  );
};
