import { createRef, useEffect, useMemo, useState, forwardRef, useImperativeHandle, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { json } from '@codemirror/lang-json';
import { useCodeMirror } from '@uiw/react-codemirror';
import { eclipse } from '@uiw/codemirror-theme-eclipse';
import { vscodeDark } from '@uiw/codemirror-theme-vscode';
import { classname } from '@uiw/codemirror-extensions-classname';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBracketsCurly } from '@fortawesome/pro-solid-svg-icons';
import { Button, Divider, FormGroup, Intent } from '@blueprintjs/core';
import { get, isUndefined } from 'lodash';

import { specSelectItems } from '../../constants';
import PropertiesList from './PropertiesList';
import {
  RHFDateInput,
  RHFNumericInput,
  RHFSelect,
  RHFTextInput,
} from 'components/RHFInputs';
import { PartConfig, usePartConfigsQuery } from 'graphql/generated/graphql';
import { selectDarkMode } from 'reducers/ui';

import styles from './index.module.css';

const defaultPartDataStr = '{\n  \n}';

const PartForm = forwardRef((props, ref) => {
  const [searchParams] = useSearchParams();
  const darkMode = useSelector(selectDarkMode);
  const dataEditor = createRef<HTMLDivElement>();

  const { control, formState: { errors }, getValues, setValue, watch } = useFormContext();
  const partId = getValues('id');
  const partData = getValues('data');
  let partDataStr = defaultPartDataStr;
  if (partData) partDataStr = JSON.stringify(partData, null, 2);
  const partConfigId = watch('part_config_id');
  const spec = watch('spec');
  const data = watch('data');

  const [partConfigs, setPartConfigs] = useState<PartConfig[]>([]);
  const [selectedConfig, setSelectedConfig] = useState<PartConfig>();
  const [dataStr, setDataStr] = useState<string>(partDataStr);
  const [dataErrors, setDataErrors] = useState<Error[] | null>(null);

  // Expose `getData` to the parent component via ref
  useImperativeHandle(ref, () => ({
    getData: () => {
      try {
        // Attempt to parse the JSON and return it
        return JSON.parse(dataStr);
      } catch (error) {
        return null;
      }
    },
  }));

  const setDataStrIfDifferent = useCallback((newDataStr: string) => {
    if (dataStr !== newDataStr) {
      setDataStr(newDataStr);
    }
  }, [dataStr]);

  const specProperties = useMemo(() => {
    return selectedConfig?.properties.filter(p => {
      return p.specs?.length === 0 || p.specs?.includes(spec);
    }) ?? [];
  }, [selectedConfig, spec]);

  usePartConfigsQuery({
    onCompleted: data => setPartConfigs(data.partConfigs.rows as PartConfig[]),
  });

  const classnameExt = classname({
    add: (lineNumber: number) => {
      // Only need to check the first element here -- if JSON.parse fails, it
      // fails after finding the first error
      if (dataErrors?.[0] instanceof SyntaxError) {
        const res = dataErrors[0].message.match(/line (?<lineNumber>\d+)/);
        if (lineNumber === Number(res?.groups?.lineNumber)) {
          return styles.errorLine;
        }
      }
      return undefined;
    },
  });
  const { setContainer: setDataContainer } = useCodeMirror({
    height: '100%',
    theme: darkMode ? vscodeDark : eclipse,
    container: dataEditor.current,
    extensions: [
      json(),
      classnameExt,
    ],
    onChange: setDataStr,
    value: dataStr,
  });

  useEffect(() => {
    if (dataEditor.current) setDataContainer(dataEditor.current);
  }, [dataEditor.current]);

  useEffect(() => {
    try {
      const dataObj = JSON.parse(dataStr);
      if (typeof dataObj !== 'object' || Array.isArray(dataObj)) {
        throw new Error('Data must be an object (begin with { and end with })');
      }

      // Searches for data paths conflicting with property paths and throws
      // errors for them
      const conflictingProperties = specProperties.filter(p => {
        const path = p.path ?? p.property.path ?? p.property.name;
        const value = get(dataObj, path);
        return !isUndefined(value);
      });
      if (conflictingProperties.length > 0) {
        setDataErrors(conflictingProperties.map(p => {
          const path = p.path ?? p.property.path ?? p.property.name;
          return new Error(`Data path "${path}" conflicts with path for existing part property "${p.property.display_name}"`);
        }));
      } else {
        setDataErrors(null);
      }
    } catch (e) {
      setDataErrors([e as Error]);
    }
  }, [dataStr, specProperties]);

  useEffect(() => {
    const currentFormValue = JSON.stringify(data, null, 2);
    setDataStrIfDifferent(currentFormValue);
  }, [data]);

  useEffect(() => {
    const configIdStr = searchParams.get('config');
    if (configIdStr) {
      const configId = Number(configIdStr);
      const configFromParams = partConfigs.find(c => c.id === configId);
      if (configFromParams) {
        setSelectedConfig(configFromParams);
        setValue('part_config_id', configId);
      }
    }
  }, [partConfigs, searchParams]);

  useEffect(() => {
    const selectedConfig = partConfigs.find(p => p.id === partConfigId);
    if (selectedConfig) {
      setSelectedConfig(selectedConfig);
      if (!partId) {
        setValue('properties', selectedConfig.properties?.map(p => ({
          part_config_property_id: p.id,
          config_property: p,
          value: undefined,
        })) ?? []);
      }
    }
  }, [partConfigs, partConfigId]);

  const onPrettyPrintClicked = () => {
    setDataStr(JSON.stringify(JSON.parse(dataStr), null, 2));
  };

  const partConfigItems = partConfigs.map(p => ({
    label: p.display_name,
    value: p.id,
  })) ?? [];

  return (
    <div className={styles.mainForm}>
      <div className={styles.columnsContainer}>
        <div className={styles.inputsColumn}>
          <FormGroup
            helperText={<ErrorMessage errors={errors} name="part_config_id" />}
            intent={errors.part_config_id ? Intent.DANGER : Intent.NONE}
            label="Config"
            labelInfo="(required)"
          >
            <RHFSelect
              controllerProps={{
                control,
                name: 'part_config_id',
                rules: {
                  required: 'Config is required',
                },
              }}
              disabled={partId}
              intent={errors.part_config_id && Intent.DANGER}
              items={partConfigItems}
              selectProps={{
                popoverTargetProps: { className: styles.partConfigSelectTarget },
              }}
            />
          </FormGroup>
          <FormGroup
            helperText={<ErrorMessage errors={errors} name="spec" />}
            intent={errors.spec ? Intent.DANGER : Intent.NONE}
            label="Spec"
            labelInfo="(required)"
          >
            <RHFSelect
              controllerProps={{
                control,
                name: 'spec',
                rules: {
                  required: 'Spec is required',
                },
              }}
              intent={errors.spec && Intent.DANGER}
              items={specSelectItems}
            />
          </FormGroup>
          <FormGroup
            helperText={<ErrorMessage errors={errors} name="description" />}
            label="Description"
            labelInfo="(required)"
            intent={errors.description ? Intent.DANGER : Intent.NONE}
          >
            <RHFTextInput
              controllerProps={{
                control,
                name: 'description',
                rules: {
                  required: 'Description is required',
                },
              }}
              inputProps={{
                intent: errors.description && Intent.DANGER,
              }}
            />
          </FormGroup>
          <FormGroup
            helperText={<ErrorMessage errors={errors} name="part_number" />}
            intent={errors.part_number ? Intent.DANGER : Intent.NONE}
            label="Part Number"
          >
            <RHFTextInput
              controllerProps={{
                control,
                name: 'part_number',
              }}
              inputProps={{
                intent: errors.part_number && Intent.DANGER,
              }}
            />
          </FormGroup>
          <FormGroup
            helperText={<ErrorMessage errors={errors} name="serial_number" />}
            intent={errors.serial_number ? Intent.DANGER : Intent.NONE}
            label="Serial Number"
          >
            <RHFTextInput
              controllerProps={{
                control,
                name: 'serial_number',
              }}
              inputProps={{
                intent: errors.serial_number && Intent.DANGER,
              }}
            />
          </FormGroup>
          {selectedConfig?.expires && (
            <FormGroup
              helperText={<ErrorMessage errors={errors} name="exp_date" />}
              label="Expiration Date"
              labelInfo="yyyy-mm-dd"
            >
              <RHFDateInput
                controllerProps={{
                  control,
                  name: 'exp_date',
                  rules: {
                    pattern: {
                      value: /\d{4}-\d{2}-\d{2}/,
                      message: 'Expiration Date must be in the format yyyy-mm-dd',
                    },
                  },
                }}
                intent={errors.exp_date && Intent.DANGER}
              />
            </FormGroup>
          )}
          <FormGroup
            helperText={<ErrorMessage errors={errors} name="mileage" />}
            intent={errors.mileage ? Intent.DANGER : Intent.NONE}
            label="Mileage"
          >
            <RHFNumericInput
              controllerProps={{
                control,
                name: 'mileage',
                rules: {
                  min: {
                    value: 0,
                    message: 'Mileage cannot be less than zero',
                  },
                },
              }}
              inputProps={{
                fill: true,
                intent: errors.mileage && Intent.DANGER,
              }}
            />
          </FormGroup>
          <PropertiesList />
        </div>
        <Divider />
        <div className={styles.editorColumn}>
          <FormGroup
            className={styles.editorGroup}
            contentClassName={styles.editorContent}
            helperText={dataErrors?.map(e => <p>{e.message}</p>)}
            label="Data"
            intent={dataErrors ? Intent.DANGER : Intent.NONE}
          >
            <div className={styles.dataEditor}>
              <div
                className={styles.editorContainer}
                id="dataEditor"
                ref={dataEditor}
              />
              <Button
                className={styles.prettyPrintButton}
                icon={<FontAwesomeIcon icon={faBracketsCurly} />}
                minimal
                onClick={onPrettyPrintClicked}
                outlined
                title="Auto-format data"
              />
            </div>
          </FormGroup>
        </div>
      </div>
    </div>
  );
});

export default PartForm;
