import { Dialog, DialogBody, DialogFooter, Button, FormGroup, Intent } from '@blueprintjs/core';
import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import { RHFTextInput } from 'components/RHFInputs';
import { CreateRUITInput } from 'graphql/generated/graphql';
import { useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectDarkMode } from 'reducers/ui';

interface Props {
  isOpen: boolean,
  onClose: () => void,
  onOk: (input: CreateRUITInput) => void,
}

const CreateRUITDialog = (props: Props) => {
  const darkMode = useSelector(selectDarkMode);
  const nameRef = useRef<HTMLInputElement>(null);
  const form = useForm<Partial<CreateRUITInput>>();
  const { reset } = form;

  const onSubmit = (input: Partial<CreateRUITInput>) => {
    props.onOk(input as CreateRUITInput);
    reset();
    props.onClose();
  };

  return (
    <Dialog
      className={classNames({ 'bp4-dark': darkMode })}
      isCloseButtonShown
      isOpen={props.isOpen}
      onClose={props.onClose}
      title="New RUIT"
      onOpened={() => nameRef.current?.focus()}
      style={{ width: '50%' }}
    >
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <DialogBody>
            <FormGroup
              helperText={<ErrorMessage errors={form.formState.errors} name="name" />}
              label="Name"
              labelInfo="(required)"
            >
              <RHFTextInput
                controllerProps={{
                  control: form.control as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                  name: 'name',
                  rules: { required: 'Name is required' },
                }}
                inputProps={{
                  intent: form.formState.errors.name && Intent.DANGER,
                }}
              />
            </FormGroup>
            <FormGroup label="Description">
              <RHFTextInput
                controllerProps={{
                  control: form.control as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                  name: 'description',
                }}
              />
            </FormGroup>
          </DialogBody>
          <DialogFooter
            actions={[
              <Button
                text="Cancel"
                onClick={props.onClose}
              />,
              <Button
                intent="primary"
                text="OK"
                type="submit"
              />]}
          />
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default CreateRUITDialog;
