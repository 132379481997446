import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ColumnDef, ColumnFiltersState, SortingState, createColumnHelper } from '@tanstack/react-table';
import { Intent, Dialog, DialogBody, DialogFooter, Button, InputGroup } from '@blueprintjs/core';
import classNames from 'classnames';
import { isNil, debounce, mapValues, keyBy } from 'lodash';
import { format } from 'date-fns';

import { useAlert } from 'components/Alert';
import Table, { ParamsChangeFn, RowActions } from 'components/Table';
import {
  useSetupSummaryLazyQuery,
  useDeleteBranchMutation,
  useCloneSetupMutation,
  SetupSummaryGetBranchesDocument,
  SetupsQueryInput,
} from 'graphql/generated/graphql';
import apolloClient from '../../graphql';
import AppToaster from 'helpers/toaster';
import { selectDarkMode } from 'reducers/ui';
import { GQLSetup, GQLSetupHead } from 'types';

type SetupTableRow = GQLSetup & {
  branch: {
    id: number,
    name: string,
  },
  numBranches?: number | null;
  subRows?: SetupTableRow[];
}

const GQLBranchHeadToSetupTableRow = (item: GQLSetupHead) => {
  const { numBranches, setup, branch } = item;
  return {
    ...setup,
    branch: {
      id: branch.id,
      name: branch.name,
    },
    numBranches,
  };
};

export default () => {
  const darkMode = useSelector(selectDarkMode);
  const [cloneSource, setCloneSource] = useState<GQLSetup>();
  const [isCloneModalOpen, setCloneModalOpen] = useState(false);
  const cloneName = useRef<HTMLInputElement>(null);
  const [setupRows, setSetupRows] = useState<SetupTableRow[]>([]);
  const [tableFilters, setTableFilters] = useState<ColumnFiltersState>();
  const [tableSort, setTableSort] = useState<SortingState>();
  const [
    getSetups, {
      data: setupsData,
    }] = useSetupSummaryLazyQuery();
  const [deleteBranch] = useDeleteBranchMutation();
  const [cloneSetup] = useCloneSetupMutation();
  const navigate = useNavigate();
  const alert = useAlert();
  const columnHelper = createColumnHelper<SetupTableRow>();
  const columns = [
    columnHelper.accessor('name', {
      header: 'Name',
      cell: info => info.getValue(),
      enableColumnFilter: true,
    }),
    columnHelper.accessor('year', {
      header: 'Year',
      cell: info => info.getValue(),
      enableColumnFilter: true,
      size: 100,
    }),
    columnHelper.accessor('car', {
      header: 'Car',
      cell: info => info.getValue(),
      enableColumnFilter: true,
      size: 100,
    }),
    columnHelper.accessor('description', {
      header: 'Description',
      cell: info => info.getValue(),
      enableColumnFilter: true,
      enableSorting: true,
    }),
    columnHelper.accessor('event', {
      header: 'Event',
      cell: info => info.getValue(),
      enableColumnFilter: true,
      size: 100,
    }),
    columnHelper.accessor('owner', {
      header: 'Owner',
      cell: info => info.getValue(),
      enableColumnFilter: true,
    }),
    columnHelper.accessor('branch', {
      header: 'Branch',
      cell: info => info.getValue().name,
      enableColumnFilter: true,
    }),
    columnHelper.accessor('created_at', {
      header: 'Created',
      cell: info => {
        const value = info.getValue() as string;
        return format(new Date(value), 'MM/dd/yy HH:mm:ss');
      },
    }),
    columnHelper.accessor('updated_at', {
      header: 'Modified',
      cell: info => {
        const value = info.getValue() as string;
        return format(new Date(value), 'MM/dd/yy HH:mm:ss');
      },
    }),
  ] as ColumnDef<SetupTableRow>[];

  const rowActions: RowActions<SetupTableRow> = [{
    label: 'Edit',
    value: row => {
      navigate(`/setup/${row.original.branch.id}`);
    },
  }, {
    label: 'Clone',
    value: row => {
      setCloneSource(row.original);
      setCloneModalOpen(true);
      cloneName.current?.focus();
    },
  }, {
    intent: Intent.DANGER,
    label: 'Delete',
    value: row => {
      const branchId = row.original.branch?.id;
      if (!branchId) return;
      alert.showAlert(`Delete branch "${row.original.branch.name}" of "${row.original.name}"?`, {
        intent: Intent.DANGER,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
      }).then((yes) => {
        if (!yes) return;
        deleteBranch({
          variables: {
            id: branchId,
          },
          onCompleted: () => {
            AppToaster.show({
              intent: Intent.SUCCESS,
              message: 'Successfully deleted setup',
            });
          },
          onError: e => {
            AppToaster.show({
              intent: Intent.DANGER,
              message: `Failed to delete setup: ${e.message}`,
            });
          },
          refetchQueries: ['SetupSummary'],
        });
      });
    },
  }];

  /**
   *
   * @param mainRowId id of a main branch's row
   * @param allMainRows all main branch rows
   * @returns Promise of getting updating subrows of a row
   */
  const querySubRowsData = (mainRowId: number, allMainRows: SetupTableRow[]) => {
    const mainHead = allMainRows.find(row => row.id === mainRowId);
    if (!mainHead || !mainHead.root?.id) return Promise.reject(new Error('Could not find main head'));

    const queryInput: SetupsQueryInput = {
      filters: mapValues(keyBy(tableFilters, 'id'), 'value'),
    };
    if (tableSort && tableSort.length > 0) {
      queryInput.sorts = { [tableSort[0].id]: tableSort[0].desc ? 'DESC' : 'ASC' };
    }

    return apolloClient.query({
      query: SetupSummaryGetBranchesDocument,
      variables: {
        rootId: mainHead.root?.id,
        input: queryInput,
      },
      fetchPolicy: 'no-cache',
    });
  };

  useEffect(() => {
    if (!setupsData?.setupBranchHeads.rows) return;
    const newData = setupsData?.setupBranchHeads.rows as GQLSetupHead[];
    const translatedNewData: SetupTableRow[] = newData.map(data => GQLBranchHeadToSetupTableRow(data));

    // copy subrows from old to new
    const modifiedRows = translatedNewData.map(r => {
      const existingRow = setupRows.find(sr => sr.id === r.id);
      if (existingRow) return { ...r, subRows: existingRow.subRows };
      return r;
    });

    setSetupRows(modifiedRows);

    // update subrows with sorting and filtering
    const getBranchPromises: ReturnType<typeof querySubRowsData>[] = [];
    const rowsToProcess: number[] = [];
    modifiedRows.forEach((mainHead, index) => {
      if (!mainHead.root?.id || mainHead.subRows?.length === 0) return;
      rowsToProcess.push(index);
      const promise = querySubRowsData(mainHead.id, modifiedRows);
      if (!isNil(promise)) getBranchPromises.push(promise);
    });

    Promise.all(getBranchPromises)
      .then(allData => {
        allData.forEach((data, index) => {
          const setupHeads = data.data.setupBranchHeadsByRootId.rows as GQLSetupHead[];
          modifiedRows[rowsToProcess[index]].subRows = setupHeads.filter(sh => sh.branch?.name !== 'main').map(item => GQLBranchHeadToSetupTableRow(item));
        });
        setSetupRows([...modifiedRows]);
      })
      .catch(e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Subrow error: ${e}`,
        });
      });
  }, [setupsData]);

  useEffect(() => {
    const onEnter = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleClone();
      }
    };

    if (isCloneModalOpen) {
      document.addEventListener('keydown', onEnter);
    }

    return () => {
      document.removeEventListener('keydown', onEnter);
    };
  }, [isCloneModalOpen]);

  const handleClone = () => {
    if (!cloneSource) return;
    cloneSetup({
      variables: {
        id: cloneSource.id,
        name: cloneName.current?.value || `${cloneSource.name}@${Date.now()}`,
      },
      onCompleted: () => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Successfully cloned setup',
        });
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Failed to clone setup: ${e.message}`,
        });
      },
      refetchQueries: ['SetupSummary'],
    });
    setCloneModalOpen(false);
  };

  const onTableParamsChange: ParamsChangeFn = async (filters, pagination, sorting) => {
    const { pageIndex, pageSize } = pagination;

    const queryInput: SetupsQueryInput = {
      filters: mapValues(keyBy(filters, 'id'), 'value'),
    };
    if (sorting.length > 0) {
      queryInput.sorts = { [sorting[0].id]: sorting[0].desc ? 'DESC' : 'ASC' };
    }
    if (!isNil(pageIndex) && !isNil(pageSize)) {
      queryInput.pagination = {
        offset: pageIndex * pageSize,
        limit: pageSize,
      };
    }

    setTableFilters(filters);
    setTableSort(sorting);
    getSetups({ variables: { input: queryInput }, fetchPolicy: 'no-cache' });
  };
  const debouncedOnTableParamsChange = debounce(onTableParamsChange, 200);

  return (
    <div>
      <Table
        id="setup-summary"
        columns={columns}
        data={setupRows}
        rowActions={rowActions}
        enableHiding
        persistColumnVisibility
        enablePagination
        manualPagination
        manualSorting
        manualFiltering
        onParamsChange={debouncedOnTableParamsChange as ParamsChangeFn}
        totalRowCount={setupsData?.setupBranchHeads.totalCount || 0}
        getRowCanExpand={(row) => {
          if (!row.original.numBranches) return false;
          return row.original.numBranches > 1;
        }}
        onExpandedChange={(expandedChanges) => {
          expandedChanges.forEach(ec => {
            if (!ec.rowData.root?.id || ec.rowData.subRows?.length === 0) return;
            querySubRowsData(ec.rowData.id, setupRows)
              .then(data => {
                const subRows = data.data.setupBranchHeadsByRootId.rows
                  .filter((row: GQLSetupHead) => row.branch?.name !== 'main')
                  .map((item: GQLSetupHead) => GQLBranchHeadToSetupTableRow(item));
                const newSetupRows = setupRows.map(row => (
                  row.id === ec.rowData.root?.id
                    ? {
                      ...row,
                      subRows,
                    }
                    : row
                ));
                setSetupRows([...newSetupRows]);
              });
          });
        }}
        getSubRows={(row) => {
          return row.subRows || [];
        }}
      />
      <Dialog
        className={classNames({ 'bp4-dark': darkMode })}
        isCloseButtonShown
        isOpen={isCloneModalOpen}
        onClose={() => setCloneModalOpen(false)}
        title={`Cloning from "${cloneSource?.name}"`}
        onOpened={() => cloneName.current?.focus()}
      >
        <DialogBody>
          <InputGroup
            placeholder="Enter new setup name"
            inputRef={cloneName}
            defaultValue={`${cloneSource?.name} CLONE`}
          />
        </DialogBody>
        <DialogFooter
          actions={(
            <Button
              intent="primary"
              text="OK"
              onClick={() => handleClone()}
            />
          )}
        />
      </Dialog>
    </div>
  );
};
