import { FormProvider, useForm } from 'react-hook-form';
import { Button, H3, Intent } from '@blueprintjs/core';
import { omit } from 'lodash';

import PartConfigForm from 'components/PartConfigForm';
import {
  CreatePartConfigInput,
  useCreatePartConfigMutation,
} from 'graphql/generated/graphql';
import AppToaster from 'helpers/toaster';

import styles from './index.module.css';
import { useNavigate } from 'react-router-dom';

const defaultPartConfig = {
  name: '',
  display_name: '',
  expires: false,
  properties: [],
};

export default () => {
  const navigate = useNavigate();
  const form = useForm<CreatePartConfigInput>({ defaultValues: defaultPartConfig });
  const [createPartConfig] = useCreatePartConfigMutation();

  const onSubmit = (input: CreatePartConfigInput) => {
    createPartConfig({
      variables: {
        input: {
          ...input,
          properties: input.properties?.map(p => omit(p, 'property')) ?? [],
        },
      },
      onCompleted: data => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Part config successfully created',
        });
        if (data.partConfig) navigate(`/parts/configs/${data.partConfig.id}`);
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Error creating part config: ${e.message}`,
        });
      },
    });
  };

  return (
    <>
      <H3>Create Part Config</H3>
      <FormProvider {...form}>
        <form
          className={styles.form}
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <PartConfigForm />
          <Button
            className={styles.saveButton}
            icon="floppy-disk"
            intent={Intent.PRIMARY}
            large
            text="Save"
            type="submit"
          />
        </form>
      </FormProvider>
    </>
  );
};
