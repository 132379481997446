import { useFormContext, useWatch } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { FormGroup, Intent } from '@blueprintjs/core';

import { runFieldTypeSelectItems } from '../../constants';
import RHFSelect from 'components/RHFInputs/Select';
import RHFTagInput from 'components/RHFInputs/TagInput';
import RHFTextInput from 'components/RHFInputs/TextInput';
import { RunFieldType } from 'graphql/generated/graphql';
import Positions from './Positions';

import styles from './index.module.css';
import IconTooltip from 'components/IconTooltip';

export default () => {
  const { control, formState: { errors } } = useFormContext();
  const type = useWatch({ control, name: 'type' });

  return (
    <>
      <FormGroup
        contentClassName={styles.selectFormGroup}
        helperText={<ErrorMessage errors={errors} name="type" />}
        label="Type"
        labelInfo="(required)"
      >
        <RHFSelect
          controllerProps={{
            control,
            name: 'type',
            rules: { required: 'Type is required' },
          }}
          intent={errors.type && Intent.DANGER}
          items={runFieldTypeSelectItems}
        />
      </FormGroup>
      <FormGroup
        helperText={<ErrorMessage errors={errors} name="name" />}
        label="Name"
        labelInfo={(
          <>
            <IconTooltip
              content={`
                The name that will be specified in RUITs to include this
                field. It should be lowercase with no spaces, ideally using
                underscores between words.
              `}
            />
            <span> (required)</span>
          </>
        )}
      >
        <RHFTextInput
          controllerProps={{
            control,
            name: 'name',
            rules: {
              required: 'Name is required',
              pattern: {
                value: /^\S*$/,
                message: 'Name must not contain spaces',
              },
            },
          }}
          inputProps={{
            intent: errors.name && Intent.DANGER,
          }}
        />
      </FormGroup>
      <FormGroup
        helperText={<ErrorMessage errors={errors} name="label" />}
        label="Label"
        labelInfo="(required)"
      >
        <RHFTextInput
          controllerProps={{
            control,
            name: 'label',
            rules: { required: 'Label is required' },
          }}
          inputProps={{
            intent: errors.label && Intent.DANGER,
          }}
        />
      </FormGroup>
      <FormGroup
        helperText={<ErrorMessage errors={errors} name="tooltip" />}
        label="Tooltip"
        labelInfo={(
          <IconTooltip content="Text shown in a tooltip when hovering this field" />
        )}
      >
        <RHFTextInput
          controllerProps={{
            control,
            name: 'tooltip',
          }}
          inputProps={{
            intent: errors.tooltip && Intent.DANGER,
          }}
        />
      </FormGroup>
      <FormGroup label="Positions">
        <Positions />
      </FormGroup>
      {type === RunFieldType.STRING && (
        <FormGroup
          label="Options"
          labelInfo={(
            <IconTooltip
              content={`
                Optional. When specified, options limit the values an input can
                have by rendering a dropdown with the specified values rather
                than a text input.
              `}
            />
          )}
        >
          <RHFTagInput
            controllerProps={{
              control,
              name: 'options',
            }}
          />
        </FormGroup>
      )}
    </>
  );
};
